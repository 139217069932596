import { CommonModule, DatePipe } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormFieldsModule } from '@upr-web-primer/form-fields';
import { GoogleRecaptchaModule } from '@upr-web-primer/google-recaptcha';
import { InheritStyleModule } from '@upr-web-primer/inherit-style';
import { LocaleModule } from '@upr-web-primer/locale';
import { ModalModule } from '@upr-web-primer/modal';
import { MsUtilsModule } from '@upr-web-primer/ms-utils';
import { UprSvgModule } from '@upr-web-primer/svg';
import { TcmAnchorModule } from '@upr-web-primer/tcm-anchor';
import { TcmImageModule } from '@upr-web-primer/tcm-image';
import { TcmPictureModule } from '@upr-web-primer/tcm-picture';
import { TooltipModule } from '@upr-web-primer/tooltip';
import { UrlInfoModule } from '@upr-web-primer/url-info';
import { WindowModule } from '@upr-web-primer/window';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SharedModule } from '../shared/shared-module';
import { TimeFormatPipe } from '../shared/time.pipe';
import { CartNavigationComponent } from './cart-navigation/cart-navigation.component';
import { ForgotMyPasswordComponent } from './forgot-my-password/forgot-my-password.component';
import { InquiryWebFormComponent } from './inquiry-web-form/inquiry-web-form.component';
import { ConfirmLinkTicketsComponent } from './link-ticket/confirm-link-tickets/confirm-link-tickets.component';
import { LinkTicketComponent } from './link-ticket/link-ticket.component';
import { RegisteredLinkTicketsComponent } from './link-ticket/registered-link-tickets/registered-link-tickets.component';
import { RegistrationLinkTicketsComponent } from './link-ticket/registration-link-tickets/registration-link-tickets.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { LostFoundWebFormComponent } from './lost-found-web-form/lost-found-web-form.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PastTicketsComponent } from './past-tickets/past-tickets.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { UnsubscribeEmailComponent } from './unsubscribe-email/unsubscribe-email.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
@NgModule({
  imports: [
    CommonModule,
    WindowModule,
    LocaleModule,
    UrlInfoModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TcmAnchorModule,
    TcmImageModule,
    TcmPictureModule,
    ModalModule,
    GoogleRecaptchaModule,
    TooltipModule,
    FormsModule,
    MsUtilsModule,
    FormFieldsModule,
    InheritStyleModule,
    UprSvgModule,
    SharedModule,
    ZXingScannerModule,
    SharedModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [
    TimeFormatPipe,
    LoginComponent,
    UpdatePasswordComponent,
    ForgotMyPasswordComponent,
    PageNotFoundComponent,
    TicketDetailsComponent,
    RegistrationLinkTicketsComponent,
    LinkTicketComponent,
    ConfirmLinkTicketsComponent,
    RegisteredLinkTicketsComponent,
    PastTicketsComponent,
    UnsubscribeEmailComponent,
    InquiryWebFormComponent,
    CartNavigationComponent,
    LostFoundWebFormComponent,
    LogoutComponent,
    SignInComponent,
  ],
  exports: [TimeFormatPipe],
  providers: [Title, DatePipe],
})
export class AccountModule {}
