import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@upr-web-primer/spinner';
import { WINDOW } from '@upr-web-primer/window';
import { appRoutes } from '../../../config/app-default.constant';
import { defaultValues } from '../../../config/defaultValues.constant';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  pageLabels: any;
  @ViewChild('birthDayContainer', { read: ViewContainerRef, static: true })
  birthDayContainer!: ViewContainerRef;
  constructor(
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    private commonUtilityService: CommonUtilityService,
    private spinner: SpinnerService,
    public router: Router,
    public route: ActivatedRoute,
    @Inject(WINDOW) private window: Window,
    @Inject('NotFoundPage') private notFoundPage: string
  ) {
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
  }
  ngOnInit(): void {
    const url = this.route.snapshot.url;
    if (!url || url.length === 0) {
      const defaultLogin = [
        defaultValues.DEFAULT_ROUTE_LANGUAGE,
        defaultValues.DEFAULT_ROUTE_COUNTRY,
        appRoutes.LOGIN,
      ].join('/');
      if (
        this.window?.location?.origin?.includes('web-local.use.ucdp.net') &&
        !this.window?.location?.origin?.includes('web-local.use.ucdp.net:4202')
      ) {
        return;
      }
      this.router.navigateByUrl(defaultLogin);
      return;
    }
    let defaultUrl = url.join('/');
    if (defaultUrl.indexOf('.html') === -1) {
      defaultUrl += '/index.html';
    }
    if (
      this.window.location.origin.includes('localhost') ||
      this.window.location.pathname.includes(this.notFoundPage)
    ) {
      this.getContent();
      return;
    }
    const notFoundUrl =
      [
        defaultValues.DEFAULT_ROUTE_LANGUAGE,
        defaultValues.DEFAULT_ROUTE_COUNTRY,
      ].join('/') + this.notFoundPage;
    this.commonUtilityService.changeUrl(notFoundUrl);
  }

  /**
   * goToPage - It will redirect to the specified route
   * @param type : specify which route to navigate
   */

  goToPage(type: any): any {
    switch (type) {
      case this.globalDefault.BIRTHDAY_DISCOUNT:
        this.commonUtilityService.changeRoute(this.appRoutes.DASHBOARD);
        break;
    }
  }

  goToHomepage(): void {
    this.commonUtilityService.changeRoute(this.appRoutes.DASHBOARD);
  }

  getContent(): void {
    this.spinner.show();
    this.commonUtilityService
      .fetchTridionContent('birthday-content')
      ?.subscribe((data: any) => {
        if (data && data.length > 0) this.initializeUI(data[0].elements);
      });
  }
  initializeUI(elements: any): void {
    this.birthDayContainer?.clear();
    this.spinner.hide();
    this.commonUtilityService.loadTridionUtility(
      this.birthDayContainer,
      elements
    );
  }
}
