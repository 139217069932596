import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { CommonUtilityService } from '../../services/common-utility.service';

@Injectable({
  providedIn: 'root',
})
export class PageResolver implements Resolve<any> {
  constructor(private common: CommonUtilityService) {}

  resolve(): Observable<any> {
    return this.resolvepagelabelData();
  }

  private resolvepagelabelData(): Observable<any> {
    let pagelabelData = this.common.getResolvedPageData();
    if (pagelabelData) {
      return pagelabelData;
    } else {
      return this.common.getPageLabels().pipe(
        tap((data: any) => {
          this.common.setResolvedPageData(data);
          pagelabelData = data; // Set pagelabelData on successful API call
        })
      );
    }
  }
}
