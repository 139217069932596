import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TcmService } from '@upr-web-primer/tcm-service';
import { UrlInfo, UrlInfoService } from '@upr-web-primer/url-info';
import { get } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class TcmPageResolver implements Resolve<any> {
  language: string;

  constructor(private tridion: TcmService, private urlInfo: UrlInfoService) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    const urlInfo = this.urlInfo.get(route);
    const tridionUrl = this.buildTridionUrl(urlInfo);
    return this.tridion.request(tridionUrl);
  }

  private buildTridionUrl(urlInfo: UrlInfo): string {
    let hasInterest = get(urlInfo, 'interest');
    let urlArr = [
      urlInfo.publication,
      urlInfo.language,
      urlInfo.country,
      urlInfo.slug,
    ];
    if (!urlInfo.slug && urlInfo.path) {
      urlArr = [
        urlInfo.publication,
        decodeURIComponent(urlInfo.path).replace(/([^\?]+)(\?.*)/i, '$1'),
      ];
    }
    if (hasInterest) {
      hasInterest = hasInterest.replace('.html', '');
      urlArr.push(`${hasInterest}.html`);
    } else if (urlInfo.path.indexOf('.html') < 0) {
      urlArr.push('index.html');
    }
    return urlArr.filter(Boolean).join('/');
  }
}
