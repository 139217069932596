import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-logout',
  template: '',
  styles: [],
})
export class LogoutComponent implements OnInit {
  constructor(
    private router: ActivatedRoute,
    private commonUtilityService: CommonUtilityService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    /**
     * logout - to logout from the application and redirect to login
     */
    this.router.queryParams.subscribe((params) => {
      if (params.hasOwnProperty('signoff') && params.signoff === 'true') {
        localStorage.clear();
        sessionStorage.clear();
        this.cookieService.deleteAll('/', environment.cookieSubDomain);
        const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
        this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
      }
    });
  }
}
