<div class="container" *ngIf="!returnValue">
  <!--LOGIN PAGE-->
  <div class="row">
    <div class="columns small-12">
      <gds-heading
        [data]="{
          heading: 'Sign In To Your Account'
        }"
        classes="heading-1"
      >
      </gds-heading>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <div class="align-left">
        <div class="text-contain">
          <gds-heading
            class="A3-W6"
            classes="heading-8"
            [data]="{
              heading: 'New ?'
            }"
          ></gds-heading>
        </div>
        <!--create new account-->
        <gds-button
          [data]="{
            type: 'tertiary',
            href: '',
            label: 'Create Account',
            disabled: false,
            target: ''
          }"
          (buttonClicked)="signUp()"
        >
        </gds-button>
      </div>
      <div class="align-left">
        <div class="text-contain">
          <gds-heading
            class="A3-W6"
            classes="heading-8"
            [data]="{
              heading: 'Vaction Package'
            }"
          ></gds-heading>
        </div>
        <gds-button
          [data]="{
            type: 'tertiary',
            href: '',
            label: 'Sign In',
            disabled: false,
            target: ''
          }"
        >
        </gds-button>
      </div>
      <gds-heading
        class="A3-W6"
        classes="heading-12"
        [data]="{
          heading: 'All fields are required, unless noted as optional.'
        }"
      ></gds-heading>
      <!--Login Form-->
      <div [formGroup]="loginForm">
        <gds-input
          id="email"
          formControlName="email"
          ngDefaultControl
          [data]="{
            primaryLabel: 'Email*',
            supportingText: '',
            tooltip: '',
            type: 'email',
            altText: 'input of surname',
            ariaText: '',
            required: true
          }"
        >
        </gds-input>

        <gds-spacer classes="spacer-20"></gds-spacer>
        <gds-input
          id="passWord"
          formControlName="passWord"
          ngDefaultControl
          [data]="{
            primaryLabel: 'Password*',
            supportingText: '',
            tooltip: '',
            type: 'password',
            altText: 'input of surname',
            ariaText: '',
            required: true
          }"
        >
        </gds-input>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <gds-button
          [data]="{
            type: 'tertiary',
            href: '',
            label: 'Forget My Password',
            target: ''
          }"
          (buttonClicked)="onLoginSubmit()"
        >
        </gds-button>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <gds-input
          id="termCondition"
          ngDefaultControl
          [data]="{
            primaryLabel: 'Remember Me',
            secondaryText: '',
            primaryIconTitleText: '',
            type: 'checkbox',
            altText: 'input of re-enter password',
            ariaText: ''
          }"
          (valueChange)="onValueChange($event)"
        >
        </gds-input>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <div class="align-left">
          <gds-button
            [data]="{
              type: 'primary',
              href: '',
              label: 'SIGN IN',
              target: ''
            }"
            (buttonClicked)="onLoginSubmit()"
          >
          </gds-button>
        </div>
      </div>
    </div>
  </div>
</div>
