import { Component, Inject, Input, OnInit } from '@angular/core';
import _ from 'lodash';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-selection-card',
  templateUrl: './selection-card.component.html',
  styleUrls: ['./selection-card.component.scss'],
})
export class SelectionCardComponent implements OnInit {
  @Input() pageTitle: any;
  @Input() selectionCardData: any;
  @Input() isExpired: any;
  @Input() baseCode: any;
  @Input() dataFromParent: any;
  @Input() pageLabels: any;
  timedAttractions: any;
  nonTimedAttractions: any;
  timedAttractionsWithGroupWise: any;
  nonTimedAttractionsWithGroupWise: any;
  choiceAHeading: any;
  choiceBHeading: any;
  baseUrl: any;
  ticketImage: any;
  ticketsData: any;
  expressArray: any;

  constructor(
    @Inject('GlobalDefaults') public globalDefault: any // private common: CommonUtilityService
  ) {}

  /**
   * ngOnInit - Loading data for Timed/Untimed area attractions, pass data to getAttractions method
   */
  ngOnInit(): void {
    this.baseUrl = environment?.tridionBaseUrl;
    this.choiceAHeading = this.globalDefault.CHOICEA;
    this.choiceBHeading = this.globalDefault.CHOICEB;
  }

  /**
   * getBaseProduct - To get Base product code for commerce api
   */
  getBaseProduct(data: any): void {
    this.expressArray = data;
    this.getTicketPictures(this.expressArray);
  }

  /**
   * getAttractions - Filter data in Timed / Non-Timed attractions
   * @param product - Data containing all attractions
   */
  getAttractions(product: any): any {
    const attractions = product;

    this.timedAttractions = attractions?.filter((attraction: any) => {
      return attraction?.maingroup === 'TIMED';
    });

    this.nonTimedAttractions = attractions?.filter((attraction: any) => {
      return attraction?.maingroup === 'NON_TIMED';
    });

    this.timedAttractionsWithGroupWise = this.getByAttractionGroup(
      this.timedAttractions
    );

    this.nonTimedAttractionsWithGroupWise = this.getByAttractionGroup(
      this.nonTimedAttractions
    );

    const choiceAArray: any = [];
    const choiceBArray: any = [];
    const choices: any = [];
    if (this.nonTimedAttractionsWithGroupWise) {
      this.nonTimedAttractionsWithGroupWise.map((key: any) => {
        if (key.subgroup.endsWith(this.choiceAHeading)) {
          choiceAArray.push(key);
        } else if (key.subgroup.endsWith(this.choiceBHeading)) {
          choiceBArray.push(key);
        } else {
          choices.push(key);
        }
      });
      this.nonTimedAttractionsWithGroupWise = choices.concat(
        choiceAArray,
        choiceBArray
      );
    }
  }

  /**
   * getByAttractionGroup - Data with subgroup pass to function to distinguish
   * @param group - containing choices subgroup
   * @returns - return distinguish data with subgroup and attraction
   */
  getByAttractionGroup(group: any): any {
    if (!group) {
      return [];
    }

    const result = Array.from(
      group?.reduce(
        (m: any, o: any) =>
          m.set(o.subgroup, (m.get(o.subgroup) || []).concat(o)),
        new Map()
      ),
      ([subgroup, attractions]) => ({ subgroup, attractions })
    );

    return result;
  }

  /**
   * getTicketPictures - Fetch ticket pictures from tridion
   * @param data - eventCode
   * @returns - return tridionurl for image
   */
  getTicketPictures(data: any): any {
    data = _.map(data?.attractions?.events, (item: any) => {
      if (item?.tridionContent && typeof item.tridionContent === 'string') {
        item.tridionContent = JSON.parse(item.tridionContent);
      }
      return item;
    });
    this.ticketsData = data;
    this.getAttractions(this.ticketsData);
  }
}
