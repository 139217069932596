import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IAddress, MsGuestsService } from '@upr-web-primer/ms-guests';
import { UrlInfoService } from '@upr-web-primer/url-info';
import { WINDOW } from '@upr-web-primer/window';
import _, { get } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import {
  EmailPasswordPattern,
  PhonePattern,
} from '../../../config/pattern.constant';
import { environment } from '../../../environments/environment';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';
import { ToastService } from '../../shared/service/toast.service';

@Component({
  selector: 'app-update-address',
  templateUrl: './update-address.component.html',
  styleUrls: ['./update-address.component.scss'],
})
export class UpdateAddressComponent implements OnInit, AfterViewInit {
  page: any;
  pageLabels: any;
  backLink: string;
  prefixes: any;
  openDeleteModal = false;
  updateAddressForm: FormGroup;
  emailRegex = EmailPasswordPattern.EMAIL_SIGNUP;
  phoneNumberRegex = PhonePattern.PHONE_SIGNUP;
  primaryOfferSelected = false;
  termsConditionSelected = false;
  userProfileData: any;
  termsErrorMsg = '';
  guestId: any;
  postalPin: any;
  validPostalResponse: any;
  prefectureDetailsInfo: any;
  showPrefectureDropdownOptions: any = [];
  selectedPrefectureList: any;
  postalErrorMsg: any;
  prefectureErrorMsg: any;
  cityErrorMsg: any;
  address1ErrorMsg: any;
  phoneErrorMsg: any;
  postalCodeLengthError = '';
  commonErrorMsg = '';
  prefectureFieldLabel = '';
  primaryVal = false;
  showDropdown = true;
  showEmptyDropdown = false;
  savedSourceId: any;
  private route: ActivatedRoute;
  public commonUtilityService: CommonUtilityService;
  private guestsService: MsGuestsService;
  private urlInfoService: UrlInfoService;
  inputChanged: boolean;
  @ViewChild('updateAddressModalContent')
  updateAddressModalContent!: ElementRef;

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    public cdRef: ChangeDetectorRef,
    private toastService: ToastService,
    private title: Title,
    private appDataService: AppDataService,
    @Inject('AppRoutes') private appRoutes: any,
    @Inject('Ranges') public ranges: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject(WINDOW) private window: any,
    @Inject('sourceKeys') public sourceKeys: any,
    private cookieService: CookieService
  ) {
    this.setDependencies();
    this.page = this.route.snapshot.data.page;
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.addressEdit']);
    this.backLink =
      this.urlInfoService.getUrlPrefix() + '/' + this.appRoutes.MY_PROFILE;
  }

  /**
   * ngOnInit-It will initialize the  Page
   */

  ngOnInit(): void {
    this.createForm();
    this.getAddress();
    this.loadPrefecture();
    this.enableButton();
  }

  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(
      this.updateAddressModalContent,
      164
    );
  }

  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(
      this.updateAddressModalContent,
      164
    );
  }
  /**
   * enableButton-It will use when user changes previous value
   */

  enableButton(): any {
    this.updateAddressForm.valueChanges.subscribe((currentValue) => {
      if (
        currentValue.postalCode === this.userProfileData?.postal_code &&
        currentValue.prefecture.name === this.userProfileData?.state &&
        currentValue.city === this.userProfileData?.city &&
        currentValue.address1 === this.userProfileData?.address_line1 &&
        currentValue.address2 === this.userProfileData?.address_line2 &&
        currentValue.primary === this.userProfileData?.primary
      ) {
        this.inputChanged = false;
      } else {
        this.inputChanged = true;
      }
    });
  }

  /**
   * getAddress-Get edited value from the Address
   */

  getAddress(): void {
    this.userProfileData = this.commonUtilityService.getFormData();
  }

  /**
   * checkAddress-Check the Address Whether its primary or Not
   */

  checkAddress(): void {
    if (this.userProfileData?.primary == true) {
      this.primaryVal = true;
      this.updateAddressForm.patchValue({
        primary: this.primaryVal,
      });
    } else {
      this.primaryVal = false;
      this.updateAddressForm.patchValue({
        primary: this.primaryVal,
      });
    }
    if (this.userProfileData) {
      this.updateAddressForm.patchValue({
        postalCode: this.userProfileData?.postal_code,
        city: this.userProfileData?.city,
        address1: this.userProfileData?.address_line1,
        address2: this.userProfileData?.address_line2,
        prefecture: this.userProfileData?.state,
      });
      this.getPostalCodes();
    }
  }

  private setDependencies(): void {
    this.route = this.injector.get(ActivatedRoute);
    this.commonUtilityService = this.injector.get(CommonUtilityService);
    this.guestsService = this.injector.get(MsGuestsService);
    this.urlInfoService = this.injector.get(UrlInfoService);
  }

  /**
   * createForm-It will create a Add_Address_Form
   */

  createForm(): void {
    this.updateAddressForm = this.formBuilder.group({
      postalCode: new FormControl('', [
        <any>Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7),
        this.noWhitespaceValidator(),
      ]),
      prefecture: new FormControl('', [Validators.required]),
      city: new FormControl('', [
        <any>Validators.required,
        this.noWhitespaceValidator(),
      ]),
      address1: new FormControl('', [
        <any>Validators.required,
        this.noWhitespaceValidator(),
      ]),
      address2: new FormControl(),
      primary: new FormControl(),
    });
  }

  /**
   * noWhitespaceValidator-It won't allow white space from  Add_Address_Form
   */

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  /**
   * getErrorMessageInfo-It will get the error from the events
   */

  getErrorMessageInfo(event: any): any {
    const fieldInfo = event?.target.id;
    switch (fieldInfo) {
      case this.globalDefault.POSTALCODE:
        if (event?.target?.value?.length !== this.ranges.MAX.POSTALCODE) {
          this.updateAddressForm.reset();
          this.showEmptyDropdown = true;
          this.showDropdown = false;
          this.cdRef.detectChanges();
          return (this.postalErrorMsg = this.pageLabels?.['errCode.E013']);
        } else {
          this.showDropdown = true;
          this.showEmptyDropdown = false;
          this.selectedPrefectureList = '';
        }
        break;
      case this.globalDefault.PREFECTURE:
        this.updateAddressForm
          ?.get('prefecture')
          ?.patchValue(this.selectedPrefectureList);
        this.prefectureErrorMsg = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.CITY:
        this.cityErrorMsg = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.ADDRESS1:
        this.address1ErrorMsg = this.getErrorMessage(fieldInfo);
        break;
    }
    this.cdRef.detectChanges();
  }

  /**
   * getErrorMessage-It will get the error-msg from the events
   */

  getErrorMessage(field: any): string {
    const fieldName = this.updateAddressForm?.get(field);
    const emptyCheck = this.pageLabels?.['errCode.E015'];
    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      switch (field) {
        case this.globalDefault.CITY: {
          if (!this.updateAddressForm?.value?.city) return emptyCheck;
          else return this.pageLabels?.['errCode.E015'];
        }

        case this.globalDefault.ADDRESS1: {
          if (!this.updateAddressForm?.value?.address1) return emptyCheck;
          else return this.pageLabels?.['errCode.E015'];
        }
        case this.globalDefault.PREFECTURE: {
          if (!this.updateAddressForm?.value?.prefecture) return emptyCheck;
          else return this.pageLabels?.['errCode.E015'];
        }
      }
    }
    return '';
  }

  /**
   * loadPrefecture-It will load the Prefecture
   */

  loadPrefecture(): void {
    this.commonUtilityService
      .loadPrefectureList()
      .then((res: any) => {
        this.prefectureDetailsInfo = res;
        this.sortAddress();
        this.checkAddress();
        this.showPrefectureDropdownOptions = _.map(
          this.prefectureDetailsInfo,
          (optionInfo: any) => {
            return { label: optionInfo.name, value: optionInfo };
          }
        );
        this.cdRef.detectChanges();
      })
      .catch((error: any) => {
        if (error.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
      });
  }

  /**
   * matchPrefecture-It will load the Prefecture based on name
   */

  matchPrefecture(event: any): void {
    this.prefectureDetailsInfo?.filter((res: any) => {
      if (res.name === event.detail.name) {
        this.selectedPrefectureList = res;
      }
    });
  }

  /**
   * onPrimaryClick-It will set the primary Address
   */

  onPrimaryClick(event: any): void {
    if (event?.target?.id === this.globalDefault.BTN_PRIMARY) {
      this.primaryOfferSelected =
        event?.detail === this.globalDefault.CHECKED ? true : false;

      this.updateAddressForm.patchValue({
        primary: this.primaryOfferSelected,
      });
    }
  }

  /**
   * backtoAddressList-It will navigate to My_Address Page
   */

  backtoAddressList(): void {
    this.commonUtilityService.changeRoute(this.appRoutes.MY_ADDRESSES);
  }

  /**
   * changePostalCode-If user change the previous Value on postal code will return the prefecture,city,line1
   */

  changePostalCode(): any {
    this.postalPin = this.updateAddressForm.get('postalCode')?.value;
    if (
      this.updateAddressForm?.value?.postalCode.length !==
      this.ranges.MAX.POSTALCODE
    ) {
      this.updateAddressForm.reset();
      this.cdRef.detectChanges();
      this.postalErrorMsg = this.pageLabels?.['errCode.E013'];
    } else {
      this.postalErrorMsg = '';
    }
    this.commonUtilityService.loadPostal(this.postalPin).then(
      (res: any) => {
        this.validPostalResponse = res;
        this.showDropdown = true;
        this.showEmptyDropdown = false;
        this.updateAddressForm
          .get('city')
          ?.patchValue(this.validPostalResponse[0]?.region);
        this.updateAddressForm
          .get('address1')
          ?.patchValue(this.validPostalResponse[0]?.locality);
        _.filter(this.prefectureDetailsInfo, (pdiRes: any) => {
          if (pdiRes.name === this.validPostalResponse[0].municipality) {
            this.selectedPrefectureList = pdiRes;
            this.updateAddressForm
              ?.get('prefecture')
              ?.patchValue(this.selectedPrefectureList);
            this.cityErrorMsg = '';
            this.address1ErrorMsg = '';
          }
        });
        this.cdRef.detectChanges();
      },
      (err: any) => {
        if (err.error.title === this.globalDefault.NOT_FOUND) {
          this.showEmptyDropdown = true;
          this.showDropdown = false;
          this.postalErrorMsg = this.pageLabels?.['errCode.E014'];
          this.updateAddressForm.get('city')?.patchValue('');
          this.updateAddressForm.get('address1')?.patchValue('');
          this.cityErrorMsg = '';
          this.address1ErrorMsg = '';
          this.cdRef.detectChanges();
        }
        if (err.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
      }
    );
  }

  /**
   * getPostalCodes-Based on postal code will return the prefecture,city,line1
   */

  getPostalCodes(): any {
    this.selectedPrefectureList = this.prefectureDetailsInfo?.filter(
      (prefecture: any) => prefecture?.name === this.userProfileData?.state
    );
    this.updateAddressForm
      ?.get('prefecture')
      ?.patchValue(this.selectedPrefectureList?.[0]);
  }
  /**
   * onSubmit-It will save the form value to API
   */

  onSubmit(): void {
    this.savedSourceId = this.commonUtilityService?.getSourceId(
      this.sourceKeys?.WEB_EDITADDRESS
    );
    this.commonUtilityService.validateAllFormFields(this.updateAddressForm);
    const address_id = this.userProfileData?.address_id;
    const request: IAddress = {
      first_name: this.userProfileData?.first_name,
      last_name: this.userProfileData?.last_name,
      postal_code: get(this.updateAddressForm, 'value.postalCode', ''),
      state: get(this.updateAddressForm, 'value.prefecture.name', ''),
      city: get(this.updateAddressForm, 'value.city', ''),
      country: get(this.updateAddressForm, 'value.prefecture.code', ''),
      country_code: get(
        this.updateAddressForm,
        'value.prefecture.country_code',
        ''
      ),
      address_line1: get(this.updateAddressForm, 'value.address1', ''),
      address_line2: get(this.updateAddressForm, 'value.address2', ''),
      primary: get(this.updateAddressForm, 'value.primary', ''),
    };
    this.guestsService.personal
      .updateAddress(request, this.savedSourceId, address_id)
      .then(() => {
        this.ddlEvents(this.globalDefault.SUCCESS);
        this.toastService.show({
          text: this.pageLabels?.['editAddress.updateSnack'],
          type: this.globalDefault?.SUCCESS,
        });
        this.commonUtilityService.setCommonData(this.ranges.SUCCESS_STATUS);
        this.commonUtilityService.changeRoute(this.appRoutes.MY_ADDRESSES);
      })
      .catch((error) => {
        this.ddlEvents(this.globalDefault.FAILED);
        if (error?.status == this.globalDefault.ERROR_409) {
          this.commonUtilityService.setCommonData(error?.status);
          this.commonUtilityService.changeRoute(this.appRoutes?.MY_ADDRESSES);
        }
        if (error?.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
      });
  }

  /**
   * ddlEvents - On address update these events are getting set
   * @param type - On api response success or failed
   */
  ddlEvents(type: string): void {
    const guestId = this.guestsService.helper.getGuestId();
    this.commonUtilityService.invokeFireEvent(
      'addressUpdate',
      type,
      'Form',
      'null',
      new Date().toISOString(),
      guestId,
      '',
      this.window?.digitalData?.page?.category?.primaryCategory ?? '',
      this.window?.digitalData?.page?.category?.subCategory1 ?? ''
    );
    // this.commonUtilityService?.setUser();
  }

  /**
   * onDelete-It will delete the form value to API
   */

  onDelete(): void {
    const address_id = this.userProfileData?.address_id;
    this.savedSourceId = this.commonUtilityService?.getSourceId(
      this.sourceKeys?.WEB_EDITADDRESS
    );
    this.guestsService.personal
      .deleteAddress(this.savedSourceId, address_id)
      .then(() => {
        this.toastService.show({
          text: this.pageLabels?.['editAddress.deleteSnack'],
          type: this.globalDefault.SUCCESS,
        });
        this.commonUtilityService.changeRoute(this.appRoutes.MY_ADDRESSES);
      })
      .catch((error) => {
        if (error.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
        return false;
      });
  }

  /**
   * sortAddress-Sort Address based on Iso-Numeric
   */
  sortAddress(): void {
    if (Array.isArray(this.prefectureDetailsInfo)) {
      this.prefectureDetailsInfo.sort((a: any, b: any) => {
        const extractNumeric = (obj: any): string =>
          (obj.iso_numeric || '').replace(/^\D+/g, ''); // Specify the return type here
        const a1 = parseFloat(extractNumeric(a));
        const b1 = parseFloat(extractNumeric(b));
        return a1 - b1;
      });
    } else {
      console.error('prefectureDetailsInfo is not an array.');
    }
  }
}
