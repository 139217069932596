<gds-full-screen-modal opened (modalClose)="backToTravelPartyInfo()">
  <div slot="components">
    <!--Warning travel party section-->
    <div *ngIf="opened">
      <app-warning-travel-party
        [showConfirmPopup]="showConfirmPopup"
        [opened]="opened"
        (backToEditTravel)="onWarning($event)"
      >
      </app-warning-travel-party>
    </div>
    <!--Add travel party model popup-->
    <div class="container modal-content" #addPartyModalContent *ngIf="!opened">
      <!--confirm travel party section-->
      <div *ngIf="showConfirmPopup">
        <app-confirm-travel-party-members
          [addTravelPartyForm]="addTravelPartyForm"
          (backToAddTravelParty)="onTravelPartyList($event)"
          [travelMembersCount]="travelMembersCount"
          [type]="fromPage"
        ></app-confirm-travel-party-members>
      </div>
      <!--Add travel party header-->
      <div *ngIf="!opened">
        <div class="container">
          <gds-heading
            [data]="{
              heading: pageLabels?.['trpAdd.heading']
            }"
            class="A2-W5"
          >
          </gds-heading>
          <gds-spacer classes="spacer-50"></gds-spacer>

          <gds-body
            [data]="{
              description: pageLabels?.['trpUpdate.subText1']
            }"
            class="B1-W3"
          ></gds-body>

          <gds-body
            [innerHtml]="
            pageLabels?.['trpUpdate.subText2']
          "
            class="B1-W3"
          ></gds-body>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <app-add-party-form
            [addTravelPartyForm]="addTravelPartyForm"
            (updateTravelPartyForm)="addTravelPartyForm = $event"
          >
          </app-add-party-form>
        </div>
      </div>
    </div>
    <!--Confirm footer button-->
    <ng-container *ngIf="!opened">
      <gds-sticky-nav-footer
        [data]="{
          button: {
            type: 'primary',
            href: '',
            label: pageLabels?.['trpAdd.confirm'],
            disabled: addTravelPartyForm.valid ? 'false' : 'true',
            target: ''
          }
        }"
        (buttonClicked)="onConfirm()"
      >
      </gds-sticky-nav-footer>
    </ng-container>
  </div>
</gds-full-screen-modal>
