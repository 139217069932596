import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, NgZone, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from '@upr-web-primer/locale';
import { NotificationService } from '@upr-web-primer/notification';
import { SpinnerService } from '@upr-web-primer/spinner';
import { LocalStorageService } from '@upr-web-primer/storage';
import { CookieService } from 'ngx-cookie-service';
import { tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CreditCardTypes } from '../../account/order-history/creditcardtypes';
import { ORDER_STATUS } from '../../account/order-history/order-status';
import { StoreCodes } from '../../account/order-history/storecodeslist';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  pageLabels: any;
  orderCode: any;
  orderDetailsData: any;
  orderEntries: any[];
  groupedOrderEntries = new Map();
  orderCancelledStatus = false;
  updatedCallOutInfo: any;
  matchedKey: string | any;
  valueForMatchedKey: any;
  matchedDesc: any;
  valueForMatchedDesc: any;
  orderShipping: any;
  StoreCodesInfo: any = StoreCodes;
  selectedStoreDescription: any;
  formattedPaymentDueDate: any;
  formattedTime: string | null;
  notConfirmedDes: any;
  existingCardTypes: any = CreditCardTypes;
  couponCost: any;
  combinedDateTime: Date | null = null;
  removedHyphensString: string;
  orderDetailsErrorMsgInfo: string;
  groupByNameTickets: any;
  heading: any;
  description: any;
  isConfirmed: boolean;
  languageCode: string;
  constructor(
    private locale: LocaleService,
    private route: ActivatedRoute,
    private commonUtilityService: CommonUtilityService,
    private title: Title,
    protected injector: Injector,
    @Inject('AppRoutes') public appRoutes: any,
    private datePipe: DatePipe,
    @Inject('GlobalDefaults') public globalDefault: any,
    private localStorage: LocalStorageService,
    private zone: NgZone,
    private cookieService: CookieService,
    private notification: NotificationService,
    private spinner: SpinnerService
  ) {
    this.zone.runOutsideAngular(() => {
      window.addEventListener('popstate', () => {
        window.location.reload();
      });
    });

    this.pageLabels = this.commonUtilityService.getResolvedPageData();
    this.setDependencies();
  }

  /**
   * ngOnInit - initial methods are called
   */

  ngOnInit(): void {
    this.spinner?.show();
    this.orderCode = this.route?.snapshot?.params['orderId'];
    this.title.setTitle(this.pageLabels?.['page.orderHistoryDetail']);
    this.localStorage.setItem('orderId', this.orderCode);

    this.getSelectedOrder();
  }
  replaceTimestamp(text: any): any {
    this.notConfirmedDes = text?.replace(
      '{{paymentDue}}',
      this.formattedPaymentDueDate + ', ' + this.formattedTime
    );
  }
  /**
   * getSelectedOrder it will get the particular order details from the api
   */

  getSelectedOrder(): any {
    this.commonUtilityService.getOrderDetails(this.orderCode).subscribe(
      (data: any) => {
        this.spinner?.hide();
        this.orderDetailsData = data;
        this.orderShipping = parseInt(
          this.orderDetailsData?.deliveryCost?.formattedValue
            .slice(1)
            .replace(/,/g, ''),
          10
        );

        this.couponCost = parseInt(
          this.orderDetailsData?.totalDiscounts?.formattedValue
            .slice(1)
            .replace(/,/g, ''),
          10
        );
        this.orderDetailsStatus(this.orderDetailsData.status);
        this.orderEntries = this.orderDetailsData?.entries;
        this.fetchBaseCategoryCode();
        this.orderGrouping(this.orderEntries);
        this.paymentMethodSection();
        this.getOrderStatus(this.orderDetailsData?.status);
      },
      (errors: any) => {
        this.spinner?.hide();
        if (errors.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        } else {
          this.removeOrderHyphens(errors?.error?.errors?.[0]?.errorCode);
        }
      }
    );
  }
  /**
   * orderGrouping - get grouped TRP ticket entries
   * @param orderEntries - passing order entries data
   */
  orderGrouping(orderEntries: any): any {
    if (orderEntries?.length) {
      orderEntries?.forEach((entry: any) => {
        entry?.ticketEntryInfo?.guestInfos?.forEach((guest: any) => {
          const key: any = guest.lastName + ' ' + guest.firstName;
          if (this.groupedOrderEntries.has(key)) {
            this.groupedOrderEntries.set(key, [
              ...this.groupedOrderEntries.get(key),
              entry,
            ]);
          } else {
            this.groupedOrderEntries.set(key, [entry]);
          }
        });
      });
      this.groupByNameTickets = Array.from(this.groupedOrderEntries).map(
        ([key, value]) => {
          return { fullName: key, entries: value };
        }
      );

      this.sortOrderTickets();
    }
  }
  /**
   * sortOrderTickets - sort the OrderTickets
   */
  sortOrderTickets(): void {
    this.groupByNameTickets.forEach((element: any) => {
      element?.entries.sort((a: any, b: any) => {
        const getPriority = (productCategoryName: any): number => {
          switch (productCategoryName) {
            case this.globalDefault.COMM_STUDIO_PASS:
              return 0;
            case this.globalDefault.COMM_ANNUAL_PASS:
              return 1;
            case this.globalDefault.COMM_EXPRESS_PASS:
              return 2;
            default:
              return 3; // Assuming Extras or any other category comes after Express Pass
          }
        };

        // Get the product category names for the tickets
        const productCategoryNameA = a.product?.categories?.[0].code;
        const productCategoryNameB = b.product?.categories?.[0].code;

        // Get the priority of the product category for both tickets
        const priorityA = getPriority(productCategoryNameA);
        const priorityB = getPriority(productCategoryNameB);

        // If product types are different, sort by product type priority
        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        } else {
          // If product types are the same, prioritize the one with later future date
          const futureDateComparison =
            new Date(b.ticketEntryInfo.visitationDate).getTime() -
            new Date(a.ticketEntryInfo.visitationDate).getTime();
          if (futureDateComparison !== 0) {
            return futureDateComparison; // Return the comparison result if dates are different
          } else {
            // If dates are the same, prioritize by visitation date in ascending order
            return (
              new Date(a.ticketEntryInfo.visitationDate).getTime() -
              new Date(b.ticketEntryInfo.visitationDate).getTime()
            );
          }
        }
      });
    });
  }

  /**
   * removeOrderHyphens - removed the hypens from error code
   */
  removeOrderHyphens(errorCode: any): any {
    this.removedHyphensString = errorCode?.replace(/-/g, '');
    this.orderDetailsErrorMsgInfo = this.pageLabels?.[
      this.globalDefault.ERROR_CODE + this.removedHyphensString
    ]
      ? this.pageLabels?.[
          this.globalDefault.ERROR_CODE + this.removedHyphensString
        ]
      : this.pageLabels?.['errCode.E019'];
    this.notification?.show(this.orderDetailsErrorMsgInfo);
  }
  /**
   * orderDetailsStatus - search the order which is having cancelled status
   * @param status - get the status from orders
   */
  orderDetailsStatus(status: any): any {
    switch (status) {
      case this.globalDefault.CVS_PAYMENT_EXPIRED:
        this.orderCancelledStatus = true;
        break;
      case this.globalDefault.EXPIRED_ORDER:
        this.orderCancelledStatus = true;
        break;
      case this.globalDefault.CLOSED_ORDER:
        this.orderCancelledStatus = true;
        break;
      case this.globalDefault.CANCELLED:
        this.orderCancelledStatus = true;
        break;
    }
  }
  /**
   * paymentMethodSection - shows the payment method section
   */
  paymentMethodSection(): any {
    this.orderStoreDescriptions(
      this.orderDetailsData?.udxPaymentInfo?.cvsOption
    );
    this.formattedTime = this.datePipe.transform(
      this.orderDetailsData?.udxPaymentInfo?.paymentExpireDate,
      'HH:mm',
      'UTC'
    );
    this.formattedPaymentDueDate = this.getDate(
      this.orderDetailsData?.udxPaymentInfo?.paymentExpireDate,
      true
    );
    this.notConfirmedDes = this.pageLabels?.['orderbefPayment.callOutInfo'];
    this.replaceTimestamp(this.notConfirmedDes);
  }

  /**
   * orderStoreDescriptions - it will store the description of the matched storeCode from the API
   * @param storeDescription - it will get the storeCodes from the API
   */
  orderStoreDescriptions(storeDescription: any): any {
    switch (storeDescription) {
      case this.StoreCodesInfo.CUSTOM_CONFIRMATION_NUMBERS[0]:
        this.selectedStoreDescription =
          this.pageLabels?.['orderDetails.payMethod_desc_FAMILYMART'];
        break;
      case this.StoreCodesInfo.CUSTOM_CONFIRMATION_NUMBERS[1]:
        this.selectedStoreDescription =
          this.pageLabels?.['orderDetails.payMethod_desc_LAWSON'];
        break;
      case this.StoreCodesInfo.SEJ:
        this.selectedStoreDescription =
          this.pageLabels?.['orderDetails.payMethod_desc_SEJ'];
        break;
      case this.StoreCodesInfo.CUSTOM_CONFIRMATION_NUMBERS[2]:
        this.selectedStoreDescription =
          this.pageLabels?.['orderDetails.payMethod_desc_MINISTOP'];
        break;
      case this.StoreCodesInfo.SEICOMART:
        this.selectedStoreDescription =
          this.pageLabels?.['orderDetails.payMethod_desc_SEICOMART'];
        break;
    }
  }

  /**
   * getTickets-It will navigate to ticket-detail component
   */

  getTickets(ticketData: any, fullName: string): void {
    ticketData.subEntries.forEach((ticket: any) => {
      const firstName = ticket?.guestInfo?.firstName;
      const lastName = ticket?.guestInfo?.lastName;

      if (fullName === `${lastName} ${firstName}` && ticket.ticketId) {
        this.localStorage.setItem('isOrderPage', true);
        this.commonUtilityService.changeRoute(
          `${this.appRoutes.TICKET_DETAILS}/${ticket.ticketId}`
        );
      }
    });
  }

  /**
   * getOrderStatus - It will show the status of the Orders in Order Details Page by clicking the Order
   * @param status - It will get the status of the order from API Response
   * @returns - heading & description
   */

  getOrderStatus(status: any): any {
    this.isConfirmed = status === this.globalDefault.CONFIRMED;
    const isWaitingForPayment =
      status === this.globalDefault.WAITING_FOR_PAYMENT;
    const isCVSPaymentReqSuccess =
      status === this.globalDefault.CVS_PAYMENT_REQSUCCESS;
    // It will show the callout message of the Order in Order Details Page by clicking the Order expect CONFIRMED, WAITING_FOR_PAYMENT and CVS_PAYMENT_REQSUCCESS these status.
    if (!this.isConfirmed && !isWaitingForPayment && !isCVSPaymentReqSuccess) {
      const documentsReviewStatus =
        this.orderDetailsData?.documentsReviewStatus;
      if (documentsReviewStatus === this.globalDefault.REJECTED) {
        this.getOrderDetailsStatus(documentsReviewStatus);
      } else {
        this.getOrderDetailsStatus(this.orderDetailsData?.status);
      }
    }
    //It will show the ticket not issued callout message of the Orders having these status WAITING_FOR_PAYMENT,CVS_PAYMENT_REQSUCCESS if it is CVS
    else if (
      (isWaitingForPayment || isCVSPaymentReqSuccess) &&
      this.orderDetailsData?.paymentType?.code ===
        this.globalDefault.CONVENIENCESTORE
    ) {
      this.heading =
        this.pageLabels?.['Comm.orderStatus.WAITING_FOR_PAYMENT'] || '';
      this.description = this.notConfirmedDes;
    }
    //It will show the ticket not issued callout message of the Orders having these status WAITING_FOR_PAYMENT,CVS_PAYMENT_REQSUCCESS if it is CARD
    else if (
      (isWaitingForPayment || isCVSPaymentReqSuccess) &&
      this.orderDetailsData?.paymentType?.code === this.globalDefault.CARD
    ) {
      this.heading =
        this.pageLabels?.['Comm.orderStatus.WAITING_FOR_PAYMENT'] || '';
      this.description =
        this.pageLabels?.['Comm.orderStatus.PAYMENT_NOT_AUTHORIZED.desc'] || '';
    }
    // It will show the other then combination statues
    else {
      const matchedKey = ORDER_STATUS[`Comm.orderStatus.${status}`];
      const matchedDesc = ORDER_STATUS[`Comm.orderStatus.${status}.desc`];
      this.heading = this.pageLabels?.[matchedKey] || '';
      this.description = this.pageLabels?.[matchedDesc] || '';
    }
  }

  /**
   * getOrderDetailsStatus - It will show the status of the Orders in Order Details Page by clicking the Order
   * @param status - get the order status
   */
  getOrderDetailsStatus(status: string): any {
    this.matchedKey = ORDER_STATUS[`Comm.orderStatus.${status}`];
    this.valueForMatchedKey = this.matchedKey
      ? this.pageLabels?.[this.matchedKey]
      : '';
    this.matchedDesc = ORDER_STATUS[`Comm.orderStatus.${status}.desc`];
    this.valueForMatchedDesc = this.matchedDesc
      ? this.pageLabels?.[this.matchedDesc]
      : '';
    this.heading = this.valueForMatchedKey;
    this.description = this.valueForMatchedDesc;
  }

  /**
   * goToPage - It will redirect to the specified route
   * @param type : specify which route to navigate
   */

  goToPage(type: any): any {
    switch (type) {
      case this.pageLabels?.['orderDetails.backCTA']:
        this.commonUtilityService.changeRoute(this.appRoutes.ORDER_LIST);
        break;
    }
  }

  /**
   * setDependencies - It will set the injector of dependencies services
   */
  private setDependencies(): void {
    this.commonUtilityService = this.injector.get(CommonUtilityService);
  }

  /**
   * getDate - It will format the date based on locale
   */

  getDate(date: any, weekday?: boolean): string {
    const formattedDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    return this.commonUtilityService.getFormattedDateOnLocale(
      formattedDate,
      weekday
    );
  }
  // /**
  //  *
  //  * @param cardType -display credit card icon in order details payment method
  //  * @returns -returns the credit card type
  //  */
  getCardType(cardType: any): any {
    let card = '';
    this.existingCardTypes.forEach((type: any) => {
      if (cardType === type[1]) {
        card = type[0];
      }
    });
    return card;
  }

  /**
   * fetchBaseCategoryCode - Fetch ticket icon from commerce
   */
  fetchBaseCategoryCode(): void {
    if (!Array.isArray(this.orderEntries)) {
      console.error('orderEntries is not an array');
      return;
    }

    this.commonUtilityService
      .getIconArrayData()
      ?.pipe(
        tap((iconArrayData: any[]) => {
          this.orderEntries.forEach((entry) => {
            if (!entry.product || !Array.isArray(entry.product.categories)) {
              console.error('Invalid entry:', entry);
              return; // Skip this entry and move to the next one
            }

            entry.product.categories.forEach((category: any) => {
              const categoryCode = category?.code;

              if (!categoryCode) {
                console.error('Category code not found:', category);
                return; // Skip this category and move to the next one
              }

              const matchedIcon = iconArrayData?.find((iconItem: any) =>
                iconItem?.navigationDisplayName
                  .toLowerCase()
                  .replace(/\s/g, '')
                  .includes(categoryCode.toLowerCase().replace(/\s/g, ''))
              );

              const matchedCategory = iconArrayData?.find(
                (iconItem: any) =>
                  iconItem?.url ===
                  this.globalDefault.PRODUCT_CATEGORY.toLowerCase().replace(
                    /\s/g,
                    ''
                  )
              );

              if (
                matchedIcon &&
                typeof matchedIcon === 'object' &&
                'icon' in matchedIcon
              ) {
                entry.icon = matchedIcon?.icon;
              } else if (matchedCategory) {
                entry.icon = matchedCategory?.icon;
              }
            });
          });
        })
      )
      .subscribe();
  }
  /**
   * redirecting to the actual page when we right click and open with new tabs and windows.
   */
  orderProfileUrl(endpointName: any): any {
    const localeInfo = this.locale.getFromUrl();
    this.localStorage.setItem('isOrderPage', true);
    return (this.languageCode = `${localeInfo.language}/${localeInfo.country}/wallet/${endpointName}`);
  }
}
