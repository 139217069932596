<upr-spinner></upr-spinner>
<upr-notification
  data-aui="notification-message"
  [ngClass]=""
></upr-notification>
<!-- <upr-k2-layout
  [globalNavigation]="globalNavigation"
  [footerSignUp]="true"
  [notificationClass]="{ 'collapse-nav': isStickyNavOpen }"
>
  <router-outlet></router-outlet>
</upr-k2-layout> -->
<!-- <app-header></app-header> -->
<gds-global-nav
  [data]="navRegularData"
  (globalNavLoaded)="loadGdsNav()"
  (gdsComponentLoaded)="componentLoaded()"
  (buttonElementClick)="globalNavButtonElementClicked($event)"
  class="header-nav"
></gds-global-nav>
<router-outlet></router-outlet>
<!-- <app-footer></app-footer> -->
<gds-footer class="footer-nav" [data]="footerRegularData"></gds-footer>
