<gds-full-screen-modal opened (modalClose)="backToCoupons()">
  <!--Add coupon model popup-->
  <div slot="components">
    <div class="container modal-content" #addCouponModalContent>
      <!--Add coupon title-->
      <gds-heading
        [data]="{
          heading: this.pageLabels?.['inputCoupon.title']
        }"
        class="A2-W5"
      >
      </gds-heading>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!--Add coupon title text-->
      <gds-body
        [data]="{
          description: this.pageLabels?.['inputCoupon.titletext']
        }"
        class="B1-W3"
      ></gds-body>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!--Add coupon form data-->
      <div [formGroup]="addCouponsForm">
        <!--Add coupon input filed-->
        <gds-input
          id="addCouponCode"
          formControlName="addCouponCode"
          ngDefaultControl
          [errors]="couponCodeErrorMsgInfo"
          [data]="{
            primaryLabel: this.pageLabels?.['inputCoupon.inputLabel'],
            supportingText: '',
            tooltip: '',
            type: 'text',
            altText: 'input of phoneNumber',
            ariaText: '',
            required: true,
            placeholder: this.pageLabels?.['inputCoupon.inputPlaceholder']
          }"
          (valueChange)="onValueChange($event)"
          (blurChange)="getErrorMessageInfo($event)"
          onKeyPress=" if( this.value?.length === 30 ) return false;"
        >
        </gds-input>
        <gds-spacer classes="spacer-85"></gds-spacer>
      </div>
    </div>
    <!--Add coupon footer-->
    <gds-sticky-nav-footer
      [data]="{
        button: {
          type: 'primary',
          href: '',
          label: this.pageLabels?.['inputCoupon.buttonCTA'],
          disabled: addCouponsForm?.valid ? 'false' : 'true',
          target: ''
        }
      }"
      (buttonClicked)="addCoupon()"
    >
    </gds-sticky-nav-footer>
  </div>
</gds-full-screen-modal>
