import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { DalService } from '@upr-web-primer/dal';
import { NotificationService } from '@upr-web-primer/notification';
import { PageLabelsResolver } from '@upr-web-primer/page-labels';
import { SpinnerService } from '@upr-web-primer/spinner';
import {
  LocalStorageService,
  SessionStorageService,
} from '@upr-web-primer/storage';
import { WINDOW } from '@upr-web-primer/window';
import { get, isEmpty } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { ApplicationDataService } from './services/application-data.service';
import { CommonUtilityService } from './services/common-utility.service';

@Injectable({
  providedIn: 'root',
})
export class OidcLoginGuard implements CanActivate {
  isLoggedIn: any;
  pageLabels: any;
  constructor(
    private common: CommonUtilityService,
    private localStorage: LocalStorageService,
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('DefaultValues') private defaultValues: any,
    private dalService: DalService,
    private sessionStorage: SessionStorageService,
    @Inject(WINDOW) private window: any,
    private applicationDataService: ApplicationDataService,
    private notification: NotificationService,
    private spinner: SpinnerService,
    // private route: ActivatedRoute,
    private pageLabelsService: PageLabelsResolver,
    private cookieService: CookieService
  ) {
    this.pageLabelsService.get().then((pageLabels: any) => {
      this.pageLabels = pageLabels;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.oidcLogin(route).then((status: boolean) => {
      if (status) {
        return true;
      } else {
        return false;
      }
    });

    return true;
  }

  oidcLogin(route: ActivatedRouteSnapshot): Promise<boolean> {
    const oidcRedirectEnabled = get(
      this.defaultValues,
      'OIDC_REDIRECT_ENABLED'
    );
    const isAccountDelete =
      this.localStorage.getItem('isAccountDelete') === 'true' ? true : false;
    if (isAccountDelete) {
      this.localStorage.setItem('isAccountDelete', false);
      const redirectUrl = this.common?.getOidcRedirectionUrl();
      this.common?.redirectToOidcAppEndSession(redirectUrl);
    }

    if (!oidcRedirectEnabled || isAccountDelete) {
      return Promise.resolve(false);
    }

    const isEmptyQuery = isEmpty(route.queryParams);
    const id_token = isEmptyQuery
      ? this.getValueFromFragment('id_token', route)
      : get(route.queryParams, 'id_token');
    const access_token = isEmptyQuery
      ? this.getValueFromFragment('access_token', route)
      : get(route.queryParams, 'access_token');
    const expires_in = isEmptyQuery
      ? this.getValueFromFragment('expires_in', route)
      : get(route.queryParams, 'expires_in');

    if (id_token && access_token && expires_in) {
      // this means user came to login page with tokens from oidc app
      this.localStorage.setItem('OIDC_ACCESS_TOKEN', access_token);
      this.localStorage.setItem('OIDC_ID_TOKEN', id_token);
      this.localStorage.setItem('OIDC_EXPIRES_IN', expires_in);
      this.sessionStorage.setItem('OIDC_ID_TOKEN', id_token);
      this.sessionStorage.setItem('OIDC_EXPIRES_IN', expires_in);
      this.sessionStorage.setItem('OIDC_ACCESS_TOKEN', access_token);
      this.dalService.setOidcLoginSession(access_token, id_token, expires_in);
      const guestId = this.localStorage.getItem('OAUTH_LOGIN_GID');
      this.localStorage.setItem('oAuthLoginUserName', guestId);
      this.localStorage.setItem('LOGGEDIN_GUEST_ID', guestId);
      this.localStorage.setItem('IS_SIGN_IN', 'true');
      this.sessionStorage.setItem('IS_SIGN_IN', 'true');
      this.setCookieInfo(id_token, access_token, expires_in, guestId);
      this.common.setIdleTimeout(expires_in);
      this.common.changeRoute(this.appRoutes.DASHBOARD);
      return Promise.resolve(true);
    }

    const wtsAccessToken = this.cookieService?.get('access_token');
    const wtsAccessCommerceToken = this.cookieService?.get('commerceTokenInfo');
    const wtsAccessExpiresIn = this.cookieService?.get('expires_in');
    if (wtsAccessToken && wtsAccessCommerceToken && wtsAccessExpiresIn) {
      this.localStorage.setItem('IS_SIGN_IN', 'true');
      this.sessionStorage.setItem('IS_SIGN_IN', 'true');
      this.common.changeRoute(this.appRoutes.DASHBOARD);
      return Promise.resolve(true);
    }

    const redirectUrl = this.common.getOidcRedirectionUrl();
    const isSignOff = Boolean(get(route.queryParams, 'signoff', false));
    const locationPort = get(this.window, 'location.port', '');
    const locationHost = get(this.window, 'location.host', '');
    this.sessionStorage.setItem('OIDC_ACCESS_TOKEN', access_token);
    this.sessionStorage.setItem('OIDC_EXPIRES_IN', expires_in);
    if (
      !!locationPort &&
      locationPort != '4202' &&
      !locationHost.includes('web-local')
    ) {
      return Promise.resolve(true);
    }
    isSignOff
      ? this.common.redirectToOidcAppEndSession(redirectUrl)
      : this.common.redirectToOidcApp(redirectUrl);
    return Promise.resolve(true);
  }

  getValueFromFragment(key: string, route: ActivatedRouteSnapshot): string {
    const fragment = get(route, 'fragment', '');
    if (!fragment) {
      return '';
    }
    const fragmentList: any = fragment
      .split('&')
      .reduce((prev: any, item: any) => {
        return Object.assign(
          { [item.split('=')[0]]: item.split('=')[1] },
          prev
        );
      }, {});
    return fragmentList && fragmentList[key];
  }
  setCookieInfo(
    id_token: string,
    access_token: string,
    expires_in: number,
    guestId: string
  ): void {
    this.applicationDataService.setApplicationCookieInfo('id_token', id_token);
    this.applicationDataService.setApplicationCookieInfo(
      'access_token',
      access_token
    );
    this.applicationDataService.setApplicationCookieInfo(
      'expires_in',
      `${expires_in}`
    );
    this.applicationDataService.setApplicationCookieInfo('guestId', guestId);
  }

  commerceAuthentication(): any {
    this.spinner.show();

    this.common.getB2COAuthToken().subscribe((authData: any) => {
      this.localStorage.setItem('authObj', JSON.stringify(authData));

      if (authData) {
        this.common.postauthLogin().subscribe(
          (loginAPIResponse: any) => {
            this.spinner.hide();
            this.localStorage.setItem(
              'loginAuthToken',
              JSON.stringify(loginAPIResponse)
            );
            this.applicationDataService.setApplicationCookieInfo(
              'commerceTokenInfo',
              JSON.stringify(loginAPIResponse)
            );
            this.localStorage.removeItem('dashboard_version');
            this.common.changeRoute(this.appRoutes.DASHBOARD);
            return Promise.resolve(true);
          },
          (errors: any) => {
            const errorCode = errors.error?.errorMessage
              ? errors.error?.errorMessage
              : errors.error.errors[0]?.message;
            this.notification?.show(errorCode);
            return Promise.resolve(false);
          }
        );
      }
    });
    return Promise.resolve(true);
  }
}
