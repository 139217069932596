import { Component, OnInit } from '@angular/core';
import _, { get } from 'lodash';
import { EnvironmentService } from '../../../environments/environment.service';
import { CommonUtilityService } from '../../services/common-utility.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  logoData: object | any;
  siteLogo: any;
  siteHeaderLogo: any;
  pageHeading: object | any;
  languageSelector: any;
  isAsideHide = false;
  constructor(
    private commonUtilityService: CommonUtilityService,
    private enviromentService: EnvironmentService
  ) {}

  // GetLayout Header & language selector
  ngOnInit(): void {
    if (this.enviromentService.language_dropdown == false) {
      this.isAsideHide = true;
    }
    this.commonUtilityService
      .requestLayoutheader()
      .subscribe((response: any) => {
        const cp = get(response, 'ComponentPresentations', []);
        _.forEach(cp, (presentation: any) => {
          const component = presentation?.Component;
          if (component?.Fields && component?.Fields?.Language) {
            this.languageSelector = presentation;
          }
        });

        if (response && Array.isArray(cp)) {
          this.logoData = cp.find((component) =>
            get(component, 'ComponentTemplate.Title', '')
              .toLowerCase()
              .startsWith('nav - header logo')
          );
          this.siteLogo = get(this.logoData, 'Component.Multimedia.Url', '');

          if (this.logoData) {
            cp.splice(cp.indexOf(this.logoData), 1);
            response.ComponentPresentations = cp;
          }
        }

        this.siteHeaderLogo = this.commonUtilityService.requestImageUrl(
          this.siteLogo
        );
      });
  }
}
