import { HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DalService } from '@upr-web-primer/dal';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { LocalStorageService } from '@upr-web-primer/storage';
import { WINDOW } from '@upr-web-primer/window';
import _ from 'lodash';
import { get } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { from } from 'rxjs';
import {
  EmailPasswordPattern,
  NamePattern,
  NAME_REGEX,
} from '../../../config/pattern.constant';
import { environment } from '../../../environments/environment';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';
import { ToastService } from '../../shared/service/toast.service';
@Component({
  selector: 'app-update-personal-info',
  templateUrl: './update-personal-info.component.html',
  styleUrls: ['./update-personal-info.component.scss'],
})
export class UpdatePersonalInfoComponent implements OnInit, AfterViewInit {
  page: any;
  pageLabels: any;
  editPersonalForm: FormGroup;
  emailRegex = EmailPasswordPattern.EMAIL_SIGNUP;
  private route: ActivatedRoute;
  private commonUtilityService: CommonUtilityService;
  private guestsService: MsGuestsService;
  private notification: NotificationService;
  private fb: FormBuilder;

  firstNameErrorMsgInfo: any;
  lastNameErrorMsgInfo: any;
  emailErrorMsgInfo: any;
  phoneNoErrorMsgInfo: any;
  passWordErrorMsgInfo: any;
  rePassWordErrorMsgInfo: any;
  termsErrorMsgInfo: any;
  dobErrorMsgInfo: any;
  primaryOfferSelected = false;
  dateVal: any;
  dateShow = false;
  genderShow = false;
  guestId: any;
  userProfileData: any;
  selectedGender: any;
  genderArray: any;
  inputChanged: boolean;
  invalidEmail = false;
  savedSourceId: any;
  @ViewChild('updatePersonalInfoModalContent')
  updatePersonalInfoModalContent!: ElementRef;
  genderValue: any;
  contactToSend: any;
  fullWidthFuriganaRegex = NamePattern.FULL_WIDTH_FURIGANA_REGEX;
  firstNameFuriganaErrorMsgInfo: any;
  lastNameFuriganaErrorMsgInfo: any;
  addressToSend: any;
  nameRegex = NAME_REGEX;
  constructor(
    private cdRef: ChangeDetectorRef,
    private injector: Injector,
    private appDataService: AppDataService,
    @Inject('AppRoutes') public appRoutes: any,
    public dal: DalService,
    @Inject('RelativeUrls') private relativeUrls: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('sourceKeys') public sourceKeys: any,
    @Inject('Ranges') public ranges: any,
    @Inject('Gender') public gender: any,
    private localStorage: LocalStorageService,
    private title: Title,
    @Inject('AppDefaults') public appDefault: any,
    public toastService: ToastService,
    @Inject(WINDOW) private window: any,
    private cookieService: CookieService
  ) {
    this.setDependencies();
    this.page = this.route.snapshot.data.page;
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.editPersonalInfo']);
  }

  /**
   * ngOnInit-It will initialize the  Page
   */

  ngOnInit(): void {
    this.createForm();
    this.genderArray = [
      {
        label: this.pageLabels?.['editInfo.genderFemale'],
        value: this.pageLabels?.['editInfo.genderFemale'],
      },
      {
        label: this.pageLabels?.['editInfo.genderMale'],
        value: this.pageLabels?.['editInfo.genderMale'],
      },
      {
        label: this.pageLabels?.['editInfo.genderOther'],
        value: this.pageLabels?.['editInfo.genderOther'],
      },
      {
        label: this.pageLabels?.['editInfo.gendernotResponded'],
        value: this.pageLabels?.['editInfo.gendernotResponded'],
      },
    ];

    this.notification.hide();
    this.loadUserInfo();
    this.getGenderTransform();
  }

  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(
      this.updatePersonalInfoModalContent,
      164
    );
  }

  /**
   * onResize - To resize full screen modal popup
   */
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(
      this.updatePersonalInfoModalContent,
      164
    );
  }

  /**
   * getDate - It will format the date based on locale
   */

  getDate(date: any): string {
    return this.commonUtilityService.getFormattedDateOnLocale(date);
  }

  /**
   * getGenderTransform-It will get the genderArray and return Label&Value
   */

  getGenderTransform(): void {
    this.genderArray = _.map(this.genderArray, (item: any) => {
      return {
        label: this.toTitleCase(item.label),
        value: item?.value,
      };
    });
  }

  /**
   * toTitleCase-It will return  the genderArray value to title case
   */

  toTitleCase(str: string | undefined): string {
    if (str === undefined) {
      return ''; // Or handle this case based on your requirements
    }
    return str
      .toLowerCase()
      .replace(/\b\w/g, (firstChar) => firstChar.toUpperCase());
  }
  /**
   * loadUserInfo-It will load the userProfileData
   */

  loadUserInfo(): any {
    this.guestsService.profile
      .getByGuestId(this.guestsService.helper.getGuestId())
      .then((userData: any) => {
        this.contactToSend = userData?.personal_info?.contacts;
        this.addressToSend = userData?.personal_info?.addresses;
        if (userData) {
          this.userProfileData = userData;
          this.getUpdatedvalue();
        }
      })
      .catch(() => {
        return false;
      });
  }

  /**
   * enableButton-It will use when user changes previous value
   */

  enableButton(): any {
    this.editPersonalForm.valueChanges.subscribe((currentValue) => {
      const englishContact = _.find(
        this.userProfileData?.personal_info?.contacts,
        (contact: any) =>
          contact?.contact_type === this.globalDefault?.CONTACT_TYPE_KANJI ||
          contact?.primary === true
      );

      const japaneseFuriganaContact = _.find(
        this.userProfileData?.personal_info?.contacts,
        (contact: any) =>
          contact?.contact_type === this.globalDefault?.CONTACT_TYPE_FURGINA
      );
      if (
        currentValue?.lastName === englishContact?.last_name &&
        currentValue?.firstName === englishContact?.first_name &&
        currentValue?.date_of_birth === this.userProfileData?.date_of_birth &&
        currentValue?.email ===
          this.userProfileData?.personal_info?.emails[0]?.email_address &&
        currentValue?.primary ===
          this.userProfileData?.preferences?.comm_prefs?.email_offers_promos &&
        currentValue?.last_name_furgina ===
          japaneseFuriganaContact?.last_name &&
        currentValue?.first_name_furgina ===
          japaneseFuriganaContact?.first_name &&
        (currentValue?.gender || undefined) === this.genderValue
      ) {
        this.inputChanged = false;
      } else {
        this.inputChanged = true;
      }
    });
  }

  /**
   * getUpdatedvalue-It will patch the Updated Value in Current Form
   */

  getUpdatedvalue(): void {
    if (
      this.userProfileData?.date_of_birth == '' ||
      this.userProfileData?.date_of_birth == undefined
    ) {
      this.dateShow = false;
    } else {
      this.dateShow = true;
      this.dateVal = this.userProfileData.date_of_birth;
    }
    if (this.userProfileData?.gender == undefined) {
      this.genderShow = false;
    } else {
      this.genderShow = true;
      this.mappingGenderRelationshipList();
    }
    const englishContact = _.find(
      this.userProfileData.personal_info?.contacts,
      (contact: any) =>
        contact?.contact_type === this.globalDefault?.CONTACT_TYPE_KANJI ||
        contact?.primary === true
    );

    const japaneseFuriganaContact = _.find(
      this.userProfileData.personal_info?.contacts,
      (contact: any) =>
        contact.contact_type === this.globalDefault.CONTACT_TYPE_FURGINA
    );
    this.editPersonalForm.patchValue({
      lastName: englishContact?.last_name,
      firstName: englishContact?.first_name,
      date_of_birth: this.userProfileData.date_of_birth,
      email: this.userProfileData.personal_info?.emails[0]?.email_address,
      primary: this.userProfileData.preferences.comm_prefs.email_offers_promos,
      last_name_furgina: japaneseFuriganaContact?.last_name,
      first_name_furgina: japaneseFuriganaContact?.first_name,
    });
    this.enableButton();
  }

  /**
   * mappingGenderRelationshipList - match the value ja-en
   */
  mappingGenderRelationshipList(): any {
    this.genderValue = this.userProfileData?.gender;
    if (this.genderValue === this.gender[0]) {
      this.genderValue = this.pageLabels['editInfo.genderFemale'];
    }
    if (this.genderValue === this.gender[1]) {
      this.genderValue = this.pageLabels['editInfo.genderMale'];
    }
    if (this.genderValue === this.gender[2]) {
      this.genderValue = this.pageLabels['editInfo.genderOther'];
    }
    this.editPersonalForm.patchValue({
      gender: this.genderValue,
    });
  }

  /**
   * backtoAccountInfo-It will navigate to Profile Page
   */

  backtoAccountInfo(): void {
    this.commonUtilityService.changeRoute(this.appRoutes.MY_PROFILE);
  }

  /**
   * createForm-It will create a Edit_personal_Form
   */

  createForm(): void {
    this.editPersonalForm = this.fb.group({
      lastName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.nameRegex),
        this.noWhitespaceValidator(),
      ]),
      last_name_furgina: new FormControl('', [
        Validators.pattern(this.fullWidthFuriganaRegex),
        Validators.maxLength(30),
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.pattern(this.nameRegex),
        this.noWhitespaceValidator(),
      ]),
      first_name_furgina: new FormControl('', [
        Validators.pattern(this.fullWidthFuriganaRegex),
        Validators.maxLength(30),
      ]),
      date_of_birth: new FormControl('', [this.dobValidator()]),
      gender: new FormControl(''),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailRegex),
      ]),
      primary: new FormControl(''),
    });
  }

  /**
   * dobValidator - dob validation
   * @returns - returns the invalidDOb as true if invalid date enetered
   */

  dobValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dob = control.value;

      if (!dob) {
        // DOB is optional, so it's valid if not provided
        return null;
      }

      // Your custom DOB validation logic goes here
      const inputDate = dob.toString();
      this.dobErrorMsgInfo = this.commonUtilityService.checkDate(
        inputDate,
        this.pageLabels
      );
      return this.dobErrorMsgInfo === '' ? null : { invalidDob: true };
    };
  }
  /**
   * noWhitespaceValidator-It won't allow white space
   */

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  /**
   * getErrorMessageInfo-It will get the error from the events
   */

  getErrorMessageInfo(event: any): void {
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.FIRSTNAME:
        this.editPersonalForm.value.firstName =
          this.editPersonalForm.value.firstName.trim();

        const firstNameValue = this.editPersonalForm.value.firstName;
        if (firstNameValue.length > this.ranges.MAX.FIRST_NAME) {
          this.editPersonalForm.controls[this.globalDefault.FIRSTNAME].setValue(
            this.editPersonalForm.value.firstName.substring(
              0,
              this.ranges.MAX.FIRST_NAME
            )
          );
        }
        this.firstNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.LASTNAME:
        this.editPersonalForm.value.lastName =
          this.editPersonalForm.value.lastName.trim();
        const lastNameValue = this.editPersonalForm.value.lastName;
        if (lastNameValue.length > this.ranges.MAX.LAST_NAME) {
          this.editPersonalForm.controls[this.globalDefault.LASTNAME].setValue(
            this.editPersonalForm.value.lastName.substring(
              0,
              this.ranges.MAX.LAST_NAME
            )
          );
        }
        this.lastNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault?.FURGINA_LASTNAME: {
        const lastNameFuriganaValue =
          this.editPersonalForm?.value?.last_name_furgina;
        if (
          lastNameFuriganaValue === undefined ||
          lastNameFuriganaValue === null
        ) {
          this.lastNameFuriganaErrorMsgInfo = '';
        } else if (!this.fullWidthFuriganaRegex.test(lastNameFuriganaValue)) {
          return (this.lastNameFuriganaErrorMsgInfo =
            this.pageLabels?.['errCode.E029']);
        } else if (
          lastNameFuriganaValue?.length > this.ranges?.MAX?.FURGINA_LASTNAME
        ) {
          this.editPersonalForm.controls[
            this.globalDefault?.FURGINA_LASTNAME
          ].setValue(
            lastNameFuriganaValue.substring(
              0,
              this.ranges?.MAX?.FURGINA_LASTNAME
            )
          );
        }
        this.lastNameFuriganaErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      }

      case this.globalDefault?.FURGINA_FIRSTNAME: {
        const firstNameFuriganaValue =
          this.editPersonalForm?.value?.first_name_furgina;
        if (
          firstNameFuriganaValue === undefined ||
          firstNameFuriganaValue === null
        ) {
          this.firstNameFuriganaErrorMsgInfo = '';
        } else if (!this.fullWidthFuriganaRegex.test(firstNameFuriganaValue)) {
          return (this.firstNameFuriganaErrorMsgInfo =
            this.pageLabels?.['errCode.E029']);
        } else if (
          firstNameFuriganaValue?.length > this.ranges?.MAX?.FURGINA_FIRSTNAME
        ) {
          this.editPersonalForm.controls[
            this.globalDefault?.FURGINA_FIRSTNAME
          ].setValue(
            firstNameFuriganaValue.substring(
              0,
              this.ranges?.MAX?.FURGINA_FIRSTNAME
            )
          );
        }
        this.firstNameFuriganaErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      }

      case this.globalDefault.EMAIL:
        const emailValue = this.editPersonalForm.value.email;
        if (emailValue.length >= this.ranges.MAX.EMAIL) {
          this.editPersonalForm.controls[this.globalDefault.EMAIL].setValue(
            this.editPersonalForm.value.email.substring(
              0,
              this.ranges.MAX.EMAIL
            )
          );
        }
        this.emailErrorMsgInfo = this.getErrorMessage(fieldInfo);
    }
    this.cdRef.detectChanges();
  }

  /**
   * getErrorMessage-It will get the error-msg from the events
   */

  getErrorMessage(field: any): string {
    const fieldName = this.editPersonalForm?.get(field);
    const regex = /\s/;
    if (regex.test(this.editPersonalForm.value[field])) {
      this.editPersonalForm.value[field] = '';
    }
    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      return this.getErrorInfo(field);
    }
    this.cdRef.detectChanges();
    return '';
  }
  /**
   * GetErrorInfo()
   * @param field Input Field
   * @returns error
   */
  getErrorInfo(field: any): any {
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    switch (field) {
      case this.globalDefault.EMAIL: {
        if (!this.editPersonalForm.value.email) {
          return emptyCheck;
        } else if (
          this.editPersonalForm.value.email.length < this.ranges.MAX.EMAIL &&
          this.editPersonalForm.value.email.match(this.emailRegex) == null
        ) {
          return this.pageLabels?.['errCode.E021'];
        } else if (
          this.editPersonalForm.value.email.length < this.ranges.MAX.EMAIL
        ) {
          return '';
        } else {
          return this.pageLabels?.['errCode.E021'];
        }
      }

      case this.globalDefault.FIRSTNAME: {
        if (!this.editPersonalForm.value.firstName) {
          return emptyCheck;
        } else if (field == this.globalDefault.FIRSTNAME) {
          this.firstNameErrorMsgInfo = this.pageLabels?.['errCode.E032'];
          return this.firstNameErrorMsgInfo;
        } else if (
          this.editPersonalForm.value.firstName.length <
          this.ranges.MAX.FIRST_NAME
        ) {
          return '';
        } else {
          this.firstNameErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.FIRST_NAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.firstNameErrorMsgInfo;
        }
      }
      case this.globalDefault.LASTNAME: {
        if (!this.editPersonalForm.value.lastName) {
          return emptyCheck;
        } else if (field == this.globalDefault.LASTNAME) {
          this.lastNameErrorMsgInfo = this.pageLabels?.['errCode.E032'];
          return this.lastNameErrorMsgInfo;
        } else if (
          this.editPersonalForm.value.lastName.length <
          this.ranges.MAX.LAST_NAME
        ) {
          return '';
        } else {
          this.lastNameErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.LAST_NAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.lastNameErrorMsgInfo;
        }
      }
      case this.globalDefault.FURGINA_LASTNAME: {
        if (
          this.editPersonalForm.value.last_name_furgina.length <
          this.ranges.MAX.FURGINA_LASTNAME
        ) {
          return '';
        } else {
          this.lastNameFuriganaErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.FURGINA_LASTNAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.lastNameFuriganaErrorMsgInfo;
        }
      }
      case this.globalDefault.FURGINA_FIRSTNAME: {
        if (
          this.editPersonalForm.value.first_name_furgina.length <
          this.ranges.MAX.FURGINA_FIRSTNAME
        ) {
          return '';
        } else {
          this.firstNameFuriganaErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.FURGINA_FIRSTNAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.firstNameFuriganaErrorMsgInfo;
        }
      }
    }
  }

  /**
   * checkDate-check the format of DOB
   */

  checkDate(): any {
    const inputDate = this.editPersonalForm.value.date_of_birth.toString();
    this.dobErrorMsgInfo = this.commonUtilityService.checkDate(
      inputDate,
      this.pageLabels
    );
  }

  /**
   * checkDate-check the primary Value
   */

  onPrimaryClick(event: any): void {
    if (event?.target?.id === 'primary') {
      this.primaryOfferSelected =
        event?.detail === this.globalDefault.CHECKED ? true : false;

      this.editPersonalForm.patchValue({
        primary: this.primaryOfferSelected,
      });
    }
  }

  /**
   * getRelativeUrl-It Will Construct and return Api URL
   */

  getRelativeUrl(url: string, guestId: string): string {
    return url.replace('{guestId}', guestId);
  }

  /**
   * matchDropDown-It Will generate GenderArray Dropdown
   */

  matchDropDown(event: any, type: any): void {
    if (type === this.globalDefault.GENDER && this.genderArray) {
      const selectedGenderValue = this.genderArray.find(
        (res: any) => res.value === event.detail
      );

      if (selectedGenderValue) {
        const englishEquivalentIndex = this.mapToEnglishGenderIndex(
          this.genderArray.indexOf(selectedGenderValue)
        );

        this.editPersonalForm.patchValue({
          gender: this.gender[englishEquivalentIndex],
        });
      }
    }
  }

  mapToEnglishGenderIndex(selectedIndex: number): number {
    return selectedIndex;
  }

  /**
   * onSubmit-It will save the form value to API
   */

  onSubmit(): any {
    this.savedSourceId = this.commonUtilityService?.getSourceId(
      this.sourceKeys?.WEB_EDITPERSONAL
    );
    if (!this.editPersonalForm.valid) {
      return false;
    }
    const formattedDob: any = this.commonUtilityService.formatDate(
      this.editPersonalForm?.value?.date_of_birth
    );
    if (this.contactToSend?.length > 1) {
      this.contactToSend.filter((data: any) => {
        if (data.primary === true) {
          const indexval = this.contactToSend.indexOf(data);
          this.contactToSend[indexval].last_name = get(
            this.editPersonalForm,
            'value.lastName',
            ''
          );
          this.contactToSend[indexval].first_name = get(
            this.editPersonalForm,
            'value.firstName',
            ''
          );
        } else {
          const indexval = this.contactToSend.indexOf(data);
          this.contactToSend[indexval].last_name = get(
            this.editPersonalForm,
            'value.last_name_furgina',
            ''
          );
          this.contactToSend[indexval].first_name = get(
            this.editPersonalForm,
            'value.first_name_furgina',
            ''
          );
        }
      });
      this.addressToSend.filter((data: any) => {
        if (data.primary === true) {
          const indexval = this.addressToSend.indexOf(data);
          this.addressToSend[indexval].last_name = get(
            this.editPersonalForm,
            'value.lastName',
            ''
          );
          this.addressToSend[indexval].first_name = get(
            this.editPersonalForm,
            'value.firstName',
            ''
          );
        }
      });
    } else {
      this.contactToSend = [
        {
          last_name: get(this.editPersonalForm, 'value.lastName', ''),
          first_name: get(this.editPersonalForm, 'value.firstName', ''),
          contact_type: this.globalDefault.CONTACT_TYPE_KANJI,
        },
        {
          last_name: get(this.editPersonalForm, 'value.last_name_furgina', ''),
          first_name: get(
            this.editPersonalForm,
            'value.first_name_furgina',
            ''
          ),
          contact_type: this.globalDefault.CONTACT_TYPE_FURGINA,
        },
      ];
    }

    const requestPayload = {
      gender: get(this.editPersonalForm, 'value.gender', ''),
      date_of_birth: formattedDob,
      contacts: this.contactToSend,
      addresses: this.addressToSend,

      email_address: get(this.editPersonalForm, 'value.email', ''),

      preferences: {
        comm_prefs: {
          email_offers_promos: this.editPersonalForm.value.primary,
        },
      },
      status: 'active',
    };
    this.processRequest(requestPayload);
  }

  /**
   * processRequest - on submit api call
   * @param requestPayload  - pass the request payload
   */

  processRequest(requestPayload: any): any {
    const containsNumbers = /\d/.test(this.userProfileData?.date_of_birth);
    if (this.editPersonalForm?.value?.date_of_birth === '' || containsNumbers) {
      delete requestPayload.date_of_birth;
    }
    if (
      (this.userProfileData?.gender == undefined &&
        this.editPersonalForm?.value?.gender == '') ||
      this.editPersonalForm?.value?.gender == undefined ||
      this.editPersonalForm?.value?.gender == this.gender[3] ||
      this.genderShow == true
    ) {
      delete requestPayload.gender;
    }
    if (
      this.editPersonalForm?.value?.first_name_furgina == undefined &&
      this.editPersonalForm?.value?.last_name_furgina == undefined
    ) {
      requestPayload.contacts.splice(1, 1);
    }
    if (requestPayload) {
      const guestId = this.guestsService.helper.getGuestId();
      const url = this.getRelativeUrl(this.relativeUrls.UPDATE_USER, guestId);
      let httpHeaders = new HttpHeaders({});

      httpHeaders = httpHeaders.append('X-Source-Id', this.savedSourceId);
      const eTag = this.localStorage?.getItem('GUESTS-ETAG') || '';
      httpHeaders = httpHeaders.append('If-Match', eTag);
      from(
        this.dal.httpRequest(url, 'PATCH', requestPayload, httpHeaders)
      ).subscribe(
        (response) => {
          if (response) this.ddlEvents(this.globalDefault.SUCCESS);
          this.commonUtilityService.changeRoute(this.appRoutes.MY_PROFILE);
        },
        (error) => {
          if (error?.status === this.globalDefault.ERROR_409) {
            this.notification.show(
              this.pageLabels?.['errCode.MS.editInfo.409']
            );
          } else if (error?.status === this.globalDefault.ERROR_401) {
            this.commonUtilityService.setUser();
            localStorage.clear();
            sessionStorage.clear();
            this.cookieService.deleteAll('/', environment.cookieSubDomain);
            const redirectUrl =
              this.commonUtilityService.getOidcRedirectionUrl();
            this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
          } else {
            this.notification.show(this.pageLabels?.['errorCode.E019']);
          }
          this.ddlEvents(this.globalDefault.FAILED);
          this.commonUtilityService.changeRoute(this.appRoutes.MY_PROFILE);
        }
      );
    }
  }

  /**
   * ddlEvents - On address update these events are getting set
   * @param type - On api response success or failed
   */
  ddlEvents(type: string): void {
    const guestId = this.guestsService.helper.getGuestId();
    this.commonUtilityService.invokeFireEvent(
      'personalInformationUpdate',
      type,
      'Form',
      'null',
      new Date().toISOString(),
      guestId,
      '',
      this.window?.digitalData?.page?.category?.primaryCategory ?? '',
      this.window?.digitalData?.page?.category?.subCategory1 ?? ''
    );
    // this.commonUtilityService?.setUser();
  }

  private setDependencies(): void {
    this.route = this.injector.get(ActivatedRoute);
    this.commonUtilityService = this.injector.get(CommonUtilityService);
    this.guestsService = this.injector.get(MsGuestsService);
    this.fb = this.injector.get(FormBuilder);
    this.notification = this.injector.get(NotificationService);
  }

  /**
   * emailValidation-It will validate email from API
   */

  emailValidation(event: any): any {
    const email_id = event.target.value;
    this.postEmailValidate(email_id);
  }

  /**
   * postEmailValidate-It will validate email from API
   */

  postEmailValidate(email_id: any): any {
    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append('x-instance-id', '123');
    httpHeaders = httpHeaders.append('x-source-id', '1234');

    const request = {
      email_address: email_id,
    };
    const url = this.getRelativeUrls(
      this.relativeUrls.EMAIL_VALIDATE,
      this.appDefault.USJ
    );
    this.invalidEmail = true;
    this.dal
      .httpRequest(url, this.appDefault.POST, request, httpHeaders)
      .then((data: any) => {
        const verdict = data?.verdict;
        if (verdict === this.globalDefault.RISKY) {
          this.invalidEmail = false;
          if (this.globalDefault.SEARCH_KEY in data && data?.suggestion != '') {
            this.toastService.show({
              text:
                this.pageLabels?.['email.risky_with_suggestion_snack'] +
                data?.suggestion,
              type: this.globalDefault.SUCCESS,
            });
          }
          if (
            this.globalDefault.SEARCH_KEY in data === false ||
            (this.globalDefault.SEARCH_KEY in data && data?.suggestion === '')
          ) {
            this.toastService.show({
              text: this.pageLabels?.['email.risky_wo_suggestion_snack'],
              type: this.globalDefault.SUCCESS,
            });
          }
        }

        if (verdict === this.globalDefault.INVALID) {
          this.toastService.show({
            text: this.pageLabels?.['email.invalid_snack'],
            type: this.globalDefault.SUCCESS,
          });
          this.invalidEmail = true;
        }
        if (verdict === this.globalDefault.VALID) {
          this.invalidEmail = false;
        }
      })
      .catch((error) => {
        this.invalidEmail = error.status ? false : true;
        if (error.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
      });
  }

  /**
   * getRelativeUrls-It will construct the URL for Post email-validation
   */

  public getRelativeUrls(url: string, areaCode: string): string {
    return url.replace('{resort-area-code}', areaCode);
  }
}
