import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { sendEvent } from '@upr-web-primer/app-utils';
import {
  CALENDAR_OUTPUT_EVENT_KEYS,
  CALENDAR_TRIGGER_EVENT_KEYS,
  CECalendarStore,
  IGetMonthStoreProps,
} from '@upr-web-primer/gds-calendar';
import { LocaleService } from '@upr-web-primer/locale';
import { NotificationService } from '@upr-web-primer/notification';
import { WINDOW } from '@upr-web-primer/window';
import _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CommonUtilityService } from '../../services/common-utility.service';
@Component({
  selector: 'app-lost-found-web-form',
  templateUrl: './lost-found-web-form.component.html',
  styleUrls: ['./lost-found-web-form.component.scss'],
})
export class LostFoundWebFormComponent implements OnInit {
  pageLabels: any;
  @Input() lostFoundWebForm: FormGroup;
  nameRegex = this.namePattern.NAME_SIGNUP;
  subscriptions = new Subscription();
  firstNameErrorMsgInfo: string;
  lastNameErrorMsgInfo: string;
  emailErrorMsgInfo: string;
  reEnterEmailErrorMsgInfo: string;
  inputChanged: boolean;
  agreeConditionSelected: boolean;
  countAgreeSelected: boolean;
  privacyAgreeSelected: boolean;
  lostFoundWebFormData: any;
  calendarOutputInfo: any;
  datePickerMonths: any;
  gdsDatePickerCalendarInfo: {
    shortWeekdays: any;
    data: { defaultDate: { dayInfo: any } };
  };
  currentMonthObj: any;
  datePickerData: boolean;
  selectedDate: any;
  postalPin: any;
  postalErrorMsg: any;
  validPostalResponse: any;
  prefectureDetailsInfo: any;
  selectedPrefectureList: any;
  cityErrorMsg: string;
  prefectureErrorMsg: string;
  townErrorMsg: string;
  showPrefectureDropdownOptions: { label: any; value: any }[];
  firstNameFurginaErrorMsgInfo: string;
  lastNameFurginaErrorMsgInfo: string;
  phoneNumberErrorInfo: string;
  lostTimeErrorInfo: string;
  lostLocErrorInfo: string;
  checkboxValue: boolean;
  streetErrorMsg: string;
  lostItemRadioButtons: { label: any; value: number }[];
  lostPropertyRadioButtons: (Promise<any> | { label: any; value: number })[];
  selectedLostItemRadio: boolean;
  selectedLostPropertyRadio: boolean;
  detailsOfLostLocErrorInfo: any;
  patchingDate: string | null;
  selectionDate = false;
  disableSendBtn = false;

  constructor(
    public common: CommonUtilityService,
    public formBuilder: FormBuilder,
    @Inject('NamePattern') public namePattern: any,
    @Inject('Ranges') public ranges: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    public cdRef: ChangeDetectorRef,
    @Inject('EmailPasswordPattern') public emailRegex: any,
    @Inject('AppDefaults') public appDefault: any,
    private datePipe: DatePipe,
    @Inject(WINDOW) public window: Window,
    private cdr: ChangeDetectorRef,
    // private localeService: LocaleService,
    private cookieService: CookieService,
    public notification: NotificationService,
    private locale: LocaleService
  ) {
    this.pageLabels = this.common.getResolvedPageData();
  }
  /**
   * ngOnInit - It will load all the functions
   */
  ngOnInit(): void {
    this.lostItemRadioButtons = [
      {
        label: this.pageLabels?.['lostForm.lostItem_option1'],
        value: 752510000,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option2'],
        value: 752510001,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option3'],
        value: 752510002,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option4'],
        value: 752510003,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option5'],
        value: 752510004,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option6'],
        value: 752510005,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option7'],
        value: 752510006,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option8'],
        value: 752510007,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option9'],
        value: 752510008,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option10'],
        value: 752510009,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option11'],
        value: 752510010,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option12'],
        value: 752510011,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option13'],
        value: 752510012,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option14'],
        value: 752510013,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option15'],
        value: 752510014,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option16'],
        value: 752510015,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option17'],
        value: 752510016,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option18'],
        value: 752510017,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option19'],
        value: 752510018,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option20'],
        value: 752510019,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option21'],
        value: 752510020,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option22'],
        value: 752510021,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option23'],
        value: 752510022,
      },
      {
        label: this.pageLabels?.['lostForm.lostItem_option24'],
        value: 752510023,
      },
    ];
    this.lostPropertyRadioButtons = [
      {
        label: this.pageLabels?.['lostForm.property_found_park'],
        value: 752510000,
      },
      {
        label: this.pageLabels?.['lostForm.property_found_mail'],
        value: 752510001,
      },
    ];
    this.common?.OIDCTokenAnonymous();
    const date = new Date();
    this.createForm();
    this.getFormData();
    const firstDate: any = new Date(date.getFullYear(), date.getMonth() - 3, 1);
    const lastDate: any = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.getCurrentDateObj();
    this.getCalenderInfo(
      this.datePipe?.transform(firstDate, 'YYYY-MM-dd'),
      this.datePipe?.transform(lastDate, 'YYYY-MM-dd')
    );
    this.loadPrefecture();
  }
  /**
   * createForm - create the form
   */

  createForm(): void {
    this.lostFoundWebForm = this.formBuilder.group({
      agree: new FormControl('', [Validators.requiredTrue]),
      countAgree: new FormControl('', [Validators.requiredTrue]),
      privacyAgree: new FormControl('', [Validators.requiredTrue]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
        this.noWhitespaceValidator(),
        Validators.pattern(this.nameRegex),
      ]),
      last_name_furgina: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
        this.noWhitespaceValidator(),
        Validators.pattern(this.nameRegex),
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(this.nameRegex),
        this.noWhitespaceValidator(),
      ]),
      first_name_furgina: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(this.nameRegex),
        this.noWhitespaceValidator(),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailRegex.EMAIL),
      ]),
      reEnterEmail: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailRegex.EMAIL),
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(11),
      ]),
      postalCode: new FormControl('', [
        <any>Validators.required,
        Validators.minLength(7),
        Validators.maxLength(20),
        this.noWhitespaceValidator(),
      ]),
      prefecture: new FormControl('', [
        Validators.required,
        Validators.maxLength(120),
        this.noWhitespaceValidator(),
      ]),
      city: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        this.noWhitespaceValidator(),
      ]),
      town: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        this.noWhitespaceValidator(),
      ]),
      street: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      dob: new FormControl(''),
      lostTime: new FormControl('', [Validators.required]),
      lostLocation: new FormControl('', [Validators.required]),
      selectedLostItemButton: new FormControl('', [Validators.required]),
      detailLostItem: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(2000),
      ]),
      selectedLostPropertyButton: new FormControl('', [Validators.required]),
    });
  }
  /**
   * noWhitespaceValidator
   * @returns
   */

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace =
        (control.value || '')?.trim()?.length ===
        this.ranges.PASSWORDS.INDEX[0];
      return isWhitespace ? { whitespace: true } : null;
    };
  }
  /**
   * getErrorMessageInfo - Validations of each field
   * @param event - event
   */
  getErrorMessageInfo(event: any): void {
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.POSTALCODE:
        if (event?.target?.value?.length !== this.ranges?.MAX?.POSTALCODE) {
          this.cdRef.detectChanges();
          return (this.postalErrorMsg = this.pageLabels?.['errCode.E013']);
        } else {
          this.selectedPrefectureList = '';
        }
        break;
      case this.globalDefault.FIRSTNAME:
        this.lostFoundWebForm.value.firstName =
          this.lostFoundWebForm.value.firstName?.trim();

        const firstNameValue = this.lostFoundWebForm.value.firstName;
        if (firstNameValue?.length > this.ranges.MAX.FIRST_NAME) {
          this.lostFoundWebForm.controls[this.globalDefault.FIRSTNAME].setValue(
            this.lostFoundWebForm.value.firstName.substring(
              0,
              this.ranges.MAX.FIRST_NAME
            )
          );
        }
        this.firstNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.LASTNAME:
        this.lostFoundWebForm.value.lastName =
          this.lostFoundWebForm.value.lastName?.trim();
        const lastNameValue = this.lostFoundWebForm.value.lastName;
        if (lastNameValue?.length > this.ranges.MAX.LAST_NAME) {
          this.lostFoundWebForm.controls[this.globalDefault.LASTNAME].setValue(
            this.lostFoundWebForm.value.lastName.substring(
              0,
              this.ranges.MAX.LAST_NAME
            )
          );
        }
        this.lastNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.FURGINA_FIRSTNAME:
        this.lostFoundWebForm.value.first_name_furgina =
          this.lostFoundWebForm.value.first_name_furgina?.trim();

        const firstNameFurginaValue =
          this.lostFoundWebForm.value.first_name_furgina;
        if (firstNameFurginaValue?.length > this.ranges.MAX.FIRST_NAME) {
          this.lostFoundWebForm.controls[
            this.globalDefault.FURGINA_FIRSTNAME
          ].setValue(
            this.lostFoundWebForm.value.first_name_furgina.substring(
              0,
              this.ranges.MAX.FIRST_NAME
            )
          );
        }
        this.firstNameFurginaErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.FURGINA_LASTNAME:
        this.lostFoundWebForm.value.last_name_furgina =
          this.lostFoundWebForm.value.last_name_furgina?.trim();

        const lastNameFurginaValue =
          this.lostFoundWebForm.value.last_name_furgina;
        if (lastNameFurginaValue?.length > this.ranges.MAX.FIRST_NAME) {
          this.lostFoundWebForm.controls[
            this.globalDefault.FURGINA_LASTNAME
          ].setValue(
            this.lostFoundWebForm.value.last_name_furgina.substring(
              0,
              this.ranges.MAX.FIRST_NAME
            )
          );
        }
        this.lastNameFurginaErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.EMAIL:
        const emailValue = this.lostFoundWebForm.value.email;
        if (emailValue?.length >= this.ranges.MAX.EMAIL) {
          this.lostFoundWebForm.controls[this.globalDefault.EMAIL].setValue(
            this.lostFoundWebForm.value.email.substring(
              0,
              this.ranges.MAX.EMAIL
            )
          );
        }
        this.emailErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case 'reEnterEmail':
        const reEnterEmailValue = this.lostFoundWebForm.value.reEnterEmail;
        if (reEnterEmailValue?.length >= this.ranges.MAX.EMAIL) {
          this.lostFoundWebForm.controls['reEnterEmail'].setValue(
            this.lostFoundWebForm.value.reEnterEmail.substring(
              0,
              this.ranges.MAX.EMAIL
            )
          );
        }
        this.reEnterEmailErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.PHONENUMBER:
        this.phoneNumberErrorInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.PREFECTURE:
        this.prefectureErrorMsg = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.CITY:
        this.cityErrorMsg = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.TOWN:
        this.townErrorMsg = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.STREET:
        this.streetErrorMsg = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.LOST_TIME:
        this.lostTimeErrorInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.LOST_LOCATION:
        this.lostLocErrorInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.DETAILS_OF_LOST_ITEM:
        this.detailsOfLostLocErrorInfo = this.getErrorMessage(fieldInfo);
        break;
    }
    this.cdRef.detectChanges();
  }
  /**
   * getErrorMessage - Validations for each field
   * @param field
   * @returns
   */
  getErrorMessage(field: any): string {
    const fieldName = this.lostFoundWebForm?.get(field);
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    const regex = /\s/;
    if (regex.test(this.lostFoundWebForm.value[field]))
      this.lostFoundWebForm.value[field] = '';

    if (field === 'reEnterEmail') {
      // Checking re-enter email
      if (!this.lostFoundWebForm.value.reEnterEmail) return emptyCheck;
      else if (
        this.lostFoundWebForm.value.reEnterEmail?.length <
          this.ranges.MAX.EMAIL &&
        this.lostFoundWebForm.value.reEnterEmail.match(
          this.emailRegex.EMAIL_SIGNUP
        ) == null
      ) {
        return this.pageLabels?.['errCode.E021'];
      } else if (
        this.lostFoundWebForm.value.email !==
        this.lostFoundWebForm.value.reEnterEmail
      ) {
        this.inputChanged = false;
        this.reEnterEmailErrorMsgInfo = this.pageLabels?.['errCode.E030'];
        return this.reEnterEmailErrorMsgInfo;
      }
    }

    if (field === this.globalDefault.EMAIL) {
      if (!this.lostFoundWebForm.value.email) return emptyCheck;
      else if (
        this.lostFoundWebForm.value.email?.length < this.ranges.MAX.EMAIL &&
        this.lostFoundWebForm.value.email.match(this.emailRegex.EMAIL_SIGNUP) ==
          null
      ) {
        return this.pageLabels?.['errCode.E021'];
      } else if (
        this.lostFoundWebForm.value.email?.length < this.ranges.MAX.EMAIL
      ) {
        return '';
      } else {
        return this.pageLabels?.['errCode.E021'];
      }
    }

    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      return this.getErrorInfo(field);
    }
    this.cdRef.detectChanges();
    return '';
  }
  /**
   * getErrorInfo()
   * @param field Input field
   * @returns Error message
   */
  getErrorInfo(field: any): any {
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    switch (field) {
      case this.globalDefault.FIRSTNAME: {
        if (!this.lostFoundWebForm.value.firstName) return emptyCheck;
        else if (
          this.lostFoundWebForm.value.firstName?.length <
          this.ranges.MAX.FIRST_NAME
        ) {
          return '';
        } else {
          this.firstNameErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.FIRST_NAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.firstNameErrorMsgInfo;
        }
      }
      case this.globalDefault.LASTNAME: {
        if (!this.lostFoundWebForm.value.lastName) return emptyCheck;
        else if (
          this.lostFoundWebForm.value.lastName?.length <
          this.ranges.MAX.LAST_NAME
        ) {
          return '';
        } else {
          this.lastNameErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.LAST_NAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.lastNameErrorMsgInfo;
        }
      }
      case this.globalDefault.FURGINA_LASTNAME: {
        if (!this.lostFoundWebForm.value.last_name_furgina) return emptyCheck;
        else if (
          this.lostFoundWebForm.value.last_name_furgina?.length <
          this.ranges.MAX.FIRST_NAME
        ) {
          return '';
        } else {
          this.firstNameFurginaErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.FIRST_NAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.firstNameFurginaErrorMsgInfo;
        }
      }
      case this.globalDefault.FURGINA_FIRSTNAME: {
        if (!this.lostFoundWebForm.value.first_name_furgina) return emptyCheck;
        else if (
          this.lostFoundWebForm.value.first_name_furgina?.length <
          this.ranges.MAX.FIRST_NAME
        ) {
          return '';
        } else {
          this.lastNameFurginaErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.FIRST_NAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.lastNameFurginaErrorMsgInfo;
        }
      }
      case this.globalDefault.PHONENUMBER: {
        if (!this.lostFoundWebForm.value.phoneNumber) return emptyCheck;
        else {
          this.phoneNumberErrorInfo = this.pageLabels?.['errCode.E007'];
          return this.phoneNumberErrorInfo;
        }
      }
      case this.globalDefault.LOST_TIME: {
        if (!this.lostFoundWebForm.value.lostTime) return emptyCheck;
        else {
          this.lostTimeErrorInfo = this.pageLabels?.['errCode.E007'];
          return this.lostTimeErrorInfo;
        }
      }
      case this.globalDefault.LOST_LOCATION: {
        if (!this.lostFoundWebForm.value.lostLocation) return emptyCheck;
        else {
          this.lostLocErrorInfo = this.pageLabels?.['errCode.E007'];
          return this.lostLocErrorInfo;
        }
      }
      case this.globalDefault.DETAILS_OF_LOST_ITEM: {
        if (!this.lostFoundWebForm.value.detailLostItem) return emptyCheck;
        else {
          this.detailsOfLostLocErrorInfo = this.pageLabels?.['errCode.E031'];
          return this.detailsOfLostLocErrorInfo;
        }
      }
      case this.globalDefault.STREET: {
        if (!this.lostFoundWebForm.value.street) return emptyCheck;
        else {
          this.streetErrorMsg = this.pageLabels?.['errCode.E007'];
          return this.streetErrorMsg;
        }
      }
      case this.globalDefault.CITY: {
        if (!this.lostFoundWebForm.value.city) return emptyCheck;
        else {
          this.cityErrorMsg = this.pageLabels?.['errCode.E015'];
          return this.cityErrorMsg;
        }
      }
      case this.globalDefault.TOWN: {
        if (!this.lostFoundWebForm.value.town) return emptyCheck;
        else {
          this.townErrorMsg = this.pageLabels?.['errCode.E015'];
          return this.townErrorMsg;
        }
      }
      case this.globalDefault.PREFECTURE: {
        if (!this.lostFoundWebForm.value.prefecture) return emptyCheck;
        else {
          this.prefectureErrorMsg = this.pageLabels?.['errCode.E015'];
          return this.prefectureErrorMsg;
        }
      }
    }
  }
  /**
   * onCheckboxChange - to handle the change of checkbox values
   * @param event The event object
   * @param checkboxType The type of checkbox ('agree' or 'reply')
   */
  onCheckboxChange(event: any, checkboxType: string): void {
    this.checkboxValue = false;

    // Determine the checkbox value based on the event detail
    if (event?.target?.id === checkboxType) {
      this.checkboxValue =
        event?.detail === this.globalDefault.CHECKED ? true : false;
    }

    // Update the corresponding form control based on the checkbox type
    switch (checkboxType) {
      case this.globalDefault.AGREE:
        this.agreeConditionSelected = this.checkboxValue;
        this.lostFoundWebForm.patchValue({
          agree: this.agreeConditionSelected,
        });
        break;
      case this.globalDefault.COUNT_AGREE:
        this.countAgreeSelected = this.checkboxValue;
        this.lostFoundWebForm.patchValue({
          countAgree: this.countAgreeSelected,
        });
        break;
      case this.globalDefault.PRIVACY_AGREE:
        this.privacyAgreeSelected = this.checkboxValue;
        this.lostFoundWebForm.patchValue({
          privacyAgree: this.privacyAgreeSelected,
        });
        break;
      default:
        break;
    }
  }
  /**
   * onRadioButtonChange - to handle the change of radio values
   * @param event The event object
   * @param onRadioButtonChange The type of radio ('Park' or 'Mail')
   */
  onRadioButtonChange(event: any, radioType: any): void {
    if (event?.target?.id == this.globalDefault.SELECTED_LOST_ITEM) {
      this.selectedLostItemRadio = true;
      this.lostFoundWebForm.patchValue({
        selectedLostItemButton: radioType.value,
      });
    } else {
      this.selectedLostPropertyRadio = true;
      this.lostFoundWebForm.patchValue({
        selectedLostPropertyButton: radioType.value,
      });
    }
  }
  /**
   * getAddress-Get edited value from the Address
   */

  getFormData(): void {
    this.lostFoundWebFormData = this.common.getFormData();
  }
  /**
   * onKeyPressEvent - on key press event occur to check the input type
   * @param event - contains the characters entered in input field
   */
  onKeyPressEvent(event: KeyboardEvent): void {
    const isNumeric = /[0-9]/.test(event.key);
    const isControlKey = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
    ].includes(event.key);

    // Allow numeric input or control keys, prevent other characters
    if (!isNumeric && !isControlKey) {
      event.preventDefault();
    }
  }
  /**
   * getCalenderInfo - get the date from the calender
   * @param startDate - pass the startDate
   * @param endDate -  pass the endDate
   */
  getCalenderInfo(startDate: any, endDate: any): any {
    const productObj = {
      product: [],
      products: [],
    };
    this.addMonthSubscription();
    this.getInitialMonths(startDate, endDate, productObj, 0);
    this.cdr.detectChanges();
  }
  /**
   * getCurrentDateObj - get the user selected date
   */
  getCurrentDateObj(): any {
    this.datePickerData = false;
    this.cdr.detectChanges();
    const selectedDate = this.selectedDate ? this.selectedDate.toString() : '';
    let date;
    if (selectedDate) {
      date = new Date(selectedDate);
    } else {
      date = '';
    }
    this.patchingDate = this.datePipe?.transform(date, 'YYYY-MM-dd');
    this.currentMonthObj = {
      shortDate: this.pageLabels?.['lostForm.visitDate_ChooseDate'],
      longDate: this.patchingDate
        ? this.patchingDate
        : this.datePipe?.transform(new Date(), 'YYYY-MM-dd'),
      date: this.patchingDate
        ? this.patchingDate
        : this.datePipe?.transform(new Date(), 'YYYY-MM-dd'),
      ariaLabel: this.patchingDate
        ? this.patchingDate
        : this.datePipe?.transform(new Date(), 'YYYY-MM-dd'),
      isoDate: this.patchingDate
        ? this.patchingDate
        : this.datePipe?.transform(new Date(), 'YYYY-MM-dd'),
    };
    this.buildGdsDatePickerCalendarInfo();
    this.cdr.detectChanges();
  }
  /**
   * formatShortDate - display the date format based on the languages
   * @param date - pass the selected date from user
   * @returns
   */
  formatShortDate(date: Date): string {
    const localeInfo = this.locale.getFromUrl();
    if (localeInfo.language === 'en') {
      return this.datePipe?.transform(date, 'MMM dd, yyyy') || '';
    } else {
      return this.datePipe?.transform(date, 'yyyy年MM月dd日') || '';
    }
  }
  /**
   * addMonthSubscription - get the month
   */
  addMonthSubscription(): void {
    const monthWithInventory = fromEvent<CECalendarStore>(
      this.window,
      CALENDAR_OUTPUT_EVENT_KEYS.OUT_GET_MONTH_PARK_HOURS
    ).subscribe((data: any) => {
      this.calendarOutputInfo = data?.detail;
      this.datePickerMonths = data?.detail?.months;
      const currentDate = new Date();
      for (const month of this.datePickerMonths) {
        for (const day of month.days) {
          // Disable future dates within the current month
          if (month.number === currentDate.getMonth() + 1) {
            if (day.number > currentDate.getDate()) {
              day.available = false;
            } else {
              day.available = true;
            }
          } else {
            day.available = true;
          }
          day.isPast = false;
        }
        if (month.number === 4) {
          month.isFuture = false;
        } else {
          month.isFuture = true;
        }
      }
      this.buildGdsDatePickerCalendarInfo();
      this.cdr.detectChanges();
    });
    this.subscriptions?.add(monthWithInventory);
  }
  /**
   * buildGdsDatePickerCalendarInfo - display the date
   */
  buildGdsDatePickerCalendarInfo(): any {
    this.gdsDatePickerCalendarInfo = {
      shortWeekdays: this.calendarOutputInfo?.info?.shortWeekdays,
      data: { defaultDate: { dayInfo: this.currentMonthObj } },
    };
    this.datePickerData = true;
  }
  /**
   * getInitialMonths - get initial months
   * @param startDate - pass startDate
   * @param endDate - pass endDate
   * @param item - pass item
   * @param monthCount - pass monthCount
   */
  getInitialMonths(
    startDate: string,
    endDate: string,
    item: IGetMonthStoreProps['item'],
    monthCount: number | undefined
  ): void {
    //Used GET_MONTH_PARK_HOURS instead of GET_MONTH (Used in calendar integration ) because it is impacting calendar store
    sendEvent<IGetMonthStoreProps>(
      CALENDAR_TRIGGER_EVENT_KEYS.GET_MONTH_PARK_HOURS,
      {
        startDate,
        endDate: endDate,
        item,
        monthCount,
        useSpinner: true,
      }
    );
    this.cdr.detectChanges();
  }
  /**
   * getSelectDate - get selected date and patch in the form
   * @param event - get the selected date
   */
  getSelectDate(event: any): void {
    this.selectionDate = true;
    if (event?.detail?.dayInfo?.isoDate) {
      this.selectedDate = event?.detail?.dayInfo?.isoDate?.substring(0, 10);
    } else {
      this.selectedDate = event?.detail?.dayInfo?.date;
    }
    this.getCurrentDateObj();
  }
  /**
   * getPostalCodes-Based on postal code will return the prefecture,city,line1
   */

  getPostalCodes(): any {
    this.postalPin = this.lostFoundWebForm.get('postalCode')?.value;
    if (
      this.lostFoundWebForm.value.postalCode?.length !==
      this.ranges.MAX.POSTALCODE
    ) {
      this.cdRef.detectChanges();
      return (this.postalErrorMsg = this.pageLabels?.['errCode.E013']);
    } else {
      this.postalErrorMsg = '';
    }
    this.common.loadPostal(this.postalPin).then(
      (res: any) => {
        this.validPostalResponse = res;
        this.lostFoundWebForm
          .get('city')
          ?.patchValue(this.validPostalResponse?.[0]?.region);
        this.lostFoundWebForm
          .get('town')
          ?.patchValue(this.validPostalResponse?.[0]?.locality);
        _.filter(this.prefectureDetailsInfo, (pdiRes: any) => {
          if (pdiRes.name === this.validPostalResponse[0].municipality) {
            this.selectedPrefectureList = pdiRes;
            this.lostFoundWebForm
              ?.get('prefecture')
              ?.patchValue(this.selectedPrefectureList?.name);
            this.cityErrorMsg = '';
            this.townErrorMsg = '';
            this.prefectureErrorMsg = '';
          }
        });
        this.cdRef.detectChanges();
      },
      (err) => {
        if (err.error.title === this.globalDefault.NOT_FOUND) {
          this.postalErrorMsg = this.pageLabels?.['errCode.E014'];
          this.lostFoundWebForm.get('city')?.patchValue('');
          this.lostFoundWebForm.get('town')?.patchValue('');
          this.cityErrorMsg = '';
          this.townErrorMsg = '';
          this.prefectureErrorMsg = '';
          this.cdRef.detectChanges();
        }
        if (err.status == this.globalDefault.ERROR_401) {
          this.common.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.common.getOidcRedirectionUrl();
          this.common.redirectToOidcAppEndSession(redirectUrl);
        }
      }
    );
  }
  /**
   * loadPrefecture-It will load the Prefecture
   */

  loadPrefecture(): void {
    this.common
      .loadPrefectureList()
      .then((res) => {
        this.prefectureDetailsInfo = res;
        this.sortAddress();
        this.showPrefectureDropdownOptions = _.map(
          this.prefectureDetailsInfo,
          (optionInfo: any) => {
            return { label: optionInfo.name, value: optionInfo };
          }
        );
        this.cdRef.detectChanges();
      })
      .catch((errors: any) => {
        if (errors.status == this.globalDefault.ERROR_401) {
          this.common.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.common.getOidcRedirectionUrl();
          this.common.redirectToOidcAppEndSession(redirectUrl);
        }
      });
  }
  /**
   * sortAddress-Sort Address based on Iso-Numeric
   */
  sortAddress(): void {
    if (Array.isArray(this.prefectureDetailsInfo)) {
      this.prefectureDetailsInfo?.sort((a: any, b: any) => {
        const extractNumeric = (obj: any): string =>
          (obj.iso_numeric || '').replace(/^\D+/g, ''); // Specify the return type here
        const a1 = parseFloat(extractNumeric(a));
        const b1 = parseFloat(extractNumeric(b));
        return a1 - b1;
      });
    } else {
      console.error('prefectureDetailsInfo is not an array.');
    }
  }
  /**
   * onSend - It will submit the formControl to api url
   */
  onSend(): any {
    this.lostFoundWebForm.patchValue({
      dob: this.patchingDate,
    });
    const data = this.lostFoundWebForm.value;
    const request = {
      udx_emailaddress: data?.email,
      udx_furiganafirstname: data?.first_name_furgina,
      udx_firstname: data?.firstName,
      udx_surname: data?.lastName,
      udx_furiganasurname: data?.last_name_furgina,
      udx_registrationconfirmation: data?.agree,
      udx_firsttimeregistration: data?.countAgree,
      udx_privacypolicy: data?.privacyAgree,
      udx_postalcode: data?.postalCode,
      udx_prefecture: data?.prefecture,
      udx_city: data?.city,
      udx_townvillage: data?.town,
      udx_streetblocknumberhouse: data?.street,
      udx_dateofloss: data?.dob,
      udx_losttime: data?.lostTime,
      udx_lostlocation: data?.lostLocation,
      udx_lostitem: data?.selectedLostItemButton,
      udx_detailsoflostitem: data?.detailLostItem,
      udx_returnmethod: data?.selectedLostPropertyButton,
      udx_phonenumber: data?.phoneNumber,
    };
    this.common.lostFoundWebForm(request).subscribe(
      (res: any) => {
        if (res) this.disableSendBtn = true;
        this.notification.show(
          this.pageLabels?.['lostForm.success'],
          this.globalDefault.SUCCESS
        );
      },
      (err: any) => {
        if (err) this.disableSendBtn = true;
        this.notification.show(this.pageLabels?.['errCode.E019']);
      }
    );
  }
}
