// initialization.service.ts

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InitializationService {
  constructor(
    @Inject('RelativeUrls') private relativeUrls: any,
    private http: HttpClient
  ) {}

  initializeApp(): Promise<any> {
    const url =
      environment.contentApiBaseUrl + this.relativeUrls.GET_CONTENTIDTOKEN;
    const params = new HttpParams()
      .set('client_id', encodeURIComponent(environment.contentLoginClientID))
      .set(
        'client_secret',
        encodeURIComponent(environment.contentLoginClientKeyID)
      )
      .set('grant_type', encodeURIComponent('client_credentials'));

    const headersInfo: any = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const headers = new HttpHeaders(headersInfo);

    return this.http
      .post(url, params.toString(), { headers })
      .toPromise()
      .then((res: any) => {
        localStorage.setItem('contentOidcToken', res?.access_token);
      })
      .catch((error) => {
        console.error('Error initializing app:', error);
      });
  }
}
