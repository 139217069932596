import { Injectable } from '@angular/core';
import { DalService } from '@upr-web-primer/dal';
import {
  LocalStorageService,
  SessionStorageService,
} from '@upr-web-primer/storage';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationDataService {
  constructor(
    private localStorage: LocalStorageService,
    private dal: DalService,
    private sessionStorage: SessionStorageService,
    private cookieService: CookieService
  ) {}

  readCookieData(): void {
    const access_token = this.cookieService.get('access_token');
    const commerceTokenInfo = this.cookieService.get('commerceTokenInfo');
    const expires_in = this.cookieService.get('expires_in');
    const userInfo = this.cookieService.get('userInfo');
    if (access_token && commerceTokenInfo && userInfo) {
      this.localStorage.setItem('oAuthLogin', 'true');
      this.sessionStorage.setItem('oAuthLogin', 'true');
      this.localStorage.setItem('IS_SIGN_IN', 'true');
      this.sessionStorage.setItem('IS_SIGN_IN', 'true');
      this.localStorage.setItem('oAuthLogin', 'true');
      this.sessionStorage.setItem('IsGuestAccount', 'true');
      if (access_token) {
        this.localStorage.setItem('OIDC_ACCESS_TOKEN', access_token);
        this.sessionStorage.setItem('OIDC_ACCESS_TOKEN', access_token);
        this.localStorage.setItem('OIDC_ID_TOKEN', access_token);
        this.sessionStorage.setItem('OIDC_ID_TOKEN', access_token);
      }

      if (expires_in) {
        this.localStorage.setItem('OIDC_EXPIRES_IN', expires_in);
        this.sessionStorage.setItem('OIDC_EXPIRES_IN', expires_in);
      }
      this.dal.setOAuthTokens(
        true,
        access_token,
        access_token,
        Number(expires_in),
        Number(expires_in)
      );
      localStorage.setItem(
        'loginAuthToken',
        JSON.stringify(JSON.parse(commerceTokenInfo))
      );
      const userInfoObj: any = JSON.parse(userInfo);
      const { email, firstName, lastName, guestId } = userInfoObj;
      this.localStorage.setItem('EMAIL_USER_ID', email);
      this.sessionStorage.setItem('EMAIL_USER_ID', email);
      this.localStorage.setItem('USER_LNAME', lastName);
      this.sessionStorage.setItem('USER_LNAME', lastName);

      this.localStorage.setItem('USER_FNAME', firstName);
      this.sessionStorage.setItem('USER_FNAME', firstName);
      this.sessionStorage.setItem('LOGGEDIN_GUEST_ID', guestId);

      this.localStorage.setItem('USER_Ldap', guestId);
      this.localStorage.setItem('LOGGEDIN_GUEST_ID', guestId);
      this.localStorage.setItem('oAuthLoginUserName', email);
      this.localStorage.setItem('OAUTH_LOGIN_GID', guestId);
    }
  }

  setApplicationCookieInfo(key: any, value: any): void {
    this.cookieService.set(
      key,
      value,
      0,
      '/',
      `${environment.cookieSubDomain}`
    );
  }

  getCookieInfo(key: any): any {
    return this.cookieService.get(key);
  }

  deleteCookie(cookieName: any): any {
    this.cookieService.delete(
      cookieName,
      '/',
      `${environment.cookieSubDomain}`
    );
  }
}
