<div class="container">
  <gds-spacer classes="spacer-120"></gds-spacer>
  <gds-heading
    tagtype="h4"
    [data]="{
      heading:pageLabels?.['inquiryForm.heading']
    }"
  ></gds-heading>
  <gds-spacer classes="spacer-80"></gds-spacer>
  <gds-body [innerHtml]="pageLabels?.['inquiryForm.body']" class="B1-W2">
  </gds-body>
  <gds-spacer classes="spacer-50"></gds-spacer>
  <div [formGroup]="inquiryWebForm">
    <gds-input
      id="lastName"
      formControlName="lastName"
      [errors]="lastNameErrorMsgInfo"
      ngDefaultControl
      [data]="{
        primaryLabel: pageLabels?.['inquiryForm.lastName'],
        supportingText: pageLabels?.['inquiryForm.required'],
        secondaryText: pageLabels?.['inquiryForm.name_fullWidth'],
        type: 'text',
        altText: 'input of lastName',
        required: true
      }"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress=" if( this.value.length === 30 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="last_name_furgina"
      formControlName="last_name_furgina"
      [errors]="lastNameFuriganaErrorMsgInfo"
      ngDefaultControl
      [data]="{
        primaryLabel:
        pageLabels?.['inquiryForm.lastName'] + pageLabels?.['inquiryForm.furigana'],
        supportingText:pageLabels?.['inquiryForm.required'],
        secondaryText: pageLabels?.['inquiryForm.name_fullWidth'],
        type: 'text',
        required: true
      }"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress=" if( this.value?.length === 30 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>

    <gds-input
      id="firstName"
      formControlName="firstName"
      ngDefaultControl
      [errors]="firstNameErrorMsgInfo"
      [data]="{
        primaryLabel: pageLabels?.['inquiryForm.firstName'],
        supportingText: pageLabels?.['inquiryForm.required'],
        secondaryText: pageLabels?.['inquiryForm.name_fullWidth'],
        type: 'text',
        altText: 'Input of first name',
        required: true
      }"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress=" if( this.value.length === 25 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="first_name_furgina"
      formControlName="first_name_furgina"
      [errors]="firstNameFuriganaErrorMsgInfo"
      ngDefaultControl
      [data]="{
        primaryLabel:
        pageLabels?.['inquiryForm.firstName'] + pageLabels?.['inquiryForm.furigana'],
        supportingText: pageLabels?.['inquiryForm.required'],
        secondaryText: pageLabels?.['inquiryForm.name_fullWidth'],
        type: 'text',
        required: true
      }"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress=" if( this.value?.length === 25 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>

    <hr class="hr-rule" />

    <gds-spacer classes="spacer-70"></gds-spacer>

    <gds-input
      id="email"
      formControlName="email"
      [errors]="emailErrorMsgInfo"
      ngDefaultControl
      [data]="{
        primaryLabel: pageLabels?.['inquiryForm.emailAddress'],
        supportingText: pageLabels?.['inquiryForm.required'],

        type: 'email',
        altText: 'input of email',
        ariaText: '',
        required: true
      }"
      onKeyPress=" if( this.value.length >= 100 ) return false;"
      (blurChange)="getErrorMessageInfo($event)"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="reEnterEmail"
      formControlName="reEnterEmail"
      [errors]="reEnterEmailErrorMsgInfo"
      ngDefaultControl
      [data]="{
        primaryLabel:  pageLabels?.['inquiryForm.email_reEnter'],
        supportingText: pageLabels?.['inquiryForm.required'],
        secondaryText: pageLabels?.['inquiryForm.emailEnsure'],

        type: 'email',
        altText: 'Re-enter email',
        ariaText: '',
        required: true
      }"
      onKeyPress=" if( this.value.length >= 40 ) return false;"
      (blurChange)="getErrorMessageInfo($event)"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="phoneNumber"
      formControlName="phoneNumber"
      [errors]="phoneNoErrorMsgInfo"
      ngDefaultControl
      [data]="{
        primaryLabel: pageLabels?.['inquiryForm.phoneNumber'],
        supportingText: pageLabels?.['inquiryForm.required'],
        secondaryText:pageLabels?.['inquiryForm.phoneNumber_text'],
        tooltip: '',
        type: 'text',
        altText: 'input of phoneNumber',
        ariaText: '',
        required: true
      }"
      onkeydown="return (/[0-9]/i.test(event.key) || event.key === 'Delete' || event.key === 'Backspace' || event.key==='Tab' || (event.key.startsWith('Arrow')) ?  true :  false)"
      (blurChange)="getErrorMessageInfo($event)"
      hide-spinner="true"
      onKeyPress=" if( this.value.length === 11 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>

    <gds-dropdown
      formControlName="type"
      ngDefaultControl
      id="type"
      [data]="{
        formField: {
          primaryLabel:  pageLabels?.['inquiryForm.type_heading'],
          supportingText: pageLabels?.['inquiryForm.required'],
          secondaryText:pageLabels?.['inquiryForm.type_text']
        },
        placeholder:pageLabels?.['inquiryForm.type_type_defaultLabel'],
        options: typeArray,
        required: true
      }"
      (change)="getErrorMessageInfo($event)"
      (valueChange)="matchDropDown($event)"
    >
    </gds-dropdown>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="privacy-cls">
      <gds-heading
        [data]="{
        heading: pageLabels?.['inquiryForm.privacyPolicyHeading']
      }"
        class="A5-W4"
      >
      </gds-heading>
      <gds-body
        [data]="{
        description: pageLabels?.['inquiryForm.required']
      }"
        class="required-cls"
      >
      </gds-body>
    </div>
    <gds-spacer classes="spacer-30"></gds-spacer>

    <gds-body
      [data]="{
        description: pageLabels?.['inquiryForm.privacyPolicy']
      }"
      class="B1-W2"
    >
    </gds-body>
    <gds-spacer classes="spacer-30"></gds-spacer>

    <gds-input
      id="agree"
      ngDefaultControl
      class="checkbox-label"
      [data]="{
        primaryLabel: pageLabels?.['inquiryForm.privacyPolicy_checkBox'],
        type: 'checkbox',
        required: true
      }"
      (valueChange)="onCheckboxChange($event, 'agree')"
      [value]="inquiryWebForm?.value?.agree ? 'true' : 'false'"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-heading
      [data]="{
        heading: pageLabels?.['inquiryForm.inputForm']
      }"
      class="A5-W4"
    >
    </gds-heading>
    <gds-spacer classes="spacer-30"></gds-spacer>
    <gds-body
      [data]="{
        description: pageLabels?.['inquiryForm.inputForm_criteria']
      }"
      class="B1-W2"
    >
    </gds-body>
    <gds-spacer classes="spacer-30"></gds-spacer>

    <gds-input
      id="replyInput"
      formControlName="replyInput"
      [errors]="replyInputErrorInfo"
      ngDefaultControl
      [data]="{
        maxCharacterCount: 2000,
        characterCountThreshold: 50,
        clearButtonLabel: '',
        cols: 4,
        rows: null,
        type: 'textarea',
        primaryLabel: pageLabels?.['inquiryForm.inputForm_text'],
        supportingText: '',
        secondaryText: '',
        primaryIconTitleText: '',
        secondaryIconTitleText: '',
        tooltip: '',
        altText: '',
        ariaText: '',
        labelledby: '',
        describedby: '',
        required: false,
        disabled: false,
        readonly: false,
        pattern: '',
        inputmode: '',
        placeholder: '',
        maxLength: null,
        name: '',
        default: false,
        groupErrorState: false,
        autocomplete: '',
        checked: '',
        customEventDetail: {},
        prependIconName: '',
        maxCharacterThreshold: null
      }"
      maxlength="2000"
      onKeyPress=" if( this.value.length === 2000 ) return false;"
      (blurChange)="getErrorMessageInfo($event)"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="privacy-cls">
      <gds-heading
        [data]="{
        heading: pageLabels?.['inquiryForm.noReply_heading']
      }"
        class="A5-W4"
      ></gds-heading>
      <gds-body
        [data]="{
        description: pageLabels?.['inquiryForm.optional']
      }"
        class="required-cls"
      >
      </gds-body>
    </div>

    <gds-spacer classes="spacer-30"></gds-spacer>

    <!--Welcome message-->
    <gds-body
      [data]="{
        description: pageLabels?.['inquiryForm.noReply_text']
      }"
      class="B1-W2"
    ></gds-body>

    <gds-spacer classes="spacer-50"></gds-spacer>
    <gds-input
      id="reply"
      ngDefaultControl
      class="checkbox-label"
      [data]="{
        primaryLabel: pageLabels?.['inquiryForm.noReplyCheckBox'],
        type: 'checkbox',
        required: true
      }"
      (valueChange)="onCheckboxChange($event, 'reply')"
      [value]="inquiryWebForm?.value?.reply ? 'true' : 'false'"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="align-left">
      <gds-button
        [data]="{
          type: 'primary',
          href: '',
          label: pageLabels?.['inquiryForm.send'],
          disabled: (inquiryWebForm?.valid && inputChanged) && !disableSendBtn ? 'false' : 'true',
          target: ''
        }"
        (buttonClicked)="onSend()"
      >
      </gds-button>
    </div>
  </div>
</div>
