import { formatDate } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
// import { LocaleService } from '@upr-web-primer/locale';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { LocalStorageService } from '@upr-web-primer/storage';
import { WINDOW } from '@upr-web-primer/window';
import _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-buy-tickets',
  templateUrl: './buy-tickets.component.html',
  styleUrls: ['./buy-tickets.component.scss'],
})
export class BuyTicketsComponent implements OnInit {
  pageLabels: any;
  tickets = false;
  couponData: any;
  data: any;
  isOpened = false;
  displayProductCardCount: any;
  fromDashboard = true;
  languageCode: string;
  constructor(
    // private locale: LocaleService,
    private title: Title,
    private common: CommonUtilityService,
    private guestsService: MsGuestsService,
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('AppDefaults') public appDefault: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('Ranges') public ranges: any,
    public notification: NotificationService,
    private localStorage: LocalStorageService,
    @Inject(WINDOW) private window: any,
    private cookieService: CookieService
  ) {
    this.pageLabels = this.common.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.dashboard']);
  }

  /**
   * ngOnInit - call commerceAuthentication() and showCoupons() to get coupons from commerce API
   * displayProductCardCount - Should display no of coupon cards
   */
  ngOnInit(): void {
    const authToken = this.localStorage.getItem('loginAuthToken');
    if (authToken) {
      this.showCoupons();
      this.displayProductCardCount = this.ranges.PASSWORDS.INDEX[2];
    } else {
      this.common.getResponseObservable().subscribe((data: any) => {
        if (data) {
          this.showCoupons();
          this.displayProductCardCount = this.ranges.PASSWORDS.INDEX[2];
        }
      });
    }
  }

  /**
   * getLink - On basis of type navigate to respective route
   * @param type
   */
  getLink(type: any): any {
    switch (type) {
      case this.globalDefault.PROFILE:
        this.common.changeRoute(this.appRoutes.MY_PROFILE);
        break;
      case this.globalDefault.LINK_COUPONS:
        this.common.changeRoute(this.appRoutes.COUPONS);
        break;
      case this.globalDefault.ORDER:
        this.common.changeRoute(this.appRoutes.ORDER_LIST);
        break;
      case this.globalDefault.LINK_TICKETS:
        this.common.setLinkRoute(this.appRoutes.DASHBOARD);
        this.common.changeRoute(this.appRoutes.LINK_TICKETS);
        break;
    }
  }

  /**
   * showCoupons - Call commerce API to get coupon list for logged in user
   */
  showCoupons(): any {
    const guestId = this.guestsService.helper.getGuestId();

    this.common?.getCoupons(guestId)?.subscribe(
      (data: any) => {
        this.data = [];
        const coupons = data.couponCodes;
        const todayDate = formatDate(new Date(), 'YYYY-MM-dd', 'en-US');

        coupons.map((a: any) => {
          const dateValue = formatDate(
            a.couponExpirationDate,
            'YYYY-MM-dd',
            'en-US'
          );
          if (
            dateValue >= todayDate &&
            a.status === this.globalDefault.DISTRIBUTED
          ) {
            this.data.push(a);
          }
        });
        this.fetchCouponData();
      },
      (errors: any) => {
        this.fetchCouponData();
        if (errors?.status == this.globalDefault.ERROR_401) {
          this.common.invokeFireEvent(
            'accountLogout',
            this.pageLabels?.['accountInfo.logout'],
            'Form',
            'Success',
            new Date().toISOString(),
            localStorage.getItem('OAUTH_LOGIN_GID') ?? '',
            '',
            this.window?.digitalData?.page?.category?.primaryCategory,
            this.window?.digitalData?.page?.category?.subCategory1
          );
          this.common?.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.common.getOidcRedirectionUrl();
          this.common.redirectToOidcAppEndSession(redirectUrl);
        } else {
          const errCode = 'errCode.' + errors.error?.errorCode;
          const errorCode = errors.error?.errorCode
            ? this.pageLabels?.[errCode]
              ? this.pageLabels?.[errCode]
              : this.pageLabels?.['errCode.E018']
            : errors.error?.errors[0]?.message;

          this.notification?.show(errorCode);
        }
      }
    );
  }

  /**
   * fetchCouponData -coupon data sorting and processinf from tridion
   */
  fetchCouponData(): void {
    const couponData = this.data
      ?.sort((a: any, b: any): any => {
        return (
          new Date(a.couponExpirationDate).valueOf() -
          new Date(b.couponExpirationDate).valueOf()
        );
      })
      .slice(this.ranges.PASSWORDS.INDEX[0], this.ranges.PASSWORDS.INDEX[2]);

    _.map(couponData, (a: any) => {
      const couponIdentifier = a.couponGroupId ? a.couponGroupId : a.couponId; // Determine whether to use couponGroupId or couponId
      this.common?.fetchCoupons(couponIdentifier)?.subscribe(
        (data: any) => {
          a.attractionBody = data?.[0]?.['data']?.attractionBody
            .replace(
              '{{couponStartDate}}',
              a.couponValidStartDate
                ? this.getDate(
                    a.couponValidStartDate.replace('T00:00:00', ''),
                    true
                  )
                : ''
            )
            .replace(
              '{{couponExpirationDate}}',
              a.couponExpirationDate
                ? this.getDate(
                    a.couponExpirationDate.replace('T00:00:00', ''),
                    true
                  )
                : ''
            );
          a.heading = data?.[0]?.['data']?.heading;
          a.contentId = data?.[0]?.['data']?.contentId;
          a.description = data?.[0]?.['data']?.description;
          a.attractionHeadline = data?.[0]?.['data']?.attractionHeadline;
        },
        (errors: any) => {
          if (errors.status == this.globalDefault.ERROR_401) {
            this.common.setUser();
            localStorage.clear();
            sessionStorage.clear();
            this.cookieService.deleteAll('/', environment.cookieSubDomain);
            const redirectUrl = this.common.getOidcRedirectionUrl();
            this.common.redirectToOidcAppEndSession(redirectUrl);
          }
        }
      );
    });

    this.couponData = couponData;
    this.tickets = this.couponData?.length > 0 ? true : false;
  }

  /**
   * redirectToWTS - Navigate back to WTS account
   */
  redirectToWTS(): any {
    this.common.clearAllCookies(false, false, false, false);
    this.common.onClickRedirectWTS('', '', false, false);
  }

  /**
   * openPopup - Open Popup
   */
  openPopup(): void {
    this.isOpened = true;
  }

  /**
   * closePopup - Close Popup
   */
  closePopup(): void {
    this.isOpened = false;
  }

  /**
   * getDate - It will format the date based on locale
   */
  getDate(date: any, weekday?: boolean): string {
    return this.common.getFormattedDateOnLocale(date, weekday);
  }
  /**
   * redirecting to the actual page when we right click and open with new tabs and windows.
   */

  // orderProfileUrl(endpointName: any): any {
  //   const localeInfo = this.locale.getFromUrl();
  //   return (this.languageCode = `${localeInfo.language}/${localeInfo.country}/${endpointName}`);
  // }
  /**
   * redirecting to the WTS page when we right click and open with new tabs and windows.
   */
  getBuyTicketsLink(): any {
    const USJWTSLoginAuthConfig = this.common.getUSJWTSLoginAuthConfig();
    const url =
      environment.wtsHostBaseUrl +
      `spa_redirect?${new HttpParams({
        fromObject: USJWTSLoginAuthConfig,
      })}`;
    return url;
  }
}
