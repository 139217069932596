<!--Account Info redirect to profile page-->
<gds-full-screen-modal opened (modalClose)="backtoAccountInfo()">
  <div slot="components">
    <div class="container modal-content" #updatePersonalInfoModalContent>
      <ng-container>
        <!--Edit-Info heading-->
        <gds-heading
          [data]="{
            heading: pageLabels?.['editInfo.heading']
          }"
          class="A2-W5"
        >
        </gds-heading>
        <gds-spacer classes="spacer-50"></gds-spacer>

        <gds-body
          [data]="{
                  description: pageLabels?.['editInfo.subText']
                }"
          class="B1-W3"
        ></gds-body>
        <gds-spacer classes="spacer-70"></gds-spacer>
        <!--Edit-Personal_Info Form Creation-->
        <div [formGroup]="editPersonalForm">
          <gds-input
            id="lastName"
            formControlName="lastName"
            ngDefaultControl
            [errors]="lastNameErrorMsgInfo"
            [data]="{
              primaryLabel: pageLabels?.['editInfo.lastName'],
              type: 'text',
              required: true
            }"
            onkeydown="return /[a-zA-Z]/i.test(event.key)"
            (blurChange)="getErrorMessageInfo($event)"
            onKeyPress=" if( this.value?.length === 30 ) return false;"
          >
          </gds-input>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-input
            id="last_name_furgina"
            formControlName="last_name_furgina"
            ngDefaultControl
            [errors]="lastNameFuriganaErrorMsgInfo"
            [data]="{
              primaryLabel: pageLabels?.['editInfo.lastName']+ pageLabels?.['editInfo.furigana'],
              supportingText:  pageLabels?.['editInfo.optional'],
              type: 'text',
              required: true
            }"
            onkeydown="return /[a-zA-Z]/i.test(event.key)"
            (blurChange)="getErrorMessageInfo($event)"
            onKeyPress=" if( this.value?.length === 30 ) return false;"
          >
          </gds-input>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-input
            id="firstName"
            formControlName="firstName"
            [errors]="firstNameErrorMsgInfo"
            ngDefaultControl
            [data]="{
              primaryLabel:pageLabels?.['editInfo.firstName'],
              type: 'text',
              required: true
            }"
            onkeydown="return /[a-zA-Z]/i.test(event.key)"
            (blurChange)="getErrorMessageInfo($event)"
            onKeyPress=" if( this.value?.length === 25 ) return false;"
          >
          </gds-input>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-input
            id="first_name_furgina"
            formControlName="first_name_furgina"
            [errors]="firstNameFuriganaErrorMsgInfo"
            ngDefaultControl
            [data]="{
              primaryLabel:pageLabels?.['editInfo.firstName']+ pageLabels?.['editInfo.furigana'],
              supportingText: pageLabels?.['editInfo.optional'],
              type: 'text',
              required: true
            }"
            onkeydown="return /[a-zA-Z]/i.test(event.key)"
            (blurChange)="getErrorMessageInfo($event)"
            onKeyPress=" if( this.value?.length === 30 ) return false;"
          >
          </gds-input>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <ng-container *ngIf="!dateShow">
            <gds-input
              id="date_of_birth"
              formControlName="date_of_birth"
              [errors]="dobErrorMsgInfo"
              ngDefaultControl
              [data]="{
                primaryLabel: pageLabels?.['editInfo.birthday'],
                supportingText: pageLabels?.['editInfo.optional'],
                tooltip:  pageLabels?.['createAccount.bdayFormat'],
                type: 'number',
                required: false
              }"
              onKeyPress=" if( this.value?.length == 8 ) return false;"
              (change)="checkDate()"
              onkeydown="return (/[0-9]/i.test(event.key) || event.key === 'Delete' || event.key === 'Backspace' || event.key==='Tab' ?  true :  false)"
              hide-spinner="true"
            >
            </gds-input>
            <gds-spacer classes="spacer-30"></gds-spacer>
            <!-- Date of Birth input field heading -->
            <gds-heading
              [data]="{
            heading:
                pageLabels?.['trpAdd.birthdayInfo']
            }"
              class="B1-W3"
            >
            </gds-heading>
          </ng-container>

          <ng-container *ngIf="dateShow">
            <gds-heading
              [data]="{
    heading: pageLabels?.['editInfo.birthday']
  }"
              class="B1-W4"
              classes="heading-7"
            >
            </gds-heading>
            <gds-spacer classes="spacer-30"></gds-spacer>
            <gds-heading
              [data]="{
                heading: getDate(dateVal)
              }"
              class="B1-W4 adventure"
            >
            </gds-heading>
          </ng-container>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <ng-container *ngIf="!genderShow">
            <gds-dropdown
              id="gender"
              formControlName="gender"
              ngDefaultControl
              [data]="{
            options: genderArray,
            formField: { primaryLabel: pageLabels?.['editInfo.gender'], supportingText: pageLabels?.['editAddress.optional']}
          }"
              [value]="selectedGender"
              (valueChange)="matchDropDown($event, 'gender')"
            >
            </gds-dropdown>
          </ng-container>
          <ng-container *ngIf="genderShow">
            <gds-heading
              [data]="{
    heading: pageLabels?.['editInfo.gender']
  }"
              class="B1-W4"
              classes="heading-7"
            >
            </gds-heading>
            <gds-spacer classes="spacer-20"></gds-spacer>
            <gds-heading
              [data]="{
                heading: genderValue | titlecase
              }"
              class="B1-W4 adventure"
            >
            </gds-heading>
          </ng-container>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-input
            id="email"
            formControlName="email"
            [errors]="emailErrorMsgInfo"
            ngDefaultControl
            [data]="{
              primaryLabel: pageLabels?.['editInfo.emailAddress'],
              type: 'email',
              required: true
            }"
            (blurChange)="getErrorMessageInfo($event)"
            onKeyPress=" if( this.value?.length >= 512 ) return false;"
            (change)="emailValidation($event)"
          >
          </gds-input>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-input
            id="primary"
            ngDefaultControl
            class="checkbox-label"
            [data]="{
              primaryLabel: pageLabels?.['createAccount.specialOffers'],
              type: 'checkbox',
              required: true
            }"
            (valueChange)="onPrimaryClick($event)"
            [value]="editPersonalForm.value.primary ? 'true' : 'false'"
          >
          </gds-input>
          <gds-spacer classes="spacer-85"></gds-spacer>
        </div>
      </ng-container>
    </div>
    <!--Update CTA for the Edit_personal_Info-->
    <gds-sticky-nav-footer
      [data]="{
        button: {
          type: 'primary',
          title: '',
          label: pageLabels?.['editAddress.save'],
          disabled: editPersonalForm.valid && inputChanged && !invalidEmail ? 'false' : 'true'
        }
      }"
      (buttonClicked)="onSubmit()"
    >
    </gds-sticky-nav-footer>
  </div>
</gds-full-screen-modal>
