import { environment } from '../environments/environment';
import { EnvironmentService } from '../environments/environment.service';

const env = new EnvironmentService();

export const tridionDefault = {
  TRIDION_SITE: env.tridionSite,
};

export const appDefault = {
  DATA: '',
  PAGE_404: env.sorryPagePath,
  PAGE_INFO: '',
  LAYOUT_PATH:
    '/' + tridionDefault.TRIDION_SITE + '/%language%/%country%/views/layout/',
  LAYOUT_HEADER_DESKTOP: '_LayoutHeaderDesktopTabletAccount.html',
  LAYOUT_HEADER_MOBILE: '_LayoutHeaderDesktopTabletAccount.html',
  LAYOUT_FOOTER: '_LayoutFooterAccount.html',
  JUNCTION: '',
  TRIDION_WEBDATA: env.tridionBaseUrl,
  FB_SDK_URL: '//connect.facebook.net/en_GB/sdk.js',
  GOOGLE_RECAPTCHA_API_URL: env.grecaptchaApiUrl,
  GOOGLE_RECAPTCHA_SITE_KEY: env.grecaptchaSiteKey,
  GOOGLE_RECAPTCHA_ENABLE: env.grecaptchaEnabled,
  GOOGLE_RECAPTCHA_TEST_RESPONSE: env.grecaptchaTestResponse,
  FB_SDK_APP_ID: '106534096137391',
  FB_SDK_VERSION: 'v3.0',
  CONTACT_PERMISSION_EMAIL_UO: 'email_offers_promos',
  CONTACT_PERMISSION_EMAIL_USH: 'email_offers_promos_ush',
  CONTACT_PERMISSION_MAIL: 'directmail_offers_promos',
  CONTACT_PERMISSION_SOCIAL: 'targeted_social_adv',
  REGEX_LOCALE: /([a-z]{2}\/)+([a-z]{2}\/)+(.*)/i,
  DEFAULT_ROUTE_COUNTRY: env.defaultCountry,
  DEFAULT_ROUTE_LANGUAGE: env.defaultLanguage,
  ADOBE_MBOX: env.adobeMbox,
  FACEBOOK_LIKE_URL: env.seoFacebookSiteName,
  FONT_CSS_URL: env.fontCssUrl,
  ICON_CSS_URL: env.iconCssUrl,
  TRIP_DETAIL_LINK: env.tripDetailLink,
  LOCALHOST_MASKED_HOST: 'https://web-qa.use.ucdp.net',
  GOOGLE_CALENDAR_API_KEY: env.googleCalendarApiKey,
  GEOLOCATION_DISABLED: env.geoDisabled,
  GEOLOCATION_API_URL: env.geoApiUrl,
  GEOLOCATION_AKAMAI: env.GEOLOCATION_AKAMAI,
  PRIMARY_GEO_REGION: env.PRIMARY_GEO_REGION,
  DIGITAL_DATA_SITE: env.digitalDataSite,
  DIGITAL_DATA_ENVIRONMENT: env.digitalDataEnvironment,
  DIGITAL_DATA_DESTINATION: env.digitalDataDestination,
  DIGITAL_DATA_SEGMENT: env.digitalDataSegment,
  DIGITAL_DATA_AD_BLOCKER_ENABLED: env.digitalDataAdblockerEnabled,
  DIGITAL_DATA_FLASH_VERSION: env.digitalDataFlashVersion,
  DIGITAL_DATA_APP_VERSION: env.digitalDataAppVersion,
  DIGITAL_DATA_ANGULAR_VERSION: env.digitalDataAngularVersion,
  DIGITAL_DATA_TRIDION_VERSION: env.digitalDataTridionVersion,
  DIGITAL_DATA_ENABLED: env.digitalDataEnabled,
  ADOBE_HEADER_SCRIPT: env.adobeHeaderScript,
  ADOBE_BODY_SCRIPT: env.adobeBodyScript,
  ADOBE_TARGET_DISABLED: env.adobeTargetDisabled,
  LAYOUT_HEADER: env.layoutHeader,
  GLOBAL_NAVIGATION_ENABLED: env.globalNavigationEnabled,
  ONETRUST_MODAL_ENABLED: env.oneTrustEnabled,
  ONETRUST_MODAL_SCRIPT: env.oneTrustModalScript,
  ONETRUST_MODAL_DOMAIN_SCRIPT: env.oneTrustDomainScript,
  ONETRUST_MODAL_HASH: env.oneTrustHash,
  ONETRUST_MODAL_CROSSORIGIN: env.oneTrustCrossorigin,
  ONETRUST_PRIVACY_SCRIPT: env.oneTrustPrivacyScript,
  ONETRUST_PRIVACY_IDS: env.oneTrustPrivacyIds,
  ONETRUST_PRIVACY_GEO: env.oneTrustPrivacyGeo,
  ONETRUST_PRIVACY_LSPA: env.oneTrustPrivacyLSPA,
  COVEO_ENABLED: env.coveoEnabled,
  COVEO_SCRIPT_SEARCH_URL: env.coveoScriptSearchUrl,
  COVEO_SCRIPT_SEARCH_HASH: env.coveoScriptSearchHash,
  COVEO_SCRIPT_TEMPLATE_URL: env.coveoScriptTemplateUrl,
  COVEO_SCRIPT_TEMPLATE_HASH: env.coveoScriptTemplateHash,
  COVEO_SCRIPT_LOCALE: env.coveoScriptLocale,
  COVEO_SCRIPT_LOCALE_HASH: env.coveoScriptLocaleHash,
  COVEO_CSS: env.coveoCss,
  COVEO_CSS_HASH: env.coveoCssHash,
  COVEO_CROSSORIGIN: env.coveoCrossOrigin,
  COVEO_ACCESSTOKEN: env.coveoAccessKeyID,
  COVEO_ORGANIZATIONID: env.coveoOrganizationId,
  VENUES_API_ENDPOINT: env.mobileServicesVenueEndpoints,
  SHOWTIMES_API_ENDPOINT: env.mobileServicesShowtimesEndpoint,
  ACCOUNT_APP_PATH: env.accountAppPath,
  WEATHER_API: env.weatherApi,
  RESORT_NAME: env.resortName,
  RESORT_AREA_CODE: env.resortAreaCode,
  SEO_DEFAULT_TITLE: env.seoTitle,
  SEO_DEFAULT_DESCRIPTION: env.seoDescription,
  SEO_DEFAULT_IMAGE_URL: env.seoImage,
  SEO_DEFAULT_FACEBOOK_SITE_NAME: env.seoFacebookSiteName,
  SEO_DEFAULT_TWITTER_SITE_NAME: env.seoTwitterSiteName,
  UPR_TIME_ZONE: env.timeZone,
  USJ: 'USJ',
  ENROLL: '/enroll-account',
  POST: 'POST',
  PATCH: 'PATCH',
  DASHBOARD: false,
  COMMERCEAPI: 'client_credentials',
  BLANK: '_blank',
  COPY_RIGHTS_LINK: 'https://www.usj.co.jp/web/en/us/copyright-notice',
  PRIVACY_LINK: 'https://www.nbcuniversal.com/privacy',
  BASE_REDIRECT_BYPASS: env.baseRedirectBypass,
  WEB_APP_PATH: env.webAppPath,
};

export const appRegExs = {
  CONTENT_URL_EXP: new RegExp(
    `(/k2/|/ush/|/usj/|/web/|/${tridionDefault.TRIDION_SITE}/)`
  ),
  WEB_PACKAGES_EXP: new RegExp(`(../../web-packages/|/web-packages/)`),
  WEB_STORE_EXP: new RegExp(`(../../web-store/|/web-store/)`),
  FORMATTED_LINK_EXP: new RegExp('^(http|https)://'),
  LANGUAGE_EXP: new RegExp('(/en/|/es/)'),
  SPECIAL_CHARS_EXP: new RegExp('/[^a-zA-Z0-9 ]', 'g'),
  REGEX_LOCALE: new RegExp(/([a-z]{2}\/)+([a-z]{2}\/)+(.*)/i),
  MULTIMEDIA_REGEX: new RegExp('\\.(jpg|JPG|gif|GIF|png|PNG|pdf|PDF|mp4|MP4)'),
  INTERNAL_REGEX: new RegExp('\\.\\./', 'gi'),
};
export const appRoutes = {
  LOGIN: 'account-management/login',
  CREATE_ACCOUNT: 'signup',
  NOT_FOUND: '/oops-sorry',
  SIGNUP: 'signup',
  SIGNUP_CONFIRM: 'signup/confirm',
  SIGNUP_COMPLETE: 'signup/complete',
  EMAIL_SUBSCRIBE: 'account-management/email-sign-up',
  EMAIL_UNSUBSCRIBE: 'account-management/email-unsubscribe',
  HHN_EMAIL_SUBSCRIBE: 'account-management/hhn-email-sign-up',
  FORGOT_PASSWORD: 'account-management/forgot-my-password',
  RESET_PASSWORD: 'account-management/reset-my-password',
  DELETE_ACCOUNT: 'profile/delete',
  DELETE_ACCOUNT_CONFIRMATION: 'profile/delete/complete',
  MY_PROFILE: 'profile',
  UPDATE_PASSWORD: 'profile/password/edit',
  WALLET: 'account-management/wallet',
  LINK_PURCHASE: 'account-management/link-purchase',
  LINK_ENTITLEMENT: 'account-management/link-entitlement',
  WALLET_PAYMENT_METHODS: 'account-management/wallet-payment-manage-methods',
  UPDATE_PERSONAL_INFO: 'profile/personal-info/edit',
  UPDATE_CONTACT_INFO: 'account-management/update-contact-info',
  UPDATE_PREFERENCES: 'account-management/my-contact-preferences',
  MY_ADDRESSES: 'profile/addresses',
  ADD_ADDRESS: 'profile/addresses/add',
  EDIT_ADDRESS: 'profile/addresses/:id/edit',
  WALLET_DETAIL: 'account-management/wallet-details',
  PACKAGE_CREATE_ACCOUNT: 'account-management/package-create-account',
  PURCHASES: 'account-management/purchases',
  REGISTER_ACCOUNT: 'account-management/register-account',
  RETRIEVE_PURCHASES: 'account-management/retrieve-purchases',
  SWEEPSTAKES: 'account-management/sweepstakes',
  VIEW_PURCHASES: 'account-management/view-package-details',
  VIEW_TRIPS: 'account-management/trips',
  RETRIEVE_RESERVATIONS: 'account-management/retrieve-reservations',
  DASHBOARD: 'dashboard',
  PHONE_NUMBERS_PAGE: 'profile/phone-numbers',
  UPDATE_PHONE_NUMBER: 'profile/phone-numbers/:id/edit',
  TICKETS: 'ticket-details',
  TRAVEL_PARTY_INFORMATION: 'profile/travel-party-members',
  ADD_TRAVEL_PARTY_MEMBERS: 'profile/travel-party-members/add',
  EDIT_TRAVEL_PARTY_MEMBERS: 'profile/travel-party-members/:id/edit',
  CONFIRM_TRAVEL_PARTY_MEMBERS: 'profile/confirm-travel-party-members',
  WARNING_TRAVEL_PARTY_MEMBERS: 'profile/warning-travel-party',
  VIEW_ENTITLMENT: 'wallet',
  BUY_TICKETS: 'profile/buy-tickets',
  ENLARGE_QR_CODE: 'profile/enlarge-qr-code',
  SHARE_QR_CODE: 'entitlements',
  BASESITE: 'b2cportal/customers/login',
  COUPONS: 'coupons',
  LINK_COUPON: 'coupons/coupon-code',
  REGISTER_TICKETS: 'register-tickets',
  ORDER_HISTORY: 'dashboard/order-history',
  ORDER_LIST: 'orders',
  ORDER_DETAILS: 'orders',
  LINK_TICKETS: 'link-tickets',
  CONFIRM_TICKETS: 'confirm-tickets',
  CONFIRM_REGISTRATION: 'confirm-registration',
  TICKET_DETAILS: 'wallet',
  CONFIRM_DELETE: 'profile/delete/confirmation',
  PAST_TICKETS: 'past-tickets',
  BIRTHDAY_DISCOUNT: 'birthday-discount',
  UNSUBSCRIBE: 'email-unsubscribe',
  CONTACT_US: 'contact-us',
  LOST_FOUND: 'lost-and-found',
  CART: 'cart',
  LOGOUT: 'account/account-management/login',
  SIGN_IN: 'account-management/signln',
};
export const HEADER_NAV_FOOTER_ENV_REPLACE: any = {
  gds_footer: {
    enabled: false,
    content_id: environment.contentIds.GDS_FOOTER_CONTENT_ID,
    cookie_name: 'display_global_footer',
    use_tridion_config: false,
  },
  tridion: {
    app_directory: '',
    config_enabled: false,
    config_url:
      '/usj/ja/jp/api/getcontent/?contentIds=upr-gds-microsite-configurator',
    dynamic_path_enabled: false,
    publication: environment.contentIds.TRIDION_PUBLICATION,
  },
  gds_global_nav: {
    enabled: false,
    cookie_name: 'display_global_nav',
    utility_bar_content_id:
      environment.contentIds.GDS_GLOBAL_NAV_UTILITY_BAR_CONTENT_ID,
    mega_nav_content_id:
      environment.contentIds.GDS_GLOBAL_NAV_MEGA_NAV_CONTENT_ID,
    use_tridion_config: false,
    use_ms_places: false,
    app: {
      web_store: 'checkout-login,payment-options,checkout-confirmation',
      packages: 'checkout-login,payment-options,confirmation',
      hotels: 'checkout-login,checkout,confirmation',
    },
  },
};
