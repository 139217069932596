import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Resolve } from '@angular/router';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { SpinnerService } from '@upr-web-primer/spinner';
import { LocalStorageService } from '@upr-web-primer/storage';
import _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import {
  catchError,
  finalize,
  forkJoin,
  from,
  Observable,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApplicationDataService } from '../../services/application-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';

@Injectable({
  providedIn: 'root',
})
export class DataResolver implements Resolve<any> {
  pageLabels: any;
  isAuthenticatedSubscription: any;
  quantity: any = 0;
  errorState = false;
  constructor(
    private common: CommonUtilityService,
    private guestsService: MsGuestsService,
    private spinner: SpinnerService,
    private localStorage: LocalStorageService,
    private applicationDataService: ApplicationDataService,
    private notification: NotificationService,
    @Inject('GlobalDefaults') public globalDefault: any,
    private route: ActivatedRoute,
    private cookieService: CookieService
  ) {
    this.pageLabels = this.route.snapshot.data.pageLabels;
  }

  resolve(): Observable<any> {
    // Resolve both entitlements and travel party members concurrently
    this.commerceAuthentication();
    return forkJoin({
      entitlementData: this.resolveEntitlementData(),
      travelPartyMembersData: this.resolveTravelPartyMembersData(),
    });
  }
  public resolveEntitlementData(): Observable<any> {
    const authToken = this.localStorage.getItem('loginAuthToken');

    let entitlementData = this.common.getResolvedData();
    if (entitlementData) {
      return of(entitlementData);
    } else {
      const guestId = this.guestsService.helper.getGuestId();
      return this.common.getEntitlements(guestId).pipe(
        switchMap((data: any) => {
          if (!data || data?.entitlements?.length <= 0) {
            this.common.setResolvedData([]);
            return of([]);
          } else {
            this.common.setResolvedData(data);
            entitlementData = data;
            return new Observable((observer) => {
              this.isAuthenticatedSubscription = this.common
                .getIsAuthenticated()
                .subscribe((isAuthenticated) => {
                  if (isAuthenticated || authToken) {
                    this.checkBaseCode().subscribe(() => {
                      observer.next(entitlementData);
                      observer.complete();
                    });
                  } else {
                    observer.next([]); // Empty array if not authenticated
                    observer.complete();
                  }
                });
            });
          }
        }),
        catchError((error: any) => {
          console.error('Error fetching entitlements:', error);
          this.errorState = true;
          this.common.setResolvedData([]);
          return of([]);
        })
      );
    }
  }

  private resolveTravelPartyMembersData(): Observable<any> {
    const travelMembersDetails = this.common.getResolvedData();

    if (travelMembersDetails) {
      // If data is already resolved, return it immediately
      return of(travelMembersDetails);
    } else {
      const guestId = this.guestsService.helper.getGuestId();
      // Fetch TRP and update the resolved data in the service
      return from(
        this.guestsService.wallet.getTravelPartyMembers(guestId)
      ).pipe(
        tap((res: any) => {
          // Set travelMembersDetails in the common service
          this.common.setTravelMembersDetails(
            _.filter(
              res,
              (member) => !member.is_owner && member.is_active === true
            )
          );
        }),
        catchError((error: any) => {
          console.error('Error fetching travel party members:', error);
          if (error?.status === 401) {
            this.notification.hide();
            localStorage.clear();
            sessionStorage.clear();
            this.cookieService.deleteAll('/', environment.cookieSubDomain);
            const redirectUrl = this.common.getOidcRedirectionUrl();
            this.common.redirectToOidcAppEndSession(redirectUrl);
          } else {
            this.notification.hide();
            this.notification.show(this.globalDefault?.ER71);
          }
          return throwError(error);
        })
      );
    }
  }

  private commerceAuthentication(): any {
    this.spinner.show();
    const authToken = this.localStorage.getItem('loginAuthToken');
    const isSignIn = this.localStorage.getItem('IS_SIGN_IN');

    if (authToken == null && isSignIn == 'true') {
      this.common.getB2COAuthToken().subscribe((authData: any) => {
        this.localStorage.setItem('authObj', JSON.stringify(authData));

        if (authData) {
          this.common.postauthLogin().subscribe(
            (loginAPIResponse: any) => {
              this.spinner.hide();
              this.localStorage.setItem(
                'loginAuthToken',
                JSON.stringify(loginAPIResponse)
              );
              this.applicationDataService.setApplicationCookieInfo(
                'commerceTokenInfo',
                JSON.stringify(loginAPIResponse)
              );
              this.localStorage.removeItem('dashboard_version');
              this.common.emitResponse(
                this.localStorage.getItem('loginAuthToken')
              );
              this.setAuthenticated(loginAPIResponse);
              this.common.getCartItems();
            },
            (errors: any) => {
              if (errors?.status === this.globalDefault.ERROR_401) {
                this.common.setUser();
                localStorage.clear();
                sessionStorage.clear();
                this.cookieService.deleteAll('/', environment.cookieSubDomain);
                const redirectUrl = this.common.getOidcRedirectionUrl();
                this.common.redirectToOidcAppEndSession(redirectUrl);
              } else {
                const errorCode = errors.error.errors[0]?.errorCode;
                const err = this.globalDefault.ERROR_CODE + errorCode;
                const pageLabels = this.getErrorCodesArray();
                this.common.handleCommerceErr(pageLabels, err);
                this.notification?.show(errorCode);
              }
            }
          );
        }
      });
    }
  }
  /**
   * setAuthenticated - set the authToken data
   * @param value - pass the authToken data
   */
  setAuthenticated(value: boolean): any {
    this.common?.setIsAuthenticated(value);
  }
  /**
   * getErrorCodesArray - get error code message from page labels
   * @returns
   */
  getErrorCodesArray(): any {
    const pageLabels = Object.entries(this.pageLabels);
    const obj: any = {};
    _.each(pageLabels, (key: any) => {
      if (key[0].startsWith(this.globalDefault.ERROR_CODE)) {
        obj[key[0]] = key[1];
      }
    });
    return obj;
  }

  checkBaseCode(): Observable<any> {
    const entitlementArray = this.common.getResolvedData();
    this.spinner.show();

    const observables = entitlementArray.map((entitlement: any) => {
      return this.common
        .getBaseProduct(entitlement.product_code)
        ?.pipe(
          switchMap((res: any) => {
            entitlement.categoryCode = res?.categories[0]?.code;
            const variantArray =
              res?.baseOptions[0]?.selected?.variantOptionQualifiers;
            const matchedItem = variantArray.find(
              (item: any) => item?.value === entitlement?.product_code
            );
            entitlement.baseCode = matchedItem?.value;
            entitlement.attractions = res?.attractions;
            entitlement.tridionContent = res.tridionContent;
            // Check if categoryCode is 'expresspass'
            if (
              entitlement.categoryCode === this.globalDefault?.COMM_EXPRESS_PASS
            ) {
              return this.common.getBaseProduct(entitlement?.baseCode).pipe(
                switchMap((response: any) => {
                  entitlement.expresstridion = response?.tridionContent;
                  try {
                    const tridionContentArray = JSON.parse(
                      response?.tridionContent
                    );
                    if (
                      Array.isArray(tridionContentArray) &&
                      tridionContentArray.length > 0
                    ) {
                      const firstContent = tridionContentArray[0];
                      if (firstContent?.data?.name) {
                        entitlement.expressName = firstContent.data.name;
                      }
                    }
                  } catch (error) {
                    console.error('Error parsing tridionContent:', error);
                  }

                  return this.common
                    .getIconArrayData()
                    ?.pipe(catchError(() => of(null)));
                }),
                catchError(() => of(null))
              );
            } else {
              return this.common
                .getIconArrayData()
                ?.pipe(catchError(() => of(null)));
            }
          }),
          catchError(() => of(null))
        )
        .pipe(
          tap((iconArrayData: any[]) => {
            const matchedIcon = iconArrayData?.find((iconItem: any) =>
              iconItem?.navigationDisplayName
                .toLowerCase()
                .replace(/\s/g, '')
                .includes(
                  entitlement?.categoryCode?.toLowerCase().replace(/\s/g, '')
                )
            );
            const matchedCategory = iconArrayData?.find(
              (iconItem: any) =>
                iconItem?.url ===
                this.globalDefault.PRODUCT_CATEGORY.toLowerCase().replace(
                  /\s/g,
                  ''
                )
            );

            if (
              matchedIcon &&
              typeof matchedIcon === 'object' &&
              'icon' in matchedIcon
            ) {
              entitlement.icons = matchedIcon?.icon;
            } else if (matchedCategory) {
              entitlement.icons = matchedCategory?.icon;
            }
          }),
          catchError(() => of(null))
        );
    });

    return forkJoin(observables).pipe(
      tap(() => {
        this.common.setResolvedData(entitlementArray);
      }),
      catchError(() => of(null)),
      finalize(() => {
        this.spinner.hide();
      })
    );
  }
}
