<gds-spacer classes="spacer-70"></gds-spacer>
<!--Signup complete heading -->
<gds-heading
  [data]="{
    heading: pageLabels?.['accountComplete.heading']
  }"
  class="A3-W6"
>
</gds-heading>
<gds-spacer classes="spacer-30"></gds-spacer>

<!--Signup complete description -->
<gds-heading
  [data]="{
    heading: pageLabels?.['accountComplete.Create']
  }"
  class="A5-W4"
>
</gds-heading>
<gds-spacer classes="spacer-50"></gds-spacer>
<gds-heading
  [data]="{
    heading:
    pageLabels?.['accountComplete.verified']
  }"
  class="B1-W3"
>
</gds-heading>
<gds-spacer classes="spacer-70"></gds-spacer>

<!--Signup complete CTA redirect to login Page -->
<gds-button
  [data]="{
    type: 'primary',
    href: '',
    label:  pageLabels?.['createAccount.signIn'],
    disabled: false,
    target: ''
  }"
  (buttonClicked)="redirectToLogin()"
>
</gds-button>
