import { Component, OnInit } from '@angular/core';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-sign-in',
  template: '',
  styles: [],
})
export class SignInComponent implements OnInit {
  constructor(private common: CommonUtilityService) {}

  ngOnInit(): void {
    const redirectUrl = this.common.getOidcRedirectionUrl();
    this.common.redirectToOidcApp(redirectUrl);
  }
}
