<!-- toast div-->
<div class="toast-outer">
  <div class="toast-bar">
    <gds-spacer classes="vertical-spacer-50"></gds-spacer>
    <div
      class="toast"
      [@fadeAnimation]="{
        value: animationState,
        params: {
          fadeIn: toastConfig.animation?.fadeIn,
          fadeOut: toastConfig.animation?.fadeOut
        }
      }"
      (@fadeAnimation.done)="onFadeFinished($event)"
    >
      <div>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <div class="B1-W3">{{ data.text }}</div>
        <gds-spacer classes="spacer-50"></gds-spacer>
      </div>
      <div class="close-btn">
        <gds-spacer classes="vertical-spacer-30"></gds-spacer>
        <gds-button
          id="close"
          [data]="{
            type: 'close'
          }"
          (buttonClicked)="close()"
        ></gds-button>
      </div>
    </div>
    <gds-spacer classes="vertical-spacer-50"></gds-spacer>
  </div>
  <gds-spacer classes="spacer-140"></gds-spacer>
</div>
