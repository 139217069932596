import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../../environments/environment';
import { CommonUtilityService } from '../../../services/common-utility.service';

@Component({
  selector: 'app-registration-link-tickets',
  templateUrl: './registration-link-tickets.component.html',
  styleUrls: ['./registration-link-tickets.component.scss'],
})
export class RegistrationLinkTicketsComponent implements OnInit {
  pageLabels: any;
  showPrefectureDropdownOptions: any;
  selectedPrefectureList: any;
  showForm = false;
  formData: any;
  showConfirmPopup = false;
  fromPage: string;
  travelPartyForm: any;

  constructor(
    private title: Title,
    private common: CommonUtilityService,
    @Inject('AppRoutes') public appRoutes: any,
    public guestsService: MsGuestsService,
    @Inject('GlobalDefaults') public globalDefault: any,
    private cookieService: CookieService
  ) {
    if (this.common?.getResolvedPageData) {
      this.pageLabels = this.common.getResolvedPageData();
      this.title.setTitle(this.pageLabels?.['page.linkTickets']);
    }
  }

  /**
   * ngOnInit - set the from page as link tickets
   */

  ngOnInit(): void {
    this.fromPage = this.globalDefault.LINK_TICKETS;
    this.getTravelPartyMembers();
  }

  /**
   * goToPage - navigation on selection of type
   * @param type - type of page to route
   */

  goToPage(type: any): any {
    switch (type) {
      case this.globalDefault.LINK_TICKETS:
        this.common.changeRoute(this.appRoutes.LINK_TICKETS);
        break;

      case this.globalDefault.CLOSE:
        this.common.changeRoute(this.appRoutes.DASHBOARD);
        break;

      case this.globalDefault.CONFIRM:
        if (this.selectedPrefectureList == this.globalDefault.NEW_TPM) {
          this.showConfirmPopup = true;
        } else {
          this.common.changeRoute(this.appRoutes.CONFIRM_TICKETS, {
            guest_name: this.selectedPrefectureList,
          });
          break;
        }
    }
  }

  /**
   * navigatePage - on button click it will navigate as per the action
   * @param event - as per button click, check the button action
   */

  navigatePage(event: any): any {
    const type =
      event.target?.data.action === this.pageLabels?.['linkticketReg.backCTA']
        ? this.globalDefault.LINK_TICKETS
        : this.globalDefault.CONFIRM;
    this.goToPage(type);
  }

  /**
   * getTravelPartyMembers - Get list of travel party members to display in dropdown
   */

  getTravelPartyMembers(): any {
    const full_name = 'Nikita';
    const guestId = this.guestsService.helper.getGuestId();

    this.guestsService?.wallet
      ?.getTravelPartyMembers(guestId)
      ?.then((res: any) => {
        const data = res;
        this.showPrefectureDropdownOptions = [];
        data.map((member: any) => {
          if (member.member_id === guestId) {
            this.showPrefectureDropdownOptions.unshift(member);
            this.showPrefectureDropdownOptions[0] = {
              label: member.contact.full_name,
              value: member.contact.full_name,
            };
          } else {
            this.showPrefectureDropdownOptions.push({
              label: member.contact.full_name,
              value: member.contact.full_name,
            });
          }
        });
        this.showPrefectureDropdownOptions.push({
          label: this.pageLabels?.['linkticketReg.addNewTRP'],
          value: this.globalDefault.NEW_TPM,
        });
        this.showPrefectureDropdownOptions.map((name: any) => {
          if (full_name === name.value) {
            this.selectedPrefectureList = name.value;
          } else {
            this.selectedPrefectureList = this.globalDefault.NEW_TPM;
            this.showForm = true;
          }
        });
      })
      .catch((error: any) => {
        if (error.status == this.globalDefault.ERROR_401) {
          this.common.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.common.getOidcRedirectionUrl();
          this.common.redirectToOidcAppEndSession(redirectUrl);
        }
      });
  }

  /**
   * selectValue - on dropdown value change function get called
   * @param item - on dropdown value showForm flag set to true or false
   */

  selectValue(item: any): any {
    this.selectedPrefectureList = item.detail;
    if (item.detail == this.globalDefault.NEW_TPM) {
      this.showForm = true;
    }
  }

  /**
   * getFormData - get data of form values
   * @param event - input field values
   */

  getFormData(event: any): any {
    this.formData = event.value;
    this.travelPartyForm = event;
    // this.common.setTravelPartyMemberData(this.formData);
  }

  /**
   * onTravelPartyList - on data confirmation navigate back to ticket registration page
   * @param event - set the confirm popup value
   */

  onTravelPartyList(event: any): any {
    this.showConfirmPopup = event.showConfirmPopup;
    this.goToPage(this.globalDefault.CONFIRM);
  }
}
