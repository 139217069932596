// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormControlService, FormData } from '@upr-web-primer/form-fields';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { SessionStorageService } from '@upr-web-primer/storage';
// Primer
import { UrlInfo } from '@upr-web-primer/url-info';
// Lodash
import { get, includes } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
// Service
import { CommonUtilityService } from '../../services/common-utility.service';
@Component({
  selector: 'app-reset-my-password',
  templateUrl: './reset-my-password.component.html',
  styleUrls: ['./reset-my-password.component.scss'],
})
/**
 * Export ResetMyPasswordComponent
 */
export class ResetMyPasswordComponent implements OnInit {
  /**
   * Url info
   * @type UrlInfo
   */
  urlInfo: UrlInfo;

  /**
   * Page
   * @type any
   */
  page: any;

  /**
   * Page labels
   * @type any
   */
  pageLabels: any;

  /**
   * Component Presentations
   * @type Array<any>
   */
  componentPresentations: Array<any>;

  /**
   * Content intro
   * @type any
   */
  contentIntro: any;

  /**
   * Reset password content
   * @type any
   */
  resetPasswordContent: any;

  /**
   * Right rail article
   * @type any
   */
  rightRailArticle: any;

  /**
   * Reset password form
   * @type FormGroup
   */
  resetPasswordForm: FormGroup;

  /**
   * Password controls
   * @type FormData<any>[]
   */
  passwordControls: FormData<any>[];

  /**
   * Request ID
   * @type string
   */
  requestId: string;

  /**
   * Email
   * @type string
   */
  email: string;

  /**
   * Constructor
   * @param {ActivatedRoute} route
   * @param {CommonUtilityService} commonUtilityService
   * @param {MsGuestsService} guestsService
   * @param {FormBuilder} fb
   * @param {FormControlService} formControlService
   * @param {NotificationService} notification
   * @param {any} appRoutes
   */
  constructor(
    private route: ActivatedRoute,
    private commonUtilityService: CommonUtilityService,
    private guestsService: MsGuestsService,
    private fb: FormBuilder,
    private formControlService: FormControlService,
    private notification: NotificationService,
    private sessionStorage: SessionStorageService,
    @Inject('AppRoutes') private appRoutes: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    private cookieService: CookieService
  ) {
    this.urlInfo = this.route.snapshot.data.urlInfo;
    this.page = this.route.snapshot.data.page;
    this.componentPresentations =
      get(this.page, 'ComponentPresentations') || [];
    this.route?.queryParams?.subscribe((params) => {
      this.requestId = get(params, 'requestId');
      this.email = get(params, 'email');
    });
    this.pageLabels = this.commonUtilityService?.getResolvedPageData();
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    if (this.sessionStorage.getItem('RESET_PASSWORD_PRECLUDED')) {
      return this.commonUtilityService.changeRoute(this.appRoutes.LOGIN);
    }
    this.notification.hide();
    this.contentIntro =
      this.componentPresentations.find((cp) =>
        includes(cp.ComponentTemplate.Title, 'Account Profile - Content Intro')
      ) || {};
    this.rightRailArticle =
      this.componentPresentations.find((cp) =>
        includes(
          cp.ComponentTemplate.Title,
          'Account Profile - Right Rail Article'
        )
      ) || {};
    this.resetPasswordContent =
      this.componentPresentations.find((cp) =>
        includes(cp.ComponentTemplate.Title, 'Account Profile - Reset Password')
      ) || {};
    if (this.contentIntro) {
      this.commonUtilityService.setPageSEO(this.contentIntro);
    }

    this.initForm();
    this.commonUtilityService.initPageDDL(this.page);
  }

  /**
   * Add|Reset password form control
   * @param {string} name
   * @param {FormGroup} formGroup
   */
  addResetPasswordFormControl(name: string, formGroup: FormGroup): void {
    if (!this.resetPasswordForm) {
      return;
    }
    this.resetPasswordForm.addControl(name, formGroup);
  }

  /**
   * On Submit
   */
  onSubmit(): void {
    this.commonUtilityService.validateAllFormFields(this.resetPasswordForm);
    if (!this.resetPasswordForm.valid) {
      return this.notification.show(this.pageLabels?.['errCode.E019']);
    }
    if (!this.email || !this.requestId) {
      return this.notification.show(this.pageLabels?.['errCode.E019']);
    }
    const request = {
      newPassword: get(this.resetPasswordForm, 'value.passwordValid'),
      requestId: this.requestId,
      loginId: this.email,
    };
    this.guestsService.account
      .resetPassword(request)
      .then((response) => {
        if (!response) {
          return;
        }

        this.commonUtilityService.changeRoute(this.appRoutes.LOGIN);
      })
      .then(() =>
        this.commonUtilityService.notifyFormSuccessEvent(
          'Reset Password Form',
          'OK'
        )
      )
      .catch((err) => {
        console.error(err);
        if (err.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        } else {
          this.commonUtilityService.notifyFormErrorEvent(
            'Reset Password Form',
            err
          );
        }
      });
  }

  /**
   * Init form
   */
  private initForm(): void {
    this.resetPasswordForm = this.fb.group({});
    this.passwordControls = this.formControlService.passwordControl;
    const passwordLabel = get(
      this.resetPasswordContent,
      'Component.Fields.NewPasswordLabel.Values[0]'
    );
    if (passwordLabel && this.passwordControls[1]) {
      this.passwordControls[2].label = passwordLabel;
    }
  }
}
