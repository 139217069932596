import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  Output,
  SimpleChange,
} from '@angular/core';
import { CommonUtilityService } from '../../../services/common-utility.service';

@Component({
  selector: 'app-password-strength',
  styleUrls: ['./password-strength.component.scss'],
  templateUrl: './password-strength.component.html',
})
export class PasswordStrengthComponent implements OnChanges {
  bar0: string;
  bar1: string;
  bar2: string;
  bar3: string;
  bar4: string;

  @Input() public passwordToCheck: any;

  @Output() passwordStrength = new EventEmitter<boolean>();

  public colors = this.ranges.PASSWORDS.PASSWORD_FLAG_COLORS;

  message: string;
  messageColor: string;
  upperLetters = false;
  numbers = false;
  symbols = false;
  lowerLetters = false;
  pageLabels: any;

  constructor(
    protected injector: Injector,
    private common: CommonUtilityService,
    @Inject('Ranges') public ranges: any,
    @Inject('EmailPasswordPattern') public passwordPattern: any,
    @Inject('PhonePattern') public numberPattern: any,
    @Inject('GlobalDefaults') public globalDefault: any
  ) {
    this.pageLabels = this.common.getResolvedPageData();
  }

  /**
   * ngOnChanges - triggered when an input property is changed
   * @param changes - when value is entered in input field
   */

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const password = changes.passwordToCheck.currentValue;
    this.symbols = false;
    this.numbers = false;
    this.lowerLetters = false;
    this.upperLetters = false;

    this.setBarColors(
      this.ranges.PASSWORDS.INDEX[5],
      this.ranges.PASSWORDS.DEFAULT_BACKGROUND
    );

    if (password) {
      const color = this.getColor(this.checkStrength(password));
      this.setBarColors(color.index, color.color);

      const pwdStrength = this.checkStrength(password);
      pwdStrength === this.ranges.PASSWORDS.FORCE_INDEX[4]
        ? this.passwordStrength.emit(true)
        : this.passwordStrength.emit(false);

      switch (pwdStrength) {
        case this.ranges.PASSWORDS.FORCE_INDEX[0]:
          this.message = this.pageLabels?.['editPwd.veryweak'];
          break;
        case this.ranges.PASSWORDS.FORCE_INDEX[1]:
          this.message = this.pageLabels?.['editPwd.weak'];
          break;
        case this.ranges.PASSWORDS.FORCE_INDEX[2]:
          this.message = this.pageLabels?.['editPwd.medium'];
          break;
        case this.ranges.PASSWORDS.FORCE_INDEX[3]:
          this.message = this.pageLabels?.['editPwd.moderate'];
          break;
        case this.ranges.PASSWORDS.FORCE_INDEX[4]:
          this.message = this.pageLabels?.['editPwd.strong'];
          break;
      }
    } else {
      this.message = '';
    }
  }

  /**
   * getColor - To get the color for the bars
   * @param strength - the password strength number
   * @returns
   */

  public getColor(strength: number): { index: number; color: string } {
    let index: any;
    switch (strength) {
      case this.ranges.PASSWORDS.FORCE_INDEX[1]:
        index = this.ranges.PASSWORDS.INDEX[1];
        break;
      case this.ranges.PASSWORDS.FORCE_INDEX[2]:
        index = this.ranges.PASSWORDS.INDEX[2];
        break;
      case this.ranges.PASSWORDS.FORCE_INDEX[3]:
        index = this.ranges.PASSWORDS.INDEX[3];
        break;
      case this.ranges.PASSWORDS.FORCE_INDEX[4]:
        index = this.ranges.PASSWORDS.INDEX[4];
        break;
      case this.ranges.PASSWORDS.FORCE_INDEX[0]:
        index = this.ranges.PASSWORDS.INDEX[0];
        break;
      default:
        index = this.ranges.PASSWORDS.INDEX[5];
    }

    this.messageColor = this.colors[index];

    return {
      index: index + this.ranges.PASSWORDS.INDEX[1],
      color: this.colors[index],
    };
  }

  /**
   * checkStrength - to verify the password strength
   * @param password  - entered password value
   * @returns
   */

  checkStrength(password: string): number {
    // 1;
    let force = this.ranges.PASSWORDS.INDEX[0];
    const arrayOfSp = this.ranges.PASSWORDS.SPECIAL_CHAR;
    const arrayOfInvSp = this.ranges.PASSWORDS.INVALID_SPECIAL_CHAR;
    const specialChars = new Set(arrayOfSp);
    const invSpecialChars = new Set(arrayOfInvSp);

    this.lowerLetters = this.passwordPattern.LOWERCASE_PASSWORD.test(password);
    this.upperLetters = this.passwordPattern.UPPERCASE_PASSWORD.test(password);
    this.numbers = this.numberPattern.ONLY_NUMBER.test(password);
    const specialChar = [...password].some((c) => specialChars.has(c));
    const invalidSpecialChar = [...password].some((c) =>
      invSpecialChars.has(c)
    );

    // 2
    this.symbols = specialChar && invalidSpecialChar === false ? true : false;

    const passLength =
      password?.length > this.ranges.PASSWORDS.PASSWORD_LENGTH_INDEX;

    // const passwordValid = this.passwordPattern.VALID_PASSWORD.test(password);
    // this.symbols = passwordValid;

    // 3
    const flags = [
      passLength,
      this.lowerLetters,
      this.upperLetters,
      this.numbers,
      this.symbols,
    ];

    // 4
    let passedMatches = this.ranges.PASSWORDS.INDEX[0];
    for (const flag of flags) {
      passedMatches +=
        flag === true
          ? this.ranges.PASSWORDS.INDEX[1]
          : this.ranges.PASSWORDS.INDEX[0];
    }

    // 5
    force +=
      this.ranges.PASSWORDS.INDEX[2] * password?.length +
      (password?.length >= this.ranges.PASSWORDS.FORCE_INDEX[0]
        ? this.ranges.PASSWORDS.INDEX[1]
        : this.ranges.PASSWORDS.INDEX[0]);
    force += passedMatches * this.ranges.PASSWORDS.FORCE_INDEX[0];

    // 6
    force =
      password?.length <= this.ranges.PASSWORDS.INDEX[6]
        ? Math.min(force, this.ranges.PASSWORDS.FORCE_INDEX[0])
        : force;

    // 7
    force =
      passedMatches === this.ranges.PASSWORDS.INDEX[1]
        ? Math.min(force, this.ranges.PASSWORDS.FORCE_INDEX[0])
        : force;
    force =
      passedMatches === this.ranges.PASSWORDS.INDEX[2]
        ? Math.min(force, this.ranges.PASSWORDS.FORCE_INDEX[1])
        : force;
    force =
      passedMatches === this.ranges.PASSWORDS.INDEX[3]
        ? Math.min(force, this.ranges.PASSWORDS.FORCE_INDEX[2])
        : force;
    force =
      passedMatches === this.ranges.PASSWORDS.INDEX[4]
        ? Math.min(force, this.ranges.PASSWORDS.FORCE_INDEX[3])
        : force;
    force =
      passedMatches === this.ranges.PASSWORDS.INDEX[5]
        ? Math.min(force, this.ranges.PASSWORDS.FORCE_INDEX[4])
        : force;
    return force;
  }

  /**
   * setBarColors
   * @param count - return from getColor()
   * @param color - value of the color attribute
   */
  public setBarColors(count: number, color: string): void {
    for (let i = 0; i < count; i++) {
      (this as any)['bar' + i] = color;
    }
  }
}
