<gds-full-screen-modal opened="isOpened" (modalClose)="goToPage('close')">
  <div slot="components">
    <div class="container modal-content" #registerTicketsModalContent>
      <gds-spacer classes="spacer-70"></gds-spacer>

      <gds-heading
        [data]="{
            heading: pageLabels?.['linkTicketConf.heading']
          }"
        class="A2-W5"
      >
      </gds-heading>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <gds-body
        [data]="{
        description: pageLabels?.['linkTicketConf.description']
      }"
        class="B1-W3"
      ></gds-body>

      <gds-spacer classes="spacer-70"></gds-spacer>

      <!-- Display USername-->
      <div>
        <gds-heading
          [data]="{
            heading: userName
          }"
          class="user_content A2-W5"
        ></gds-heading>
      </div>

      <gds-spacer classes="spacer-70"></gds-spacer>

      <!-- Ticket Details-->
      <div *ngFor="let ticket of ticketData; let last = last">
        <div>
          <gds-feature
            [data]="{
            icon: 'tickets2',
            heading: ticket['product_name'],
            shortDescription: pageLabels?.['linkTicketReg.ticketDet_entry'] + ': '+getDate(ticket['valid_from'])
     }"
          >
          </gds-feature>
        </div>
        <div *ngIf="!last">
          <gds-spacer classes="spacer-50"></gds-spacer>
          <hr />
          <gds-spacer classes="spacer-50"></gds-spacer>
        </div>
      </div>
    </div>

    <!--Footer-->
    <div>
      <gds-sticky-nav-footer
        [data]="{
            button: {
              type: 'primary',
              href: '',
              label: pageLabels?.['linkTicketConf.finishCTA'],
              disabled: false,
              target: '',
              action: pageLabels?.['linkTicketConf.finishCTA']
            },
            secondaryButton: {
              label:  pageLabels?.['linkticketConf.linkMoreCTA'],
              type: 'secondary',
              href: '',
              disabled: '',
              target: '',
              action:  pageLabels?.['linkticketConf.linkMoreCTA']
            }
          }"
        (buttonClicked)="navigatePage($event)"
      >
      </gds-sticky-nav-footer>
    </div>
  </div>
</gds-full-screen-modal>
