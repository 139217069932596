<gds-full-screen-modal opened="isOpened" (modalClose)="goToPage('close')">
  <div slot="components">
    <div class="container">
      <gds-spacer class="spacer-70"></gds-spacer>
      <gds-body
        [data]="{
                  description: pageLabels?.['linkTicketConf.heading']
                }"
        class="A2-W5"
      ></gds-body>
      <gds-spacer class="spacer-50"></gds-spacer>
      <gds-body
        [data]="{
          description: pageLabels?.['linkTicketConf.subText']
        }"
        class="A2-W5"
      ></gds-body>
      <gds-spacer class="spacer-80"></gds-spacer>
      <div class="content-callout">
        <!-- Registration Information-->
        <gds-content-callout
          [data]="{
                icon: 'info-solid',
                heading: pageLabels?.['linkTicketConf.calloutInfo'],
                description: pageLabels?.['linkTicketConf.calloutMsg']
            }"
        >
        </gds-content-callout>
      </div>
      <h6 class="heading A2-W5">
        {{pageLabels?.['linkTicketConf.user']}}
      </h6>
      <!-- display user name-->
      <div *ngIf="showContent">
        <h6 class="user_content A2-W5">
          {{ userName }}
        </h6>
      </div>
      <!-- display user details-->
      <div *ngIf="!showContent">
        <div class="user_data">
          <h6 class="user_heading_content A2-W5">
            {{ pageLabels?.['trpAdd.lastName']}}
          </h6>
          <h6 class="user_content A2-W5">{{ content.lastName }}</h6>
        </div>
        <hr />
        <div class="user_data">
          <h6 class="user_heading_content A2-W5">
            {{ pageLabels?.['trpAddConf.firstName'] }}
          </h6>
          <h6 class="user_content A2-W5">{{ content.firstName }}</h6>
        </div>
        <hr />
        <div class="user_data">
          <h6 class="user_heading_content A2-W5">
            {{ pageLabels?.['trpAdd.relationship'] }}
          </h6>
          <h6 class="user_content A2-W5">{{ content.relationship }}</h6>
        </div>
      </div>
      <gds-spacer class="spacer-80"></gds-spacer>
      <!-- <app-ticket-card></app-ticket-card> -->
    </div>
    <gds-spacer class="spacer-80"></gds-spacer>
    <!--Footer-->
    <gds-sticky-nav-footer
      [data]="{
          button: {
            type: 'primary',
            href: '',
            label:  pageLabels?.['linkticket.registerCTA'],
            disabled: false,
            target: '',
            action: pageLabels?.['linkticket.registerCTA']
          },
          secondaryButton: {
            label:  pageLabels?.['linkticketReg.backCTA'],
            type: 'secondary',
            href: '',
            disabled: '',
            target: '',
            action:  pageLabels?.['linkticketReg.backCTA']
          }
        }"
      (buttonClicked)="navigatePage($event)"
    >
    </gds-sticky-nav-footer>
  </div>
</gds-full-screen-modal>
