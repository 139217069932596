import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { SpinnerService } from '@upr-web-primer/spinner';
import { LocalStorageService } from '@upr-web-primer/storage';
import _ from 'lodash';
import { EnvironmentService } from '../../../environments/environment.service';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-shareqrcode',
  templateUrl: './share-qr-code.component.html',
  styleUrls: ['./share-qr-code.component.scss'],
})
export class ShareqrcodeComponent implements OnInit, OnDestroy {
  pageLabels: any;
  guest_id: any;
  @Input() ticketDetails: any[] = [];
  mediaID: any;
  qrCode: any;
  qrDesc: any;
  qrDetail: any;
  entitlementData: any[];
  dateEntry: any;
  dateShow = false;
  subscription: any;
  @Input() guestId!: string;
  @Input() mediaId!: string;
  @Input() isPrint: boolean;

  printService: any;
  print: any;

  @Output() printEvent = new EventEmitter<boolean>();
  decryptedData: any;

  constructor(
    protected injector: Injector,
    private environment: EnvironmentService,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('RelativeUrls') private relativeUrls: any,
    public commonUtilityService: CommonUtilityService,
    public guestsService: MsGuestsService,
    private spinner: SpinnerService,
    public title: Title,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private cdRef: ChangeDetectorRef,
    private notification: NotificationService
  ) {}

  /**
   * ngOnInit - initial methods are called
   */
  ngOnInit(): void {
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
    this.qrDesc = this.pageLabels?.['scanQR.info'];
    this.qrDetail = this.pageLabels?.['enalrgeQR.info'];
    this.dateEntry = this.pageLabels?.['viewEntPopulated.entryDate'];
    this.spinner.show();
    const printQr = this.localStorage.getItem('isPrint');
    this.dateShow = printQr === 'true';
    if (!this.dateShow) {
      this.title?.setTitle(this.pageLabels?.['page.shareQR']);
    }
    this.getEntitledDetails();
    this.fetchQrCode();
  }

  /**
   * getEntitledDetails - to filter ticket details from entitlements.
   */
  getEntitledDetails(): void {
    if (this.dateShow) {
      this.guest_id = this.guestId;
      this.mediaID = this.mediaId;
      this.spinner.hide();
    }
    const encodedData = this.route?.snapshot?.params['data'];
    try {
      const decodedData = decodeURIComponent(atob(encodedData));
      this.decryptedData = JSON.parse(decodedData);
      this.guestId = this.decryptedData?.guestId;
      this.mediaID = this.decryptedData?.mediaId;
      this.ticketDetails = this.decryptedData?.tickets;
      this.spinner.hide();
    } catch (error) {
      console.error('Error decoding data:', error);
    }
  }

  /**
   * getTickets - Get the tickets of guest user
   */
  getTickets(): void {
    this.ticketDetails = _.filter(
      this.entitlementData,
      (item: any) =>
        item.guest_id === this.guest_id && this.isDateValid(item?.valid_to)
    );
  }

  /**
   * fetchQrCode - get the QR code
   */

  fetchQrCode(): void {
    const qrCodeValue = this.mediaID;

    // Check if mediaID is empty
    if (!qrCodeValue) {
      this.notification.show(this.pageLabels?.['errCode.MS.ent.503']);
      return; // Exit the function early if mediaID is empty
    }

    const url = new URL(
      this.environment?.oidcBaseUrl + this.relativeUrls?.GET_QRCODE
    );
    url.searchParams.append('data', qrCodeValue);
    this.qrCode = url.href;
    this.cdRef.detectChanges();
  }

  /**
   * isDateValid-compare the ticket date with current Date
   */
  isDateValid(validDate: string): boolean {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in "yyyy-MM-dd" format
    return currentDate <= validDate;
  }

  /**
   * getDate - It will format the date based on locale
   */
  getDate(date: any): string {
    return this.commonUtilityService.getFormattedDateOnLocale(date);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
