import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { LocalStorageService } from '@upr-web-primer/storage';
import { get } from 'lodash-es';
import { Observable } from 'rxjs';
import { CommonUtilityService } from './services/common-utility.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private guestsService: MsGuestsService,
    private common: CommonUtilityService,
    private localStorage: LocalStorageService,
    @Inject('AppRoutes') private appRoutes: any
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const id_token_hint = get(route.queryParams, 'id_token_hint', '');
    const guestId = this.localStorage.getItem('LOGGEDIN_GUEST_ID') || '';
    const oidcLoginToken = this.localStorage.getItem('OIDC_ID_TOKEN') || '';
    const oauthLoginId = this.localStorage.getItem('OAUTH_LOGIN_GID') || '';

    if (
      !id_token_hint &&
      !guestId &&
      !oidcLoginToken &&
      !oauthLoginId &&
      !this.guestsService.helper.isAuth()
    ) {
      this.common.changeRoute(this.appRoutes.LOGIN, { signoff: true });
      return false;
    }
    return true;
  }
}
