import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-warning-travel-party',
  templateUrl: './warning-travel-party.component.html',
  styleUrls: ['./warning-travel-party.component.scss'],
})
export class WarningTravelPartyComponent implements OnInit, AfterViewInit {
  @Input() showConfirmPopup: boolean;
  @Input() opened: boolean;
  @Output() backToEditTravel = new EventEmitter<any>();
  @ViewChild('warningPartyModalContent') warningPartyModalContent!: ElementRef;
  /**
   * Page labels
   * @type any
   */
  pageLabels: any;
  constructor(
    private appDataService: AppDataService,
    private commonUtilityService: CommonUtilityService
  ) {
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
  }

  /**
   * ngOnInit - initial methods are called
   */

  ngOnInit(): void {}
  /**
   * ngAfterViewInit - initial methods are called
   */
  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(
      this.warningPartyModalContent,
      164
    );
  }
  /**
   * onResize - To resize full screen modal popup
   */
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(
      this.warningPartyModalContent,
      164
    );
  }

  /**
   * closePopup - close the popup
   */

  closePopup(): any {
    this.backToEditTravel.emit({ opened: false });
  }

  /**
   * confirmTravelPartyMember - shows the warning msg and emit the event to edit travel page
   * @param event : specify the event from field
   */

  // confirmTravelPartyMember(event: any): any {
  //   event.target?.data.action === 'continue'
  //     ? this.backToEditTravel.emit({ showConfirm: true, opened: false })
  //     : this.closePopup();
  // }
}
