<div [formGroup]="addTravelPartyForm">
  <!-- Last Name Input field -->
  <gds-input
    id="lastName"
    formControlName="lastName"
    [errors]="lastNameErrorMsgInfo"
    ngDefaultControl
    [data]="{
            primaryLabel: pageLabels?.['trpAdd.lastName'],
            supportingText: '',
            tooltip: '',
            type: 'text',
            altText: 'input of lastName',
            ariaText: '',
            required: true
            }"
    onkeydown="return /[a-zA-Z]/i.test(event.key)"
    (blurChange)="getErrorMessageInfo($event)"
    onKeyPress=" if( this.value.length === 30 ) return false;"
    (valueChange)="onValueChange($event)"
  >
  </gds-input>
  <gds-spacer classes="spacer-50"></gds-spacer>
  <!-- First Name Input field -->
  <gds-input
    id="firstName"
    formControlName="firstName"
    [errors]="firstNameErrorMsgInfo"
    ngDefaultControl
    [data]="{
            primaryLabel: pageLabels?.['trpAdd.firstName'],
            supportingText: '',
            tooltip: '',
            type: 'text',
            altText: 'input of firstName',
            ariaText: '',
            required: true
            }"
    onkeydown="return /[a-zA-Z]/i.test(event.key)"
    (blurChange)="getErrorMessageInfo($event)"
    onKeyPress=" if( this.value.length === 25 ) return false;"
    (valueChange)="onValueChange($event)"
  >
  </gds-input>
  <gds-spacer classes="spacer-50"></gds-spacer>
  <!-- Relationship dropdown field -->
  <gds-dropdown
    id="relationship"
    formControlName="relationship"
    ngDefaultControl
    [data]="{
            formField: { primaryLabel:pageLabels?.['trpAdd.relationship'], supportingText: pageLabels?.['trpAdd.optional']},
            options: relationshipArray
            }"
    [value]="selectedRelationship"
    (valueChange)="matchDropDown($event, 'relationship')"
  >
  </gds-dropdown>
  <gds-spacer classes="spacer-50"></gds-spacer>
  <!-- Date of Birth Input field -->
  <div *ngIf="dobGenderValue">
    <gds-input
      id="dob"
      formControlName="dob"
      [errors]="dobErrorMsgInfo"
      ngDefaultControl
      [data]="{
            primaryLabel: pageLabels?.['trpAdd.birthday'],
            supportingText: pageLabels?.['trpAdd.optional'],
            tooltip: pageLabels?.['trpAdd.birthdayFormat'],
            type: 'number',
            altText: 'input of surname',
            ariaText: '',
            required: false
            
            }"
      onKeyPress=" if( this.value.length == 8 ) return false;"
      (valueChange)="onValueChange($event)"
      (input)="validateDob($event)"
      (blurChange)="getErrorMessageInfo($event)"
      hide-spinner="true"
      onkeydown="if(event.key === 'ArrowUp' || event.key === 'ArrowDown') event.preventDefault();"
    >
    </gds-input>
    <gds-spacer classes="spacer-30"></gds-spacer>
    <!-- Date of Birth input field heading -->
    <gds-heading
      [data]="{
            heading:
                pageLabels?.['trpAdd.birthdayInfo']
            }"
      class="B1-W3"
    >
    </gds-heading>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <!-- Gender dropdown field -->
    <gds-dropdown
      id="gender"
      formControlName="gender"
      ngDefaultControl
      [data]="{
            options: genderArray,
            formField: { primaryLabel: pageLabels?.['trpAdd.gender'], supportingText: pageLabels?.['trpAdd.optional']}
            }"
      [value]="selectedGender"
      (valueChange)="matchDropDown($event, 'gender')"
    >
    </gds-dropdown>
    <gds-spacer classes="spacer-85"></gds-spacer>
  </div>
</div>
