<section class="row footer-section copyright-footer align-middle">
  <div class="content columns" [innerHTML]="pageHeading"></div>

  <div class="content columns">
    <ul class="copyright-links copyright-links-mobile">
      <li>
        <div (click)="redirectToCopyrights()">
          <span class="links-heading">{{ copyrightLabel }}</span
          ><i aria-hidden="true" class="icon icon-arrow-right"></i>
        </div>
      </li>
      <li>
        <div (click)="redirectToPrivacy()">
          <span class="links-heading">{{ privacyLabel }} </span
          ><i aria-hidden="true" class="icon icon-arrow-right"></i>
        </div>
      </li>
    </ul>
  </div>
</section>
<div class="bottom-footer"></div>
