import { Injectable } from '@angular/core';
import { environment } from './environment';
import { IEnvironment } from './environment.type';
import { udeploy } from './udeploy';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService implements IEnvironment {
  get appTheme(): string {
    return udeploy.appTheme || environment.appTheme;
  }

  get bingSiteVerificationKey(): string {
    return (
      udeploy.bingSiteVerificationKey || environment.bingSiteVerificationKey
    );
  }

  get cardinalScriptUrl(): string {
    return udeploy.cardinalScriptUrl || environment.cardinalScriptUrl;
  }

  get defaultTheme(): string {
    return udeploy.defaultTheme || environment.defaultTheme;
  }

  get googleSiteVerificationKey(): string {
    return (
      udeploy.googleSiteVerificationKey || environment.googleSiteVerificationKey
    );
  }

  get production(): boolean {
    return environment.production || false;
  }

  get basic_version(): boolean {
    return environment.basic_version || false;
  }

  get tridionBaseUrl(): string {
    return udeploy.tridionBaseUrl || environment.tridionBaseUrl;
  }

  get tridionSite(): string {
    return udeploy.tridionSite || environment.tridionSite;
  }

  get accountAppPath(): string {
    return udeploy.accountAppPath || environment.accountAppPath;
  }

  get adobeAutosearchUrl(): string {
    return udeploy.adobeAutosearchUrl || environment.adobeAutosearchUrl;
  }

  get adobeHeaderScript(): string {
    return udeploy.adobeHeaderScript || environment.adobeHeaderScript;
  }

  get adobeBodyScript(): string {
    return udeploy.adobeBodyScript || environment.adobeBodyScript;
  }

  get adobeMbox(): string {
    return udeploy.adobeMbox || environment.adobeMbox;
  }

  get adobeTargetDisabled(): boolean {
    return udeploy.adobeTargetDisabled || environment.adobeTargetDisabled;
  }

  get defaultCountry(): string {
    return udeploy.defaultCountry || environment.defaultCountry || 'ja';
  }

  get defaultLanguage(): string {
    return udeploy.defaultLanguage || environment.defaultLanguage;
  }

  get denaliUrl(): string {
    return udeploy.denaliUrl || environment.denaliUrl;
  }

  get digitalDataAdblockerEnabled(): boolean {
    return (
      udeploy.digitalDataAdblockerEnabled ||
      environment.digitalDataAdblockerEnabled
    );
  }

  get digitalDataAppVersion(): string {
    return udeploy.digitalDataAppVersion || environment.digitalDataAppVersion;
  }

  get digitalDataAngularVersion(): string {
    return (
      udeploy.digitalDataAngularVersion || environment.digitalDataAngularVersion
    );
  }

  get digitalDataDestination(): string {
    return udeploy.digitalDataDestination || environment.digitalDataDestination;
  }

  get digitalDataEnabled(): boolean {
    return udeploy.digitalDataEnabled || environment.digitalDataEnabled;
  }

  get digitalDataSegment(): string {
    return udeploy.digitalDataSegment || environment.digitalDataSegment;
  }

  get digitalDataEnvironment(): string {
    return udeploy.digitalDataEnvironment || environment.digitalDataEnvironment;
  }

  get digitalDataFlashVersion(): string {
    return (
      udeploy.digitalDataFlashVersion || environment.digitalDataFlashVersion
    );
  }

  get digitalDataSite(): string {
    return udeploy.digitalDataSite || environment.digitalDataSite;
  }

  get digitalDataTridionVersion(): string {
    return (
      udeploy.digitalDataTridionVersion || environment.digitalDataTridionVersion
    );
  }

  get facebookLikeUrl(): string {
    return udeploy.facebookLikeUrl || environment.facebookLikeUrl;
  }

  get fontCssUrl(): string {
    return udeploy.fontCssUrl || environment.fontCssUrl;
  }

  get iconCssUrl(): string {
    return udeploy.iconCssUrl || environment.iconCssUrl;
  }

  get globalNavigationEnabled(): boolean {
    return (
      udeploy.globalNavigationEnabled || environment.globalNavigationEnabled
    );
  }

  get grecaptchaApiUrl(): string {
    return udeploy.grecaptchaApiUrl || environment.grecaptchaApiUrl;
  }

  get grecaptchaSiteKey(): string {
    return udeploy.grecaptchaSiteKey || environment.grecaptchaSiteKey;
  }

  get grecaptchaEnabled(): boolean {
    return udeploy.grecaptchaEnabled || environment.grecaptchaEnabled;
  }

  get grecaptchaTestResponse(): string {
    return udeploy.grecaptchaTestResponse || environment.grecaptchaTestResponse;
  }

  get layoutHeader(): string {
    return udeploy.layoutHeader || environment.layoutHeader;
  }

  get mobileServicesShowtimesEndpoint(): string {
    return (
      udeploy.mobileServicesShowtimesEndpoint ||
      environment.mobileServicesShowtimesEndpoint
    );
  }

  get mobileServicesVenueEndpoints(): string {
    return (
      udeploy.mobileServicesVenueEndpoints ||
      environment.mobileServicesVenueEndpoints
    );
  }

  get oauthBaseUrl(): string {
    return udeploy.oauthBaseUrl || environment.oauthBaseUrl;
  }

  get oauthClientId(): string {
    return udeploy.oauthClientId || environment.oauthClientId;
  }

  get oauthClientKeyID(): string {
    return udeploy.oauthClientKeyID || environment.oauthClientKeyID;
  }

  get oauthAppFlowPath(): string {
    return udeploy.oauthAppFlowPath || environment.oauthAppFlowPath;
  }

  get oauthAppPasswordFlowPath(): string {
    return (
      udeploy.oauthAppPasswordFlowPath || environment.oauthAppPasswordFlowPath
    );
  }

  get oauthAppSourceId(): string {
    return udeploy.oauthAppSourceId || environment.oauthAppSourceId;
  }

  get oidcBaseUrl(): string {
    return udeploy.oidcBaseUrl || environment.oidcBaseUrl;
  }

  get oidcEnabled(): boolean {
    return udeploy.oidcEnabled || environment.oidcEnabled;
  }

  get oidcRedirectEnabled(): boolean {
    return udeploy.oidcRedirectEnabled || environment.oidcRedirectEnabled;
  }

  get oidcRedirectApi(): string {
    return udeploy.oidcRedirectApi || environment.oidcRedirectApi;
  }

  get oidcClientId(): string {
    return udeploy.oidcClientId || environment.oidcClientId;
  }

  get oidcClientKeyID(): string {
    return udeploy.oidcClientKeyID || environment.oidcClientKeyID;
  }

  get oidcNodeApiBaseUrl(): string {
    return udeploy.oidcNodeApiBaseUrl || environment.oidcNodeApiBaseUrl;
  }

  get oidcAppFlowPath(): string {
    return udeploy.oidcAppFlowPath || environment.oidcAppFlowPath;
  }

  get oidcAppPasswordFlowPath(): string {
    return (
      udeploy.oidcAppPasswordFlowPath || environment.oidcAppPasswordFlowPath
    );
  }

  get oidcSignoffApi(): string {
    return udeploy.oidcSignoffApi || environment.oidcSignoffApi;
  }

  get oneTrustDomainScript(): string {
    return udeploy.oneTrustDomainScript || environment.oneTrustDomainScript;
  }

  get oneTrustEnabled(): boolean {
    return udeploy.oneTrustEnabled || environment.oneTrustEnabled;
  }

  get oneTrustModalScript(): string {
    return udeploy.oneTrustModalScript || environment.oneTrustModalScript;
  }

  get oneTrustHash(): string {
    return udeploy.oneTrustHash || environment.oneTrustHash;
  }

  get oneTrustCrossorigin(): string {
    return udeploy.oneTrustCrossorigin || environment.oneTrustCrossorigin;
  }

  get oneTrustPrivacyScript(): string {
    return udeploy.oneTrustPrivacyScript || environment.oneTrustPrivacyScript;
  }

  get oneTrustPrivacyIds(): string {
    return udeploy.oneTrustPrivacyIds || environment.oneTrustPrivacyIds;
  }

  get oneTrustPrivacyGeo(): string {
    return udeploy.oneTrustPrivacyGeo || environment.oneTrustPrivacyGeo;
  }

  get oneTrustPrivacyLSPA(): string {
    return udeploy.oneTrustPrivacyLSPA || environment.oneTrustPrivacyLSPA;
  }
  get coveo(): any {
    return udeploy.coveo || environment.coveo;
  }
  get coveoEnabled(): boolean {
    return udeploy.coveo.enabled || environment.coveo.enabled;
  }
  get coveoScriptSearchUrl(): string {
    return udeploy.coveo.scriptSearchUrl || environment.coveo.scriptSearchUrl;
  }
  get coveoScriptSearchHash(): string {
    return udeploy.coveo.scriptSearchHash || environment.coveo.scriptSearchHash;
  }
  get coveoScriptTemplateUrl(): string {
    return (
      udeploy.coveo.scriptTemplateUrl || environment.coveo.scriptTemplateUrl
    );
  }
  get coveoScriptTemplateHash(): string {
    return (
      udeploy.coveo.scriptTemplateHash || environment.coveo.scriptTemplateHash
    );
  }
  get coveoScriptLocale(): string {
    return udeploy.coveo.scriptLocale || environment.coveo.scriptLocale;
  }
  get coveoScriptLocaleHash(): string {
    return udeploy.coveo.scriptLocaleHash || environment.coveo.scriptLocaleHash;
  }
  get coveoCss(): string {
    return udeploy.coveo.css || environment.coveo.css;
  }
  get coveoCssHash(): string {
    return udeploy.coveo.cssHash || environment.coveo.cssHash;
  }
  get coveoCrossOrigin(): string {
    return udeploy.coveo.crossOrigin || environment.coveo.crossOrigin;
  }
  get coveoOrganizationId(): string {
    return udeploy.coveo.organizationId || environment.coveo.organizationId;
  }
  get coveoAccessKeyID(): string {
    return udeploy.coveo.accessKeyID || environment.coveo.accessKeyID;
  }

  get geo(): any {
    return udeploy.geo || environment.geo;
  }
  get geoDisabled(): boolean {
    return udeploy.geo.disabled || environment.geo.disabled;
  }

  get geoApiUrl(): string {
    return udeploy.geo.apiUrl || environment.geo.apiUrl;
  }
  get GEOLOCATION_AKAMAI(): boolean {
    return udeploy.geo.location.akamai || environment.geo.location.akamai;
  }
  get PRIMARY_GEO_REGION(): string {
    return udeploy.geo.region.primary || environment.geo.region.primary;
  }

  get weatherApi(): string {
    return udeploy.weatherApi || environment.weatherApi;
  }

  get googleCalendarApiKey(): string {
    return udeploy.googleCalendarApiKey || environment.googleCalendarApiKey;
  }

  get resortName(): string {
    return udeploy.resortName || environment.resortName;
  }

  get resortAreaCode(): string {
    return udeploy.resortAreaCode || environment.resortAreaCode;
  }

  get hmacKey(): string {
    return udeploy.hmacKey || environment.hmacKey;
  }

  get seoTitle(): string {
    return udeploy.seoTitle || environment.seoTitle;
  }

  get seoDescription(): string {
    return udeploy.seoDescription || environment.seoDescription;
  }

  get seoImage(): string {
    return udeploy.seoImage || environment.seoImage;
  }

  get seoFacebookSiteName(): string {
    return udeploy.seoFacebookSiteName || environment.seoFacebookSiteName;
  }

  get seoTwitterSiteName(): string {
    return udeploy.seoTwitterSiteName || environment.seoTwitterSiteName;
  }

  get sorryPagePath(): string {
    return udeploy.sorryPagePath || environment.sorryPagePath;
  }

  get tripDetailLink(): string {
    return udeploy.tripDetailLink || environment.tripDetailLink;
  }

  get uprvUrl(): string {
    return udeploy.uprvUrl || environment.uprvUrl;
  }

  get upsellPackages(): string {
    return udeploy.upsellPackages || environment.upsellPackages;
  }

  get wcsStoreId(): string {
    return udeploy.wcsStoreId || environment.wcsStoreId;
  }

  get webAppPath(): string {
    return udeploy.webAppPath || environment.webAppPath;
  }

  get webstoreAppPath(): string {
    return udeploy.webstoreAppPath || environment.webstoreAppPath;
  }

  get webstoreJunction(): string {
    return udeploy.webstoreJunction || environment.webstoreJunction;
  }

  get diningReservationsAppPath(): string {
    return (
      udeploy.diningReservationsAppPath || environment.diningReservationsAppPath
    );
  }

  get timeZone(): string {
    return udeploy.timeZone || environment.timeZone;
  }

  get uniWebServiceApiKey(): string {
    return udeploy.uniWebServiceApiKey || environment.uniWebServiceApiKey;
  }

  get COMPLETE_AP(): string {
    return udeploy.COMPLETE_AP || environment.COMPLETE_AP;
  }

  get commerceClientId(): string {
    return udeploy.commerceClientId || environment.commerceClientId;
  }
  get commerceClientKeyID(): string {
    return udeploy.commerceClientKeyID || environment.commerceClientKeyID;
  }
  get commerceApiUrl(): string {
    return udeploy.commerceApiUrl || environment.commerceApiUrl;
  }
  get wtsHostBaseUrl(): string {
    return udeploy.wtsHostBaseUrl || environment.wtsHostBaseUrl;
  }
  get entitlementBaseUrl(): string {
    return udeploy.entitlementBaseUrl || environment.entitlementBaseUrl;
  }
  get gtmId(): string {
    return udeploy.gtmId || environment.gtmId;
  }

  get cookieSubDomain(): string {
    return udeploy.cookieSubDomain || environment.cookieSubDomain;
  }

  get contentApiBaseUrl(): string {
    return udeploy.contentApiBaseUrl || environment.contentApiBaseUrl;
  }

  get contentLoginClientID(): string {
    return udeploy.contentLoginClientID || environment.contentLoginClientID;
  }

  get contentLoginClientKeyID(): string {
    return (
      udeploy.contentLoginClientKeyID || environment.contentLoginClientKeyID
    );
  }
  get language_dropdown(): boolean {
    return environment.language_dropdown || false;
  }
  get version_dropdown(): boolean {
    return environment.version_dropdown || false;
  }
  get crewToolsApi(): string {
    return udeploy.crewToolsApi || environment.crewToolsApi;
  }
  get rToasterTagId(): string {
    return udeploy.rToasterTagId || environment.rToasterTagId;
  }
  get isRToasterTagEnabled(): boolean {
    return environment.isRToasterTagEnabled || false;
  }
  contentIds: {
    GDS_FOOTER_CONTENT_ID: 'GDS-Footer-Utility-USJ-ACCOUNT';
    TRIDION_PUBLICATION: 'usj';
    GDS_GLOBAL_NAV_UTILITY_BAR_CONTENT_ID: 'GDS-Main-Nav-Utility-USJ-ACCOUNT';
    GDS_GLOBAL_NAV_MEGA_NAV_CONTENT_ID: 'gds-global-nav-usj-mega-nav-main-account';
  };
  get inquiryToken(): string {
    return udeploy.inquiryToken || environment.inquiryToken;
  }
  get oidcInquiryClientId(): string {
    return udeploy.oidcInquiryClientId || environment.oidcInquiryClientId;
  }
  get oidcInquiryClientKeyID(): string {
    return udeploy.oidcInquiryClientKeyID || environment.oidcInquiryClientKeyID;
  }
  get prependImageBaseUrl(): boolean {
    return environment.prependImageBaseUrl || true;
  }
  get baseRedirectBypass(): boolean {
    return udeploy.baseRedirectBypass || environment.baseRedirectBypass;
  }
}
