import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { AppDataService } from '../../../services/app-data.service';
import { CommonUtilityService } from '../../../services/common-utility.service';
@Component({
  selector: 'app-registered-link-tickets',
  templateUrl: './registered-link-tickets.component.html',
  styleUrls: ['./registered-link-tickets.component.scss'],
})
export class RegisteredLinkTicketsComponent implements OnInit, AfterViewInit {
  pageLabels: any;
  userName: any;
  ticketData: any;

  @ViewChild('registerTicketsModalContent')
  registerTicketsModalContent!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    @Inject('AppRoutes') public appRoutes: any,
    private common: CommonUtilityService,
    private appDataService: AppDataService,
    @Inject('GlobalDefaults') public globalDefault: any,
    public guestsService: MsGuestsService,
    public notification: NotificationService
  ) {
    if (this.common?.getResolvedPageData) {
      this.pageLabels = this.common.getResolvedPageData();
      this.title.setTitle(this.pageLabels?.['page.linkTickets']);
    }
    this.userName = this.route.snapshot?.queryParams?.guest_name;
  }

  ngOnInit(): void {
    this.notification?.show(
      this.pageLabels?.['linkTicketConf.successMsg'],
      this.globalDefault.SUCCESS
    );
    this.ticketData = this.common.getTravelPartyMemberData();
    this.sortTicketsByUpcomingMonthAndDate(this.ticketData);
  }

  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightGenerator(
      this.registerTicketsModalContent
    );
  }
  /**
   * getDate - It will format the date based on locale
   */
  getDate(date: any): string {
    return this.common.getFormattedDateOnLocale(date);
  }

  /**
   * navigatePage - on button click check button action
   * @param event - on button click it will pass event to check button type
   */
  navigatePage(event: any): any {
    this.updateEntitlement();
    const type =
      event.target?.data.action ===
      this.pageLabels?.['linkticketConf.linkMoreCTA']
        ? this.globalDefault.BACK_NAVIGATION
        : this.globalDefault.REGISTER;

    this.goToPage(type);
  }

  /**
   * goToPage - navigation on basis of selected type
   * @param type - type of page to route
   */
  goToPage(type: any): any {
    switch (type) {
      case this.globalDefault.CLOSE:
        this.common.changeRoute(this.appRoutes.DASHBOARD);
        break;

      case this.globalDefault.BACK_NAVIGATION:
        this.common.changeRoute(this.appRoutes.LINK_TICKETS);
        break;

      case this.globalDefault.REGISTER:
        this.common.changeRoute(this.appRoutes.VIEW_ENTITLMENT);
        break;
    }
  }

  /**
   * getEntitlement() - Update the entitlement after linktickets
   */
  updateEntitlement(): void {
    const guestId = this.guestsService.helper.getGuestId();
    this.common?.getEntitlements(guestId)?.subscribe((res: any) => {
      this.common?.setResolvedData(res);
    });
  }
  /**
   * sortTicketsByUpcomingMonthAndDate - sort the tickets based on the upcoming entry dates.
   * @param tickets - pass the tickets response
   */
  sortTicketsByUpcomingMonthAndDate(tickets: any[]): void {
    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear();

    tickets?.sort((a, b) => {
      const dateA = new Date(a.valid_from);
      const dateB = new Date(b.valid_from);

      const monthA = dateA.getMonth();
      const monthB = dateB.getMonth();
      const yearA = dateA.getFullYear();
      const yearB = dateB.getFullYear();

      const isUpcomingMonthA =
        yearA > currentYear || (yearA === currentYear && monthA > currentMonth);
      const isUpcomingMonthB =
        yearB > currentYear || (yearB === currentYear && monthB > currentMonth);

      if (isUpcomingMonthA && !isUpcomingMonthB) {
        return -1;
      } else if (!isUpcomingMonthA && isUpcomingMonthB) {
        return 1;
      } else if (isUpcomingMonthA && isUpcomingMonthB) {
        if (yearA === yearB) {
          return monthB - monthA;
        } else {
          return yearB - yearA;
        }
      } else {
        return dateB.getTime() - dateA.getTime();
      }
    });
  }

  /**
   * onResize() - To set the height of model hostListener
   */
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightGenerator(
      this.registerTicketsModalContent
    );
  }
}
