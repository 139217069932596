<!--Express Pass-->
<div class="container" *ngIf="express">
  <div class="club-universal-btn">
    <gds-utility-action
      [data]="{
              icon: {
                iconName: 'left-arrow-v2'
              },
              label: pageLabels?.['tdExpressPass.backCTA'],
              type: 'left-arrow-v2',
              disabled: false
            }"
      (click)="redirectTo('back')"
    ></gds-utility-action>
  </div>

  <div>
    <gds-picture
      desktop="{{ ticketsImage?.desktop }}"
      tablet="{{ ticketsImage?.tablet }}"
      mobile="{{ ticketsImage?.mobile }}"
      alt="{{ ticketsImage?.alt }}"
    >
    </gds-picture>
  </div>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <gds-category-label
    class="expiredCategory"
    *ngIf="isExpiredTickets"
    [data]="{
      categoryLabel: ticketStatus,
      arialabel: 'Meaningful aria-label'
    }"
  ></gds-category-label>
  <gds-spacer *ngIf="isExpiredTickets" classes="spacer-50"></gds-spacer>
  <!-- Express Pass Ticket-->
  <div class="studio-content">
    <div class="left-icon">
      <gds-svg
        [iconName]="entitlements?.icons"
        height="24"
        width="24"
        view-box="0 0 32 32"
      ></gds-svg>
    </div>
    <gds-spacer classes="vertical-spacer-30"></gds-spacer>
    <div class="right-description">
      <h5 class="labelHeading">{{pageLabels?.['tdExpressPass.type']}}</h5>
    </div>
  </div>
  <gds-spacer classes="spacer-30"></gds-spacer>
  <gds-heading
    class="A3-W6"
    [data]="{
      heading: entitlements?.expressName
    }"
  ></gds-heading>

  <gds-spacer classes="spacer-70"></gds-spacer>
  <gds-info-section
    [data]="{
      categorySection: {
        categoryLabel: pageLabels?.['tdExpressPass.individualName'],
        ariaLabel: 'Meaningful aria-label'
      },
      heading: fullName
    }"
  >
  </gds-info-section>
  <gds-spacer classes="spacer-50"></gds-spacer>
  <hr />
  <gds-spacer classes="spacer-50"></gds-spacer>

  <div class="content">
    <div class="content-left">
      <gds-info-section
        [data]="{
          categorySection: {
            categoryLabel: pageLabels?.['tdExpressPass.entryDate'],
            ariaLabel: 'Meaningful aria-label'
          },
          heading: getDate(entitlements?.valid_from, true)
        }"
      >
      </gds-info-section>
    </div>
  </div>
  <div *ngIf="!entitlements?.change_date_allowed && !isExpiredTickets">
    <gds-body
      [innerHtml]="
      pageLabels?.['tdExpressPass.notEditableDesc']
    "
      class="B2-W3"
    ></gds-body>
  </div>
  <gds-spacer classes="spacer-50"></gds-spacer>
  <hr />

  <gds-spacer classes="spacer-50"></gds-spacer>
  <!--Display Timed/UnTimed Attractions-->
  <app-selection-card
    [pageTitle]="pageLabels"
    [selectionCardData]="attractionsData"
    [isExpired]="isExpiredTickets"
    [baseCode]="baseCode"
    [dataFromParent]="baseCode"
    [pageLabels]="pageLabels"
    #selectionCardComponent
  ></app-selection-card>

  <gds-info-section
    *ngIf="!isExpiredTickets && entitlements?.commerce_order_id"
    [data]="{
      categorySection: {
        categoryLabel: pageLabels?.['tdExpressPass.inquiryNumber'],
        ariaLabel: 'Meaningful aria-label'
      },
      heading: entitlements?.commerce_order_id
    }"
  >
  </gds-info-section>
  <gds-spacer
    *ngIf="!isExpiredTickets && entitlements?.commerce_order_id"
    classes="spacer-50"
  ></gds-spacer>
  <hr *ngIf="!isExpiredTickets && entitlements?.commerce_order_id" />
  <gds-spacer *ngIf="!isExpiredTickets" classes="spacer-50"></gds-spacer>
  <gds-info-section
    [data]="{
      categorySection: {
        categoryLabel: pageLabels?.['tdExpressPass.ticketNumber'],
        ariaLabel: 'Meaningful aria-label'
      },
      heading: entitlements?.ticket_code
    }"
  >
  </gds-info-section>
  <gds-spacer classes="spacer-50"></gds-spacer>
  <ng-container>
    <hr />
    <gds-spacer classes="spacer-50"></gds-spacer>
    <gds-info-section
      [data]="{
      categorySection: {
        categoryLabel: pageLabels?.['tdExpressPass.expirationDate'],
        ariaLabel: 'Meaningful aria-label'
      },
      heading: getDate(entitlements?.valid_to, true)
    }"
    >
    </gds-info-section>
    <gds-spacer classes="spacer-50"></gds-spacer>
  </ng-container>
  <hr *ngIf="!isExpiredTickets" />
  <gds-spacer *ngIf="!isExpiredTickets" classes="spacer-50"></gds-spacer>
  <gds-info-section
    *ngIf="!isExpiredTickets"
    [data]="{
      categorySection: {
        categoryLabel: pageLabels?.['tdExpressPass.issueDate'],
        ariaLabel: 'Meaningful aria-label'
      },
      heading: getDate(entitlements?.encode_date_time, true)
    }"
  >
  </gds-info-section>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <gds-button
    *ngIf="!isExpiredTickets"
    [data]="{
      type: 'tertiary',
      href: '',
      label: pageLabels?.['tdExpressPass.viewTerms'],
      disabled: false,
      target: ''
    }"
    (buttonClicked)="redirectTo('view')"
  >
  </gds-button>
</div>
<!--Extra Pass Ticket-->
<div class="container" *ngIf="extras">
  <div class="club-universal-btn">
    <gds-utility-action
      [data]="{
              icon: {
                iconName: 'left-arrow-v2'
              },
              label: pageLabels?.['tdExtras.backCTA'],
              type: 'left-arrow-v2',
              disabled: false
            }"
      (click)="redirectTo('back')"
    ></gds-utility-action>
  </div>

  <div>
    <gds-picture
      desktop="{{ ticketsImage?.desktop }}"
      tablet="{{ ticketsImage?.tablet }}"
      mobile="{{ ticketsImage?.mobile }}"
      alt="{{ ticketsImage?.alt }}"
    >
    </gds-picture>
  </div>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <gds-category-label
    *ngIf="isExpiredTickets"
    class="expiredCategory"
    [data]="{
      categoryLabel: ticketStatus,
      arialabel: 'Meaningful aria-label'
    }"
  ></gds-category-label>
  <gds-spacer *ngIf="isExpiredTickets" classes="spacer-50"></gds-spacer>
  <div>
    <div class="annual-content">
      <div class="left-icon">
        <gds-svg
          [iconName]="entitlements?.icons"
          view-box="0 0 32 32"
          height="24"
          width="24"
        ></gds-svg>
      </div>
      <gds-spacer classes="vertical-spacer-30"></gds-spacer>
      <div class="right-description">
        <h5 class="labelHeading">{{ pageLabels?.['tdExtras.type'] }}</h5>
      </div>
    </div>
    <gds-spacer classes="spacer-30"></gds-spacer>

    <gds-heading
      class="A3-W6"
      [data]="{
        heading: entitlements?.product_name
      }"
    ></gds-heading>
    <gds-spacer classes="spacer-30"></gds-spacer>

    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-info-section
      [data]="{
        categorySection: {
          categoryLabel: pageLabels?.['tdExtras.individualName'],
          ariaLabel: 'Meaningful aria-label'
        },
        heading: fullName
      }"
    >
    </gds-info-section>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <hr />
    <gds-spacer classes="spacer-50"></gds-spacer>

    <gds-info-section
      [data]="{
        categorySection: {
          categoryLabel: pageLabels?.['tdExtras.entranceDate'],
          ariaLabel: 'Meaningful aria-label'
        },
        heading: getDate(entitlements?.performances?.[0].date_time_from, true) + ' ' + convertToJapanTime( entitlements?.performances?.[0].date_time_from) + ' ' + '~'
      }"
    >
    </gds-info-section>

    <div *ngIf="!entitlements?.change_date_allowed && !isExpiredTickets">
      <gds-body
        [innerHtml]="
        pageLabels?.['linkTicketConf.ticketDet_dateChange']
      "
        class="B2-W3"
      ></gds-body>
    </div>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <hr />
    <gds-spacer
      *ngIf="!isExpiredTickets && entitlements?.commerce_order_id"
      classes="spacer-50"
    ></gds-spacer>

    <gds-info-section
      *ngIf="!isExpiredTickets && entitlements?.commerce_order_id"
      [data]="{
        categorySection: {
          categoryLabel: pageLabels?.['tdExtras.inquiryNumber'],
          ariaLabel: 'Meaningful aria-label'
        },
        heading: entitlements?.commerce_order_id
      }"
    >
    </gds-info-section>
    <gds-spacer
      *ngIf="!isExpiredTickets && entitlements?.commerce_order_id"
      classes="spacer-50"
    ></gds-spacer>
    <hr *ngIf="!isExpiredTickets && entitlements?.commerce_order_id" />
    <gds-spacer classes="spacer-50"></gds-spacer>
    <gds-info-section
      [data]="{
        categorySection: {
          categoryLabel: pageLabels?.['tdExtras.ticketNumber'],
          ariaLabel: 'Meaningful aria-label'
        },
        heading: entitlements?.ticket_code
      }"
    >
    </gds-info-section>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <ng-container *ngIf="!isExpiredTickets">
      <hr />
      <gds-spacer classes="spacer-50"></gds-spacer>
      <gds-info-section
        *ngIf="!isExpiredTickets"
        [data]="{
        categorySection: {
          categoryLabel: pageLabels?.['tdExtras.expirationDate'],
          ariaLabel: 'Meaningful aria-label'
        },
        heading: getDate(entitlements?.valid_to, true)
      }"
      >
      </gds-info-section>
      <gds-spacer *ngIf="!isExpiredTickets" classes="spacer-50"></gds-spacer>
    </ng-container>
    <hr *ngIf="!isExpiredTickets" />
    <gds-spacer *ngIf="!isExpiredTickets" classes="spacer-50"></gds-spacer>
    <gds-info-section
      *ngIf="!isExpiredTickets"
      [data]="{
        categorySection: {
          categoryLabel: pageLabels?.['tdExtras.issueDate'],
          ariaLabel: 'Meaningful aria-label'
        },
        heading: getDate(entitlements?.encode_date_time, true)
      }"
    >
    </gds-info-section>
    <gds-spacer *ngIf="!isExpiredTickets" classes="spacer-70"></gds-spacer>
    <gds-button
      *ngIf="!isExpiredTickets"
      [data]="{
        type: 'tertiary',
        href: '',
        label: pageLabels?.['tdExtras.viewTerms'],
        disabled: false,
        target: ''
      }"
      (buttonClicked)="redirectTo('view')"
    >
    </gds-button>
  </div>
</div>
<!--Annual Pass Ticket Type-->
<div class="container" *ngIf="annual_passes">
  <div class="club-universal-btn">
    <gds-utility-action
      [data]="{
              icon: {
                iconName: 'left-arrow-v2'
              },
              label: pageLabels?.['tdAnnualPass.backCTA'],
              type: 'left-arrow-v2',
              disabled: false
            }"
      (click)="redirectTo('back')"
    ></gds-utility-action>
  </div>

  <div>
    <gds-picture
      desktop="{{ ticketsImage?.desktop }}"
      tablet="{{ ticketsImage?.tablet }}"
      mobile="{{ ticketsImage?.mobile }}"
      alt="{{ ticketsImage?.alt }}"
    >
    </gds-picture>
  </div>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <gds-category-label
    class="expiredCategory"
    *ngIf="isExpiredTickets"
    [data]="{
      categoryLabel: ticketStatus,
      arialabel: 'Meaningful aria-label'
    }"
  ></gds-category-label>
  <gds-spacer *ngIf="isExpiredTickets" classes="spacer-50"></gds-spacer>
  <div>
    <div class="annual-content">
      <div class="left-icon">
        <gds-svg
          [iconName]="entitlements?.icons"
          view-box="0 0 32 32"
          height="24"
          width="24"
        ></gds-svg>
      </div>
      <gds-spacer classes="vertical-spacer-30"></gds-spacer>
      <div class="right-description">
        <h5 class="labelHeading">{{pageLabels?.['tdAnnualPass.type']}}</h5>
      </div>
    </div>
    <gds-spacer classes="spacer-30"></gds-spacer>
    <gds-heading
      class="A3-W6"
      [data]="{
        heading: entitlements?.product_name
      }"
    ></gds-heading>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="content">
      <div class="content-left">
        <gds-info-section
          *ngIf="!isExpiredTickets"
          [data]="{
            categorySection: {
              categoryLabel: pageLabels?.['tdAnnualPass.periodValidity'],
              ariaLabel: 'Meaningful aria-label'
            },
            heading:
            getDate(entitlements?.valid_from, true) +
              ' ~ ' +
              getDate(entitlements?.valid_to, true)
          }"
          classes="B1-W4"
        >
        </gds-info-section>
        <gds-heading
          [data]="{
          heading:
          pageLabels?.['tdAnnualPass.renewalDesc']
        }"
          class="B2-W3"
        >
        </gds-heading>
      </div>
      <gds-spacer classes="vertical-spacer-50"></gds-spacer>
      <div class="content-right" *ngIf="entitlements?.renewable">
        <gds-button
          [data]="{
            type: 'tertiary',
            href: '',
            label: pageLabels?.['tdAnnualPass.renewCTA'],
            disabled: false,
            target: ''
          }"
          (buttonClicked)="onClickRenewApTicket()"
        >
        </gds-button>
      </div>
    </div>

    <gds-spacer classes="spacer-50"></gds-spacer>
    <div *ngIf="entitlements.ap_plus_eligible && !entitlements.ap_plus">
      <gds-button
        [data]="{
        type: 'primary',
        href: '',
        label: pageLabels?.['tdAnnualPass.renewCTA1'],
        disabled: false,
        target: ''
      }"
        (buttonClicked)="redirectTo('completeAP', entitlements)"
      >
      </gds-button>
    </div>
    <div *ngIf="entitlements.ap_plus_eligible && entitlements.ap_plus">
      <gds-button
        [data]="{
        type: 'primary',
        href: '',
        label: pageLabels?.['tdAnnualPass.renewCTA2'],
        disabled: false,
        target: ''
      }"
        (buttonClicked)="redirectTo('completeAP', entitlements)"
      >
      </gds-button>
    </div>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <hr />
    <gds-spacer classes="spacer-50"></gds-spacer>
    <gds-info-section
      [data]="{
        categorySection: {
          categoryLabel: pageLabels?.['tdAnnualPass.individulaName'],
          ariaLabel: 'Meaningful aria-label'
        },
        heading: fullName
      }"
    >
    </gds-info-section>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <hr />
    <gds-spacer classes="spacer-50"></gds-spacer>
    <gds-info-section
      [data]="{
      categorySection: {
        categoryLabel: pageLabels?.['tdStudioPass.ticketNumber'],
        ariaLabel: 'Meaningful aria-label'
      },
      heading: entitlements?.ticket_code
    }"
    >
    </gds-info-section>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <hr />
    <gds-spacer classes="spacer-50"></gds-spacer>
    <gds-info-section
      [data]="{
        categorySection: {
          categoryLabel: pageLabels?.['tdAnnualPass.issueDate'],
          ariaLabel: 'Meaningful aria-label'
        },
        heading: getDate(entitlements?.encode_date_time, true)
      }"
    >
    </gds-info-section>
    <gds-spacer classes="spacer-50"></gds-spacer>

    <hr *ngIf="!isExpiredTickets && entitlements?.commerce_order_id" />
    <gds-spacer
      *ngIf="!isExpiredTickets && entitlements?.commerce_order_id"
      classes="spacer-50"
    ></gds-spacer>

    <gds-info-section
      *ngIf="!isExpiredTickets && entitlements?.commerce_order_id"
      [data]="{
        categorySection: {
          categoryLabel: pageLabels?.['tdAnnualPass.inquiryNumber'],
          ariaLabel: 'Meaningful aria-label'
        },
        heading: entitlements?.commerce_order_id
      }"
    >
    </gds-info-section>

    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-button
      [data]="{
        type: 'tertiary',
        href: '',
        label: pageLabels?.['tdAnnualPass.viewTerms'],
        disabled: false,
        target: ''
      }"
      (buttonClicked)="redirectTo('view')"
    >
    </gds-button>
  </div>
</div>
<!--Studio Pass Ticket Type-->
<div class="container" *ngIf="studio_pass">
  <div class="club-universal-btn">
    <gds-utility-action
      [data]="{
              icon: {
                iconName: 'left-arrow-v2'
              },
              label: pageLabels?.['tdStudioPass.backCTA'],
              type: 'left-arrow-v2',
              disabled: false
            }"
      (click)="redirectTo('back')"
    ></gds-utility-action>
  </div>

  <div>
    <gds-picture
      desktop="{{ ticketsImage?.desktop }}"
      tablet="{{ ticketsImage?.tablet }}"
      mobile="{{ ticketsImage?.mobile }}"
      alt="{{ ticketsImage?.alt }}"
    >
    </gds-picture>
  </div>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <gds-category-label
    *ngIf="isExpiredTickets"
    class="expiredCategory"
    [data]="{
      categoryLabel: ticketStatus,
      arialabel: 'Meaningful aria-label'
    }"
  ></gds-category-label>
  <gds-spacer *ngIf="isExpiredTickets" classes="spacer-50"></gds-spacer>
  <div class="studio-content">
    <div class="left-icon">
      <gds-svg
        [iconName]="entitlements?.icons"
        view-box="0 0 32 32"
        height="24"
        width="24"
      ></gds-svg>
    </div>
    <gds-spacer classes="vertical-spacer-30"></gds-spacer>
    <div class="right-description">
      <h5 class="labelHeading">{{pageLabels?.['tdStudioPass.type']}}</h5>
    </div>
  </div>
  <gds-spacer classes="spacer-30"></gds-spacer>
  <gds-heading
    class="A3-W6"
    classes="heading-6"
    [data]="{
      heading: entitlements?.product_name
    }"
  ></gds-heading>

  <gds-spacer classes="spacer-70"></gds-spacer>

  <gds-info-section
    [data]="{
    categorySection: {
      categoryLabel: pageLabels?.['tdStudioPass.individualName'],
      ariaLabel: 'Meaningful aria-label'
    },
    heading: fullName
  }"
  >
  </gds-info-section>
  <gds-spacer classes="spacer-50"></gds-spacer>
  <hr />
  <gds-spacer classes="spacer-50"></gds-spacer>
  <div class="content">
    <!-- <div class="content-left" *ngIf="entitlements?.days === 1">
      <gds-info-section
        [data]="{
          categorySection: {
            categoryLabel: pageLabels?.['tdStudioPass.entryDate'],
            ariaLabel: 'Meaningful aria-label'
          },
          heading: getDate(entitlements?.valid_from, true)
        }"
      >
      </gds-info-section>
    </div> -->
    <div class="content-left">
      <gds-info-section
        [data]="{
          categorySection: {
            categoryLabel: generateHeading().categoryLabel,
            ariaLabel: 'Meaningful aria-label'
          },
          heading: generateHeading().heading
        }"
        classes="B1-W4"
      >
      </gds-info-section>
    </div>
    <gds-spacer classes="vertical-spacer-50"></gds-spacer>
    <div class="content-right" *ngIf="entitlements?.change_date_allowed">
      <gds-button
        *ngIf="
          !isExpiredTickets || (isExpiredTickets && this.isDateChange_allowed)
        "
        [data]="{
          type: 'tertiary',
          href: '',
          label: pageLabels?.['tdStudioPass.editCTA'],
          disabled: false,
          target: ''
        }"
        (buttonClicked)="dateChangeEditBtnClicked()"
      >
      </gds-button>
    </div>
  </div>

  <div *ngIf="!entitlements?.change_date_allowed && !isExpiredTickets">
    <gds-body
      [innerHtml]="
      pageLabels?.['linkTicketConf.ticketDet_dateChange']
    "
      class="B2-W3"
    ></gds-body>
  </div>

  <gds-spacer classes="spacer-50"></gds-spacer>
  <hr />
  <gds-spacer
    *ngIf="!isExpiredTickets && entitlements?.commerce_order_id"
    classes="spacer-50"
  ></gds-spacer>
  <gds-info-section
    *ngIf="!isExpiredTickets && entitlements?.commerce_order_id"
    [data]="{
      categorySection: {
        categoryLabel: pageLabels?.['tdStudioPass.inquiryNumber'],
        ariaLabel: 'Meaningful aria-label'
      },
      heading: entitlements?.commerce_order_id
    }"
  >
  </gds-info-section>
  <gds-spacer
    *ngIf="!isExpiredTickets && entitlements?.commerce_order_id"
    classes="spacer-50"
  ></gds-spacer>
  <hr *ngIf="!isExpiredTickets && entitlements?.commerce_order_id" />
  <gds-spacer classes="spacer-50"></gds-spacer>
  <gds-info-section
    [data]="{
      categorySection: {
        categoryLabel: pageLabels?.['tdStudioPass.ticketNumber'],
        ariaLabel: 'Meaningful aria-label'
      },
      heading: entitlements?.ticket_code
    }"
  >
  </gds-info-section>
  <gds-spacer classes="spacer-50"></gds-spacer>
  <ng-container *ngIf="!isExpiredTickets">
    <hr />
    <gds-spacer classes="spacer-50"></gds-spacer>
    <gds-info-section
      *ngIf="!isExpiredTickets"
      [data]="{
      categorySection: {
        categoryLabel: pageLabels?.['tdStudioPass.expirationDate'],
        ariaLabel: 'Meaningful aria-label'
      },
      heading: getDate(entitlements?.valid_to, true)
    }"
    >
    </gds-info-section>
    <gds-spacer classes="spacer-50"></gds-spacer>
  </ng-container>
  <hr *ngIf="!isExpiredTickets" />
  <gds-spacer classes="spacer-50"></gds-spacer>
  <gds-info-section
    *ngIf="!isExpiredTickets"
    [data]="{
      categorySection: {
        categoryLabel: pageLabels?.['tdStudioPass.issueDate'],
        ariaLabel: 'Meaningful aria-label'
      },
      heading: getDate(entitlements?.encode_date_time, true)
    }"
  >
  </gds-info-section>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <gds-button
    *ngIf="!isExpiredTickets"
    [data]="{
      type: 'tertiary',
      href: '',
      label: pageLabels?.['tdStudioPass.viewTerms'],
      disabled: false,
      target: ''
    }"
    (buttonClicked)="redirectTo('view')"
  >
  </gds-button>
</div>
