<!-- On Modal close to navigate to Profile page-->

<gds-full-screen-modal opened (modalClose)="goToProfile()">
  <div slot="components">
    <div
      class="container modal-content"
      #updatePwdModalContent
      [formGroup]="updatePasswordForm"
    >
      <!-- Update Password Heading-->
      <gds-heading
        [data]="{
          heading: pageLabels?.['editPwd.heading']
        }"
        class="A2-W5 adventure"
      >
      </gds-heading>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!-- Password Info -->
      <gds-heading
        [data]="{
          heading:
          pageLabels?.['editPwd.info']
        }"
        class="B1-W3 adventure"
      >
      </gds-heading>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!-- Existing Password input field-->
      <gds-input
        id="passWord"
        formControlName="passWord"
        [errors]="passWordErrorMsgInfo"
        ngDefaultControl
        [data]="{
          primaryLabel:  pageLabels?.['editPwd.Password'],
          supportingText: '',
          tooltip: '',
          type: 'password',
          altText: 'input of surname',
          ariaText: '',
          required: true
        }"
        (blurChange)="getErrorMessageInfo($event)"
        onkeydown="return !/\s/.test(event.key)"
      >
      </gds-input>
      <!-- Forgot password link -->
      <gds-button
        [data]="{
          type: 'tertiary',
          href: '',
          label: pageLabels?.['editPwd.forgotPwd'],
          disabled: false,
          target: ''
        }"
        onkeydown="return (event.key === 'Tab') ? true : false"
        (buttonClicked)="redirectToForget()"
      >
      </gds-button>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!-- New Password Input Filed-->
      <gds-input
        id="newPassWord"
        formControlName="newPassWord"
        [errors]="newPassWordErrorMsgInfo"
        ngDefaultControl
        [data]="{
          primaryLabel:  pageLabels?.['editPwd.newPwd'],
          supportingText: '',
          tooltip: '',
          type: 'password',
          altText: 'input of re-enter password',
          ariaText: '',
          required: true
        }"
        tabindex="1"
        (blurChange)="getErrorMessageInfo($event)"
        (input)="checkEnterVal()"
      >
      </gds-input>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!-- To check the strength of password selector called-->
      <app-password-strength
        [passwordToCheck]="updatePasswordForm.value.newPassWord"
        (passwordStrength)="onPasswordStrengthChanged($event)"
      ></app-password-strength>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!-- Re enter Password field -->
      <gds-input
        id="rePassWord"
        formControlName="rePassWord"
        [errors]="rePassWordErrorMsgInfo"
        ngDefaultControl
        [data]="{
          primaryLabel: pageLabels?.['editPwd.reEnterPwd'],
          supportingText: '',
          tooltip: '',
          type: 'password',
          altText: 'input of re-enter password',
          ariaText: '',
          required: true
        }"
        (input)="checkEnterVal()"
        (blurChange)="getErrorMessageInfo($event)"
      >
      </gds-input>
      <gds-spacer classes="spacer-85"></gds-spacer>
    </div>
    <!-- Footer btn update -->
    <gds-sticky-nav-footer
      [data]="{
        button: {
          type: 'primary',
          href: '',
          label: pageLabels?.['editPwd.update'],
          disabled:
            updatePasswordForm.valid && updatePasswordForm.value.newPassWord === updatePasswordForm.value.rePassWord  && strongPassword ? 'false' : 'true',
          target: ''
        }
      }"
      (buttonClicked)="onSubmit()"
    >
    </gds-sticky-nav-footer>
  </div>
</gds-full-screen-modal>
