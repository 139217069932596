<div class="container">
  <!-- Dashboard back button-->
  <div class="club-universal-btn">
    <gds-utility-action
      [data]="{
            icon: {
            iconName: 'left-arrow-v2'
            },
            label: pageLabels?.['tdExpressPass.backCTA'],
            type: 'left-arrow-v2',
            disabled: false
            }"
      (click)="redirectTo()"
    ></gds-utility-action>
  </div>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <gds-heading
    [data]="{
      heading: pageLabels?.['viewEntPopulated.pastTickets']
    }"
    class="A3-W6"
  >
  </gds-heading>
  <gds-spacer classes="spacer-50"></gds-spacer>

  <!--Entitlement description -->
  <gds-heading
    class="A3-W6"
    classes="heading-7"
    [data]="{
        heading: pageLabels?.['pastTickets.subText']
      }"
  ></gds-heading>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <ng-container *ngFor="let ticketName of expiredTicketsDetails">
    <gds-heading
      [data]="{
        heading: ticketName.full_name
      }"
      class="A4-W5"
    >
    </gds-heading>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <ng-container *ngFor="let detail of ticketName.tickets">
      <div class="box box-info">
        <div class="box-body">
          <div
            class="col-md-3 contentTab"
            tabindex="0"
            (keydown.enter)="getTickets(detail)"
          >
            <a [href]="orderProfileUrl(detail.ticket_id)" class="ticketctalink">
              <div class="align-left-past">
                <div class="image-section">
                  <gds-poi-feature
                    [data]="{
                    icon: detail?.icons,
                    heading: detail.product_name | titlecase,
                    description:pageLabels?.['viewEntPopulated.entryDate']+ getDate(detail.valid_from,true)+' '+getDateChange(detail)
                  }"
                  >
                  </gds-poi-feature>
                </div>
                <i
                  _ngcontent-wgy-c178=""
                  aria-hidden=""
                  class="icon icon-arrow-right"
                ></i>
              </div>
            </a>
          </div>
        </div>
      </div>
      <gds-spacer classes="spacer-20"></gds-spacer>
      <gds-right-hand-rail-divider></gds-right-hand-rail-divider>
      <gds-spacer classes="spacer-20"></gds-spacer>
    </ng-container>
    <gds-spacer classes="spacer-70"></gds-spacer>
  </ng-container>
</div>
