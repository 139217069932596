<!-- Display Ticket Details-->
<div class="ticket-content">
  <h6 class="heading A2-W5">
    {{pageLabels?.['linkticketReg.ticketDetails']}}
  </h6>
  <gds-spacer class="spacer-80"></gds-spacer>
  <div class="content">
    <div>
      <gds-svg
        icon-name="tickets2"
        height="20"
        width="20"
        view-box="0 0 35 30"
      ></gds-svg>
    </div>
    <div>
      <div>
        <h6 class="ticket-content-heading A2-W5">1 Day Studio Pass</h6>
        <h6 class="A2-W5 ticket-sub-heading">
          {{pageLabels?.['linkTicketReg.ticketDet_entry']}} : 20120809
        </h6>
      </div>
      <gds-spacer class="spacer-30"></gds-spacer>
      <div class="description">
        <h6 class="A2-W5 date_css">
          {{pageLabels?.['linkTicketReg.ticketDet_expiry']}}
        </h6>
        <h6 class="A2-W5">Ticket Number</h6>
      </div>
      <gds-spacer class="spacer-30"></gds-spacer>
      <div class="sub-description">
        <h6 class="A2-W5">
          {{pageLabels?.['linkTicketReg.ticketDet_dateChange']}}
        </h6>
      </div>
    </div>
  </div>

  <gds-spacer class="spacer-80"></gds-spacer>

  <div class="content">
    <div>
      <gds-svg
        icon-name="tickets2"
        height="20"
        width="20"
        view-box="0 0 35 30"
      ></gds-svg>
    </div>
    <div>
      <div>
        <h6 class="ticket-content-heading A2-W5">1 Day Studio Pass</h6>
        <h6 class="A2-W5 ticket-sub-heading">
          {{pageLabels?.['linkTicketReg.ticketDet_entry']}} : 20120809
        </h6>
      </div>
      <gds-spacer class="spacer-30"></gds-spacer>
      <div class="description">
        <h6 class="A2-W5 date_css">
          {{pageLabels?.['linkTicketReg.ticketDet_expiry']}}
        </h6>
        <h6 class="A2-W5">Ticket Number</h6>
      </div>
      <gds-spacer class="spacer-30"></gds-spacer>
      <div class="sub-description">
        <h6 class="A2-W5">
          {{pageLabels?.['linkTicketReg.ticketDet_dateChange']}}
        </h6>
      </div>
    </div>
  </div>

  <gds-spacer class="spacer-80"></gds-spacer>
  <div class="content">
    <div>
      <gds-svg
        icon-name="tickets2"
        height="20"
        width="20"
        view-box="0 0 35 30"
      ></gds-svg>
    </div>
    <div>
      <div>
        <h6 class="ticket-content-heading A2-W5">1 Day Studio Pass</h6>
        <h6 class="A2-W5 ticket-sub-heading">
          {{pageLabels?.['linkTicketReg.ticketDet_entry']}} : 20120809
        </h6>
      </div>
      <gds-spacer class="spacer-30"></gds-spacer>
      <div class="description">
        <h6 class="A2-W5 date_css">
          {{pageLabels?.['linkTicketReg.ticketDet_expiry']}}
        </h6>
        <h6 class="A2-W5">Ticket Number</h6>
      </div>
      <gds-spacer class="spacer-30"></gds-spacer>
      <div class="sub-description">
        <h6 class="A2-W5">
          {{pageLabels?.['linkTicketReg.ticketDet_dateChange']}}
        </h6>
      </div>
    </div>
  </div>
</div>
