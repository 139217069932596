import { HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DalService } from '@upr-web-primer/dal';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { LocalStorageService } from '@upr-web-primer/storage';
import { WINDOW } from '@upr-web-primer/window';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';
@Component({
  selector: 'app-confirm-travel-party-members',
  templateUrl: './confirm-travel-party-members.component.html',
  styleUrls: ['./confirm-travel-party-members.component.scss'],
})
export class ConfirmTravelPartyMembersComponent
  implements OnInit, AfterViewInit
{
  @Input() addTravelPartyForm: any;
  @Input() travelMembersCount: any;
  @Input() type: any;
  @Output() backToAddTravelParty = new EventEmitter<any>();
  @Output() addedTravelPartyMembers = new EventEmitter<any>();

  travelPartyInformation: any;
  @ViewChild('confirmPartyModalContent') confirmPartyModalContent!: ElementRef;
  dateVal: string;
  /**
   * Page labels
   * @type any
   */
  pageLabels: any;
  relationship: any;
  travelPartyType: any;
  savedSourceId: any;
  isSubmitting = false;
  genderArray: { label: any; value: any }[];
  mappingArray: any;
  selectedGenderValue: any;
  relationshipArray: { label: any; value: any }[];
  selectedRelationshipValue: any;
  selectedIndex: number;
  constructor(
    private commonUtilityService: CommonUtilityService,
    private guestsService: MsGuestsService,
    private ref: ChangeDetectorRef,
    private dal: DalService,
    protected injector: Injector,
    private notification: NotificationService,
    @Inject('AppRoutes') public appRoutes: any,
    private appDataService: AppDataService,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('RelativeUrls') private relativeUrls: any,
    @Inject('AppDefaults') public appDefault: any,
    @Inject('sourceKeys') public sourceKeys: any,
    @Inject('Ranges') public ranges: any,
    @Inject('Relationship') public globalRelationship: any,
    @Inject('Gender') public gender: any,
    private localStorage: LocalStorageService,
    @Inject(WINDOW) private window: any,
    private cookieService: CookieService
  ) {
    this.setDependencies();
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
  }

  /**
   * ngOnInit - initial methods are called
   */

  ngOnInit(): void {
    this.genderArray = [
      {
        label: this.pageLabels?.['trpAdd.genderFemale'],
        value: this.pageLabels?.['trpAdd.genderFemale'],
      },
      {
        label: this.pageLabels?.['trpAdd.genderMale'],
        value: this.pageLabels?.['trpAdd.genderMale'],
      },

      {
        label: this.pageLabels?.['trpAdd.genderOther'],
        value: this.pageLabels?.['trpAdd.genderOther'],
      },
      {
        label: this.pageLabels?.['trpAdd.gendernotResponded'],
        value: this.pageLabels?.['trpAdd.gendernotResponded'],
      },
    ];
    this.relationshipArray = [
      {
        label: this.pageLabels?.['trpAdd.spouse'],
        value: this.pageLabels?.['trpAdd.spouse'],
      },
      {
        label: this.pageLabels?.['trpAdd.child'],
        value: this.pageLabels?.['trpAdd.child'],
      },
      {
        label: this.pageLabels?.['trpAdd.other'],
        value: this.pageLabels?.['trpAdd.other'],
      },
    ];
    this.travelPartyType = this.type;
    this.travelPartyInformation = this.addTravelPartyForm.value;
    this.dateVal = this.travelPartyInformation.dob;
  }
  /**
   * ngAfterViewInit - initial methods are called
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.appDataService.modalContentHeightCalc(
        this.confirmPartyModalContent,
        164
      );
    }, 500);
  }

  /**
   * onResize - To resize full screen modal popup
   */
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(
      this.confirmPartyModalContent,
      164
    );
  }

  /**
   * goBackToAddTravel - redirect to add travel party page
   */

  goBackToAddTravel(): any {
    this.backToAddTravelParty.emit({ type: 'info', showConfirmPopup: false });
    this.ref.detectChanges();
  }

  /**
   * registerTravelParty - Register the travel party data
   */

  registerTravelParty(): void {
    const data = this.addTravelPartyForm.value;
    const guestId = this.guestsService.helper.getGuestId();
    const inputData = this.commonUtilityService.getCommonData();

    if (
      this.travelPartyType === this.globalDefault.ADD ||
      this.travelPartyType == this.globalDefault.TICKETS.toLowerCase()
    ) {
      const request = this?.generateRequestForAdd(data);
      this.registerAddedMembers(request, guestId);
    } else {
      const request = this.generateRequestForEdit(data, inputData);
      this.registerEditedMembers(request, data, guestId, inputData);
    }
  }

  /**
   * generateRequestForAdd - generateRequest body for add travel party members
   */

  generateRequestForAdd(data: any): any {
    this.selectedGenderValue = this.mappingJapaneseToEnglish(
      data,
      this.globalDefault.GENDER
    );
    this.selectedRelationshipValue = this?.mappingJapaneseToEnglish(
      data,
      this.globalDefault.RELATIONSHIP
    );
    this.isSubmitting = true;
    const formattedDob: any = this.commonUtilityService.formatDate(data.dob);
    const request = {
      contact: {
        first: data.firstName,
        last: data.lastName,
      },
      is_active: true,
      date_of_birth: formattedDob,
      relationship: this.selectedRelationshipValue,
      gender: this.selectedGenderValue,
    };
    if (
      data.gender == '' ||
      data.gender == this.pageLabels?.['trpAdd.gendernotResponded'] ||
      data?.gender == undefined
    ) {
      delete request.gender;
    }
    if (data?.relationship == '') {
      delete request.relationship;
    }
    if (data?.dob == '' || data?.dob == undefined) {
      delete request.date_of_birth;
    }
    return request;
  }
  /**
   * mappingJapaneseToEnglish - map the gender and relationship to japanese to english
   * @param data - specify the travel party member data
   * @returns
   */

  mappingJapaneseToEnglish(data: any, type: any): any {
    const result =
      type == this.globalDefault.GENDER
        ? data?.gender?.toLowerCase()
        : data?.relationship?.toUpperCase();
    const selectedItem =
      type == this.globalDefault.GENDER
        ? this.genderArray?.find((item: any) => item.value === result)
        : this.relationshipArray?.find((item: any) => item.value === result);
    if (selectedItem) {
      this.selectedIndex =
        type == this.globalDefault.GENDER
          ? this.genderArray.indexOf(selectedItem)
          : this.relationshipArray.indexOf(selectedItem);
    }
    this.mappingArray =
      type == this.globalDefault.GENDER ? this.gender : this.globalRelationship;
    const selectedValue = this.mappingArray[this.selectedIndex];
    return selectedValue;
  }

  /**
   * generateRequestForEdit - generateRequest body for edit travel party members
   */

  generateRequestForEdit(data: any, inputData: any): any {
    this.selectedGenderValue = this.mappingJapaneseToEnglish(
      data,
      this.globalDefault.GENDER
    );
    this.selectedRelationshipValue = this?.mappingJapaneseToEnglish(
      data,
      this.globalDefault.RELATIONSHIP
    );
    this.isSubmitting = true;
    this.commonUtilityService.validateAllFormFields(this.addTravelPartyForm);
    const formattedDob: any = this.commonUtilityService.formatDate(data.dob);
    const request = {
      first_name: data?.firstName,
      last_name: data?.lastName,
      is_active: true,
      date_of_birth: formattedDob,
      relationship: this.selectedRelationshipValue,
      gender: this.selectedGenderValue,
    };
    if (
      inputData?.genderShow ||
      request.gender == '' ||
      request.gender == undefined ||
      data.gender == this.pageLabels?.['trpAdd.gendernotResponded']
    ) {
      delete request.gender;
    }
    if (
      inputData?.relationShow ||
      request.relationship == '' ||
      request.relationship == undefined
    ) {
      delete request.relationship;
    }
    if (
      inputData?.dateShow ||
      request.date_of_birth == '' ||
      request.date_of_birth == undefined
    ) {
      delete request.date_of_birth;
    }
    return request;
  }

  /**
   * registerEditedMembers - update/edit the travel party members
   */

  registerEditedMembers(
    request: any,
    data: any,
    guestId: any,
    inputData: any
  ): any {
    const memberID = data.memberId;
    const url = this.getRelativeUrls(
      this.relativeUrls.UPDATE_TRAVELPARTY,
      guestId,
      memberID
    );
    this.savedSourceId = this.commonUtilityService?.getSourceId(
      this.sourceKeys?.WEB_CREATEEDITTRP
    );
    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append('x-source-id', this.savedSourceId);
    httpHeaders = httpHeaders.append('If-Match', inputData?.revisionID);
    this.dal
      .httpRequest(url, this.appDefault.PATCH, request, httpHeaders)
      .then((data: any) => {
        data;
        this.ddlEvents();
        this.localStorage.setItem('showSnackBar', true);
        this.backToAddTravelParty.emit({
          type: this.globalDefault.SUCCESS,
          showConfirmPopup: false,
          page: this.type,
        });
      })
      .catch((error) => {
        this.ddlEvents();
        this.handleTRPError(error.status);
      });
  }

  /**
   * registerAddedMembers - Register/Add the travel party members
   */

  registerAddedMembers(request: any, guestId: any): any {
    this.commonUtilityService
      .postTravelPartyMembers(guestId, request)
      .subscribe(
        (res: any) => {
          this.ddlEvents();
          if (res.status === this.ranges.SUCCESS_201)
            this.localStorage.setItem('showSnackBar', true);
          this.backToAddTravelParty.emit({
            type: this.globalDefault.SUCCESS,
            showConfirmPopup: false,
            page: this.type,
          });
        },
        (err: any) => {
          this.ddlEvents();
          this.handleTRPError(err.status);
        }
      );
  }

  /**
   * ddlEvents - On travel party save and update these events are getting set
   * @param type - On api response success or failed
   */
  ddlEvents(): void {
    this.commonUtilityService.invokeFireEvent(
      this.pageLabels?.['trpAddConf.register'],
      'Button',
      'Click',
      '',
      new Date().toISOString(),
      'Body | CTA:' + this.pageLabels?.['trpAddConf.register'],
      '',
      this.window?.digitalData?.page?.category?.primaryCategory ?? '',
      this.window?.digitalData?.page?.category?.subCategory1 ?? ''
    );
    // this.commonUtilityService?.setUser();
  }

  /**
   * handleTRPError - To handle 422 status code from api
   * @param err - status code
   */

  handleTRPError(err: any): any {
    if (err === this.globalDefault.ERROR_422) {
      switch (this.selectedRelationshipValue) {
        case this.globalRelationship[0]:
          this.notification.show(
            this.pageLabels?.['errCode.MS.trp_Spouse.422']
          );
          break;
        case this.globalRelationship[1]:
          this.notification.show(this.pageLabels?.['errCode.MS.trp_Child.422']);
          break;
        case this.globalRelationship[2]:
          this.notification.show(
            this.pageLabels?.['errCode.MS.trp_Others.422']
          );
          break;
      }
    } else if (err === this.globalDefault.ERROR_401) {
      this.commonUtilityService?.setUser();
      localStorage.clear();
      sessionStorage.clear();
      this.cookieService.deleteAll('/', environment.cookieSubDomain);
      const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
      this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
    } else this.notification.show(this.pageLabels?.['errorCode.E019']);
  }

  /**
   * getRelativeUrls - form the urls
   * @param url : specify the url
   * @param guestId : specify the guest id
   * @param memberID : specify the member id
   * @returns
   */

  public getRelativeUrls(
    url: string,
    guestId: string,
    memberID: string
  ): string {
    return url.replace('{guest_id}', guestId).replace('{member_id}', memberID);
  }

  /**
   * goToLink - redirect to specific pages
   * @param type : specify the type of redirect page
   */

  goToLink(type: any): void {
    switch (type) {
      case this.globalDefault.ADD:
        this.commonUtilityService.changeRoute(
          this.appRoutes.ADD_TRAVEL_PARTY_MEMBERS
        );
        break;
      case this.globalDefault.EDIT:
        this.commonUtilityService.changeRoute(
          this.appRoutes.EDIT_TRAVEL_PARTY_MEMBERS
        );
        break;
      case this.globalDefault.C_TICKETS:
        this.commonUtilityService.changeRoute(this.appRoutes.CONFIRM_TICKETS, {
          guest_name: this.globalDefault.NEW_TPM,
        });
        break;
    }
  }

  /**
   * setDependencies - It will set the injector of dependencies services
   */
  private setDependencies(): void {
    this.commonUtilityService = this.injector.get(CommonUtilityService);
    this.guestsService = this.injector.get(MsGuestsService);
    this.notification = this.injector.get(NotificationService);
  }
}
