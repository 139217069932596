import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MsGuestsService, PhoneType } from '@upr-web-primer/ms-guests';
import { get } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { PhonePattern } from '../../../config/pattern.constant';
import { environment } from '../../../environments/environment';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';
import mockData from '../../shared/constants/mockData.json';
import { ToastService } from '../../shared/service/toast.service';

@Component({
  selector: 'app-add-phone-number',
  templateUrl: './add-phone-number.component.html',
  styleUrls: ['./add-phone-number.component.scss'],
})
export class AddPhoneNumberComponent implements OnInit, AfterViewInit {
  pageLabels: any;
  addPhoneNumberForm: FormGroup;
  inputChanged = false;
  phoneNoErrorMsgInfo: any;
  phoneNumberRegex = PhonePattern.PHONE_SIGNUP;
  savedSourceId: string;
  mockData = mockData;
  @ViewChild('addPhoneContentModal') addPhoneContentModal!: ElementRef;
  constructor(
    private commonUtilityService: CommonUtilityService,
    @Inject('AppRoutes') private appRoutes: any,
    private formBuilder: FormBuilder,
    @Inject('Ranges') public ranges: any,
    private cdRef: ChangeDetectorRef,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('sourceKeys') public sourceKeys: any,
    private guestsService: MsGuestsService,
    private toastService: ToastService,
    private cookieService: CookieService,
    private appDataService: AppDataService
  ) {
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
  }
  /**
   * ngAfterViewInit - initial methods are called
   */
  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(this.addPhoneContentModal, 164);
  }
  /**
   * onResize - Resize the fullscreen modal popup
   */
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(this.addPhoneContentModal, 164);
  }

  ngOnInit(): void {
    this.createForm();
    this.enableButton();
  }
  /**
   * createForm - it will create the form with input fields
   */

  createForm(): any {
    this.addPhoneNumberForm = this.formBuilder.group({
      phoneNumber: new FormControl('', [
        <any>Validators.required,
        Validators.minLength(10),
        Validators.maxLength(11),
        Validators.pattern(this.phoneNumberRegex),
      ]),
      primary: new FormControl(),
    });
  }

  /**
   * enableButton - It will enable the save button if valid phone number
   */

  enableButton(): any {
    this.addPhoneNumberForm.valueChanges.subscribe((currentValue) => {
      this.inputChanged =
        currentValue?.phoneNumber === '' ||
        (currentValue?.phoneNumber?.length || 0) < this.ranges.MAX.USA_PHONE ||
        (currentValue?.phoneNumber?.length || 0) > 11
          ? false
          : true;
    });
  }
  /**
   * getErrorMessageInfo - Validations of each field
   * @param event - event
   */
  getErrorMessageInfo(event: any): void {
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.PHONENUMBER:
        this.phoneNoErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
    }
    this.cdRef.detectChanges();
  }
  /**
   * getErrorMessage - Validations for each field
   * @param field
   * @returns
   */
  getErrorMessage(field: any): string {
    const fieldName = this.addPhoneNumberForm?.get(field);
    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      return this.getErrorInfo(field);
    }
    this.cdRef.detectChanges();
    return '';
  }
  /**
   * getErrorInfo()
   * @param field Input field
   * @returns Error message
   */
  getErrorInfo(field: any): any {
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    switch (field) {
      case this.globalDefault.PHONENUMBER: {
        if (!this.addPhoneNumberForm?.value?.phoneNumber) return emptyCheck;
        else {
          this.phoneNoErrorMsgInfo = this.pageLabels?.['errCode.E007'];
          return this.phoneNoErrorMsgInfo;
        }
      }
    }
  }
  /**
   * onKeyPressEvent - on key press event occur to check the input type
   * @param event - contains the characters entered in input field
   */
  onKeyPressEvent(event: KeyboardEvent): void {
    const isNumeric = /[0-9]/.test(event.key);
    const isControlKey = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
    ].includes(event.key);

    // Allow numeric input or control keys, prevent other characters
    if (!isNumeric && !isControlKey) {
      event.preventDefault();
    }
  }
  /**
   * backToPhoneNumberPage - redirected to phone number page
   */

  backToPhoneNumberPage(): void {
    this.commonUtilityService.changeRoute(this.appRoutes.PHONE_NUMBERS_PAGE);
  }
  /**
   * onSubmit - submit the phone number
   */

  onSubmit(): any {
    const phone_number = get(this.addPhoneNumberForm, 'value.phoneNumber');
    const request = {
      phone_number: phone_number,
      phone_type: PhoneType.cell,
      primary: true,
    };
    this.savedSourceId = this.commonUtilityService?.getSourceId(
      this.sourceKeys?.WEB_EDITCONTACT
    );
    this.guestsService.personal
      ?.createPhone(request, this.savedSourceId)
      ?.then(() => {
        this.toastService.show({
          text: this.pageLabels?.['addPhone.successSnack'],
          type: this.globalDefault.SUCCESS,
        });
        this.commonUtilityService.changeRoute(
          this.appRoutes.PHONE_NUMBERS_PAGE
        );
      })
      .catch((error) => {
        if (error.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        } else {
          this.commonUtilityService.notifyFormErrorEvent(
            this.pageLabels?.['addPhone.successSnack'],
            this.pageLabels?.['errCode.E004']
          );
          this.toastService.show({
            text: this.pageLabels?.['errCode.E007'],
            type: this.pageLabels?.['trpAdd.warning'],
          });
        }
      });
  }

  onKeydown(event: KeyboardEvent): any {
    if (event.code === 'Space') {
      event.preventDefault();
    }
  }
}
