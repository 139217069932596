<div class="container">
  <gds-spacer classes="spacer-70"></gds-spacer>
  <!-- gds picture -->
  <gds-picture
    desktop="{{ baseUrl }}"
    mobile="{{ baseUrl }}"
    tablet="{{ baseUrl }}"
    alt="{{pageLabels?.['deleteAccComp.image_AltText'] }}"
  >
  </gds-picture>
  <gds-spacer classes="spacer-70"></gds-spacer>

  <!-- delete info messages -->
  <gds-heading
    [data]="{
      heading: pageLabels?.['deleteAccComp.msg1']
    }"
    class="A3-W6"
  ></gds-heading>
  <gds-spacer classes="spacer-50"></gds-spacer>

  <!-- delete info meassages  -->
  <gds-heading
    [data]="{
      heading:pageLabels?.['deleteAccComp.msg2']
    }"
    class="B1-W3"
  >
  </gds-heading>
  <gds-spacer classes="spacer-50"></gds-spacer>

  <!-- Return to Home page Button -->
  <div>
    <gds-button
      [data]="{
        type: 'primary',
        href: '',
        label: pageLabels?.['deleteAccComp.return'],
        disabled: '',
        target: ''
      }"
      (buttonClicked)="goToHomepage()"
    >
    </gds-button>
  </div>
</div>
