<!--closeCTA back to address list page-->
<gds-full-screen-modal opened (modalClose)="backtoAddressList()">
  <div slot="components">
    <div class="container modal-content" #addAddressModalContent>
      <ng-container>
        <!--Add-Address heading -->
        <gds-heading
          [data]="{
            heading: pageLabels?.['addAddress.heading']
          }"
          class="A2-W5"
        >
        </gds-heading>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <!--Add-Address description -->
        <gds-body
          [data]="{
              description: pageLabels?.['addAddress.subText']
            }"
          class="B1-W3"
        ></gds-body>

        <gds-spacer classes="spacer-30"></gds-spacer>
        <!--Add-Address formControl&formGroup creation -->
        <div [formGroup]="addAddressForm">
          <gds-input
            formControlName="postalCode"
            ngDefaultControl
            id="postalCode"
            hide-spinner="true"
            [errors]="postalErrorMsg"
            [data]="{
              primaryLabel:pageLabels?.['addAddress.postalCode'],
              tooltip: '',
              type: 'number',
              altText: 'input for postal code of the user',
              ariaText: 'input for postal code of the user',
              required: true
            }"
            (blurChange)="getErrorMessageInfo($event)"
            onKeyPress="return /[0-9]/i.test(event.key) && this.value.length < 7;"
          >
          </gds-input>

          <gds-spacer classes="spacer-50"></gds-spacer>

          <gds-button
            class="autofill-btn"
            [data]="{
              type: 'secondary',
              href: '',
              label:  pageLabels?.['addAddress.autoFill'],
              disabled: false,
              target: ''
            }"
            (buttonClicked)="getPostalCodes()"
          ></gds-button>

          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-heading
            [data]="{
              heading: pageLabels?.['addAddress.prefecture']
            }"
            class="A2-W5"
            classes="heading-7"
          >
          </gds-heading>
          <gds-dropdown
            *ngIf="showDropdown"
            formControlName="prefecture"
            ngDefaultControl
            id="prefecture"
            [errorMessage]="prefectureErrorMsg"
            [data]="{
              label: pageLabels?.['addAddress.prefecture'],
              options: showPrefectureDropdownOptions,
              required: true
            }"
            (valueChange)="matchPrefecture($event)"
            (blurChange)="getErrorMessageInfo($event)"
          >
          </gds-dropdown>
          <gds-dropdown
            *ngIf="showEmptyDropdown"
            formControlName="prefecture"
            ngDefaultControl
            id="prefecture"
            [errorMessage]="prefectureErrorMsg"
            [data]="{
            label: pageLabels?.['addAddress.prefecture'],
            options: [],
            required: true
          }"
            (valueChange)="matchPrefecture($event)"
            (blurChange)="getErrorMessageInfo($event)"
          >
          </gds-dropdown>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-input
            formControlName="city"
            ngDefaultControl
            id="city"
            [errors]="cityErrorMsg"
            [data]="{
              primaryLabel:pageLabels?.['addAddress.city'],
              tooltip: '',
              type: 'text',
              altText: 'input for city name of the user',
              ariaText: 'input for city name of the user',
              required: true
            }"
            (blurChange)="getErrorMessageInfo($event)"
            onkeydown="return /[a-zA-Z]/i.test(event.key)"
          >
          </gds-input>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-input
            formControlName="address1"
            ngDefaultControl
            id="address1"
            [errors]="address1ErrorMsg"
            [data]="{
              primaryLabel: pageLabels?.['addAddress.address1'],
              tooltip: '',
              type: 'text',
              altText: 'input for address 1 of the user',
              ariaText: 'input for address 1 of the user',
              required: true
            }"
            (blurChange)="getErrorMessageInfo($event)"
          >
          </gds-input>
          <gds-spacer classes="spacer-70"></gds-spacer>
          <gds-input
            formControlName="address2"
            ngDefaultControl
            [data]="{
              primaryLabel: pageLabels?.['addAddress.address2'],
              supportingText:pageLabels?.['addAddress.optional'],
              tooltip: '',
              type: 'text',
              altText: 'input for address 2 of the user',
              ariaText: 'input for address 2 of the user',
              required: true
            }"
          >
          </gds-input>
          <gds-spacer classes="spacer-70"></gds-spacer>
          <gds-input
            *ngIf="primaryVal === false"
            id="primary"
            ngDefaultControl
            class="checkbox-label"
            [data]="{
              primaryLabel: pageLabels?.['addAddress.primaryAddress'],
              secondaryText: '',
              primaryIconTitleText: '',
              type: 'checkbox',
              altText: 'input of re-enter password',
              ariaText: '',
              required: true
            }"
            (valueChange)="onPrimaryClick($event)"
            [value]="primaryVal ? 'checked' : ''"
          >
          </gds-input>
          <div class="align-left-checkbox" *ngIf="primaryVal === true">
            <div class="icon-container">
              <gds-svg
                icon-name="check-mark-box"
                fill-color="rgba(161,157,157,1)"
                width="24"
                height="24"
              ></gds-svg>
              <gds-spacer classes="vertical-spacer-20"></gds-spacer>
              <gds-heading
                class="B2-W3"
                classes="heading-7"
                [data]="{
                heading: pageLabels?.['editAddress.primaryAddress']
              }"
              ></gds-heading>
            </div>
          </div>
          <gds-spacer classes="spacer-30"></gds-spacer>
          <gds-heading
            [data]="{
              heading:
              pageLabels?.['addAddress.info']
            }"
            classes="A2.W5"
            classes="heading-7"
          >
          </gds-heading>
          <gds-spacer classes="spacer-85"></gds-spacer>
        </div>
      </ng-container>
    </div>
    <!--Add-Address saveCTA submit the form -->
    <div>
      <gds-sticky-nav-footer
        [data]="{
        button: {
          label: pageLabels?.['addAddress.save'],
          type: 'primary',
          title: '',
          disabled: addAddressForm.valid ? 'false' : 'true'
        }
      }"
        (buttonClicked)="onSubmit()"
      >
      </gds-sticky-nav-footer>
    </div>
  </div>
</gds-full-screen-modal>
