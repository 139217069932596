import { Injectable } from '@angular/core';
import { GeolocationService } from '@upr-web-primer/geolocation';
import { AppDataService } from './services/app-data.service';

@Injectable({
  providedIn: 'root',
})
export class AppLoadService {
  constructor(
    private geo: GeolocationService,
    private appDataService: AppDataService
  ) {}

  initializeApp(): void {
    this.appDataService.initAppData();
    this.geo.initGeoLocation();
  }
}
