import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { LocalStorageService } from '@upr-web-primer/storage';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';
import mockData from '../../shared/constants/mockData.json';
import { ToastService } from '../../shared/service/toast.service';

@Component({
  selector: 'app-link-ticket',
  templateUrl: './link-ticket.component.html',
  styleUrls: ['./link-ticket.component.scss'],
})
export class LinkTicketComponent implements OnInit, AfterViewInit {
  page: any;
  pageLabels: any;
  linkTicketsForm: FormGroup;
  TravelPartyForm: FormGroup;
  mockData: any;
  linkTickets = true;
  scanTickets = false;
  scannerEnabled = true;
  result: string;
  showPrefectureDropdownOptions: any;
  selectedPrefectureList: string;
  formData: any;
  showForm: boolean;
  data: boolean;
  travelPartyMembers: any[];
  trpMemberId: any;
  add: boolean;
  legacyDescription: any;
  travelMembersCount: number;
  relationship: any;
  selectedGenderValue: any;
  selectedRelationshipValue: any;
  genderArray: { label: any; value: any }[];
  relationshipArray: { label: any; value: any }[];
  selectedIndex: number;
  mappingArray: any;
  routeLink: any;
  showCourse = false;
  showConfirmPopup = false;
  opened = false;
  fromWarning = false;
  @ViewChild('linkTicketModalContent') linkTicketModalContent!: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private title: Title,
    public common: CommonUtilityService,
    public guestsService: MsGuestsService,
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('AppDefaults') public appDefaults: any,
    @Inject('AppDefaults') public appDefault: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    private localStorage: LocalStorageService,
    private formBuilder: FormBuilder,
    @Inject('RelativeUrls') private relativeUrls: any,
    private http: HttpClient,
    @Inject('sourceKeys') public sourceKeys: any,
    @Inject('Ranges') public ranges: any,
    @Inject('Relationship') public globalRelationship: any,
    @Inject('Gender') public gender: any,
    @Inject('ErrorMappings') public errorMappings: any,
    @Inject('DefaultErrorMappings') public defaultErrorMappings: any,
    public notification: NotificationService,
    private cookieService: CookieService,
    private appDataService: AppDataService,
    public toastService: ToastService
  ) {
    this.page = this.route?.snapshot?.data?.page;
    this.pageLabels = this.common.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.linkTickets']);
  }

  /**
   * ngOnInit - It will load all the functions
   */
  ngOnInit(): void {
    this.routeLink = this.common?.getLinkRoute();
    this.genderArray = [
      {
        label: this.pageLabels?.['trpAdd.genderFemale'],
        value: this.pageLabels?.['trpAdd.genderFemale'],
      },
      {
        label: this.pageLabels?.['trpAdd.genderMale'],
        value: this.pageLabels?.['trpAdd.genderMale'],
      },

      {
        label: this.pageLabels?.['trpAdd.genderOther'],
        value: this.pageLabels?.['trpAdd.genderOther'],
      },
      {
        label: this.pageLabels?.['trpAdd.gendernotResponded'],
        value: this.pageLabels?.['trpAdd.gendernotResponded'],
      },
    ];
    this.relationshipArray = [
      {
        label: this.pageLabels?.['trpAdd.spouse'],
        value: this.pageLabels?.['trpAdd.spouse'],
      },
      {
        label: this.pageLabels?.['trpAdd.child'],
        value: this.pageLabels?.['trpAdd.child'],
      },
      {
        label: this.pageLabels?.['trpAdd.other'],
        value: this.pageLabels?.['trpAdd.other'],
      },
    ];
    this.travelPartyMembers = this.common.getTravelMembersDetails();
    this.mockData = mockData;
    this.createForm();
    this.getTravelPartyMembers();
    this.getLegacyDesc();
  }
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(
      this.linkTicketModalContent,
      164
    );
  }

  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(
      this.linkTicketModalContent,
      164
    );
  }

  /**
   *  backtoDashboard - Dashboard it will route to dashboard component
   */
  backtoDashboard(): void {
    if (this.routeLink == this.appRoutes.DASHBOARD) {
      this.common.changeRoute(this.appRoutes.DASHBOARD);
    } else {
      this.common.changeRoute(this.appRoutes.VIEW_ENTITLMENT);
    }
  }

  /**
   * formCreation - It will create a formControl
   */
  createForm(): void {
    this.linkTicketsForm = this.formBuilder.group({
      ticketNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(99),
      ]),
    });
  }

  /**
   * relativeUrl -It will pass the apiUrl Parameters
   */
  public getRelativeUrls(url: string, ticketNo: string): string {
    return url.replace('{entitlementId}', ticketNo);
  }

  /**
   * OnSubmit - It will submit the formControl to api url
   */
  onSubmit(): void {
    this.common.validateAllFormFields(this.linkTicketsForm);
    if (this.selectedPrefectureList === this.globalDefault.NEW_TPM) {
      this.registerTravelParty();
    } else {
      this.getLinkData();
    }
  }

  /**
   * openDialogue - It will open the Scanner
   */
  openDialog(): void {
    this.linkTickets = false;
    this.scanTickets = true;
    this.showForm = false;
  }

  /**
   * goToLinkTickets - It will open the link Tickets
   */
  goToLinkTickets(): void {
    this.linkTickets = true;
    this.scanTickets = false;
    this.showForm = false;
  }

  /**
   * legacyLink - It will open the legacyCTA Url
   */
  legacyLink(): void {
    window.open(
      this.pageLabels?.['linkticket.legacyOrderLink'],
      this.appDefaults.BLANK
    );
  }

  /**
   * onScanSuccess - It will fetch the data from scanner
   */
  onScanSuccess(result: string): any {
    this.result = result;
    this.linkTicketsForm.patchValue({
      ticketNumber: this.result,
    });
    this.toastService.show({
      text: this.pageLabels?.['linkticket.sucessMsg'],
      type: this.globalDefault.SUCCESS,
    });
    this.scanTickets = false;
    this.linkTickets = true;
  }

  /**
   * getTravelPartyMembers - get the list of travel party members of logged in user
   * @param New_Tpm - If new travel party member added call this method with true/false value
   */
  getTravelPartyMembers(New_Tpm?: boolean): any {
    const guestId = this.guestsService.helper.getGuestId();
    this.guestsService.wallet
      .getTravelPartyMembers(guestId)
      .then((res: any) => {
        const data = res;
        this.travelPartyMembers = data;
        this.common.setTravelMembersDetails(this.travelPartyMembers);
        this.showPrefectureDropdownOptions = [];
        data.map((member: any) => {
          if (member.member_id === guestId) {
            this.showPrefectureDropdownOptions.unshift(member);
            this.showPrefectureDropdownOptions[0] = {
              label: `${member.contact.last} ${member.contact.first}`,
              value: `${member.contact.last} ${member.contact.first}`,
            };
          } else {
            this.showPrefectureDropdownOptions.push({
              label: `${member.contact.last} ${member.contact.first}`,
              value: `${member.contact.last} ${member.contact.first}`,
            });
          }
        });
        this.travelMembersCount = this.showPrefectureDropdownOptions.length - 1;
        this.showPrefectureDropdownOptions.push({
          label: this.pageLabels?.['linkticketReg.addNewTRP'],
          value: this.globalDefault.NEW_TPM,
        });
        this.showPrefectureDropdownOptions.push({
          label: this.pageLabels?.['linkticketReg.entIndividual_dropdown'],
          value: this.pageLabels?.['linkticketReg.entIndividual_dropdown'],
        });

        this.selectedPrefectureList =
          this.pageLabels?.['linkticketReg.entIndividual_dropdown'];
        if (New_Tpm) {
          this.getLinkData();
        }
      })
      .catch((error: any) => {
        if (error.status == this.globalDefault.ERROR_401) {
          this.getLogout();
        }
      });
  }

  /**
   * selectValue - on value selection of dropdown the method is getting called
   * @param item - on dropdown selection value is passed
   */
  selectValue(item: any): any {
    this.selectedPrefectureList = item.detail;
    if (item.detail == this.globalDefault.NEW_TPM) {
      this.showForm = true;
    } else {
      this.showForm = false;
    }
  }

  /**
   * getFormData - on firstname and lastname register account button is enabled
   * @param event - pass value of input fields
   */
  getFormData(event: any): any {
    this.formData = event.value;
    if (this.formData.firstName && this.formData.lastName) {
      this.data = true;
    } else {
      this.data = false;
    }
  }

  /**
   * linkTicketsRegister - on confirmation call the link entitlement service and navigate back to dashboard
   * @returns - return the response of service
   */
  linkTicketsRegister(): any {
    const guestId = this.guestsService.helper.getGuestId();

    const loggedInUserToken = this.localStorage.getItem('oAuthToken');
    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken}`
    );
    httpHeaders = httpHeaders.append(
      'x-resort-area-code',
      this.appDefaults.USJ
    );

    const options = { headers: httpHeaders };
    const request = {
      media_id: this.linkTicketsForm.controls.ticketNumber.value, // ticket number
      guest_id: this.trpMemberId ? this.trpMemberId : guestId, // travel party member id
    };

    const url =
      environment.oidcBaseUrl +
      this.getRelativeUrl(this.relativeUrls.LINK_ENTITLEMENT, guestId);

    return this.http.post(url, request, options);
  }

  /**
   *
   * @param url - url containing {guest-id} attribute
   * @param guestId - replace the guest Id in url
   * @returns - return the url with guest Id
   */
  public getRelativeUrl(url: string, guestId: string): string {
    return url.replace('{guest-id}', guestId);
  }

  /**
   * mappingJapaneseToEnglish - map the gender and relationship to japanese to english
   * @param data - specify the travel party member data
   * @returns
   */
  mappingJapaneseToEnglish(data: any, type: any): any {
    const result =
      type == this.globalDefault.GENDER
        ? data?.gender?.toLowerCase()
        : data?.relationship?.toUpperCase();
    const selectedItem =
      type == this.globalDefault.GENDER
        ? this.genderArray?.find((item: any) => item.value === result)
        : this.relationshipArray?.find((item: any) => item.value === result);
    if (selectedItem) {
      this.selectedIndex =
        type == this.globalDefault.GENDER
          ? this.genderArray.indexOf(selectedItem)
          : this.relationshipArray.indexOf(selectedItem);
    }
    this.mappingArray =
      type == this.globalDefault.GENDER ? this.gender : this.globalRelationship;
    const selectedValue = this.mappingArray[this.selectedIndex];
    return selectedValue;
  }

  /**
   * registerTravelParty - add new travel party member
   */
  registerTravelParty(): void {
    this.add = true;
    const data = this.formData;
    this.selectedGenderValue = this.mappingJapaneseToEnglish(
      data,
      this.globalDefault.GENDER
    );
    this.selectedRelationshipValue = this?.mappingJapaneseToEnglish(
      data,
      this.globalDefault.RELATIONSHIP
    );
    const guestId = this.guestsService.helper.getGuestId();
    const formattedDob: any = this.common.formatDate(data?.dob);
    const name = `${data?.lastName} ${data?.firstName}`;
    if (!this.fromWarning) {
      this.showCourse = this.showPrefectureDropdownOptions?.some(
        (data: any) => data?.label === name
      );
    } else {
      this.fromWarning = false;
      this.add = true;
    }
    if (this.showCourse) {
      this.add = false;
      this.sameNameWarningMsg();
    }
    const request = {
      contact: {
        first: data?.firstName,
        last: data?.lastName,
      },
      is_active: true,
      date_of_birth: formattedDob,
      relationship: this.selectedRelationshipValue,
      gender: this.selectedGenderValue,
    };

    if (
      data?.gender == '' ||
      data?.gender == this.pageLabels?.['trpAdd.gendernotResponded'] ||
      data?.gender == undefined
    ) {
      delete request.gender;
    }
    if (data?.relationship == '') {
      delete request.relationship;
    }
    if (data?.dob == '' || data?.dob == undefined) {
      delete request.date_of_birth;
    }

    if (this.add) {
      this.common.postTravelPartyMembers(guestId, request).subscribe(
        (res: any) => {
          if (res) this.getTravelPartyMembers(true);
        },
        (err: any) => {
          this.handleTRPError(err.status);
        }
      );
    }
  }
  sameNameWarningMsg(): any {
    if (this.showCourse) {
      this.showConfirmPopup = false;
      this.opened = true;
    } else this.showConfirmPopup = true;
  }
  /**
   * onWarning - shows the warning popup
   * @param e : specify the event
   */

  onWarning(e: any): any {
    this.fromWarning = true;
    this.showCourse = false;
    this.opened = e.opened;
    this.showConfirmPopup = e.showConfirm;
    if (this.showConfirmPopup) {
      this.registerTravelParty();
    } else {
      this.fromWarning = false;
    }
  }

  /**
   *getLinkData - On register account link entitlement service getting called
   */
  getLinkData(): void {
    const name = this.formData
      ? `${this.formData.lastName} ${this.formData.firstName}`
      : this.selectedPrefectureList;

    this.travelPartyMembers?.map((data: any) => {
      const fullName = `${data.contact.last} ${data.contact.first}`;
      if (fullName === name) {
        this.trpMemberId = data.member_id;
      }
    });

    this.linkTicketsRegister().subscribe(
      (res: any) => {
        this.common.setTravelPartyMemberData(res);
        this.common.changeRoute(this.appRoutes.CONFIRM_REGISTRATION, {
          guest_name:
            this.selectedPrefectureList != this.globalDefault?.NEW_TPM
              ? this.selectedPrefectureList
              : this.formData?.lastName + ' ' + this.formData?.firstName,
        });
      },
      (err: any) => {
        if (this.errorMappings.hasOwnProperty(err.status)) {
          const errorMessageValue =
            this.errorMappings && err?.status
              ? this.errorMappings[err.status] ?? this.defaultErrorMappings
              : this.defaultErrorMappings;
          const errorMessage =
            errorMessageValue === this.globalDefault?.ERROR_401
              ? this.getLogout()
              : this.pageLabels?.[errorMessageValue];
          this.notification?.show(errorMessage);
        }
      }
    );
  }

  /**
   * getLegacyDesc-It will display legacy Desc & legacy CTA
   */
  getLegacyDesc(): void {
    const legacyDesc = this.pageLabels?.['linkticket.legacyDesc'];
    this.legacyDescription = legacyDesc?.replace(
      this.pageLabels?.['legacyCTA.oldSystem'],
      ''
    );
    this.legacyDescription = this.legacyDescription?.replace(/\./g, '');
  }

  /**
   * handleTRPError - Display the error as per error type
   * @param err - Error Type
   */
  handleTRPError(err: any): any {
    if (err === this.globalDefault.ERROR_422) {
      switch (this.selectedRelationshipValue) {
        case this.globalRelationship[0]:
          this.notification.show(
            this.pageLabels?.['errCode.MS.trp_Spouse.422']
          );
          break;
        case this.globalRelationship[1]:
          this.notification.show(this.pageLabels?.['errCode.MS.trp_Child.422']);
          break;
        case this.globalRelationship[2]:
          this.notification.show(
            this.pageLabels?.['errCode.MS.trp_Others.422']
          );
          break;
      }
    } else if (err === this.globalDefault.ERROR_401) {
      this.getLogout();
    } else this.notification.show(this.pageLabels?.['errCode.E019']);
  }

  getLogout(): any {
    this.common.setUser();
    localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll('/', environment.cookieSubDomain);
    const redirectUrl = this.common.getOidcRedirectionUrl();
    this.common.redirectToOidcAppEndSession(redirectUrl);
  }
  /**
   * getErrorInfo - ticket
   * @param event
   */
  getErrorInfo(event: any): any {
    const ticketNo = event?.target?.value;
    if (
      ticketNo?.length < this.ranges?.MAX?.MIN_TICKETNO ||
      ticketNo?.length > this.ranges?.MAX?.MAX_TICKETNO
    ) {
      this.notification.show(this.pageLabels?.['errCode.E028']);
    } else {
      this.notification.hide();
    }
  }
}
