import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { LocalStorageService } from '@upr-web-primer/storage';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-delete-account-confirmation',
  templateUrl: './delete-account-confirmation.component.html',
  styleUrls: ['./delete-account-confirmation.component.scss'],
})
export class DeleteAccountConfirmationComponent implements OnInit {
  /**
   * Page labels
   * @type any
   */
  pageLabels: any;
  baseUrl: any;
  /**
   * Constructor
   * @param {ActivatedRoute} route
   * @param {CommonUtilityService} commonUtilityService
   */
  constructor(
    private commonUtilityService: CommonUtilityService,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('Ranges') public ranges: any,
    private cookieService: CookieService,
    private guestsService: MsGuestsService,
    private localStorage: LocalStorageService,
    private title: Title
  ) {}

  /**
   * ngOnInit - Intial call, pageLabels fetch forom tridion api
   */
  ngOnInit(): void {
    this.pageLabels = this.commonUtilityService?.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.deleteAccountComplete']);
    this.baseUrl = this.commonUtilityService?.prependImageUrl(
      this.pageLabels?.['deleteAccComp.image']
    );
  }

  /**
   * goToHomePage - to redirect to login page
   */
  goToHomepage(): void {
    this.cookieService.deleteAll('/', environment?.cookieSubDomain);
    this.guestsService.helper.localStorage?.clear();
    this.guestsService.helper.sessionStorage?.clear();
    this.localStorage.setItem('isAccountDelete', true);
    const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
    this.commonUtilityService.redirectToOidcApp(redirectUrl);
  }
}
