<gds-full-screen-modal
  opened="openDeleteModal"
  id="signOffModal"
  (modalClose)="closePopup()"
>
  <div slot="components">
    <div class="container modal-content" #deleteConfirmModalContent>
      <div class="A2-W5">
        <b>{{pageLabels?.['deleteAccConf.heading']}}</b>
      </div>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <div class="B1-W3">
        <div class="wrap">{{pageLabels?.['deleteAccConf.msg']}}</div>
      </div>
      <gds-spacer classes="spacer-85"></gds-spacer>
    </div>

    <div>
      <gds-sticky-nav-footer
        [data]="{
      button: {
        type: 'primary',
        href: '',
        label:  pageLabels?.['deleteAccConf.confirm'],
        disabled: false,
        target: '',
        action: pageLabels?.['deleteAccConf.confirm']
      },
      secondaryButton: {
        label:  pageLabels?.['deleteAccConf.back'],
        type: 'secondary',
        href: '',
        disabled: '',
        target: '',
        action:  pageLabels?.['deleteAccConf.back']
      }
    }"
        (buttonClicked)="onSubmit($event)"
      >
      </gds-sticky-nav-footer>
    </div>
  </div>
</gds-full-screen-modal>
