import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NotificationService } from '@upr-web-primer/notification';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonUtilityService } from './common-utility.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  pageLabels: any;
  errorMessage: any;
  constructor(
    private notificationService: NotificationService,
    private commonUtilityService: CommonUtilityService,
    @Inject('GlobalDefaults') public globalDefault: any
  ) {}

  intercept(req: any, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          req?.url?.includes(`users/current/carts`) ||
          req?.url?.includes(`users/current/carts/current`)
        ) {
          this.errorMessage =
            error?.status === this.globalDefault?.ERROR_500 ? '' : '';
          this.notificationService.hide();
        } else if (
          error?.status === this.globalDefault?.ERROR_503 ||
          error?.status === this.globalDefault?.ERROR_500
        ) {
          this.pageLabels = this.commonUtilityService.getResolvedPageData();
          this.errorMessage = this.pageLabels?.['errCode.COMM.503'];
          this.notificationService.show(this.errorMessage);
          this.errorMessage = '';
        }
        throwError(() => this.errorMessage);
        return next.handle(req);
      })
    );
  }
}
