import { EnvironmentService } from '../environments/environment.service';

const env = new EnvironmentService();

export const defaultValues = {
  REGEX_PUBID_FROM_TCMID: /^tcm:(\d+)-(\d+)(?:-(\d+))?$/i,
  REGEX_ICE_URL_LOCALE: /(.*)(()\/(.{2})\/(.{2})(\/.*))/i, // i.e for */en/us/* pattern
  DEFAULT_TRIDION_PUBLICATION_ID: '58',
  UNIWebService_ApiKey: env.uniWebServiceApiKey,
  UNIWebService_AppVersion: 'usj-web-account-1.0',
  UNIWebService_Platform: 'Web',
  STORE_ID: env.wcsStoreId,
  OAUTH_API_BASEURL: env.oauthBaseUrl,
  OAUTH_API_CLIENT_ID: env.oauthClientId,
  OAUTH_API_CLIENT_SECRET: env.oauthClientKeyID,
  OAUTH_API_APP_FLOW: env.oauthAppFlowPath,
  OAUTH_API_LOGIN_FLOW: env.oauthAppPasswordFlowPath,
  OAUTH_API_APP_SOURCE_ID: env.oauthAppSourceId,
  TRIDION_WEBDATA: env.tridionBaseUrl,
  WEB_APP_PATH: env.webAppPath,
  MULTIMEDIA_PATTERN_REGEX: '^/.*.(jpg|JPG|gif|GIF|png|PNG|pdf|PDF|mp4|MP4)',
  DEFAULT_ROUTE_COUNTRY: 'jp',
  DEFAULT_ROUTE_LANGUAGE: 'ja',
  RETRY_DELAY: 500,
  RETRY_ATTEMPTS: 3,
  UPSELL_PACKAGES: env.upsellPackages,
  DENALI_URL: env.denaliUrl,
  PARK_TICKETS_URL: '/us/park-tickets/index.html',
  DINING_APP_PATH: env.diningReservationsAppPath,
  WEB_STORE_APP_PATH: env.webstoreAppPath,
  SIGN_IN: '/account-management/login',
  GENERIC_ADDRESS: 'Generic Address',
  UPRV: env.uprvUrl,
  OIDC_API_CLIENT_ID: env.oidcClientId,
  OIDC_API_CLIENT_SECRET: env.oidcClientKeyID,
  OIDC_API_BASEURL: env.oidcBaseUrl,
  OIDC_NODE_API_BASE_URL: env.oidcNodeApiBaseUrl,
  OIDC_ENABLED: env.oidcEnabled,
  OIDC_REDIRECT_ENABLED: env.oidcRedirectEnabled,
  OIDC_REDIRECT_API: env.oidcRedirectApi,
  OIDC_ENDSESSIOON_API: env.oidcSignoffApi,
  OIDC_API_APP_FLOW: env.oidcAppFlowPath,
  OIDC_API_LOGIN_FLOW: env.oidcAppPasswordFlowPath,
  REMOVE_TIMESTAMP: /T.*/g,
  COMMERCE_CLIENT_ID: 'mobile_android',
};

export const Ranges = {
  MIN: {
    PASSWORD: 8,
    USA_PHONE: 10,
    USA_ZIP: 5,
  },
  MAX: {
    SUFFIX: 25,
    FIRST_NAME: 25,
    LAST_NAME: 25,
    PASSWORD: 99,
    USA_PHONE: 10,
    PHONE: 15,
    USA_ZIP: 9,
    NON_USA_ZIP: 12,
    CITY: 50,
    EMAIL: 255,
    ZIP_ENTRY: 20,
    CVV: 4,
    PAYMENT_CARD: 20,
    DATE: 11,
  },
  PASSWORDS: {
    PASSWORD_LENGTH_INDEX: 10,
    PASSWORD_FORCE_FACTOR: 10,
    PASSWORD_FLAG_COLORS: ['#F00', '#F90', '#FF0', '#9F0', '#0B0'],
    PASSWORD_FLAG_STRENGTH: [
      'Very Weak',
      'Weak',
      'Medium',
      'Moderate',
      'Strong',
    ],
    DEFAULT_BACKGROUND: '#DDD',
  },
  PHONE: {
    USA_COUNTRY_CODE: 'US',
    ANY_COUNTRY_CODE: 'any',
  },
};

export enum CategoriesDisplayCode {
  HOTEL = 'hotel',
  AIR = 'air',
  VEHICLE = 'vehicle',
  TRANSPORTATION = 'transportation',
  TICKETS = '36',
  PASSES = '37',
  EXTRAS = '38',
  CART = 'cart',
}

export enum CategoryResortProducts {
  EXTRAS = 'Extras',
  TICKETS = 'Tickets',
  SHUTTLES = 'Shuttles',
  EXTRAS_DISCOUNTS = 'Discounts',
  EXTRAS_OTHERS = 'Meals/Dining',
  EXPRESS_PASSES = 'Express Passes',
  SHUTTLE = 'Ground Transportation',
  EXTRAS_CART = 'Additional Options',
  EXTRAS_SHUTTLE = 'Ground Transportation',
  EXTRAS_TRAVEL_PROTECTION = 'Travel Protection',
  HOTEL_INCLUSIONS = 'Hotel Inclusions',
  REQUIRED_RESERVATIONS = 'Required Reservations',
}

export enum SubCategoryNames {
  UEU = 'Universal Express Unlimited',
  EARLY_PARK_ADMISSION = 'Early Park Admission',
  DISCOUNTS = 'Discounts',
}
export enum GuestAgeGroup {
  ADULT = 'adult',
  CHILD = 'child',
}
export enum GUEST {
  ADULT = 'Adult',
  CHLID = 'Child',
  INFANT = 'Infant',
}

export const dateFormats = {
  get MMM_DD_YYYY(): string {
    return 'MMM DD, YYYY';
  },
  get MMM_d_yyyy(): string {
    return 'MMM d, yyyy';
  },
  get MMM_DD_YYYY_HH_MM_A(): string {
    return 'MMM DD, YYYY hh:mm A';
  },
  get HH_MM_A(): string {
    return 'hh:mm A';
  },
  get MMM_D(): string {
    return 'MMM D';
  },
  get D(): string {
    return 'D';
  },
};
