import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: number): string {
    if (value) {
      const hour = value / 60;
      const hours = Math.floor(hour);
      const minute = (hour - hours) * 60;
      const minutes = Math.round(minute);

      return hours > 0 ? hours + 'hr ' + minutes + 'm' : minutes + 'm';
    } else {
      return '';
    }
  }
}
