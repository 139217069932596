<!-- Horizontal bars specify the password strength -->

<div class="strength">
  <ul class="strengthBar mt-2">
    <li class="point" [style.background-color]="bar0"></li>
    <li class="point" [style.background-color]="bar1"></li>
    <li class="point" [style.background-color]="bar2"></li>
    <li class="point" [style.background-color]="bar3"></li>
    <li class="point" [style.background-color]="bar4"></li>
  </ul>
</div>
<gds-spacer *ngIf="!message" classes="spacer-50"></gds-spacer>
<!-- Password strength -->
<div>
  <gds-spacer *ngIf="passwordToCheck?.length" classes="spacer-20"></gds-spacer>
  <gds-body
    *ngIf="passwordToCheck?.length"
    class="adventure strengthBar-message"
    classes="body-2 adventure strengthBar-message"
    [data]="{
      description: message
    }"
  ></gds-body>
  <!-- Password check for length -->
  <div class="container">
    <ng-container>
      <div
        *ngIf="passwordToCheck?.length > 0 && passwordToCheck?.length > 7"
        class="mtop"
      >
        <gds-svg
          icon-name="check-mark-circle-v2"
          height="24"
          width="24"
          fill-color="#008437"
        ></gds-svg>
      </div>
      <div
        *ngIf="passwordToCheck?.length === 0 || passwordToCheck?.length <= 7"
        class="mtop"
      >
        <gds-svg
          icon-name="check-mark-circle-v2"
          height="24"
          width="24"
          fill-color="#e8ebe9"
        ></gds-svg>
      </div>
    </ng-container>

    <gds-spacer classes="vertical-spacer-20"></gds-spacer>

    <gds-body
      class="adventure"
      classes="body-2 adventure"
      [data]="{
        description: pageLabels?.['editPwd.pwdreq1']
      }"
    ></gds-body>
  </div>

  <div class="container">
    <ng-container>
      <div *ngIf="numbers" class="mtop">
        <gds-svg
          icon-name="check-mark-circle-v2"
          height="24"
          width="24"
          fill-color="#008437"
        ></gds-svg>
      </div>
      <div *ngIf="!numbers" class="mtop">
        <gds-svg
          icon-name="check-mark-circle-v2"
          height="24"
          width="24"
          fill-color="#e8ebe9"
        ></gds-svg>
      </div>
    </ng-container>
    <gds-spacer classes="vertical-spacer-20"></gds-spacer>
    <gds-body
      class="adventure"
      classes="body-2 adventure"
      [data]="{ description: pageLabels?.['editPwd.pwdreq2'] }"
    ></gds-body>
  </div>
  <!-- Password check for uppercase -->
  <div class="container">
    <ng-container>
      <div *ngIf="upperLetters" class="mtop">
        <gds-svg
          icon-name="check-mark-circle-v2"
          height="24"
          width="24"
          fill-color="#008437"
        ></gds-svg>
      </div>
      <div *ngIf="!upperLetters" class="mtop">
        <gds-svg
          icon-name="check-mark-circle-v2"
          height="24"
          width="24"
          fill-color="#e8ebe9"
        ></gds-svg>
      </div>
    </ng-container>
    <gds-spacer classes="vertical-spacer-20"></gds-spacer>
    <gds-body
      class="adventure"
      classes="body-2 adventure"
      [data]="{ description: pageLabels?.['editPwd.pwdreq3']}"
    ></gds-body>
  </div>
  <!-- Password check for lower case -->
  <div class="container">
    <ng-container>
      <div *ngIf="lowerLetters" class="mtop">
        <gds-svg
          icon-name="check-mark-circle-v2"
          height="24"
          width="24"
          fill-color="#008437"
        ></gds-svg>
      </div>
      <div *ngIf="!lowerLetters" class="mtop">
        <gds-svg
          icon-name="check-mark-circle-v2"
          height="24"
          width="24"
          fill-color="#e8ebe9"
        ></gds-svg>
      </div>
    </ng-container>
    <gds-spacer classes="vertical-spacer-20"></gds-spacer>
    <gds-body
      class="adventure"
      classes="body-2 adventure"
      [data]="{ description: pageLabels?.['editPwd.pwdreq4']}"
    ></gds-body>
  </div>
  <!-- Password check for symbols -->
  <div class="container">
    <ng-container>
      <div *ngIf="symbols" class="mt-symbol">
        <gds-svg
          icon-name="check-mark-circle-v2"
          height="24"
          width="24"
          fill-color="#008437"
        ></gds-svg>
      </div>
      <div *ngIf="!symbols" class="mt-symbol">
        <gds-svg
          icon-name="check-mark-circle-v2"
          height="24"
          width="24"
          fill-color="#e8ebe9"
        ></gds-svg>
      </div>
    </ng-container>

    <gds-spacer classes="vertical-spacer-20"></gds-spacer>
    <gds-body class="adventure B2-W2" classes="body-2 adventure">
      {{pageLabels?.['editPwd.pwdreq5']}}<br />
      {{pageLabels?.['editPwd.pwdreq6']}} <br />

      {{pageLabels?.['editPwd.pwdreq7']}}</gds-body
    >
  </div>
</div>
