<gds-full-screen-model opened (modalClose)="closePopup()">
  <!--Warning travel party model popup-->
  <div slot="components">
    <!--Warning travel party heading and info message-->
    <div class="container modal-content" #warningPartyModalContent>
      <gds-heading
        [data]="{
          heading: pageLabels?.['trpAdd.warning']
        }"
        class="A2-W5"
      >
      </gds-heading>
      <gds-spacer classes="spacer-30"></gds-spacer>
      <gds-body
        [data]="{
          description:
            pageLabels?.['trpAdd.warningMsg']
        }"
        class="B1-W3"
      ></gds-body>
      <gds-spacer classes="spacer-85"></gds-spacer>
    </div>
    <!--Footer-->
    <gds-sticky-nav-footer
      [data]="{
        button: {
          label: pageLabels?.['trpAdd.back'],
          type: 'primary',
          href: '',
          disabled: '',
          target: '',
          action: 'back'
        }
      }"
      (buttonClicked)="closePopup()"
    >
    </gds-sticky-nav-footer>
  </div>
</gds-full-screen-model>
