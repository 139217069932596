<gds-full-screen-modal opened (modalClose)="backToPhoneNumberPage()">
  <!--Add phone number model popup-->
  <div slot="components">
    <div class="container modal-content" #addPhoneContentModal>
      <!--Add phone number header-->
      <gds-heading
        [data]="{
          heading: pageLabels?.['addPhone.heading']
        }"
        class="A2-W5"
      >
      </gds-heading>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!--Add phone number description-->

      <gds-body
        [data]="{
          description: pageLabels?.['addPhone.subText']
        }"
        class="B1-W3"
      ></gds-body>

      <gds-spacer classes="spacer-70"></gds-spacer>
      <!--Add phone number form data-->
      <div [formGroup]="addPhoneNumberForm">
        <!--Phone number input-->
        <gds-input
          id="phoneNumber"
          formControlName="phoneNumber"
          [errors]="phoneNoErrorMsgInfo"
          ngDefaultControl
          [data]="{
            primaryLabel: pageLabels?.['addPhone.phoneNumber'],
            supportingText: '',
            tooltip: '',
            type: 'text',
            altText: 'input of phoneNumber',
            ariaText: '',
            required: true
          }"
          (blurChange)="getErrorMessageInfo($event)"
          (keypress)="onKeyPressEvent($event)"
          hide-spinner="true"
          onKeyPress=" if( this.value.length === 11 ) return false;"
        >
        </gds-input>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <!--Checkbox of phone number-->
        <!-- <gds-input
          id="primary"
          class="checkbox-label"
          [data]="{
            primaryLabel: mockData.addPhone_setPrimary,
            secondaryText: '',
            primaryIconTitleText: '',
            type: 'checkbox',
            altText: '',
            ariaText: ''
          }"
          [value]="'checked'"
          [class.disabled]="true"
          (keydown)="onKeydown($event)"
        >
        </gds-input> -->
        <!--Set as primary button-->
        <div class="align-left-checkbox">
          <div class="icon-container">
            <gds-svg
              icon-name="check-mark-box"
              fill-color="rgba(161,157,157,1)"
              width="24"
              height="24"
            ></gds-svg>
            <gds-spacer classes="vertical-spacer-20"></gds-spacer>
            <gds-heading
              class="B2-W3"
              classes="heading-7"
              [data]="{
                heading: pageLabels?.['addPhone.setPrimary']
              }"
            ></gds-heading>
          </div>
        </div>
        <gds-spacer classes="spacer-85"></gds-spacer>
      </div>
    </div>
    <!--Confirm footer button-->
    <gds-sticky-nav-footer
      [data]="{
        button: {
          type: 'primary',
          href: '',
          label: pageLabels?.['addPhone.confirm'],
          disabled: addPhoneNumberForm.valid ? 'false' : 'true',
          target: ''
        }
      }"
      (buttonClicked)="onSubmit()"
    >
    </gds-sticky-nav-footer>
  </div>
</gds-full-screen-modal>
