import { Component, Inject, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-enlarge-qr-code',
  templateUrl: './enlarge-qr-code.component.html',
  styleUrls: ['./enlarge-qr-code.component.scss'],
})
export class EnlargeQrCodeComponent implements OnInit {
  private common: CommonUtilityService;
  fullName: any;
  QRCode: any;
  pageLabels: any;
  mediaID: any;

  constructor(
    @Inject('AppRoutes') public appRoutes: any,
    protected injector: Injector,
    private route: ActivatedRoute
  ) {
    this.setDependencies();
  }

  /**
   * ngOnInit - initial methods are called
   */
  ngOnInit(): void {
    this.fullName = this.route?.snapshot?.queryParams?.fullName;
    this.pageLabels = this.common.getResolvedPageData();
    this.QRCode = this.route?.snapshot?.queryParams?.QRCode;
    this.mediaID = this.route?.snapshot?.queryParams?.mediaID;
  }

  /**
   * backToViewEntitlement - redirected to view entitlements page
   */
  backToViewEntitlement(): any {
    this.common.changeRoute(this.appRoutes.VIEW_ENTITLMENT);
  }
  private setDependencies(): void {
    this.common = this.injector.get(CommonUtilityService);
  }
}
