import {
  appDefault,
  appRegExs,
  appRoutes,
  tridionDefault,
} from '../config/app-default.constant';
import { defaultValues } from '../config/defaultValues.constant';
import {
  DefaultErrorMappings,
  ErrorMappings,
  Gender,
  globalDefault,
  Ranges,
  Relationship,
  sourceKeys,
} from '../config/global-default.constant';
import {
  EmailPasswordPattern,
  NamePattern,
  NumberCharPattern,
  PhonePattern,
} from '../config/pattern.constant';
import { relativeUrls } from '../config/relativeUrls.constant';

export const appProviders = [
  {
    provide: 'RelativeUrls',
    useValue: relativeUrls,
  },
  {
    provide: 'DefaultValues',
    useValue: defaultValues,
  },
  {
    provide: 'IceDataUrl',
    useValue: appDefault.DATA,
  },
  {
    provide: 'TridionBaseUrl',
    useValue: defaultValues.TRIDION_WEBDATA,
  },
  {
    provide: 'PageInfoUrl',
    useValue: appDefault.PAGE_INFO,
  },
  {
    provide: 'DefaultLanguage',
    useValue: appDefault.DEFAULT_ROUTE_LANGUAGE,
  },
  {
    provide: 'DefaultCountry',
    useValue: appDefault.DEFAULT_ROUTE_COUNTRY,
  },
  {
    provide: 'LocaleRegExp',
    useValue: appDefault.REGEX_LOCALE,
  },
  {
    provide: 'IceUrlLocaleRegex',
    useValue: defaultValues.REGEX_ICE_URL_LOCALE,
  },
  {
    provide: 'AppRoutes',
    useValue: appRoutes,
  },
  {
    provide: 'AppDefaults',
    useValue: appDefault,
  },
  {
    provide: 'TridionDefaults',
    useValue: tridionDefault,
  },
  {
    provide: 'GlobalDefaults',
    useValue: globalDefault,
  },
  {
    provide: 'Ranges',
    useValue: Ranges,
  },
  {
    provide: 'Gender',
    useValue: Gender,
  },
  {
    provide: 'ErrorMappings',
    useValue: ErrorMappings,
  },
  {
    provide: 'DefaultErrorMappings',
    useValue: DefaultErrorMappings,
  },
  {
    provide: 'Relationship',
    useValue: Relationship,
  },
  {
    provide: 'sourceKeys',
    useValue: sourceKeys,
  },
  {
    provide: 'EmailPasswordPattern',
    useValue: EmailPasswordPattern,
  },
  {
    provide: 'NumberCharPattern',
    useValue: NumberCharPattern,
  },
  {
    provide: 'NamePattern',
    useValue: NamePattern,
  },
  {
    provide: 'PhonePattern',
    useValue: PhonePattern,
  },
  {
    provide: 'WebContentUrl',
    useValue: globalDefault.WEB_CONTENT_URL,
  },
  {
    provide: 'AppRegexs',
    useValue: appRegExs,
  },
  {
    provide: 'RecaptchaConfig',
    useValue: {
      apiUrl: appDefault.GOOGLE_RECAPTCHA_API_URL,
      siteKey: appDefault.GOOGLE_RECAPTCHA_SITE_KEY,
    },
  },
  {
    provide: 'FbLikeConfig',
    useValue: {
      appId: appDefault.FB_SDK_APP_ID,
      sdkUrl: appDefault.FB_SDK_URL,
      sdkVersion: appDefault.FB_SDK_VERSION,
    },
  },
  {
    provide: 'GeolocationApiUrl',
    useValue: appDefault.GEOLOCATION_API_URL,
  },
  {
    provide: 'AdobeAutosearchUrl',
    useValue: globalDefault.ADOBE_AUTOSEARCH_URL,
  },
  {
    provide: 'DenaliUrl',
    useValue: defaultValues.DENALI_URL,
  },
  {
    provide: 'LayoutPath',
    useValue: appDefault.LAYOUT_PATH,
  },
  {
    provide: 'LayoutHeaderDesktop',
    useValue: appDefault.LAYOUT_HEADER_DESKTOP,
  },
  {
    provide: 'LayoutHeaderMobile',
    useValue: appDefault.LAYOUT_HEADER_MOBILE,
  },
  {
    provide: 'LayoutHeader',
    useValue: appDefault.LAYOUT_HEADER,
  },
  {
    provide: 'LayoutFooter',
    useValue: appDefault.LAYOUT_FOOTER,
  },
  {
    provide: 'TridionSite',
    useValue: tridionDefault.TRIDION_SITE,
  },
  {
    provide: 'FacebookLikeUrl',
    useValue: appDefault.FACEBOOK_LIKE_URL,
  },
  {
    provide: 'GoogleCalendarApiKey',
    useValue: appDefault.GOOGLE_CALENDAR_API_KEY,
  },
  {
    provide: 'UprMobileServiceConfig',
    useValue: {
      baseUrl: '',
      key: '',
      token: '',
      city: '',
      cityCode: '',
    },
  },
  {
    provide: 'DigitalDataPageConfigPath',
    useValue: '', // this should be empty as tridion is the page meta source
  },
  {
    provide: 'NotFoundPage',
    useValue: appDefault.PAGE_404,
  },
];
