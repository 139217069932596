export const EmailPasswordPattern = {
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@]+(\.[^<()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))$/,
  PASSWORD_REGEX: /^(?!^(.)\1*$)(.{7,})$/,
  PASSWORD_REPEATEDCHAR_REGEX: /^(.)\1+$/,
  PASSWORD_LENGTH_REGEX: /^.{8,}$/,
  EMAIL_SIGNUP:
    '^[_A-Za-z0-9-+]+(.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(.[A-Za-z0-9]+)*(.[A-Za-z]{2,})$',
  LOWERCASE_PASSWORD: /[a-z]+/,
  UPPERCASE_PASSWORD: /[A-Z]+/,
  LOGIN_EMAIL:
    '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
  VALID_PASSWORD: /^[ A-Za-z0-9_@.'()":-]*$/,
};

export const NamePattern = {
  NAME: /^[a-z](?![a-z\.\`\'\-\s]*[\.\`\'\-]{2})(?![a-z\.\`\'\-\s]*[\s]{2})[a-z\.\`\'\-\s]*$/i,
  NAME_SUFFIX:
    /^[a-z](?![a-z\.\`\'\-\s]*[\.\`\'\-]{2})(?![a-z\.\`\'\-\s]*[\s]{2})[a-z\.\`\'\-\s]*$/i,
  //NAME_SIGNUP: /^[a-zA-Z\D\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]*$/,
  NAME_SIGNUP: /^\D*$/,
  FULL_WIDTH_FURIGANA_REGEX: /^[\u30A0-\u30FF]*$/,
};
export const NAME_REGEX = new RegExp(
  '^[\u0041-\u005A\u0061-\u007A\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FFF]*$'
);
export const NumberCharPattern = {
  NUMBER: /^[0-9]*$/, // Only Number
  NON_NUMBER: /[^0-9]/g,
  LETTER: /^[a-z].*[A-Z]|[A-Z].*[a-z]*$/, // Only combination of small & CAPS (aA)
  SPECIAL_CHAR: /^[-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/]*$/, // only special characters
  WITH_NUMBER: /[0-9]/, // number with char & special characters
  WITH_LETTER: /[a-z].*[A-Z]|[A-Z].*[a-z]/, // combination of small & CAPS (aA) with number & special char
  WITH_SPECIAL_CHAR: /[-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/]/, // special characters with char and numbers
  LETTER_NUMBER: /^[a-zA-Z0-9\s,'\-.]*$/, // Small & CAPS (aA) with number and spl. char except ! # $ % & ' * + - / = ? ^ _ ` { | } ~
  WITH_LOWER_LETTER: /[a-z]/, // combination of small letter(a) with CAPS, number & special char
  WITH_UPPER_LETTER: /[A-Z]/, // combination of CAPS letter(A) with small letter, number & special char
  NON_DIGIT: /\D/g,
  START_WITH_PLUS: /^\+/,
};

export const MiscPattern = {
  ENDS_WITH_SPACE: /\s+$/,
  BRACKET_DOUBLEQUOTES: /[\[\]'"]/g,
  DATE: /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])[\/]\d{4}$/,
  ANNUALPASS_DOB: /^(0?[1-9]|1[012])[\/](0?[1-9]|[12][0-9]|3[01])$/,
};

export const ZipCodePattern = {
  ZIP: /^(?:\d{5,9}|\d{5}(?:[-]\d{4})?)$/,
  NON_US_ZIP: /^([a-zA-Z0-9\s-]){0,12}$/,
};

export const PhonePattern = {
  PHONE: /^(\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4}))+$/,
  NON_US_PHONE: /^(\d{0,15})+$/,
  PHONE_ANY: /^[- +()]*[0-9][- +()0-9]*$/,
  PHONE_SIGNUP: /^!*([0-9]!*){10,}$/,
  ONLY_NUMBER: /[0-9]+/,
};
