import { Location } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DalService } from '@upr-web-primer/dal';
import { GoogleRecaptchaService } from '@upr-web-primer/google-recaptcha';
import { EmailType, PhoneType, SpinnerKeys } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { SpinnerService } from '@upr-web-primer/spinner';
import {
  LocalStorageService,
  SessionStorageService,
} from '@upr-web-primer/storage';
import { UrlInfo } from '@upr-web-primer/url-info';
import { WINDOW } from '@upr-web-primer/window';
import { get } from 'lodash-es';
import { ApplicationDataService } from '../../services/application-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';
import { AccountServicesInjectorClass } from '../../shared/classes/account-service-injector.class';
import { IAccountEnrollRequest } from '../../shared/interface/ms-guest.types';
@Component({
  selector: 'app-signup-confirm',
  templateUrl: './signup-confirm.component.html',
  styleUrls: ['./signup-confirm.component.scss'],
})
export class SignupConfirmComponent implements OnInit {
  /**
   * Url info
   * @type UrlInfo
   */
  urlInfo: UrlInfo;

  /**
   * Page
   * @type any
   */
  page: any;

  /**
   * Component presentations
   * @type Array<any>
   */
  componentPresentations: Array<any>;

  /**
   * Unregistered guestId
   */
  unregisteredGuestId: string;

  @Output() backbtn = new EventEmitter<any>();
  @Input() public userArray: any[];
  @Input() public fullName: any[];
  @Input() public encryptPassword: any;
  @Input() public createAccountForm: FormGroup;
  @Input() accountCheck: boolean;
  @Input() accountCreation: boolean;
  @Input() accountConfirmation: boolean;
  @Input() redirect_uri: any;

  enableCaptcha: boolean;
  captchaResponse: string;
  pageLabels: any;
  byPassTimeOut: any;
  dateVal: any;
  savedSourceId: any;
  /**
   * Vars to hold hold injected services
   * Prevents too many constructor args error, in SonarQube
   */
  private common: CommonUtilityService;
  private recaptcha: GoogleRecaptchaService;

  private notification: NotificationService;
  private spinner: SpinnerService;

  constructor(
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private dal: DalService,
    private location: Location,
    private accountServicesInjector: AccountServicesInjectorClass,
    private title: Title,
    @Inject('AppRoutes') private appRoutes: any,
    @Inject('AppDefaults') private appDefault: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject(WINDOW) public window: any,
    @Inject('sourceKeys') public sourceKeys: any,
    private sessionStorage: SessionStorageService,
    public localStorage: LocalStorageService,
    private applicationDataService: ApplicationDataService
  ) {
    this.enableCaptcha =
      this.appDefault.GOOGLE_RECAPTCHA_ENABLE === this.globalDefault.FLAGS[0];
    this.urlInfo = this.route.snapshot.data.urlInfo;
    this.page = this.route.snapshot.data.page;
    this.componentPresentations =
      get(this.page, this.globalDefault.PRESENTATION) || [];
    this.unregisteredGuestId = this.route.snapshot.queryParams.guestId || '';
    this.common = this.accountServicesInjector.common;
    this.recaptcha = this.accountServicesInjector.recaptcha;
    this.notification = this.accountServicesInjector.notification;
    this.spinner = this.accountServicesInjector.spinner;
  }

  ngOnInit(): void {
    this.redirect_uri;
    this.pageLabels = this.common.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.createAccountConfirm']);
    this.common.scrollTop();
    this.dateVal = this.userArray?.[0].dob;
  }

  createBackbtn(): void {
    this.common.invokeFireEvent(
      'CTA | Back',
      'Button',
      'Click',
      'null',
      new Date().toISOString(),
      'evar202',
      '',
      this.window?.digitalData?.page?.category?.primaryCategory,
      this.window?.digitalData?.page?.category?.subCategory1
    );

    const flag = {
      accountCheck: false,
      accountCreation: true,
      accountConfirmation: false,
    };
    this.notification.hide();

    this.backbtn.emit({
      accountCheck: flag.accountCheck,
      accountCreation: flag.accountCreation,
      accountConfirmation: flag.accountConfirmation,
      createAccountForm: this.createAccountForm,
    });
    this.common.setFormData(this.createAccountForm);
  }

  /**
   * onSubmitAccCreation : Enroll Account, After enrolling account if cartCreateAccount cookie exists then redirecting to WTS , if cookie not present then display singup confirmation
   */
  onSubmitAccCreation(): void {
    this.common.invokeFireEvent(
      'CTA | Next',
      'Button',
      'Click',
      'null',
      new Date().toISOString(),
      'evar202',
      '',
      this.window?.digitalData?.page?.category?.primaryCategory,
      this.window?.digitalData?.page?.category?.subCategory1
    );

    this.savedSourceId = this.common?.getSourceId(
      this.sourceKeys?.WEB_ACCOUNTCREATION
    );
    const flag = {
      accountCheck: false,
      accountCreation: false,
      accountConfirmation: true,
    };
    const formattedDob: any = this.common?.formatDate(
      this.createAccountForm?.value?.dob
    );
    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append('X-Source-Id', this.savedSourceId);
    httpHeaders = httpHeaders.append('x-resort-area-code', this.appDefault.USJ);
    this.notification.hide();
    this.spinner.show(this.globalDefault.CREATEACCOUNT);
    const email = (
      get(this.createAccountForm, 'value.email') ||
      get(this.route, 'snapshot.queryParams.email', '')
    ).trim();
    const pwd = this.createAccountForm?.value?.passWord;
    const request: IAccountEnrollRequest = {
      g_recaptcha_response: this.enableCaptcha
        ? this.captchaResponse
        : this.appDefault.GOOGLE_RECAPTCHA_TEST_RESPONSE,

      guests_request: {
        user_id: email,
        date_of_birth: formattedDob,
        // external_guest_id: this.guests?.helper?.getGuestId(),
        personal_info: {
          contacts: [
            {
              last_name: get(this.createAccountForm, 'value.lastName', ''),
              first_name: get(this.createAccountForm, 'value.firstName', ''),
              contact_type: this.globalDefault.CONTACT_TYPE_KANJI,
            },
          ],
          emails: [
            {
              email_address: get(this.createAccountForm, 'value.email', ''),
              primary: false,
              type: EmailType.work,
            },
          ],
          phones: [
            {
              phone_number: get(
                this.createAccountForm,
                'value.phoneNumber',
                ''
              ),
              phone_type: PhoneType.cell,
              primary: false,
            },
          ],
        },
        preferences: {
          comm_prefs: {
            // email_offers_promos:
            //   this.appDefault.RESORT_NAME ===
            //     this.globalDefault.RESORT_NAMES[0] &&
            //   this.createAccountForm.value.specialOffer,
            // email_offers_promos_ush:
            //   this.appDefault.RESORT_NAME ===
            //     this.globalDefault.RESORT_NAMES[1] &&
            //   this.createAccountForm.value.specialOffer,
            email_offers_promos: this.createAccountForm?.value?.specialOffer
              ? true
              : false,
          },
          terms_of_service: this.createAccountForm?.value?.termCondition,
        },
      },
      creds: {
        user_id: email,
        password: pwd,
      },
    };
    // const redirectUri = this.common?.getOidcRedirectionUrl();
    // console.log(redirectUri);
    const existsCartCreateAccount =
      this.applicationDataService.getCookieInfo('cartCreateAccount');
    this.dal
      .httpRequest(
        this.appDefault.ENROLL,
        this.appDefault.POST,
        request,
        httpHeaders
      )
      .then((data: any) => {
        if (this.redirect_uri) {
          this.common.changeUrl(this.redirect_uri);
        } else {
          this.common.invokeFireEvent(
            'Crete account form',
            'Submit',
            'Form',
            'null',
            new Date().toISOString(),
            `Success`,
            '',
            this.window?.digitalData?.page?.category?.primaryCategory,
            this.window?.digitalData?.page?.category?.subCategory1
          );
          this.localStorage.setItem('LOGGEDIN_GUEST_ID', data.guest_id);
          this.common?.setUser();
          if (existsCartCreateAccount) {
            this.applicationDataService.setApplicationCookieInfo(
              'isSignUp',
              'true'
            );
            const access_token = data?.auth_token?.access_token;
            const expires_in = data?.auth_token?.expires_in;
            const guestId = data?.guest_id;
            this.localStorage.setItem('OIDC_ACCESS_TOKEN', access_token);
            this.sessionStorage.setItem('OIDC_ACCESS_TOKEN', access_token);
            this.localStorage.setItem('OIDC_ID_TOKEN', access_token);
            this.localStorage.setItem('OIDC_EXPIRES_IN', expires_in);
            this.sessionStorage.setItem('OIDC_ID_TOKEN', access_token);
            this.sessionStorage.setItem('OIDC_EXPIRES_IN', expires_in);
            this.applicationDataService.setApplicationCookieInfo(
              'access_token',
              access_token
            );
            this.applicationDataService.setApplicationCookieInfo(
              'expires_in',
              `${expires_in}`
            );
            this.applicationDataService.setApplicationCookieInfo(
              'guestId',
              guestId
            );

            this.commerceAuthentication();
          } else {
            this.backbtn.emit(flag);
          }
        }
      })
      .catch((error: any) => {
        this.common.invokeFireEvent(
          'System Error',
          'Error',
          'Page',
          'null',
          new Date().toISOString(),
          `system error | ${error.error.message}`,
          '',
          this.window?.digitalData?.page?.category?.primaryCategory,
          this.window?.digitalData?.page?.category?.subCategory1
        );
        if (error?.status === this.globalDefault.ERROR_409) {
          this.notification.show(this.pageLabels?.['errCode.MS.editInfo.409']);
          this.localStorage.setItem('returnVisitor', true);
          this.common?.setUser();
        } else {
          this.notification.show(this.pageLabels?.['errCode.E019']);
        }
      })
      // .catch((error) => {
      //   this.common.notifyFormErrorEvent(
      //     'Create Account Confirmation Failed',
      //     error
      //   );
      //   console.log(error);
      // });
      // })
      /*.then(() =>
				this.guests.account.getAccountAvailable(
					request,
					sourceId,
					false,
					undefined,
					undefined,
					this.byPassTimeOut
				)
				this.accountCheck = false;
				this.accountCreation = false;
				this.accountConfirmation = true;
			)
			
				/*.then(() =>
					this.guests.account.login(
						email,
						pwd,
						redirectUri,
						undefined,
						this.environmentService.oauthAppSourceId
					)
				)*/
      .then(() =>
        this.common?.notifyFormSuccessEvent(
          this.globalDefault.CREATE_ACCOUNT_FORM,
          this.globalDefault.CREATED
        )
      )
      .catch((error) => {
        console.error(error);
        const isTimeout =
          get(error, this.globalDefault.NAME, '') ===
          this.globalDefault.TIMEOUT;
        this.notification?.show(error?.error?.message);
        this.location.go(this.appRoutes.SIGNUP_CONFIRM);
        if (isTimeout) {
          if (!this.byPassTimeOut) {
            this.byPassTimeOut = true;
            this.notification?.show(this.pageLabels.ER49);
          }
        }
        this.spinner.hide(SpinnerKeys.OVERRIDE);
        this.common?.notifyFormErrorEvent(
          this.globalDefault.CREATE_ACCOUNT_FORM,
          error
        );
        return false;
      })
      .then((response) => {
        if (!existsCartCreateAccount) {
          this.spinner.hide(this.globalDefault.CREATEACCOUNT);
        }
        if (response === false) {
          this.recaptcha.reset();
        }
      });

    this.cdRef.detectChanges();
    if (this.createAccountForm?.invalid) return;
  }

  /**
   * commerceAuthentication :Making commerce Authentication info and share the authentication information between Account and WTS as loggedIn user
   */
  commerceAuthentication(): any {
    this.spinner.show(this.globalDefault.CREATEACCOUNT);
    this.common.getB2COAuthToken().subscribe((authData: any) => {
      localStorage.setItem('authObj', JSON.stringify(authData));
      if (authData) {
        this.common.postauthLogin().subscribe(
          (apiResponse: any) => {
            localStorage.setItem('loginAuthToken', JSON.stringify(apiResponse));
            this.applicationDataService.setApplicationCookieInfo(
              'commerceTokenInfo',
              JSON.stringify(apiResponse)
            );
            this.localStorage.removeItem('returnVisitor');
            this.spinner.hide(this.globalDefault.CREATEACCOUNT);
            this.common.clearAllCookies(false, false, false, true);
            this.common.onClickRedirectWTS('', '', true, false);
          },
          () => {
            this.spinner.hide(this.globalDefault.CREATEACCOUNT);
            this.common.clearAllCookies(false, false, false, true);
            this.common.onClickRedirectWTS('', '', true, false);
          }
        );
      }
    });
  }
}
