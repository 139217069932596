import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  EventEmitter,
  Inject,
  Injectable,
  ViewContainerRef,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Params, QueryParamsHandling, Router } from '@angular/router';
import { DalService } from '@upr-web-primer/dal';
import {
  AppDataLayerService,
  CustomBoolean,
  DigitalDataService,
  FormEventType,
  IDigitalDataUser,
  IDigitalDataUserProfile,
} from '@upr-web-primer/digital-data';
import { GdsTcmLoaderComponent } from '@upr-web-primer/gds-tcm-loader';
import { LocaleService } from '@upr-web-primer/locale';
import { NotificationService } from '@upr-web-primer/notification';
import { SeoService } from '@upr-web-primer/seo';
import {
  LocalStorageService,
  SessionStorageService,
} from '@upr-web-primer/storage';
import { UrlInfoService } from '@upr-web-primer/url-info';
import { WINDOW } from '@upr-web-primer/window';
import { BnNgIdleService } from 'bn-ng-idle';
import _ from 'lodash';
import { get, isNaN } from 'lodash-es';
import { DateTime } from 'luxon';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, catchError, Observable, of, throwError } from 'rxjs';
import { appRoutes } from '../../config/app-default.constant';
import { defaultValues } from '../../config/defaultValues.constant';
import { SourceIdKey } from '../../config/sourceIds.constant';
import { environment } from '../../environments/environment';
import { TICKET_STATUS } from '../account/ticket-details/ticket-status';
@Injectable({
  providedIn: 'root',
})
export class CommonUtilityService {
  private formData: any;
  coupons: any;
  dateValue: any;
  data: any;
  travelPartyData: any;
  profile: IDigitalDataUserProfile;
  public sourceIDArray: any;
  savedSourceId: any;
  private entitlementData: any;
  linkTicketsData: any;
  travelMembersDetails: any;
  isDeleteAccount = false;
  pagelabelData: any;
  private responseReceived = new EventEmitter<any>();
  mediaData: any;
  mediaId: any[];
  formattedCurrentDate: any;
  formattedComingDate: any;
  private isAuthenticatedSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private isCartSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  iconArrayData: any;
  languageCode: string;
  cartItemLength: any;
  quantity: any = 0;
  getCartsData: any;
  getCurrentCartData: any;
  commerceGenericErrorMessage: any;
  getCurrentCartError: any;
  private resolvedDataSubject = new BehaviorSubject<any[]>([]);
  resolvedData$ = this.resolvedDataSubject.asObservable();
  constructor(
    @Inject(WINDOW) private window: any,
    @Inject(APP_BASE_HREF) private baseHref: string,
    @Inject('TridionDefaults') private tridionDefault: any,
    @Inject('RelativeUrls') private relativeUrls: any,
    @Inject('AppDefaults') public appDefaults: any,
    @Inject('AppRoutes') private appRoutes: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('DefaultValues') public defaultValues: any,
    @Inject('sourceKeys') public sourceKeys: any,
    private sanitizer: DomSanitizer,
    private router: Router,
    private urlInfoService: UrlInfoService,
    private seo: SeoService,
    private localStorage: LocalStorageService,
    private appDataLayerService: AppDataLayerService,
    private digitalDataService: DigitalDataService,
    private dal: DalService,
    private http: HttpClient,
    private sessionStorage: SessionStorageService,
    private localeService: LocaleService,
    private bnIdle: BnNgIdleService,
    private cookieService: CookieService,
    private notification: NotificationService
  ) {}

  /**
   * validateAllFormFields -  To validate all the form-fiels
   */
  validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(get(formGroup, 'controls', {})).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * getFormattedDate -  To formatted the date based on year
   */
  getFormattedDate(dateTimeStamp: any): string {
    const date = new Date(dateTimeStamp);
    const year = date.getFullYear();

    if (isNaN(year)) {
      return dateTimeStamp;
    }

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return year + '-' + month + '-' + day + ' 00:00:01';
  }

  isBreakpointMatched(breakpoint: string): boolean {
    return this.window.matchMedia(breakpoint).matches;
  }

  tcmHtml(content: string, anchorTheme?: string): any {
    if (!content) {
      return content;
    }
    content = content.replace(
      '/' + this.tridionDefault.TRIDION_SITE + '/',
      '/web/'
    );
    if (anchorTheme) {
      content = content.replace('<a ', '<a style="color:' + anchorTheme + '" ');
    }
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  /**
   * scrollTop -  To scroll the windows
   */
  scrollTop(): void {
    document.body.scrollTop = 0;
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  /**
   * changeRoute - To redirect the path based on the appDefault
   */
  changeRoute(
    url: string,
    queryParams?: Params,
    queryParamsHandling: QueryParamsHandling = ''
  ): void {
    if (!url) {
      return;
    }
    const prefix = this.urlInfoService.getUrlPrefix();
    if (url.indexOf(prefix) < 0) {
      url = prefix + '/' + url;
    }
    this.router
      .navigate([url], {
        queryParams,
        queryParamsHandling,
      })
      .catch((err) => console.error('Failed to navigate:', err));
  }

  getInternalUrl(url: string): any {
    if (!url) {
      return;
    }
    const prefix = this.urlInfoService.getUrlPrefix();
    if (url.indexOf(prefix) < 0) {
      url = prefix + '/' + url;
    }
    return url;
  }

  getWebUrl(url: string): string {
    if (!url) {
      return '';
    }
    const prefix = this.urlInfoService.getUrlPrefix();
    if (url.indexOf(prefix) < 0) {
      url = prefix + url;
    }
    return defaultValues.WEB_APP_PATH + url;
  }

  /**
   * setPageSEO - To set Page SEO
   * @param content
   */
  setPageSEO(content: any): void {
    this.seo.setPageSEO(content);
  }

  getWebStoreUrl(url: string): string {
    return defaultValues.WEB_STORE_APP_PATH + url || '';
  }

  getDiningUrl(url: string): string {
    return defaultValues.DINING_APP_PATH + url || '';
  }

  /**
   * getOidcRedirectionUrl - To redirect the oidc page
   */
  getOidcRedirectionUrl(): string {
    const localeObj = this.localeService.getFromUrl();
    const language =
      localeObj?.language || defaultValues.DEFAULT_ROUTE_LANGUAGE;
    const country = localeObj?.country || defaultValues.DEFAULT_ROUTE_COUNTRY;
    const origin = this.window.location.origin;
    const defaultRedirectPath = [language, country, appRoutes.LOGIN].join('/');
    return origin + this.baseHref + defaultRedirectPath;
  }

  /**
   * initAnalytics - Google Analytics Initialization
   */
  initAnalytics(): void {
    if (
      !this.isDDLEnabled() &&
      this.appDefaults.ADOBE_HEADER_SCRIPT &&
      this.appDefaults.ADOBE_BODY_SCRIPT
    ) {
      this.digitalDataService.setAnalytics(
        this.appDefaults.ADOBE_HEADER_SCRIPT,
        this.appDefaults.ADOBE_BODY_SCRIPT
      );
    }
  }

  /**
   * notifyFormErrorEvent - To display notification
   * @param formName
   * @param error
   * @returns
   */
  notifyFormErrorEvent(formName: string, error: string): void {
    if (!this.isDDLEnabled()) {
      return;
    }
    this.digitalDataService.notifyFormEvent(formName, error);
  }

  notifySystemErrorEvent(effect: string, name?: string): void {
    if (!this.isDDLEnabled()) {
      return;
    }
    this.digitalDataService.notifySystemErrorEvent(effect, name);
  }

  /**
   * redirectToOidcApp - To redirect the oidc app home page
   */
  redirectToOidcApp(redirectUrl: string, ignoreRedirect?: boolean): void {
    const localeObj = this.localeService.getFromUrl();
    const language =
      localeObj?.language || defaultValues.DEFAULT_ROUTE_LANGUAGE;

    const country = localeObj?.country || defaultValues.DEFAULT_ROUTE_COUNTRY;

    const url = `${get(defaultValues, 'OIDC_API_BASEURL')}${get(
      defaultValues,
      'OIDC_REDIRECT_API'
    )}?culture=${language}-${country}&response_type=code id_token token&scope=openid default offline_access&client_id=${get(
      defaultValues,
      'OIDC_API_CLIENT_ID'
    )}&redirect_uri=${redirectUrl}&nonce=${
      Math.random().toString(36).substring(2) + Date.now().toString(36)
    }&state=web&ui_locales=${language}-${country}`;
    if (ignoreRedirect) {
      return;
    }
    this.changeUrl(url);
  }

  /**
   * redirectToOidcForget - To redirect the forgot oidc password page
   */
  redirectToOidcForget(redirectUrl: string): void {
    const localeObj = this.localeService.getFromUrl();
    const language =
      //this.localStorage.getItem('language') ||
      localeObj?.language;
    const country =
      //this.localStorage.getItem('country') ||
      localeObj?.country;

    const url = `${
      environment?.oidcRedirectApi + '/callback'
    }?culture=${language}-${country}&response_type=code id_token token&scope=openid default offline_access&client_id=${get(
      defaultValues,
      'OIDC_API_CLIENT_ID'
    )}&redirect_uri=${redirectUrl}&nonce=${
      Math.random().toString(36).substring(2) + Date.now().toString(36)
    }&state=web&ui_locales=${language}-${country}`;

    const ForgotPasswordURL =
      `${
        environment?.oidcBaseUrl + '/oidc/Account/ForgotPassword'
      }?ReturnUrl=` + encodeURIComponent(url);
    this.changeUrl(ForgotPasswordURL);
  }

  /**
   * redirectToOidcAppEndSession - To redirect the oidc End-Session page
   */
  redirectToOidcAppEndSession(
    redirectUrl: string,
    ignoreRedirect?: boolean
  ): any {
    const localeObj = this.localeService.getFromUrl();
    const language =
      //this.localStorage.getItem('language') ||
      localeObj?.language;
    const country =
      //this.localStorage.getItem('country') ||
      localeObj?.country;
    const id_token_hint = this.localStorage.getItem('OIDC_ID_TOKEN') || '';

    const url = `${get(defaultValues, 'OIDC_API_BASEURL')}${get(
      defaultValues,
      'OIDC_ENDSESSIOON_API'
    )}?culture=${language}-${country}&id_token_hint=${id_token_hint}&post_logout_redirect_uri=${redirectUrl}&ui_locales=${language}-${country}`;

    this.dal.oAuthLogout().then(() => {
      if (ignoreRedirect) {
        return;
      }
      this.changeUrl(url);
    });
  }

  /**
   * notifyFormSuccessEvent - To display success event
   * @param formName
   * @param message
   * @returns
   */
  notifyFormSuccessEvent(formName: string, message: string): void {
    if (!this.isDDLEnabled()) {
      return;
    }
    this.digitalDataService.notifyFormEvent(
      formName,
      message,
      FormEventType.Success
    );
  }

  notifyFilterApplied(name: string, value: string): void {
    if (!this.isDDLEnabled()) {
      return;
    }
    this.digitalDataService.notifyFilterApplied(name, value);
  }

  /**
   * isDDLEnabled - Enable DDL Event
   */
  isDDLEnabled(): boolean {
    return get(this.appDefaults, 'DIGITAL_DATA_ENABLED', false);
  }

  /**
   * initPageDDL - DDL
   * @param data
   * @returns
   */
  initPageDDL(data: any): void {
    if (!this.isDDLEnabled()) {
      return;
    }
    if (data) {
      this.appDataLayerService?.initDigitalDataLayer(data);
    }
  }

  getCardImageUrl(
    cardTypeImageUrls: Array<{
      cardType: string;
      imageUrlNo: string;
    }>,
    card: {
      issuer_code: string;
    },
    defaultImageUrl: string,
    pageLabels: any
  ): string {
    let imageUrlNo;

    cardTypeImageUrls.forEach((cardType) => {
      if (cardType.cardType == card.issuer_code) {
        imageUrlNo = cardType.imageUrlNo;
      }
    });

    return defaultImageUrl + get(pageLabels, `pmt-card-card${imageUrlNo}`, '');
  }

  /**
   * changeUrl - To change the URL
   * @param url
   * @returns
   */
  changeUrl(url: string): void {
    if (!url) {
      return;
    }
    this.window.location.href = url;
  }

  /**
   * setFormData - To set the formControl value in formdata
   */
  public setFormData(formData: any): void {
    this.formData = formData;
  }

  /**
   * getFormData - To get the formControl value in formdata
   */
  public getFormData(): any {
    return this.formData;
  }

  /**
   * loadPostal - load the city based on postalCode
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public loadPostal(postalPin: any) {
    const configUrl =
      this.relativeUrls.POSTAL_CODE + postalPin + '?character-set=kanji';
    return this.dal.httpRequest(configUrl, 'GET', {});
  }
  /**
   * getInstance
   * @returns
   */
  public getInstance(): any {
    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append('X-Source-Id', 'WEB-ACCOUNT');
    const configUrl = this.relativeUrls.GET_INSTANCES;
    return this.dal.httpRequest(configUrl, 'POST', {}, httpHeaders);
  }

  /**
   * loadPrefectureList - load prefecture list
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public loadPrefectureList() {
    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append('X-Source-Id', 'WEB-ACCOUNT');
    const url = this.relativeUrls.STATE;
    return this.dal
      .httpRequest(url, 'GET', {}, httpHeaders)
      .catch((err: any) => {
        console.error(err);
      });
  }

  /**
   * getRelativeUrl - replace the guestId in relativeurls
   */
  public getRelativeUrl(url: string, guestId: string): string {
    return url?.replace('{guestId}', guestId);
  }

  /**
   * postTravelPartyMembers - post the TRP Details
   */
  public postTravelPartyMembers(guestId: any, request: any): any {
    this.savedSourceId = this.getSourceId(this.sourceKeys?.WEB_CREATEEDITTRP);
    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append('X-Source-Id', this.savedSourceId);
    const loggedInUserToken = this.localStorage.getItem('oAuthToken');
    const ivUser = this.localStorage.getItem('oAuthLoginUserName');
    const localeObj = this.localeService.getFromUrl();
    const lang = localeObj?.language;
    const country = localeObj?.country;
    httpHeaders = httpHeaders.append('Accept-Language', `${lang}-${country}`);
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken}`
    );
    httpHeaders = httpHeaders.append('iv-user', ivUser);
    const options = {
      headers: httpHeaders,
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast    responseType: "json"
    };
    const url =
      environment.oidcBaseUrl +
      this.getRelativeUrl(this.relativeUrls.GET_TRAVEL_PARTY, guestId);
    return this.http.post(url, request, options);
  }

  /**
   * checkDate - check current date is greater than today date
   */
  public checkDate(inputDate: any, pageLabels: any): any {
    let errorMsg = '';
    const todayDate = new Date();

    if (inputDate.length == 8) {
      const year = inputDate.substring(0, 4);
      const month = inputDate.substring(4, 6);
      const day = inputDate.substring(6, 8);
      const inputString = year + '/' + month + '/' + day;
      const dateValue = new Date(inputString);
      const outputValue = this.isValidDate(year, month, day);
      if (dateValue > todayDate || outputValue == false) {
        return (errorMsg = pageLabels?.['errCode.E011']);
      }
    }
    if (inputDate.length > 0 && inputDate.length < 8) {
      return (errorMsg = pageLabels?.['errCode.E011']);
    }
    if (inputDate.length > 8) {
      return (errorMsg = pageLabels?.['errCode.E011']);
    }
    return errorMsg;
  }

  /**
   * isValidDate - To validate the month and date
   */
  public isValidDate(year: any, month: any, day: any): boolean {
    const d = new Date(year, month - 1, day);
    const beforeDate = new Date().getFullYear() - 200;
    if (beforeDate > d.getFullYear()) {
      return false;
    }
    if (beforeDate == d.getFullYear()) {
      const m = this.validDate(d.getMonth() + 1);
      const d1 = this.validDate(d.getDate());
      const monthValue = this.validDate(new Date().getMonth() + 1);
      const dateValue = this.validDate(new Date().getDate());
      if (m < monthValue) {
        return false;
      }
      if (m === monthValue && d1 < dateValue) {
        return false;
      }
    }

    if (
      d.getFullYear() == year &&
      d.getMonth() + 1 == month &&
      d.getDate() == day
    ) {
      return true;
    }

    return false;
  }

  public validDate(m: any): any {
    if (m < 10) {
      return '0' + m;
    } else {
      return m;
    }
  }

  /**
   * getEntitlements - To get the entitlement data
   */
  public getEntitlements(guestId: any): any {
    const loggedInUserToken = this.localStorage.getItem('oAuthToken');

    let httpHeaders = new HttpHeaders({});
    // httpHeaders = httpHeaders.append(
    //   .append('X-Resort-Area-Code',
    //   this.appDefaults.USJ
    // );
    const url =
      environment.oidcBaseUrl +
      this.getRelativeUrl(this.relativeUrls.GET_ENTITLEMENT, guestId);
    httpHeaders = httpHeaders
      .append('Authorization', `Bearer ${loggedInUserToken}`)
      .append('x-resort-area-code', this.appDefaults.USJ);
    const options = { headers: httpHeaders };
    return this.http.get<any>(url, options);
  }

  /**
   * redirectToLogin - Redirect to the oidc login Page
   */
  public redirectToLogin(data: any): any {
    if (environment?.oidcEnabled) {
      const redirectUri = this.getOidcRedirectionUrl();
      const LoginUrl = `${
        environment?.oidcBaseUrl + environment?.oidcRedirectApi + '?'
      }${new HttpParams({
        fromObject: {
          response_type: data.response_Type,
          scope: data.scope,
          client_id: environment?.oidcClientId,
          redirect_uri: redirectUri,
          nonce: data.nonce,
          state: data.state,
        },
      })}`;
      //this.window.location.href = LoginUrl;
      this.changeRoute(this.appRoutes.LOGIN, {
        loginURl: LoginUrl,
      });
    } else {
      this.changeRoute(this.appRoutes.LOGIN);
    }
  }

  /**
   * postauthLogin - Post the login based on the OAUTH
   */
  public postauthLogin(): any {
    const loggedInUserToken = JSON.parse(this.localStorage.getItem('authObj'));
    const jwtToken =
      this.localStorage.getItem('OIDC_ACCESS_TOKEN') ??
      this.localStorage.getItem('oAuthToken');
    const headers = new HttpHeaders({
      Authorization: `Bearer ${loggedInUserToken?.access_token}`,
      'guest-jwt': jwtToken,
    });
    const params = new HttpParams().set(
      'clientId',
      this.defaultValues.COMMERCE_CLIENT_ID
    );

    return this.http.post(
      environment.commerceApiUrl +
        this.relativeUrls.COMMERCE_API +
        this.relativeUrls.CUSTOMER_LOGIN,
      {},
      {
        headers,
        params,
      }
    );
  }

  /**
   * getB2COAuthToken - Post the commerce api token
   */
  public getB2COAuthToken(): any {
    const url = environment.commerceApiUrl + this.relativeUrls.CUSTOMER_TOKEN;
    const params = new HttpParams()
      .set('client_id', encodeURIComponent(environment.commerceClientId))
      .set('client_secret', encodeURIComponent(environment.commerceClientKeyID))
      .set('grant_type', this.appDefaults.COMMERCEAPI);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    return this.http.post<any>(url, params, { headers });
  }

  /**
   * getCoupons - get the coupons in commerce api
   */
  public getCoupons(guestId: any): any {
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };
    const url =
      environment.commerceApiUrl +
      this.relativeUrls.COMMERCE_API +
      this.relativeUrls.COUPON_URL +
      guestId;
    return this.http.get<any>(url, options);
  }

  /**
   * requestLayoutheader - get the header data from the tridion
   */
  requestLayoutheader(): any {
    const url =
      environment?.tridionBaseUrl +
      this.relativeUrls?.LAYOUTS +
      this.appDefaults?.LAYOUT_HEADER_DESKTOP;
    return this.http.get(url);
  }

  /**
   * requestLayoutfooter - get the footer data from the tridion
   */
  requestLayoutfooter(): any {
    const url =
      environment?.tridionBaseUrl +
      this.relativeUrls?.LAYOUTS +
      this.appDefaults?.LAYOUT_FOOTER;
    return this.http.get(url);
  }

  /**
   * requestImageUrl - get the logo data from the tridion
   */
  requestImageUrl(data: any): string {
    const url = environment?.tridionBaseUrl + data;
    return url;
  }

  /**
   * fetchCoupons - get the coupons data from the tridion
   */
  fetchCoupons(couponCode?: any): any {
    const loggedInUserToken = this.localStorage.getItem('contentOidcToken');
    const localeObj = this.localeService.getFromUrl();
    const lang = localeObj?.language;
    const country = localeObj?.country;
    const params = {
      ids: couponCode,
      extendcl: true,
      resolvemultimedia: true,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': `${lang}-${country}`,
      Authorization: `Bearer ${loggedInUserToken}`,
    });
    const url = `${environment.contentApiBaseUrl}/resort-areas/usj/content`;
    return this.http.get<any>(url, { headers, params });
  }

  /**
   * fetchTicketPictures - To get the ticket Picture from the tridion
   */
  fetchTridionContent(productName: string): any {
    const url = `${environment?.tridionBaseUrl || ''}/${
      environment?.tridionSite || ''
    }/${defaultValues.DEFAULT_ROUTE_LANGUAGE || ''}/${
      defaultValues.DEFAULT_ROUTE_COUNTRY || ''
    }${this.getContentIdChange(this.relativeUrls?.GET_CONTENT, productName)}`;
    return this.http.get(url);
  }

  /**
   * getContentIdChange - replace the url based on coupons & Tickets
   */
  getContentIdChange(url: string, contentId: string): any {
    // Replace the placeholder {content-Id} in the URL with the provided dynamic value
    return url.replace('{content-Id}', contentId);
  }

  /**
   * addCoupons - To add the coupons
   */
  public addCoupons(guestId: any, request: any): Observable<any> {
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders, observe: 'response' as 'body' };
    const url =
      environment.commerceApiUrl +
      this.relativeUrls.COMMERCE_API +
      this.relativeUrls.COUPON_URL +
      guestId;
    return this.http.put<any>(url, request, options).pipe(
      catchError((error: any) => {
        if (error?.status === 200) {
          return of({ status: error?.status });
        } else {
          return throwError(error);
        }
      })
    );
  }

  /**
   * onClickRedirectWTS - To redirect to WTS
   * @param productCode
   * @param redirectUrl
   * @param isSameTab
   * @param isLogout
   */
  public onClickRedirectWTS(
    productCode?: any,
    redirectUrl?: any,
    isSameTab?: boolean,
    isLogout?: boolean,
    redirect_page?: string
  ): any {
    const USJWTSLoginAuthConfig = this.getUSJWTSLoginAuthConfig();

    if (productCode) {
      USJWTSLoginAuthConfig['redirect_product_code'] = productCode;
    }
    if (redirectUrl) {
      USJWTSLoginAuthConfig['redirectUrl'] = btoa(redirectUrl);
    }
    if (isLogout) {
      USJWTSLoginAuthConfig['isLogout'] = isLogout;
    }
    if (redirect_page) {
      USJWTSLoginAuthConfig['redirect_page'] = redirect_page;
    }
    //TO DO remove this comment
    //use https://localhost:4200/ for localhost
    const LoginUrl =
      environment.wtsHostBaseUrl +
      `spa_redirect?${new HttpParams({
        fromObject: USJWTSLoginAuthConfig,
      })}`;
    if (isSameTab && isLogout) {
      window.open(LoginUrl, 'Popup', 'width=100,height=100');
    } else if (!isSameTab) {
      window.open(LoginUrl);
    } else {
      this.window.location.href = LoginUrl;
    }
  }
  /**
   * onclick redicting to WTS page
   */

  getUSJWTSLoginAuthConfig(): any {
    const commAuthObj = localStorage.getItem('loginAuthToken') || '';
    const USJWTSLoginAuthConfig: any = {
      client_id: environment?.oidcClientId,
      id_token: localStorage.getItem('OIDC_ID_TOKEN') || '',
      access_token:
        this.sessionStorage.getItem('OIDC_ACCESS_TOKEN') ||
        this.localStorage.getItem('oAuthToken') ||
        '',
      expires_in:
        this.sessionStorage.getItem('OIDC_EXPIRES_IN') ||
        this.localStorage.getItem('OIDC_EXPIRES_IN') ||
        '',
      commerce_auth_obj: btoa(commAuthObj),
    };
    return USJWTSLoginAuthConfig;
  }

  /**
   * clearAllCookies - To remove other cookies if present
   * @param isDateChangeFlow
   * @param isAnnualPassFlow
   * @param isCouponFlow
   * @param isAccountCreationFlow
   */
  clearAllCookies(
    isDateChangeFlow: boolean,
    isAnnualPassFlow: boolean,
    isCouponFlow: boolean,
    isAccountCreationFlow: boolean
  ): void {
    if (!isAnnualPassFlow) {
      this.cookieService.delete(
        'apTicketInformation',
        '/',
        `${environment.cookieSubDomain}`
      );
      this.cookieService.delete(
        'isRenewalProduct',
        '/',
        `${environment.cookieSubDomain}`
      );
    }
    if (!isDateChangeFlow) {
      this.cookieService.delete(
        'isDateChangeProduct',
        '/',
        `${environment.cookieSubDomain}`
      );
      this.cookieService.delete(
        'dateChangeCookie',
        '/',
        `${environment.cookieSubDomain}`
      );
    }
    if (!isCouponFlow) {
      this.cookieService.delete(
        'COUPON_NAME',
        '/',
        `${environment.cookieSubDomain}`,
        true,
        'None'
      );
      this.cookieService.delete(
        'COUPON_CODE',
        '/',
        `${environment.cookieSubDomain}`
      );
      this.cookieService.delete(
        'COUPON_INFO',
        '/',
        `${environment.cookieSubDomain}`
      );
      this.cookieService.delete(
        'COUPON_TYPE',
        '/',
        `${environment.cookieSubDomain}`
      );
      this.cookieService.delete(
        'COUPON_DETAILS',
        '/',
        `${environment.cookieSubDomain}`
      );
    }
    if (!isAccountCreationFlow) {
      this.cookieService.delete(
        'cartCreateAccount',
        '/',
        `${environment.cookieSubDomain}`
      );
    }
  }

  /**
   * formattedDate -  formatting visitation date
   */
  formattedDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }
  /**
   * getOrderList - to fetch the order list
   * @returns
   */
  public getOrderList(getOrderList?: any): any {
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };
    const url =
      environment.commerceApiUrl +
      this.relativeUrls.COMMERCE_API +
      this.relativeUrls.GET_ORDER_HISTORY.replace(
        '{userId}',
        'current'
      ).replace('{pageNumber}', getOrderList);
    return this.http.get<any>(url, options);
  }

  /**
   * getOrderDetails - To get the orders from the commerce api
   */
  public getOrderDetails(orderCode: any): any {
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };
    const localeObj = this.localeService.getFromUrl();
    const lang = localeObj?.language;
    const params = new HttpParams().set('lang', lang);
    const url =
      environment.commerceApiUrl +
      this.relativeUrls.COMMERCE_API +
      this.relativeUrls.GET_ORDER_DETAILS.replace(
        '{userId}',
        'current'
      ).replace('{code}', orderCode);
    return this.http.get<any>(url, { ...options, params });
  }

  /**
   * getGuestAffiliatedProducts - To get the affliated products from commerce api
   */
  public getGuestAffiliatedProducts(guestId: any): any {
    const localeObj = this.localeService.getFromUrl();
    const language =
      localeObj?.language || defaultValues?.DEFAULT_ROUTE_LANGUAGE;
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };
    const url = `${environment.commerceApiUrl}${this.relativeUrls.COMMERCE_API}/customers/${guestId}/affiliatedProducts?lang=${language}`;
    return this.http.get<any>(url, options);
  }

  /**
   * setCommonData - To set the common data from service
   */
  public setCommonData(data: any): any {
    this.data = data;
  }

  /**
   * getCommonData - To get the common data from service
   */
  public getCommonData(): any {
    return this.data;
  }
  /**
   * setCommonData - To set the common data from service
   */
  public setOrdersData(data: any): any {
    this.data = data;
  }

  /**
   * getCommonData - To get the common data from service
   */
  public getOrdersData(): any {
    return this.data;
  }
  /**
   * getProgressBarData - To get progress bar data
   */
  public getProgressBarData(): any {
    const loggedInUserToken = this.localStorage.getItem('contentOidcToken');
    const localeObj = this.localeService.getFromUrl();
    const lang = localeObj?.language;
    const country = localeObj?.country;
    const params = {
      ids: 'wts-progress-bar',
      extendcl: true,
      resolvemultimedia: true,
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': `${lang}-${country}`,
      Authorization: `Bearer ${loggedInUserToken}`,
    });
    const url = `${environment.contentApiBaseUrl}/resort-areas/usj/content`;
    return this.http.get<any>(url, { headers, params });
  }

  /**
   * getRelativeUrls - replace the entitlement id on relativeUrls
   */
  public getRelativeUrls(url: string, ticketNo: string): string {
    return url.replace('{entitlementId}', ticketNo);
  }

  /**
   * setTravelPartyMemberData - set the TRP Data
   */
  public setTravelPartyMemberData(data: any): any {
    this.travelPartyData = data;
  }

  /**
   * getTravelPartyMemberData - get the TRP Data
   */
  public getTravelPartyMemberData(): any {
    return this.travelPartyData;
  }

  /**
   * formatDate - To format date YYYYMMDD
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  public formatDate(inputDate: string): string {
    const year = inputDate?.substr(0, 4);
    const month = inputDate?.substr(4, 2);
    const day = inputDate?.substr(6, 2);
    if (!year || !month || !day) {
      return '';
    }
    return `${year}-${month}-${day}`;
  }
  invokeFireEvent(
    eventName: string,
    eventAction: string,
    type: string,
    eventPoints: string,
    timeStamp: string,
    effect: string,
    eventTargetUrl: string,
    primaryCategory?: string,
    subCategory1?: string
  ): void {
    const eventInfo = this.digitalDataService.getEventInfoInstance(
      eventName,
      eventAction,
      type,
      eventPoints,
      timeStamp,
      effect,
      eventTargetUrl
    );
    const eventCategory = this.digitalDataService.getEventCategoryInstance(
      get(
        this.window,
        'digitalData.page.category.primaryCategory',
        primaryCategory
      ),
      get(this.window, 'digitalData.page.category.subCategory1', subCategory1)
    );

    const eventData = this.digitalDataService.getEventInstance(
      eventInfo,
      eventCategory
    );
    this.digitalDataService.fireEvent(eventData);
    this.fireGTMPushEvent();
  }

  fireGTMPushEvent(): void {
    const digitalData: any = this.window['digitalData'] ?? {};
    const dataLayerInfo: any[] = this.window['dataLayer'] ?? [];
    const index = dataLayerInfo.findIndex((obj) =>
      Object.keys(obj).includes('page')
    );
    if (index !== -1) {
      this.window['dataLayer'][index] = digitalData;
    }
  }
  /**
   * setUser - to set user info at DDL
   */
  setUser(): void {
    const locale = this.localeService.getFromUrl();
    const isReturnVisitor = this.localStorage.getItem('returnVisitor') || '';
    this.profile = {
      profileInfo: {
        language: locale.language,
        profileID: this.localStorage.getItem('OAUTH_LOGIN_GID'),
        marketingId: this.localStorage.getItem('marketing_id'),
        returningStatus: isReturnVisitor === 'true' ? 'Returning' : 'New',
        type: this.localStorage.getItem('USER_PROFILE_TYPE'),
      },
    };
    const user: IDigitalDataUser = {
      segment: '',
      instanceID: '',
      isAuthenticated: CustomBoolean.True,
      daysSinceLastVisit: 0,
      geography:
        this.localStorage.getItem('USER_GEOGRAPHY') ||
        this.cookieService.get('GEO_REGION_CODE') ||
        '',
      idAAM: '',
      idECMID: '',
      idGAID: '',
      idGCLID: '',
      returning30Days: CustomBoolean.False,
      returning90Days: CustomBoolean.False,
      sessionPOO: {
        country:
          this.localStorage.getItem('country') ||
          this.cookieService.get('GEO_COUNTRY_CODE') ||
          '',
        region: this.cookieService.get('GEO_REGION_CODE') || '',
        city: this.cookieService.get('GEO_CITY') || '',
      },
      age: '',
      gender: '',
      profile: this.profile,
    };
    this.digitalDataService?.setUser(user);
  }

  /**
   * setIdleTimeout - Idle user timeout
   */
  setIdleTimeout(expires_in: any): void {
    const expiresInNumber: number = parseInt(expires_in, 10);
    if (localStorage.getItem('oAuthLogin') === 'true') {
      this.bnIdle.startWatching(expiresInNumber).subscribe((res) => {
        if (res) {
          localStorage.clear();
          const redirectUrl = this.getOidcRedirectionUrl();
          this.redirectToOidcAppEndSession(redirectUrl);
        }
      });
    }
  }

  /**
   * getSourceId - Get Source Id based on the language
   */
  getSourceId(sourceIdKey: string, language = ''): string {
    if (language === '') {
      language = this.localeService.getFromStorage().language;
    }
    return get(
      this.tridionDefault.TRIDION_SITE === environment.tridionSite
        ? SourceIdKey
        : SourceIdKey,
      language + '.' + sourceIdKey
    );
  }

  /**
   * setResolvedData - set the Entitlement data from the resolver
   */
  // setResolvedData(data: any): void {
  //   if (data?.entitlements) {
  //     this.entitlementData = data?.entitlements;
  //     this.mediaData = data.media;
  //   } else {
  //     this.entitlementData = data;
  //   }
  // }
  setResolvedData(data: any): void {
    if (data?.entitlements) {
      this.entitlementData = data.entitlements;
      this.mediaData = data.media;
    } else {
      this.entitlementData = data;
    }
    this.resolvedDataSubject.next(this.entitlementData);
  }

  /**
   * getResolvedData - GET the Entitlement data from the resolver
   */
  getResolvedData(): any {
    return this.entitlementData;
  }

  /**
   * getResolvedMediaData - GET the Media data from the resolver
   */
  getResolvedMediaData(): any {
    return this.mediaData;
  }

  /**
   * setResolvedData - set the Entitlement data from the resolver
   */
  setResolvedPageData(data: any): void {
    this.pagelabelData = data.elements;
  }

  /**
   * getResolvedData - GET the Entitlement data from the resolver
   */
  getResolvedPageData(): any {
    return this.pagelabelData;
  }

  /**
   * getErrorCodes - to display error messages
   * @param pageLabels - contains all error codes
   * @param errorMsg - contains error messages
   */
  handleCommerceErr(pageLabels: any, errorMsg: any): any {
    pageLabels?.[errorMsg]
      ? this.notification?.show(pageLabels?.[errorMsg])
      : this.notification?.show(this.pagelabelData?.['errCode.E019']);
  }

  /**
   * emitResponse - to emit event
   * @param response
   */
  emitResponse(response: any): any {
    this.responseReceived.emit(response);
  }

  /**
   * getResponseObservable - to get response from observable
   * @returns
   */
  getResponseObservable(): any {
    return this.responseReceived.asObservable();
  }

  /**
   * setTravelMembersDetails - set travel party
   * @param data
   */
  setTravelMembersDetails(data: any[]): void {
    this.travelMembersDetails = data;
  }

  /**
   * getTravelMembersDetails - get travel party members
   * @returns
   */
  getTravelMembersDetails(): any[] {
    return this.travelMembersDetails;
  }

  /**
   * setAccDelete - To set the  deleteAcc Value
   */
  public setAccDelete(): void {
    this.isDeleteAccount = true;
  }

  /**
   * getAccDelete - To get the deleteAcc Value
   */
  public getAccDelete(): any {
    return this.isDeleteAccount;
  }

  /**
   * loadTridionUtility - load tridion data
   * @param pageContainer
   * @param displayData
   * @returns
   */
  loadTridionUtility(pageContainer: ViewContainerRef, displayData: any): void {
    if (!pageContainer) return;

    pageContainer.clear();
    if (Array.isArray(displayData)) {
      displayData.forEach((cp: any) => {
        this.render(cp, pageContainer);
      });
    }
  }

  private render(componentData: any, viewContainerRef: ViewContainerRef): void {
    const componentInstance: any = viewContainerRef.createComponent(
      GdsTcmLoaderComponent
    );
    componentInstance.instance.data = componentData;
  }

  /**
   * getFormattedDateOnLocale - It will format the date based on locale
   */

  public getFormattedDateOnLocale(dateValue: any, weekday?: boolean): string {
    if (dateValue) {
      const localeObj = this.localeService.getFromUrl();
      const _date = new Date(dateValue);
      const luxTime = DateTime.fromObject(
        {
          year: _date.getUTCFullYear(),
          month: _date.getUTCMonth() + 1,
          day: _date.getUTCDate(),
        },
        {
          locale: localeObj?.language,
        }
      );
      if (weekday) {
        return `${luxTime.toLocaleString(DateTime.DATE_MED)} (${
          luxTime.weekdayShort
        })`;
      } else {
        return luxTime.toLocaleString(DateTime.DATE_MED);
      }
    }
    return '';
  }

  /**
   * getPageLabels - It will get the PageLabel Data
   */

  getPageLabels(): any {
    const loggedInUserToken = this.localStorage.getItem('contentOidcToken');
    const localeObj = this.localeService.getFromUrl();
    const lang = localeObj?.language;
    const country = localeObj?.country;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': `${lang}-${country}`,
      Authorization: `Bearer ${loggedInUserToken}`,
    });
    const url = `${environment.contentApiBaseUrl}/${this.relativeUrls.GET_ACCOUNT}`;
    return this.http.get<any>(url, { headers });
  }

  /**
   * closeAppWebView - It will clear the cookies for delete Acc
   */
  closeAppWebView(): void {
    if (this.window.webkit && this.window.webkit.messageHandlers) {
      try {
        this.window.webkit.messageHandlers.close.postMessage();
      } catch (error) {
        console.error(error);
      }
    }
    // if it fails we can still head back to login.
    this.changeRoute(appRoutes.LOGIN, { signoff: true }, 'merge');
  }

  /**
   * setLinkRoute - set linkTickets whether its route from dashboard or entitlement
   */
  setLinkRoute(data: any): any {
    this.linkTicketsData = data;
  }

  /**
   *  getLinkRoute - get linkTickets whether its route from dashboard or entitlement
   */
  getLinkRoute(): any {
    return this.linkTicketsData;
  }

  /**
   * getBaseProduct - get base product
   * @param productName
   * @returns
   */
  public getBaseProduct(productName: any): any {
    const localeObj = this.localeService.getFromUrl();
    const language =
      localeObj?.language || defaultValues?.DEFAULT_ROUTE_LANGUAGE;
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };
    const url = `${environment.commerceApiUrl}${this.relativeUrls.COMMERCE_API}/products/${productName}?lang=${language}&curr=JPY`;
    return this.http.get<any>(url, options);
  }

  /**
   * generateQRMediaCode - generateMediaCode from the TRP
   * @param guestId - get the guestId
   * @returns
   */
  generateQRMediaCode(guestId: string): any {
    const matchedMediaObjects = _.filter(
      this.mediaData,
      (obj: any) => obj.guest_id === guestId
    );
    const uniqueGuestIds = new Set<string>();
    const uniqueMediaArray: any[] = [];
    _.forEach(matchedMediaObjects, (mediaObj: any) => {
      const guestId = mediaObj.guest_id;
      if (!uniqueGuestIds.has(guestId)) {
        uniqueMediaArray.push(mediaObj);
        uniqueGuestIds.add(guestId);
      }
    });
    return (this.mediaId = uniqueMediaArray.map((obj: any) => obj.media_code));
  }

  /**
   * setIsAuthenticated - set the authToken data
   * @param value - pass the authToken data
   */
  setIsAuthenticated(value: boolean): void {
    this.isAuthenticatedSubject.next(value);
  }

  /**
   * getIsAuthenticated - get the authToken data
   * @returns
   */
  getIsAuthenticated(): Observable<boolean> {
    return this.isAuthenticatedSubject.asObservable();
  }
  /**
   * postInquiryWebForm - create the web inquiry form
   * @param request - pass the request params
   * @returns
   */
  postInquiryWebForm(request: any): any {
    const localeObj = this.localeService.getFromUrl();
    const lang = localeObj?.language;
    const country = localeObj?.country;
    const loggedInUserToken = this.localStorage.getItem('oAuthToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'OData-MaxVersion': '4.0',
      'OData-Version': '4.0',
      'Accept-Language': `${lang}-${country}`,
      Authorization: `Bearer ${loggedInUserToken}`,
    });

    const options = {
      headers: httpHeaders,
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast    responseType: "json"
    };
    const url = environment.crewToolsApi + this.relativeUrls.POST_INQUIRY_URL;
    return this.http.post(url, request, options);
  }
  /**
   * lostFoundWebForm - create the lostFoundWebForm
   * @param request - pass the request params
   * @returns
   */
  lostFoundWebForm(request: any): any {
    const localeObj = this.localeService.getFromUrl();
    const lang = localeObj?.language;
    const country = localeObj?.country;
    const loggedInUserToken = this.localStorage.getItem('oAuthToken');
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'OData-MaxVersion': '4.0',
      'OData-Version': '4.0',
      'Accept-Language': `${lang}-${country}`,
      Authorization: `Bearer ${loggedInUserToken}`,
    });

    const options = {
      headers: httpHeaders,
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast    responseType: "json"
    };
    const url =
      environment.crewToolsApi + this.relativeUrls.POST_LOST_FOUND_URL;
    return this.http.post(url, request, options);
  }
  setIconArrayData(data: any[]): void {
    this.iconArrayData = data;
  }

  getIconArrayData(): Observable<any[]> {
    return of(this.iconArrayData);
  }

  toRemoveExpiredTickets(
    entitlementArray: any[],
    pageLabels: any
  ): { removedTicketArray: any[]; removedCount: number } {
    let removedCount = 0; // Initialize a counter for removed tickets

    const removedTicketArray =
      entitlementArray?.filter((element: any) => {
        const statusKey = `MS.ticketStatus.${element?.ticket_status}`;
        const statusValue = TICKET_STATUS?.[statusKey];
        const shouldKeep = !(pageLabels && statusValue); // Determine whether to keep or remove the element

        if (!shouldKeep) {
          removedCount++; // Increment the count if the element is removed
        }

        return shouldKeep; // Return true to keep objects where either pageLabels or statusValue is falsy
      }) ?? [];

    return { removedTicketArray, removedCount };
  }
  /**
   * sortEntitlementTickets - sort the tickets studio/annual/express/extras and along with latest dates
   * @param ticketData - pass the entitlement tickets
   * @returns
   */
  sortEntitlementTickets(ticketData: any): any {
    return ticketData?.sort((a: any, b: any) => {
      // Compare entry dates first
      const entryDateComparison =
        new Date(a.valid_from).getTime() - new Date(b.valid_from).getTime();
      if (entryDateComparison !== 0) {
        return entryDateComparison; // Sort by closest entry date first
      }

      // If entry dates are the same, compare by product category names
      const getPriority = (categoryCode: any): number => {
        switch (categoryCode) {
          case this.globalDefault.COMM_STUDIO_PASS:
            return 0;
          case this.globalDefault.COMM_ANNUAL_PASS:
            return 1;
          case this.globalDefault.COMM_EXPRESS_PASS:
            return 2;
          default:
            return 3; // Assuming Extras or any other category comes after Express Pass
        }
      };

      // Get the product category names for the tickets
      const productCategoryNameA = a?.categoryCode;
      const productCategoryNameB = b?.categoryCode;

      // Get the priority of the product category for both tickets
      const priorityA = getPriority(productCategoryNameA);
      const priorityB = getPriority(productCategoryNameB);

      // Sort by product category name if entry dates are the same
      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      } else {
        // Ensure stable sorting if priorities are the same (though not likely in this case)
        return 0;
      }
    });
  }

  public getIsDateChangeable(ticketIds: string): any {
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );
    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };

    // Split the comma-separated string into an array, map over it, and format the IDs
    const formattedTicketIds = ticketIds
      .split(',')
      .map((id: string) => `ticketIds=${id}`)
      .join('&');

    const url = `${environment.commerceApiUrl}${this.relativeUrls.COMMERCE_API}/ticket/fetchPolicy?${formattedTicketIds}&lang=ja&curr=JPY`;
    return this.http.get<any>(url, options);
  }

  OIDCTokenAnonymous(): Promise<any> {
    const url = environment?.inquiryToken + environment?.oidcAppFlowPath;
    const params = new HttpParams()
      .set('client_id', encodeURIComponent(environment?.oidcInquiryClientId))
      .set(
        'client_secret',
        encodeURIComponent(environment?.oidcInquiryClientKeyID)
      )
      .set('grant_type', encodeURIComponent('client_credentials'));

    const headersInfo: any = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const headers = new HttpHeaders(headersInfo);

    return this.http
      .post(url, params.toString(), { headers })
      .toPromise()
      .then((res: any) => {
        localStorage.setItem('oAuthToken', res?.access_token);
      })
      .catch((error) => {
        console.error('Error initializing app:', error);
      });
  }
  public fetchActiveCart(): any {
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };
    const url =
      environment.commerceApiUrl +
      this.relativeUrls.COMMERCE_API +
      this.relativeUrls.GET_CARTS;

    return this.http.get<any>(url, options);
  }
  prependImageUrl(url: string): string {
    if (environment?.prependImageBaseUrl) {
      return `${environment?.tridionBaseUrl}${url}`;
    } else {
      return url;
    }
  }
  passwordValidator(control: any): any {
    const value = control.value || '';
    // Check for spaces
    if (/\s/.test(value)) {
      return { spaceError: true };
    }
    return null;
  }
  /**
   * setGetCartsData - set the GetCarts data from the API
   */
  setGetCartsData(data: any): void {
    this.getCartsData = data;
  }

  /**
   * getResolvedData - GET the GetCarts data from the API
   */
  getGetCartsData(): any {
    return this.getCartsData;
  }

  /**
   * setCurrentCartsData - set the CurrentCart data from the API
   */
  setCurrentCartsData(data: any): void {
    this.getCurrentCartData = data;
  }

  /**
   * getCurrentCartsData - GET the CurrentCart data from the API
   */
  getCurrentCartsData(): any {
    return this.getCurrentCartData;
  }
  /**
   * setCurrentCartErrorMsg - set the CurrentCart data from the API
   */
  setCurrentCartErrorMsg(data: any): void {
    this.getCurrentCartError = data;
  }

  /**
   * getCurrentCartErrorMsg - GET the CurrentCart data from the API
   */
  getCurrentCartErrorMsg(): any {
    return this.getCurrentCartError;
  }
  /**
   * setIsCart - set the cart data
   * @param value - pass the cart data
   */
  setIsCart(value: boolean): void {
    this.isCartSubject.next(value);
  }

  /**
   * getIsAuthenticated - get the authToken data
   * @returns
   */
  getIsCart(): Observable<boolean> {
    return this.isCartSubject.asObservable();
  }
  /**
   * getCartItems - call the current cart API if the getCarts is there
   */
  getCartItems(): any {
    this.fetchActiveCartItems().subscribe(
      (items: any) => {
        this.setGetCartsData(items);
        this.cartItemLength = items.carts.length;
        if (this.cartItemLength === 1 && items?.carts?.[0]?.saveTime) return;
        if (this.cartItemLength > 0) {
          this.fetchActiveCart().subscribe(
            (cartItems: any) => {
              this.setCurrentCartsData(cartItems);
              this.setIsCart(true);
              if (cartItems?.entries?.length >= 0) {
                const oldValue = localStorage.getItem('CART_ITEM_COUNT');
                cartItems.entries.forEach((entry: any) => {
                  this.quantity += entry?.quantity;
                });
                const newValue = this.quantity;

                localStorage.setItem('CART_ITEM_COUNT', newValue);
                const event = new CustomEvent('storage', {
                  detail: {
                    key: 'CART_ITEM_COUNT',
                    newValue,
                    oldValue,
                  },
                });
                window.dispatchEvent(event);
              } else {
                localStorage.setItem('CART_ITEM_COUNT', '0');
              }
            },
            (error: any) => {
              localStorage.setItem('CART_ITEM_COUNT', '0');
              if (error?.status === this.globalDefault.ERROR_401) {
                this.setUser();
                localStorage.clear();
                sessionStorage.clear();
                this.cookieService.deleteAll('/', environment.cookieSubDomain);
                const redirectUrl = this.getOidcRedirectionUrl();
                this.redirectToOidcAppEndSession(redirectUrl);
              } else {
                const errorCode = error?.errors?.[0]?.errorCode
                  ? error?.errors?.[0]?.errorCode?.replace(/-/g, '')
                  : error?.errors?.[0]?.type;
                this.commerceGenericErrorMessage = errorCode;
                this.setCurrentCartErrorMsg(this.commerceGenericErrorMessage);
              }
            }
          );
        }
      },
      (errors: any) => {
        const errorCode = errors?.errors?.[0]?.errorCode
          ? errors?.errors?.[0]?.errorCode?.replace(/-/g, '')
          : errors?.errors?.[0]?.type;
        this.commerceGenericErrorMessage = errorCode;
        this.setCurrentCartErrorMsg(this.commerceGenericErrorMessage);
      }
    );
  }
  public fetchActiveCartItems(): any {
    const localeObj = this.localeService.getFromUrl();
    const language = localeObj?.language;
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };
    const url = `${environment.commerceApiUrl}${this.relativeUrls.COMMERCE_API}${this.relativeUrls.GET_CART_ITEM}?lang=${language}&curr=JPY`;
    // const url =
    //   environment.commerceApiUrl +
    //   this.relativeUrls.COMMERCE_API +
    //   this.relativeUrls.GET_CART_ITEM;

    return this.http.get<any>(url, options);
  }
  /**
   * removeHyphensFromCommerceAPI - removes hyphens from commerce error code and returns the corresponding page label error message
   */
  removeHyphensFromCommerceAPI(
    errorCode: string,
    pageLabels: any,
    globalDefault: any
  ): string {
    const removedHyphensString = errorCode?.replace(/-/g, '');
    return pageLabels?.[globalDefault.ERROR_CODE + removedHyphensString]
      ? pageLabels[globalDefault.ERROR_CODE + removedHyphensString]
      : pageLabels?.['errCode.E019'];
  }
}
