<div class="container">
  <gds-spacer classes="spacer-120"></gds-spacer>
  <gds-heading
    tagtype="h2"
    [data]="{
        heading: pageLabels?.['unsubscribe.heading']
    }"
  ></gds-heading>
  <gds-spacer classes="spacer-50"></gds-spacer>
  <gds-body
    [innerHtml]="
  pageLabels?.['unsubscribe.subtext1']"
    class="B2-W2"
  ></gds-body>

  <gds-spacer classes="spacer-50"></gds-spacer>
  <div [formGroup]="subscriptionForm">
    <gds-input
      id="email"
      formControlName="email"
      [errors]="emailErrorMsgInfo"
      ngDefaultControl
      [data]="{
      primaryLabel: pageLabels?.['unsubscribe.emailAddress'],
      supportingText: '',
      type: 'email',
      altText: 'input of surname',
      ariaText: '',
      required: true
    }"
      onKeyPress=" if( this.value.length >= 40 ) return false;"
      (blurChange)="getErrorMessageInfo($event)"
    >
    </gds-input>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <gds-button
      [data]="{
      type: 'primary',
      href: '',
      label: pageLabels?.['unsubscribe.sendCTA'],
      disabled: subscriptionForm.valid ?'false' : 'true',
      target: ''
    }"
      (buttonClicked)="navigateTo()"
    >
    </gds-button>
  </div>

  <gds-spacer classes="spacer-120"></gds-spacer>
</div>
