import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SpinnerService } from '@upr-web-primer/spinner';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-birthday-discount',
  templateUrl: './birthday-discount.component.html',
  styleUrls: ['./birthday-discount.component.scss'],
})
export class BirthdayDiscountComponent implements OnInit {
  pageLabels: any;
  @ViewChild('birthDayContainer', { read: ViewContainerRef, static: true })
  birthDayContainer!: ViewContainerRef;
  constructor(
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    private commonUtilityService: CommonUtilityService,
    private spinner: SpinnerService,
    private title: Title
  ) {
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.birthdayDiscount']);
  }

  /**
   * ngOnInit - Calls Fetch the content from Tridion
   */

  ngOnInit(): void {
    this.getContent();
  }

  /**
   * goToPage - It will redirect to the specified route
   * @param type : specify which route to navigate
   */

  goToPage(type: any): any {
    switch (type) {
      case this.globalDefault.BIRTHDAY_DISCOUNT:
        this.commonUtilityService.changeRoute(this.appRoutes.DASHBOARD);
        break;
    }
  }

  /**
   * goToHomepage - It will redirect to Dashboard page
   */

  goToHomepage(): void {
    this.commonUtilityService.changeRoute(this.appRoutes.DASHBOARD);
  }

  /**
   * getContent - It will call the tridion API by passing
   * the content id as 'birthday-content' and getting response for
   * initialize the UI
   */
  getContent(): void {
    this.spinner.show();
    this.commonUtilityService
      .fetchCoupons('wts-birthday-content')
      ?.subscribe((data: any) => {
        if (data && data?.length > 0)
          this.initializeUI(data?.[0].data?.elements);
      });
  }

  /**
   * initializeUI - Dynamically rendering the UI from GDS TCM loader by passing the response to GDS TCM loader
   * UI rendering happens by calling loadTridionUtility method
   */
  initializeUI(elements: any): void {
    this.birthDayContainer?.clear();
    this.spinner.hide();
    this.commonUtilityService.loadTridionUtility(
      this.birthDayContainer,
      elements
    );
  }
}
