import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DigitalDataService } from '@upr-web-primer/digital-data';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { WINDOW } from '@upr-web-primer/window';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent implements OnInit, AfterViewInit {
  pageLabels: any;
  @ViewChild('deleteConfirmModalContent')
  deleteConfirmModalContent!: ElementRef;
  savedSourceId: string;

  constructor(
    private commonUtilityService: CommonUtilityService,
    private appDataService: AppDataService,
    private guestsService: MsGuestsService,
    @Inject('sourceKeys') public sourceKeys: any,
    @Inject(WINDOW) public window: any,
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    private cookieService: CookieService,
    private digitalDataService: DigitalDataService,
    private title: Title
  ) {}
  /**
   * ngAfterViewInit - initial methods are called
   */
  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(
      this.deleteConfirmModalContent,
      164
    );
  }
  /**
   * onResize - Resize the fullscreen modal popup
   */
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(
      this.deleteConfirmModalContent,
      164
    );
  }
  /**
   * ngOnInit - Initial method called , pageLabels fetch from tridion
   */
  ngOnInit(): void {
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
  }
  /**
   * onSubmit - when user cleck on delete button api call
   * @param event - to pass the button action
   */
  onSubmit(event: any): void {
    const guestId = this.guestsService.helper.getGuestId();
    this.savedSourceId = this.commonUtilityService?.getSourceId(
      this.sourceKeys?.WEBACCOUNTDELETE
    );
    event.target?.data.action === this.pageLabels?.['deleteAccConf.confirm']
      ? this.guestsService.account
          .unenrollAccount(guestId, this.savedSourceId)
          .then(() => {
            this.commonUtilityService.invokeFireEvent(
              'OnClick',
              this.pageLabels?.['deleteAccConf.confirm'],
              'Button',
              'click',
              new Date().toISOString(),
              'evar202',
              '',
              this.window?.digitalData?.page?.category?.primaryCategory,
              this.window?.digitalData?.page?.category?.subCategory1
            );
            this.commonUtilityService?.setUser();
            this.commonUtilityService.changeRoute(
              this.appRoutes.DELETE_ACCOUNT_CONFIRMATION,
              undefined,
              'preserve'
            );
          })
          .catch((error: any) => {
            if (error?.status == this.globalDefault.ERROR_401) {
              this.commonUtilityService.setUser();
              localStorage.clear();
              sessionStorage.clear();
              this.cookieService.deleteAll('/', environment.cookieSubDomain);
              const redirectUrl =
                this.commonUtilityService.getOidcRedirectionUrl();
              this.commonUtilityService.redirectToOidcAppEndSession(
                redirectUrl
              );
            }
            this.digitalDataService.fireEvent(
              this.digitalDataService.getEventFromServerError(error)
            );
          })
      : this.closePopup();
  }
  /**
   * closePopup - On modal close to navigate to delete account
   */
  closePopup(): void {
    this.commonUtilityService.changeRoute(this.appRoutes.DELETE_ACCOUNT);
    this.title.setTitle(this.pageLabels?.['page.deleteAccount']);
  }
}
