<div class="buy-ticket-container">
  <div class="second-container">
    <div class="small-container">
      <div
        class="club-universal contentTab"
        tabindex="0"
        (click)="redirectToWTS()"
        (keydown.enter)="redirectToWTS()"
        (keyup.Space)="redirectToWTS()"
      >
        <div class="align-left">
          <div class="icon-container">
            <gds-svg
              icon-name="shopping2"
              height="32"
              width="32"
              fill-color="#222"
              class="adventure"
            ></gds-svg>
            <gds-spacer classes="vertical-spacer-20"></gds-spacer>
          </div>
        </div>

        <!-- On Buy Tickets Link redirect to WTS Page-->
        <div class="buyTicketsCls">
          <gds-button
            [data]="{
              class: 'A3-W6',
              type: 'tertiary',
              href: getBuyTicketsLink(),
              label: pageLabels?.['dashboard.buyTickets'],
              disabled: false,
              target: ''
            }"
          >
          </gds-button>
        </div>
      </div>
    </div>
    <div class="small-container">
      <div
        class="club-universal contentTab"
        tabindex="0"
        (click)="getLink('link_tickets')"
        (keydown.enter)="getLink('link_tickets')"
        (keyup.Space)="getLink('link_tickets')"
      >
        <div class="align-left">
          <div class="icon-container">
            <gds-svg
              icon-name="barcode"
              height="32"
              width="32"
              fill-color="#222"
              class="adventure"
            ></gds-svg>
            <gds-spacer classes="vertical-spacer-20"></gds-spacer>
          </div>
        </div>

        <!-- On Register Tickets Link redirect to link tickets page-->
        <a
          [href]="orderProfileUrl('link-tickets')"
          class="ticketctalink"
          onclick="return false;"
        >
          <gds-button
            [data]="{
              class: 'A3-W6',
              type: 'tertiary',
              href: '',
              label: pageLabels?.['dashboard.registerTickets'],
              disabled: false,
              target: ''
            }"
          >
          </gds-button>
        </a>
      </div>
    </div>
  </div>
  <gds-spacer classes="spacer-50"></gds-spacer>

  <!-- Display 2 coupons with details-->
  <div class="coupon-container">
    <div class="coupon-content-data">
      <div class="icon-content">
        <gds-svg
          icon-name="deals"
          fill-color="#222"
          width="32"
          height="32"
          class="adventure"
        ></gds-svg>
        <gds-spacer classes="vertical-spacer-30"></gds-spacer>
        <div>
          <gds-heading
            class="B1-W4"
            classes="adventure"
            [data]="{
              heading: pageLabels?.['dashboard.coupons']
            }"
          ></gds-heading>
        </div>
      </div>
      <div
        class="button-class contentTab"
        tabindex="0"
        (click)="getLink('LINK_COUPONS')"
        (keydown.enter)="getLink('LINK_COUPONS')"
        (keyup.Space)="getLink('LINK_COUPONS')"
      >
        <a
          [href]="orderProfileUrl('coupons')"
          class="ticketctalink"
          onclick="return false;"
        >
          <gds-button
            [data]="{
            class: 'B1-W4',
            type: 'tertiary',
            href: '',
            label: pageLabels?.['dashboard.details'],
            disabled: false,
            target: ''
          }"
          >
          </gds-button>
        </a>
      </div>
    </div>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <div *ngIf="tickets; else noTickets">
      <app-coupon-card
        [couponData]="couponData"
        [pageLabelsData]="pageLabels"
        [productCardCount]="displayProductCardCount"
        [fromDashboard]="fromDashboard"
      ></app-coupon-card>
    </div>
    <ng-template #noTickets>
      <!-- <div class="selected">
        <h6 class="A6-W3">
          {{pageLabels?.['dashboard.couponsEmpty']}}
        </h6>
      </div> -->
      <div *ngIf="couponData?.length === 0 || couponData === undefined">
        <app-coupon-card
          [pageLabelsData]="pageLabels"
          [productCardCount]="displayProductCardCount"
          [fromDashboard]="fromDashboard"
        ></app-coupon-card>
      </div>
    </ng-template>
  </div>

  <!-- To display two product Card-->
  <!-- <div class="product-card-container">
    <ng-container *ngIf="displayProductCardCount">
      <div class="offers-product-card">
        <app-coupon-offers
          [productCardCount]="displayProductCardCount"
        ></app-coupon-offers>
      </div>
    </ng-container>
  </div> -->
  <gds-spacer classes="spacer-50"></gds-spacer>

  <!--To Display Product Order List-->
  <div
    class="second-container"
    (keydown.enter)="getLink('order')"
    (keyup.Space)="getLink('order')"
  >
    <div
      class="coupon-content info-content contentTab"
      tabindex="0"
      id="order_history"
    >
      <div class="icon-content">
        <div>
          <gds-svg
            icon-name="list-view"
            fill-color="#222"
            width="32"
            height="32"
            class="adventure"
          ></gds-svg>
        </div>
        <gds-spacer classes="vertical-spacer-30"></gds-spacer>
        <div class="purchaseHistoryCls">
          <gds-heading
            class="B1-W4 adventure"
            [data]="{
              heading: pageLabels?.['dashboard.purchaseHistory']
            }"
          ></gds-heading>
        </div>
      </div>
      <div
        class="account-icon-names"
        (click)="orderProfileUrl('orders')"
        (keydown.enter)="orderProfileUrl('orders')"
        (keyup.Space)="orderProfileUrl('orders')"
      >
        <a
          [href]="orderProfileUrl('orders')"
          class="ticketctalink"
          (click)="getLink('order')"
        >
          <gds-button
            [data]="{
        class: 'B1-W4',
        type: 'tertiary',
        href: '',
        label: pageLabels?.['dashboard.details'],
        disabled: false,
        target: ''
      }"
          >
          </gds-button>
        </a>
        <a [href]="orderProfileUrl('orders')" class="ticketctalink">
          <gds-spacer classes="vertical-spacer-20"></gds-spacer>
          <i aria-hidden="" class="icon icon-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
  <gds-spacer classes="spacer-50"></gds-spacer>

  <!--TO display account information for the user-->
  <div class="second-container">
    <div
      class="coupon-content info-content contentTab"
      tabindex="0"
      (keydown.enter)="getLink('PROFILE')"
      (keyup.Space)="getLink('PROFILE')"
    >
      <div class="icon-content">
        <div>
          <gds-svg
            icon-name="profile"
            fill-color="#222"
            width="32"
            height="32"
            class="adventure"
          ></gds-svg>
        </div>
        <gds-spacer classes="vertical-spacer-30"></gds-spacer>
        <div class="accountInformationCls">
          <gds-heading
            class="B1-W4 adventure"
            [data]="{
              heading: pageLabels?.['dashboard.accountInformation']
            }"
          ></gds-heading>
        </div>
      </div>
      <div
        class="account-icon-names"
        (click)="orderProfileUrl('profile')"
        (keydown.enter)="orderProfileUrl('profile')"
        (keyup.Space)="orderProfileUrl('profile')"
      >
        <a
          [href]="orderProfileUrl('profile')"
          class="ticketctalink"
          (click)="getLink('PROFILE')"
        >
          <gds-button
            [data]="{
              class: 'B1-W4',
              type: 'tertiary',
              href: '',
              label: pageLabels?.['accountInfo.Details'],
              disabled: false,
              target: ''
            }"
          >
          </gds-button>
        </a>
        <a [href]="orderProfileUrl('profile')" class="ticketctalink">
          <gds-spacer classes="vertical-spacer-20"></gds-spacer>
          <i aria-hidden="" class="icon icon-arrow-right"></i>
        </a>
      </div>
    </div>
  </div>
</div>
