import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'postalCode',
})
export class PostalCodePipe implements PipeTransform {
  transform(value: any): any {
    if (value !== null && value !== undefined) {
      if (value.length >= 4 && value.indexOf(' ') == -1) {
        return value.substring(0, 4) + '-' + value.substring(4, value.length);
      }
      return value;
    }
  }
}
