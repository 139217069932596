import { EnvironmentService } from '../environments/environment.service';

const env = new EnvironmentService();

export const globalDefault = {
  REGEX_PUBID_FROM_TCMID: /^tcm:(\d+)-(\d+)(?:-(\d+))?$/i,
  DEFAULT_TRIDION_PUBLICATION_ID: '58',
  MULTIMEDIA_PATTERN_REGEX: '^/.*.(jpg|JPG|gif|GIF|png|PNG|pdf|PDF|mp4|MP4)',
  REGEX_URL_LOCALE: /(.*)(()\/(.{2})\/(.{2})(\/.*))/i, // i.e for */en/us/* pattern
  DEFAULT_ROUTE_COUNTRY: 'jp',
  DEFAULT_ROUTE_LANGUAGE: 'ja',
  WEB_CONTENT_URL: env.webAppPath,
  WEBSTORE_JUNCTION: env.webstoreJunction,
  DENALI_URL: env.denaliUrl,
  ADOBE_AUTOSEARCH_URL: env.adobeAutosearchUrl,

  POSTALCODE: 'postalCode',
  PREFECTURE: 'prefecture',
  CITY: 'city',
  ADDRESS1: 'address1',
  ANNUAL_PASSES: 'Annual Pass',
  STUDIO_PASSES: 'Studio Pass',
  EXPRESS: 'Express Pass',
  GRAND_ANNUAL_PASS: 'Grand Royal Annual Pass',
  PRODUCT_EXPRESS: 'Express',
  VIEW_TERMS: 'view',
  BACK_NAVIGATION: 'back',
  COMPLETE_AP: 'completeAP',
  FIRSTNAME: 'firstName',
  LASTNAME: 'lastName',
  PASSWORD: 'passWord',
  NEWPASSWRD: 'newPassWord',
  EMAIL: 'email',
  PHONENUMBER: 'phoneNumber',
  DOB: 'dob',
  REPASSWORD: 'rePassWord',
  TERMS_CONDITION: 'termCondition',
  SPECIAL_OFFER: 'specialOffer',
  RESPONSE_TYPE: 'code id_token token',
  SCOPE: 'openid default offline_access',
  WEB: 'web',
  CHECKED: 'checked',
  PRESENTATION: 'ComponentPresentations',
  CREATEACCOUNT: 'CreateAccount',
  CREATE_ACCOUNT_FORM: 'Create Account Form',
  CREATED: 'Created',
  TIMEOUT: 'TimeoutError',
  FLAGS: ['true', 'false'],
  NAME: 'name',
  SUCCESS: 'success',
  FAILED: 'Failed',
  OK: 'OK',
  MY_PROFILE: 'MY_PROFILE',
  ADD_MEMBERS: 'ADD_MEMBERS',
  EDIT_MEMBERS: 'EDIT_MEMBERS',
  ADD: 'Add',
  EDIT: 'Edit',
  GENDER: 'gender',
  RELATIONSHIP: 'relationship',
  ERROR: 'error',
  ACCOUNT: 'guest',
  LINK_COUPONS: 'LINK_COUPONS',
  VALID: 'Valid',
  RISKY: 'Risky',
  INVALID: 'Invalid',
  SEARCH_KEY: 'suggestion',
  PROFILE: 'PROFILE',
  BIRTHDAY_DISCOUNT: 'BIRTHDAY_DISCOUNT',
  LOGIN: 'LOGIN',
  //ENGLISH_FORMAT: 'EE , MMM dd, yyyy, HH:mm',
  //JAPANESE_FORMAT: 'yyyy年M月d日(EEE), HH:mm',
  // JAPANESE_DAYS_MAPPING: {
  //   Sun: '日',
  //   Mon: '月',
  //   Tue: '火',
  //   Wed: '水',
  //   Thu: '木',
  //   Fri: '金',
  //   Sat: '土',
  // },
  //EXCHANGE_ORDER: 'Exchange_Order',
  CART_ERROR: 'CartError',
  COMM_STUDIO_PASS: 'studiopass',
  COMM_ANNUAL_PASS: 'annualpass',
  COMM_EXPRESS_PASS: 'expresspass',

  //Coupon error codes
  ADD_COUPON_CODE: 'addCouponCode',
  NOT_FOUND: 'Not Found',
  CONTACT_TYPE_KANJI: 'japanese_kanji',
  CONTACT_TYPE_FURGINA: 'japanese_furigana',
  ORDER: 'order',
  TICKET: 'ticket',
  OFFER: 'offer',
  LEGACY_ORDER: 'LEGACY_ORDER',
  PHONE_NUMBERS_PAGE: 'PHONE_NUMBERS_PAGE',
  MY_ADDRESSES: 'MY_ADDRESSES',
  TICKETS: 'TICKETS',
  BUY_TICKETS: 'BUY_TICKETS',
  UPDATE_PERSONAL_INFO: 'UPDATE_PERSONAL_INFO',
  PDP_PAGE: 'pdpPage',
  PCP_OVERLAY: 'pcpOverlay',
  BTN_PRIMARY: 'primary',
  BTN_SECONDARY: 'secondary',
  PRODUCT_CATEGORY: '/c/ticket',
  DISTRIBUTED: 'Distributed',
  CVS_PAYMENT_EXPIRED: 'CVS_PAYMENT_EXPIRED',
  NEW_TPM: 'add member',
  PRODUCT_LISTING_PAGE: 'plp',
  CLOSE: 'close',
  CONFIRM: 'confirm',
  LINK_TICKETS: 'link_tickets',
  REGISTER: 'register',
  CHOICEA: 'CHOICE A',
  CHOICEB: 'CHOICE B',
  // choiceA: 'Choice A',
  // choiceB: 'Choice B',
  //  ATTRACTION_DESCRIPTION: 'Choose one attraction below',
  CLOSED_ORDER: 'CLOSED',
  EXPIRED_ORDER: 'EXPIRED',
  ERROR_CODE: 'errCode.COMM.',
  seoDescription: 'description',
  C_TICKETS: 'tickets',
  DASHBOARD_VERSION_ARRAY: ['Full Version', 'Basic Version'],
  DASHBOARD: 'dashboard',
  CANCELLED: 'CANCELLED',
  REGISTERED: 'Registered',
  CONFIRMED: 'CONFIRMED',
  CONFIRM_FAILED: 'CONFIRM_FAILED',
  REJECTED: 'REJECTED',
  WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT',
  CVS_PAYMENT_REQSUCCESS: 'CVS_PAYMENT_REQSUCCESS',
  CONVENIENCESTORE: 'CONVENIENCESTORE',
  CARD: 'CARD',
  COUPON_TYPE: 'GateCoupon',
  TOWN: 'town',
  FURGINA_FIRSTNAME: 'first_name_furgina',
  FURGINA_LASTNAME: 'last_name_furgina',
  STREET: 'street',
  ADDRESS: 'address',
  LOST_TIME: 'lostTime',
  LOST_LOCATION: 'lostLocation',
  DETAILS_OF_LOST_ITEM: 'detailLostItem',
  SELECTED_LOST_ITEM: 'selectedLostItemButton',
  REENTER_EMAIL: 'reEnterEmail',
  REPLY_INPUT: 'replyInput',
  AGREE: 'agree',
  REPLY: 'reply',
  COUNT_AGREE: 'countAgree',
  PRIVACY_AGREE: 'privacyAgree',
  // ER71: '問題が発生しました。時間をおいて再度おためしください。',
  //error
  ERROR_400: 400,
  ERROR_409: 409,
  ERROR_422: 422,
  ERROR_401: 401,
  ERROR_ER71: 'ER71',
  ERROR_503: 503,
  ERROR_500: 500,
};

export const sourceKeys = {
  WEB_ACCOUNTCREATION: 'WebUSJAccountCreation',
  WEB_EDITCONTACT: 'WebUSJEditContactInfo',
  WEB_EDITPERSONAL: 'WebUSJEditPersonalInfo',
  WEB_EDITADDRESS: 'WebUSJEditAddress',
  WEB_CREATEEDITTRP: 'WebUSJCreateorEditProfileTravelParty',
  WEBACCOUNTDELETE: 'WebUSJAccountDelete',
};

export const Ranges = {
  MAX: {
    FIRST_NAME: 25,
    LAST_NAME: 30,
    USA_PHONE: 10,
    EMAIL: 40,
    POSTALCODE: 7,
    YEAR: 1000,
    TIME: 3600,
    MONTH: 24,
    FURGINA_LASTNAME: 30,
    FURGINA_FIRSTNAME: 30,
    MIN_TICKETNO: 10,
    MAX_TICKETNO: 99,
  },
  SUCCESS_STATUS: 200,
  SUCCESS_201: 201,
  PASSWORDS: {
    PASSWORD_LENGTH_INDEX: 7,
    PASSWORD_FORCE_FACTOR: 10,
    PASSWORD_FLAG_COLORS: [
      '#03A678',
      '#03A678',
      '#03A678',
      '#03A678',
      '#03A678',
    ],
    INDEX: [0, 1, 2, 3, 4, 5, 6, 7],
    FORCE_INDEX: [10, 20, 30, 40, 50],
    DEFAULT_BACKGROUND: '#DDD',
    MINUS_INDEX: -1,
    INVALID_SPECIAL_CHAR: [
      '[',
      ']',
      '{',
      '}',
      '+',
      '=',
      '_',
      '*',
      '&',
      '^',
      '!',
      '~',
      '#',
      '$',
      '%',
      ';',
      '<',
      '?',
      ',',
      '?',
      '>',
      '|',
      '`',
    ],
    SPECIAL_CHAR: ['@', "'", '(', ')', '"', '"', ':', '-', '.', '--'],
  },
  STRING: {
    LENGTH: 36,
  },
};
export const Gender = [
  'female',
  'male',
  'other',
  'prefer not to answer (not respond)',
];
export const Relationship = ['SPOUSE', 'CHILD', 'OTHER'];
export const ErrorMappings = {
  400: 'errCode.E027',
  402: 'errCode.MS.link_tickets_402',
  404: 'linkticket.notFoundMsg',
  403: 'errCode.MS.link_tickets_403',
  422: 'errCode.MS.link_tickets_422',
  500: 'errCode.MS.link_tickets_400',
  503: 'errCode.MS.link_tickets_400',
  401: 401,
};
export const DefaultErrorMappings = 'errCode.E019';
