import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { NAME_REGEX } from '../../../config/pattern.constant';
import { environment } from '../../../environments/environment';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-edit-travel-party',
  templateUrl: './edit-travel-party.component.html',
  styleUrls: ['./edit-travel-party.component.scss'],
})
export class EditTravelPartyComponent implements OnInit, AfterViewInit {
  travelPartyMemberDetails: any;
  @Input() editTravelPartyForm: FormGroup;
  nameRegex = NAME_REGEX;
  formValues: any;
  showConfirmPopup = false;
  showCourse = false;
  opened = false;
  @Output() backToTravelList = new EventEmitter<any>();
  firstNameErrorMsgInfo: string;
  lastNameErrorMsgInfo: string;
  dobErrorMsgInfo: string;
  genderArray: any = [];
  relationshipArray: any = [];
  fromPage: string;
  type: string;
  genderShow = false;
  dateShow = false;
  relationShow = false;
  dateVal: any;
  travelMembersDetailsArray: any;
  inputChanged = false;
  @ViewChild('editPartyModalContent') editPartyModalContent!: ElementRef;
  @Output() updateTravelPartyForm = new EventEmitter();
  /**
   * Page labels
   * @type any
   */
  pageLabels: any;
  dobGenderValue = false;
  addTravelPartyForm: FormGroup;
  previousFormValues: any;
  editTrpMemberData: any;
  guestId: string;
  trpFirstName: any;
  trpLastName: any;
  constructor(
    private commonUtilityService: CommonUtilityService,
    @Inject('AppRoutes') public appRoutes: any,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private appDataService: AppDataService,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('Ranges') public ranges: any,
    @Inject('NamePattern') public namePattern: any,
    private guestsService: MsGuestsService,
    private cookieService: CookieService,
    private title: Title
  ) {
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.travelPartyMemberEdit']);
  }

  /**
   * ngOnInit - initial methods are called
   */

  ngOnInit(): void {
    this.travelPartyMemberDetails = this.commonUtilityService.getFormData();
    this.genderArray = [
      {
        label: this.pageLabels?.['trpAdd.genderFemale'],
        value: this.pageLabels?.['trpAdd.genderFemale'],
      },
      {
        label: this.pageLabels?.['trpAdd.genderMale'],
        value: this.pageLabels?.['trpAdd.genderMale'],
      },

      {
        label: this.pageLabels?.['trpAdd.genderOther'],
        value: this.pageLabels?.['trpAdd.genderOther'],
      },
      {
        label: this.pageLabels?.['trpAdd.gendernotResponded'],
        value: this.pageLabels?.['trpAdd.gendernotResponded'],
      },
    ];
    this.relationshipArray = [
      {
        label: this.pageLabels?.['trpAdd.spouse'],
        value: this.pageLabels?.['trpAdd.spouse'],
      },
      {
        label: this.pageLabels?.['trpAdd.child'],
        value: this.pageLabels?.['trpAdd.child'],
      },
      {
        label: this.pageLabels?.['trpAdd.other'],
        value: this.pageLabels?.['trpAdd.other'],
      },
    ];
    this.createForm();
    this.setFormValues();
    this.getTravelParty();
    this.checkRelationshipStatus();
    this.fromPage = this.globalDefault.EDIT;
    this.opened = false;
    this.enableButton();
  }

  /**
   * enableButton - It will enable the save button if valid phone number
   */

  enableButton(): any {
    this.editTravelPartyForm.valueChanges.subscribe((currentValue) => {
      this.inputChanged =
        currentValue?.lastName === this.editTravelPartyForm?.value.lastName &&
        currentValue?.firstName === this.editTravelPartyForm?.value.firstName &&
        currentValue?.dob === this.editTravelPartyForm?.value.dob &&
        currentValue?.relationship ===
          this.editTravelPartyForm?.value.relationship
          ? true
          : false;
    });
  }
  /**
   * getDate - It will format the date based on locale
   */

  getDate(date: any): string {
    return this.commonUtilityService.getFormattedDateOnLocale(date);
  }
  /**
   * checkRelationshipStatus - On basis of relationShow flag dobGenderValue field will show or hide
   */
  checkRelationshipStatus(): any {
    this.dobGenderValue =
      this.travelPartyMemberDetails?.relationship ===
        this.pageLabels?.['trpUpdate.other'] ||
      this.travelPartyMemberDetails?.relationship == undefined
        ? false
        : true;
    this.cdRef.detectChanges();
  }
  /**
   * ngAfterViewInit - initial methods are called
   */
  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(this.editPartyModalContent, 164);
  }

  /**
   * onResize - To resize full screen modal popup
   */
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(this.editPartyModalContent, 164);
  }

  /**
   * createForm - it will create the form
   */

  createForm(): any {
    this.editTravelPartyForm = this.formBuilder.group({
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
        this.noWhitespaceValidator(),
        Validators.pattern(this.nameRegex),
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(this.nameRegex),
        this.noWhitespaceValidator(),
      ]),
      dob: new FormControl('', [this.dobValidator()]),
      gender: new FormControl(''),
      relationship: new FormControl(''),
      memberId: new FormControl(''),
    });
  }

  /**
   * dobValidator - dob validation
   * @returns - returns the invalidDOb as true if invalid date enetered
   */

  dobValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dob = control.value;

      if (!dob) {
        // DOB is optional, so it's valid if not provided
        return null;
      }

      // Your custom DOB validation logic goes here
      const inputDate = dob.toString();
      this.dobErrorMsgInfo = this.commonUtilityService.checkDate(
        inputDate,
        this.pageLabels
      );
      return this.dobErrorMsgInfo === '' ? null : { invalidDob: true };
    };
  }

  /**
   * noWhitespaceValidator
   * @returns
   */

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace =
        (control.value || '').trim().length === this.ranges.PASSWORDS.INDEX[0];
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  /**
   * setFormValues -  set form values
   */

  setFormValues(): any {
    this.formValues = this.commonUtilityService.getFormData();
    if (this.formValues) {
      this.editTravelPartyForm.patchValue({
        firstName: this.travelPartyMemberDetails?.contact.first,
        lastName: this.travelPartyMemberDetails?.contact.last,
        dob: this.travelPartyMemberDetails?.date_of_birth,
        gender: this.travelPartyMemberDetails?.gender,
        relationship: this.travelPartyMemberDetails?.relationship,
        memberId: this.travelPartyMemberDetails.member_id,
      });
    }
    if (this.travelPartyMemberDetails?.gender == undefined) {
      this.genderShow = false;
    } else {
      this.genderShow = true;
    }
    if (this.travelPartyMemberDetails?.date_of_birth) {
      this.dateShow = true;
      const inputDate = this.travelPartyMemberDetails.date_of_birth; // Replace this with your date string
      const convertedDate = inputDate.replace(/-/g, '/');
      this.dateVal = convertedDate;
    } else {
      this.dateShow = false;
    }
    this.relationShow =
      this.travelPartyMemberDetails?.relationship === undefined ? false : true;
    const obj = {
      dateShow: this.dateShow,
      genderShow: this.genderShow,
      relationShow: this.relationShow,
      revisionID: this.travelPartyMemberDetails?.revision,
    };
    this.commonUtilityService.setCommonData(obj);

    // Store the initial form value
    this.previousFormValues = this.editTravelPartyForm.getRawValue();
  }

  /**
   * continueBtn
   */

  continueBtn(): any {
    this.getNamesByGuestId(this.guestId);
    const currentFormValues = this.editTravelPartyForm.getRawValue();
    (this.previousFormValues.lastName !== currentFormValues.lastName &&
      this.previousFormValues.firstName !== currentFormValues.firstName) ||
    (currentFormValues.lastName === this.trpLastName &&
      currentFormValues.firstName === this.trpFirstName)
      ? this.sameNameWarningMsg()
      : (this.showConfirmPopup = true);
  }

  getTravelParty(): void {
    this.guestId = this.guestsService.helper.getGuestId();
    this.guestsService.wallet
      .getTravelPartyMembers(this.guestId)
      .then((res: any) => {
        this.editTrpMemberData = res;
        this.travelMembersDetailsArray = _.filter(res, (member) => {
          if (!member.is_owner) {
            return member.is_active === true;
          }
          return false;
        });
      })
      .catch((error: any) => {
        if (error.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
      });
  }

  getNamesByGuestId(guestId: string): void {
    const member = this.editTrpMemberData?.find(
      (member: any) => member.member_id === guestId
    );
    if (member) {
      this.trpFirstName = member.contact.first;
      this.trpLastName = member.contact.last;
    }
  }

  /**
   * sameNameWarningMsg - if user enter same name it will shows warning popup
   */

  sameNameWarningMsg(): any {
    const matchingMember = _.find(this.editTrpMemberData, (member: any) => {
      return (
        member?.contact?.first === this.editTravelPartyForm.value.firstName &&
        member?.contact?.last === this.editTravelPartyForm.value.lastName
      );
    });
    this.showCourse = matchingMember ? true : false;
    if (this.showCourse) {
      this.showConfirmPopup = false;
      this.opened = true;
    } else {
      this.showConfirmPopup = true;
      this.opened = false;
    }
  }

  /**
   * onTravelPartyList - redirect to travel party information page
   * @param e : specify the true/false
   */

  onTravelPartyList(e: any): any {
    this.showConfirmPopup = e.showConfirmPopup;
    this.commonUtilityService.changeRoute(
      this.appRoutes.TRAVEL_PARTY_INFORMATION,
      { page: this.fromPage }
    );
  }

  /**
   * getErrorMessageInfo - it will return the error message for input fields
   * @param event : specify the event
   */

  getErrorMessageInfo(event: any): void {
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.FIRSTNAME:
        this.firstNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.LASTNAME:
        this.lastNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.DOB:
        this.dobErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.GENDER:
        this.editTravelPartyForm?.get('gender')?.patchValue(this.genderArray);
    }
    this.cdRef.detectChanges();
  }

  /**
   * getErrorMessage - it will return the error message for input fields
   * @param field : specify the input field
   * @returns
   */

  getErrorMessage(field: any): string {
    const fieldName = this.editTravelPartyForm?.get(field);
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      switch (field) {
        case this.globalDefault.FIRSTNAME: {
          if (!this.editTravelPartyForm.value.firstName) return emptyCheck;
          if (field == this.globalDefault.FIRSTNAME) {
            this.firstNameErrorMsgInfo = this.pageLabels?.['errCode.E032'];
            return this.firstNameErrorMsgInfo;
          } else {
            this.firstNameErrorMsgInfo =
              this.pageLabels?.['errCode.E020'] +
              ' ' +
              this.ranges.MAX.FIRST_NAME +
              ' ' +
              this.pageLabels?.['createAccount.maxCharCheck'];
            return this.firstNameErrorMsgInfo;
          }
        }
        case this.globalDefault.LASTNAME: {
          if (!this.editTravelPartyForm.value.lastName) return emptyCheck;
          if (field == this.globalDefault.LASTNAME) {
            this.lastNameErrorMsgInfo = this.pageLabels?.['errCode.E032'];
            return this.lastNameErrorMsgInfo;
          } else {
            this.lastNameErrorMsgInfo =
              this.pageLabels?.['errCode.E020'] +
              ' ' +
              this.ranges.MAX.LAST_NAME +
              ' ' +
              this.pageLabels?.['createAccount.maxCharCheck'];
            return this.lastNameErrorMsgInfo;
          }
        }
        case this.globalDefault.DOB: {
          if (!this.editTravelPartyForm.value.dob)
            this.dobErrorMsgInfo = this.pageLabels?.['errCode.E002'];
          else {
            const inputDate = this.editTravelPartyForm.value.dob.toString();
            this.dobErrorMsgInfo = this.commonUtilityService.checkDate(
              inputDate,
              this.pageLabels
            );
            return this.dobErrorMsgInfo;
          }
        }
      }
    }
    return '';
  }

  /**
   * onValueChange - Patch field value
   * @param event - Input field event
   */

  onValueChange(event: any): void {
    const field = event?.target?.id;
    const value = event?.detail;
    this.addTravelPartyForm?.get(field)?.patchValue(value);
    this.updateTravelPartyForm.emit(this.addTravelPartyForm);
  }

  /**
   * matchDropDown for gender and relationship
   * @param event : specify the event
   * @param type : specify the type
   */

  matchDropDown(event: any, type: any): any {
    this.editTravelPartyForm.markAsDirty();
    this.dobGenderValue =
      event?.detail == this.pageLabels?.['trpAdd.other'] &&
      type == this.globalDefault.RELATIONSHIP
        ? false
        : true;
    type === this.globalDefault.GENDER
      ? this.genderArray?.filter((res: any) => {
          if (res.value === event.detail) {
            this.editTravelPartyForm.patchValue({
              gender: res.value,
            });
          }
        })
      : this.relationshipArray?.filter((res: any) => {
          if (res.value === event.detail) {
            this.editTravelPartyForm.patchValue({
              relationship: res.value,
            });
          }
          if (event.detail == this.pageLabels?.['trpUpdate.other']) {
            this.editTravelPartyForm.patchValue({
              gender: '',
              dob: '',
            });
          }
        });
    this.updateTravelPartyForm.emit(this.addTravelPartyForm);
  }

  /**
   * backToTravelPage - redirect to travel party information page
   */

  backToTravelPage(): void {
    this.commonUtilityService.changeRoute(
      this.appRoutes.TRAVEL_PARTY_INFORMATION
    );
  }

  /**
   * onWarning - warning popup
   * @param e : specify the event
   */

  onWarning(e: any): any {
    this.opened = e.opened;
    this.showConfirmPopup = e.showConfirm;
  }
}
