/**
 * Udeploy constants will be overitten during deployment using
 * wildcards $foo$. Each enviorment will have own unique values
 * in Udeploy configuration.
 *
 * For local development sets defaults using regex.
 */

import { IEnvironment } from './environment.type';

// this function strips everything for wildcard to allow local development
export function param(str: string): string {
  return str.replace(/\$.*\$/g, '');
}

function paramBool(str: string): boolean | undefined {
  const fixedStr = param(str);
  if (fixedStr === '') {
    return undefined;
  }
  if (fixedStr === 'true') {
    return true;
  } else {
    return false;
  }
}

export const udeploy: IEnvironment = {
  accountAppPath: param('$akamai.accountapppath$'),
  adobeAutosearchUrl: param('$adobe.autosearchurl$'),
  adobeBodyScript: param('$adobedtm.bodyscript$'),
  adobeHeaderScript: param('$adobedtm.headerscript$'),
  adobeMbox: param('$adobe.mbox$'),
  adobeTargetDisabled:
    (param('$adobe.target.disabled$') || '').toLowerCase().trim() === 'true',
  appTheme: param('$app.theme$'),
  baseRedirectBypass: paramBool('$app.base_redirect_bypass$') || false,
  bingSiteVerificationKey: param('$bing_site_verification.key$'),
  cardinalScriptUrl: param('$cardinal.scripturl$'),
  defaultCountry: param('$default.country$'),
  defaultLanguage: param('$default.language$'),
  defaultTheme: param('$default.themescss$'),
  digitalDataAdblockerEnabled:
    (param('$digitaldata.adblockerenabled$') || '').toLowerCase().trim() ===
    'true',
  digitalDataAngularVersion: param('$digitaldata.angularversion$'),
  digitalDataAppVersion: param('$digitaldata.appversion$'),
  digitalDataDestination: param('$digitaldata.destination$'),
  digitalDataEnabled:
    (param('$digitaldata.enabled$') || '').toLowerCase().trim() === 'true',
  digitalDataEnvironment: param('$digitaldata.environment$'),
  digitalDataFlashVersion: param('$digitaldata.flashversion$'),
  digitalDataSegment: param('$digitaldata.segment$'),
  digitalDataSite: param('$digitaldata.site$'),
  digitalDataTridionVersion: param('$digitaldata.tridionversion$'),
  diningReservationsAppPath: param('$akamai.dining$'),
  denaliUrl: param('$denali.url$'),
  facebookLikeUrl: param('$facebook.likeUr$'),
  fontCssUrl: param('$default.fontcss$'),
  geo: {
    apiUrl: param('$geolocation.api_url$'),
    service: param('$geoDirectory.baseUrl$'),
    disabled: paramBool('$geo.disabled$') || false,
    location: {
      akamai: paramBool('$geo.location.akamai$') || false,
    },
    region: {
      primary: param('$geo.region.primary$'),
    },
  },
  globalNavigationEnabled:
    (param('$global.navigation.enabled$') || '').toLowerCase().trim() ===
    'true',
  googleCalendarApiKey: param('$google.calendar.apiKey$'),
  googleSiteVerificationKey: param('$google_site_verification.key$'),
  grecaptchaApiUrl: param('$grecaptcha.api_url$'),
  grecaptchaSiteKey: param('$grecaptcha.site_key$'),
  grecaptchaEnabled:
    (param('$grecaptcha.enable$') || '').toLowerCase().trim() === 'true',
  grecaptchaTestResponse: param('$grecaptcha.test_response$'),
  hmacKey: param('$hmac.secret$'),
  iconCssUrl: param('$default.iconcss$'),
  layoutHeader: param('$layout.header$'),
  mobileServicesShowtimesEndpoint: param(
    '$mobileServicesApi.showTimesEndpoint$'
  ),
  mobileServicesVenueEndpoints: param('$mobileServicesApi.venueEndpoint$'),
  oauthBaseUrl: param('$oauth.baseurl$'),
  oauthClientId: param('$oauth.clientid$'),
  oauthClientKeyID: param('$oauth.clientsecret$'),
  oauthAppFlowPath: param('$oauth.applicationflowpath$'),
  oauthAppPasswordFlowPath: param('$oauth.passwordflowpath$'),
  oauthAppSourceId: param('$oauth.appsourceid$'),
  oidcBaseUrl: param('$oidc.baseurl$'),
  oidcEnabled: (param('$oidc.enabled$') || '').toLowerCase().trim() === 'true',
  oidcClientId: param('$oidc.clientid$'),
  oidcClientKeyID: param('$oidc.clientsecret$'),
  oidcNodeApiBaseUrl: param('$oidc.nodeapi.baseurl$'),
  oidcRedirectEnabled:
    (param('$oidc.redirectenabled$') || '').toLowerCase().trim() === 'true',
  oidcRedirectApi: param('$oidc.redirectapi$'),
  oidcSignoffApi: param('$oidc.signoffapi$'),
  oidcAppFlowPath: param('$oidc.applicationflowpath$'),
  oidcAppPasswordFlowPath: param('$oidc.passwordflowpath$'),

  oneTrustDomainScript: param('$onetrust.domain.script$'),
  oneTrustEnabled:
    (param('$onetrust.enabled$') || '').toLowerCase().trim() === 'true',
  oneTrustModalScript: param('$onetrust.script$'),
  oneTrustHash: param('$onetrust.hash$'),
  oneTrustCrossorigin: param('$onetrust.crossorigin$'),
  oneTrustPrivacyScript: param('$onetrust.privacy.script$'),
  oneTrustPrivacyIds: param('$onetrust.privacy.ids$'),
  oneTrustPrivacyGeo: param('$onetrust.privacy.geo$'),
  oneTrustPrivacyLSPA: param('$onetrust.privacy.lspa$'),
  coveo: {
    enabled: param('$coveo.enabled$') === 'true',
    scriptSearchUrl: param('$coveo.script.search.search.url$'),
    scriptSearchHash: param('$coveo.script.search.search.hash$'),
    scriptTemplateUrl: param('$coveo.script.search.template.url$'),
    scriptTemplateHash: param('$coveo.script.search.template.hash$'),
    css: param('$coveo.css$'),
    cssHash: param('$coveo.css.hash$'),
    crossOrigin: param('$coveo.crossorigin$'),
    accessKeyID: param('$coveo.accesstoken$'),
    organizationId: param('$coveo.organizationid$'),
    scriptLocale: param('$coveo.script.locale$'),
    scriptLocaleHash: param('$coveo.script.locale.hash$'),
  },
  resortName: param('$resort.name$'),
  resortAreaCode: param('$default.resortareacode$'),
  seoTitle: param('$seo.default.title$') || 'Universal Studios, Orlando',
  seoDescription: param('$seo.default.description$'),
  seoImage: param('$seo.default.image$'),
  seoFacebookSiteName: param('$seo.default.facebookSiteName$'),
  seoTwitterSiteName: param('$seo.default.twitterSiteName$'),
  sorryPagePath: param('$defaults.sorrypage$'),
  tridionSite: param('$tridion.site$'),
  tridionBaseUrl: param('$akamai.tridion$'),
  tripDetailLink: param('$tripdetail.link$'),
  uprvUrl: param('$uprv.url$'),
  upsellPackages: param('$upsell.packages$'),
  wcsStoreId: param('$wcs.storeid$'),
  webAppPath: param('$akamai.webapp$'),
  webstoreAppPath: param('$akamai.storeapp$'),
  webstoreJunction: param('$webstore.junction$'),
  weatherApi: param('$weather.api$'),
  timeZone: param('$default.timeZone$'),
  uniWebServiceApiKey: param('$uniWebServiceApiKey$'),
  COMPLETE_AP: param('$COMPLETE_AP$'),
  commerceClientId: param('$commerceClientId$'),
  commerceClientKeyID: param('$commerceClientKeyID$'),
  commerceApiUrl: param('$commerceApiUrl$'),
  wtsHostBaseUrl: param('$wtsHostBaseUrl$'),
  entitlementBaseUrl: param('$entitlementBaseUrl$'),
  gtmId: param('$gtmID$'),
  cookieSubDomain: param('$cookieSubDomain$'),
  contentApiBaseUrl: param('$contentApiBaseUrl$'),
  contentLoginClientID: param('$contentLoginClientID$'),
  contentLoginClientKeyID: param('$contentLoginClientKeyID$'),
  crewToolsApi: param('$crewToolsApi$'),
  rToasterTagId: param('$rToasterTagId$'),
  contentIds: {
    GDS_FOOTER_CONTENT_ID: 'GDS-Footer-Utility-USJ-ACCOUNT',
    TRIDION_PUBLICATION: 'usj',
    GDS_GLOBAL_NAV_UTILITY_BAR_CONTENT_ID: 'GDS-Main-Nav-Utility-USJ-ACCOUNT',
    GDS_GLOBAL_NAV_MEGA_NAV_CONTENT_ID:
      'gds-global-nav-usj-mega-nav-main-account',
  },
  inquiryToken: param('$inquiryToken$'),
  oidcInquiryClientId: param('$oidcInquiryClientId$'),
  oidcInquiryClientKeyID: param('$oidcInquiryClientKeyID$'),
};
