import { DatePipe, formatDate } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { SpinnerService } from '@upr-web-primer/spinner';
import { LocalStorageService } from '@upr-web-primer/storage';
import _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { CommonUtilityService } from '../../services/common-utility.service';
import mockData from '../../shared/constants/mockData.json';
import { TICKET_STATUS } from './ticket-status';
@Component({
  selector: 'app-ticket-details',

  templateUrl: './ticket-details.component.html',

  styleUrls: ['./ticket-details.component.scss'],
})
export class TicketDetailsComponent implements OnInit {
  @ViewChild('selectionCardComponent', { static: false })
  selectionCardComponent: any;
  fullName: any;
  pageLabels: any;
  express: any;
  extras: any;
  annual_passes: any;
  studio_pass: any;
  ticketDetails: any;
  entitlements: any;
  entryDate: any;
  issueDate: any;
  ticketId: any;
  legacyDescription: any;
  mockData = mockData;
  entitlementData: any[];
  travelMembersDetails: any[];
  attractionsData: any;
  isExpiredTickets = false;
  firstName: any;
  lastName: any;
  ticketsImage: any;
  baseUrl: any;
  expirationDate: string;
  ticketStatus: any;
  isOrderPage = false;
  variantArray: any;
  baseCode: any;
  baseProductArray: any;
  formattedCurrentDate: any;
  formattedComingDate: any;
  baseProductTridion: any;
  isDateChange_allowed = false;

  constructor(
    public route: ActivatedRoute,
    protected injector: Injector,
    private title: Title,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('AppDefaults') public appDefaults: any,
    public commonUtilityService: CommonUtilityService,
    private localStorage: LocalStorageService,
    private cookieService: CookieService,
    public guestsService: MsGuestsService,
    private spinner: SpinnerService,
    private cdRef: ChangeDetectorRef,
    private datePipe: DatePipe
  ) {
    if (this.commonUtilityService?.getResolvedPageData) {
      this.pageLabels = this.commonUtilityService.getResolvedPageData();
    }
    this.title.setTitle(this.pageLabels?.['page.ticketDetail']);
  }

  /**
   * ngOnInit - Get ticket id from url, call getEntitledDetails() function
   *
   */
  ngOnInit(): void {
    this.baseUrl = environment?.tridionBaseUrl;
    this.entitlementData = this.commonUtilityService.getResolvedData();
    this.travelMembersDetails =
      this.commonUtilityService.getTravelMembersDetails();
    this.ticketId = this.route.snapshot.params['ticketId'];
    const legacyDesc = this.pageLabels?.['linkticket.legacyDesc'];
    this.legacyDescription = legacyDesc?.replace(
      this.pageLabels?.['legacyCTA.oldSystem'],
      ''
    );
    this.legacyDescription = this.legacyDescription?.replace(/\./g, '');
    this.getEntitledDetails();
  }

  /**
   * getDate - It will format the date based on locale
   */
  getDate(date: any, weekday?: boolean): string {
    const formattedDate = date?.slice(0, 10);
    return this.commonUtilityService.getFormattedDateOnLocale(
      formattedDate,
      weekday
    );
  }

  /**
   * getTicketPictures - Fetch ticket pictures from tridion
   */

  toPassProductData(data: any): void {
    this.getTicketDetails(data);
    this.selectionCardComponent?.getBaseProduct(data);
    this.getImageContainer(data);
  }
  /**
   * getTicketPictures - Fetch ticket pictures from tridion
   */
  getImageContainer(data: any): void {
    this.spinner.show();
    const tridionContent = data?.tridionContent;
    if (tridionContent) {
      this.baseProductTridion =
        JSON.parse(tridionContent)?.[0]?.data?.productDetailPage;
      this.getTicketPictures(this.baseProductTridion);
    } else {
      this.spinner.hide();
    }
  }
  /**
   * getTicketPictures - Fetch ticket pictures from tridion
   */
  getTicketPictures(data: any): void {
    this.commonUtilityService?.fetchCoupons(data)?.subscribe(
      (res: any) => {
        this.ticketsImage =
          res?.[0]?.data?.elements?.[0]?.component?.[0]?.image?.[0];
        this.spinner.hide();
      },
      (error: any) => {
        this.spinner.hide();
        console.error(error);
      }
    );
  }
  /**
   * getTicketDetails - Pass data to check which type of ticket it is
   * @param data - data contains ticket type
   */
  getTicketDetails(data: any): void {
    const productCategoryName = data?.categoryCode;
    switch (productCategoryName) {
      case this.globalDefault.COMM_ANNUAL_PASS:
        this.annual_passes = true;
        break;
      case this.globalDefault.COMM_STUDIO_PASS:
        this.studio_pass = true;
        break;
      case this.globalDefault.COMM_EXPRESS_PASS:
        this.express = true;
        break;
      default:
        this.extras = true;
    }
    this.cdRef.detectChanges();
  }

  /**
   * getEntitledDetails - API call to get details of ticket
   */
  getEntitledDetails(): any {
    this.getGuestId(this.ticketId);
    for (const element of this.entitlementData) {
      if (element['ticket_id'] === this.ticketId) {
        this.entitlements = element;
        this.getTicketUserName(element);
        this.getTicketStatus(element);
        this.toPassProductData(element);
        if (
          this.entitlements &&
          this.entitlements?.valid_to !== undefined &&
          this.entitlements?.encode_date_time !== undefined
        ) {
          this.expirationDate = formatDate(
            this.entitlements?.valid_to,
            'YYYYMMdd',
            'en-US'
          );

          this.issueDate = formatDate(
            new Date(this.entitlements?.encode_date_time),
            'yyyyMMdd',
            'en-US'
          );

          this.entryDate = formatDate(
            this.entitlements?.valid_from,
            'YYYYMMdd',
            'en-US'
          );
        } else {
          // Handle the case when the date properties are undefined
          console.error('Date properties are undefined. Cannot format dates.');
          this.entryDate = '';
          this.issueDate = '';
        }

        if (
          element['product_category_name'] ===
          this.globalDefault.PRODUCT_EXPRESS
        ) {
          this.attractionsData = this.entitlements?.entitlement?.ent_list
            ?.ent_list
            ? this.entitlements?.entitlement?.ent_list?.ent_list
            : this.entitlements?.entitlement?.ent_list; //this.entitlements?.entitlement?.ent_list?.ent_list
        }
      }
    }
  }

  /**
   * getGuestId - To get the entitlement data of logged in user
   * @param data - contains ticket id to get details
   */
  getGuestId(data: any): void {
    const guestId = this.guestsService.helper.getGuestId();
    let matchingEntitlement = _.find(
      this.entitlementData,
      (item: any) => item?.ticket_id === data
    );
    matchingEntitlement ? matchingEntitlement.guest_id : null;
    this.entitlementData = [];
    if (guestId == matchingEntitlement?.guest_id) {
      this.entitlementData.push(matchingEntitlement);
    } else {
      const trpMember = _.filter(
        this.travelMembersDetails,
        (item: any) => item?.member_id === matchingEntitlement?.guest_id
      );

      if (trpMember.length > 0) {
        matchingEntitlement = {
          ...matchingEntitlement,
          first: trpMember[0]?.contact?.first,
          last: trpMember[0]?.contact?.last,
        };
      } else {
        matchingEntitlement = {
          ...matchingEntitlement,
          first: null,
          last: null,
        };
      }

      this.entitlementData.push(matchingEntitlement);
    }
  }

  /**
   * getTicketUserName - To get the name of user ticket associated with it
   * @param element - contains user information
   */
  getTicketUserName(element: any): void {
    this.isExpiredTickets = this.ticketExpired(element?.valid_to);
    if (element?.first) {
      this.firstName = element?.first;
      this.lastName = element?.last;
      this.fullName = `${element?.last}  ${element?.first}`;
    } else {
      this.firstName = this.localStorage.getItem('USER_FNAME');
      this.lastName = this.localStorage.getItem('USER_LNAME');
      this.fullName = `${this.localStorage.getItem(
        'USER_LNAME'
      )}  ${this.localStorage.getItem('USER_FNAME')}`;
    }
  }
  /**
   * redirectTo -  redirect to respective route on basis of type
   * @param type - type to redirect route
   */
  redirectTo(type: any, entitlements?: any): void {
    const isOrderPage = this.localStorage.getItem('isOrderPage');
    this.isOrderPage = isOrderPage === 'true';
    if (this.isOrderPage) {
      this.commonUtilityService.changeRoute(
        `${this.appRoutes.ORDER_DETAILS}/${this.localStorage.getItem(
          'orderId'
        )}`
      );
    }
    if (this.isExpiredTickets == true) {
      this.commonUtilityService.changeRoute(this.appRoutes.PAST_TICKETS);
    }
    if (this.isExpiredTickets == false && this.isOrderPage == false) {
      switch (type) {
        case this.globalDefault.BACK_NAVIGATION:
          this.commonUtilityService.changeRoute(this.appRoutes.VIEW_ENTITLMENT);
          break;
        case this.globalDefault.VIEW_TERMS:
          window.open(
            this.pageLabels?.['tdStudioPass.viewTermsLink'],
            '_blank'
          );
          break;
        case this.globalDefault.COMPLETE_AP:
          window.open(
            environment.COMPLETE_AP +
              '?ticketcode=' +
              entitlements?.ticket_code,
            '_blank'
          );
          break;
      }
    }
  }

  /**
   * oldSystemLink - checkold system link
   **/

  oldSystemLink(): void {
    window.open(
      this.pageLabels?.['linkticket.legacyOrderLink'],
      this.appDefaults.BLANK
    );
  }

  /**
   * dateChangeEditBtnClicked - on click of edit date function get called
   */
  dateChangeEditBtnClicked(): void {
    this.commonUtilityService.clearAllCookies(true, false, false, false);
    const dateChangeObj: any = {
      productName: this.entitlements?.product_name,
      validDateFrom: this.entitlements?.valid_from,
      validDateTo: this.entitlements?.valid_to,
      ticketId: this.entitlements?.ticket_id,
      productCode: this.entitlements?.product_code,
      firstName: this.firstName,
      lastName: this.lastName,
      guestId: this.entitlements?.guest_id,
    };
    this.cookieService.delete(
      'apTicketInformation',
      '/',
      `${environment.cookieSubDomain}`
    );
    this.cookieService.delete(
      'isRenewalProduct',
      '/',
      `${environment.cookieSubDomain}`
    );
    const ticketInformation: any = JSON.stringify(dateChangeObj);
    this.cookieService.set(
      'isDateChangeProduct',
      'true',
      0,
      '/',
      `${environment.cookieSubDomain}`
    );

    this.cookieService.set(
      'dateChangeCookie',
      ticketInformation,
      0,
      '/',
      `${environment.cookieSubDomain}`
    );
    this.commonUtilityService.onClickRedirectWTS();
  }

  /**
   * ticketExpired - It will check the expired tickets
   **/
  ticketExpired(validDate: string): any {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in "yyyy-MM-dd" format
    return validDate < currentDate;
  }

  /**
   * onClickRenewApTicket - To renew the ticket
   */
  onClickRenewApTicket(): void {
    this.commonUtilityService.clearAllCookies(false, true, false, false);
    const {
      ticket_id,
      ticket_code,
      guest_id,
      product_code,
      product_name,
      valid_from,
      valid_to,
    } = this.entitlements;
    const wtsAPRenewRequiredProps: any = {
      guestId: guest_id,
      ticketId: ticket_id,
      previousPurchasedProduct: product_code,
      productName: product_name,
      ticketCode: ticket_code,
      validDateFrom: valid_from,
      validDateTo: valid_to,
      firstName: this.firstName,
      lastName: this.lastName,
    };
    this.cookieService.delete(
      'isDateChangeProduct',
      '/',
      `${environment.cookieSubDomain}`
    );
    this.cookieService.delete(
      'dateChangeCookie',
      '/',
      `${environment.cookieSubDomain}`
    );
    this.cookieService.set(
      'isRenewalProduct',
      'true',
      0,
      '/',
      `${environment.cookieSubDomain}`
    );
    this.cookieService.set(
      'apTicketInformation',
      JSON.stringify(wtsAPRenewRequiredProps),
      0,
      '/',
      `${environment.cookieSubDomain}`
    );
    this.commonUtilityService.onClickRedirectWTS();
  }

  /**
   * getTicketStatus - Get Ticket status
   * @param element - contains ticket details
   */
  getTicketStatus(element: any): void {
    const statusKey = `MS.ticketStatus.${element?.ticket_status}`;
    const statusValue = TICKET_STATUS[statusKey];
    if (this.pageLabels && statusValue) {
      const mappedValue = (this.pageLabels as any)[statusValue];
      this.ticketStatus = mappedValue;
      this.isExpiredTickets = true;
    } else {
      const mappedValue = this.pageLabels?.['ticketStatus.expired'];
      this.ticketStatus = mappedValue;
    }
    this.getStudioPass(element);
  }
  getStudioPass(element: any): void {
    if (
      this.isExpiredTickets &&
      element?.categoryCode === this.globalDefault?.COMM_STUDIO_PASS
    ) {
      this.commonUtilityService
        ?.getIsDateChangeable(element.ticket_id)
        ?.subscribe(
          (res: any) => {
            const dynamicKey = Object.keys(res)[0];
            const isDateChangeable =
              res[dynamicKey]?.rescheduleValidation?.isAllowed;
            this.isDateChange_allowed = isDateChangeable;
          },
          (error: any) => {
            this.spinner.hide();
            console.error(error);
          }
        );
    }
  }
  convertToJapanTime(utcDate: any): any {
    return this.datePipe.transform(utcDate, 'HH:mm', '+0900');
  }
  generateHeading(): { heading: string; categoryLabel: string } {
    let heading = '';
    let categoryLabel = '';

    // Format date range
    const formatDateRange = (fromDate: Date, toDate: Date): string => {
      return `${this.getDate(fromDate, true)} ~ ${this.getDate(toDate, true)}`;
    };

    // Format single date with Japan time
    const formatSingleDateWithJapanTime = (date: Date): string => {
      return `${this.convertToJapanTime(date)}`;
    };

    // Format time based on dynamic startTime value
    const formatTimeBasedOnCondition = (date: Date | string): string => {
      const startTime = this.entitlements?.first_day_guest_start_time;

      if (startTime) {
        // Extract hour and minute from startTime
        const [hour, minute] = startTime.split(':');

        // Example: Formatting based on dynamic startTime
        // Convert to 'HH:mm' format
        const formattedTime = `${hour}:${minute}`;

        // If the startTime is specifically '15:00:00', return '15:00' (example case)
        if (startTime === '15:00:00') {
          return formattedTime;
        } else {
          // If you want to handle other specific formats or conditions, add here
          // For now, return formattedTime for all other cases
          return formattedTime;
        }
      } else {
        // Default formatting if no startTime is available
        return formatSingleDateWithJapanTime(date as Date);
      }
    };

    // Helper function to get the date_time_to
    const getDateTimeTo = (): Date | string => {
      const lastPerformance =
        this.entitlements?.performances?.[
          this.entitlements?.performances?.length - 1
        ];
      return lastPerformance?.date_time_to || '';
    };

    // Define the dates based on the presence of first_day_guest_start_time
    const time_from: Date | string =
      this.entitlements?.first_day_guest_start_time ||
      this.entitlements?.performances?.[0]?.date_time_from ||
      '';

    const time_to: Date | string = getDateTimeTo();
    const valid_from = this.entitlements?.valid_from || '';
    const days = this.entitlements?.days || 0;
    const integerPart = Math.floor(days);
    const fractionalPart = days % 1;

    switch (true) {
      case integerPart === 0 && fractionalPart === 0.5:
        // Handle 0.5 day studio passes
        heading = `${this.getDate(
          valid_from,
          true
        )} ${formatTimeBasedOnCondition(time_from as Date)} ~`;
        categoryLabel = this.pageLabels?.['tdStudioPass.entryDate'] || '';
        break;

      case fractionalPart === 0.5:
        // Handle cases 1.5, 2.5, 3.5 studio passes, etc.
        heading = `${this.getDate(
          valid_from,
          true
        )} ${formatTimeBasedOnCondition(time_from as Date)} ~ ${this.getDate(
          time_to as Date,
          true
        )}`;
        categoryLabel = this.pageLabels?.['tdStudioPass.entryDate'] || '';
        break;

      case integerPart === 1:
        // Handle 1 day studio passes
        heading = `${this.getDate(valid_from, true)}`;
        categoryLabel = this.pageLabels?.['tdStudioPass.entryDate'] || '';
        break;

      default:
        // Handle 2 day studio passes
        heading = formatDateRange(
          valid_from as Date,
          this.entitlements?.valid_to as Date
        );
        categoryLabel = this.pageLabels?.['tdStudioPass.entryDate'] || '';
        break;
    }

    return { heading, categoryLabel };
  }
}
