export const ORDER_STATUS: any = {
  'Comm.orderStatus.PAYMENT_NOT_AUTHORIZED':
    'Comm.orderStatus.PAYMENT_NOT_AUTHORIZED',
  'Comm.orderStatus.PAYMENT_NOT_AUTHORIZED.desc':
    'Comm.orderStatus.PAYMENT_NOT_AUTHORIZED.desc',
  'Comm.orderStatus.WAITING_FOR_REFUND': 'Comm.orderStatus.WAITING_FOR_REFUND',
  'Comm.orderStatus.PRODUCT_REFUNDED': 'Comm.orderStatus.PRODUCT_REFUNDED',
  'Comm.orderStatus.PRODUCT_REFUNDED_FAILED':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED',
  'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.APPROVED': 'Comm.orderStatus.APPROVED',
  'Comm.orderStatus.PARTIAL_REFUNDED': 'Comm.orderStatus.PARTIAL_REFUNDED',
  'Comm.orderStatus.PARTIAL_REFUNDED.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.PAYMENT_CAPTURED': 'Comm.orderStatus.PAYMENT_CAPTURED',
  'Comm.orderStatus.PAYMENT_NOT_CAPTURED':
    'Comm.orderStatus.PAYMENT_NOT_AUTHORIZED',
  'Comm.orderStatus.PAYMENT_NOT_CAPTURED.desc':
    'Comm.orderStatus.PAYMENT_NOT_CAPTURED.desc',
  'Comm.orderStatus.REFUNDED': 'Comm.orderStatus.REFUNDED',
  'Comm.orderStatus.REJECTED': 'Comm.orderStatus.REJECTED',
  'Comm.orderStatus.REJECTED.desc': 'Comm.orderStatus.REJECTED.desc',
  'Comm.orderStatus.REFUND_FAILED': 'Comm.orderStatus.REFUND_FAILED',
  'Comm.orderStatus.REFUND_FAILED.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.ORDER_SPLIT': 'Comm.orderStatus.PAYMENT_CAPTURED',
  'Comm.orderStatus.REFUNDING': 'Comm.orderStatus.REFUNDING',
  'Comm.orderStatus.PROCESSING_ERROR': 'Comm.orderStatus.PAYMENT_NOT_CONFIRMED',
  'Comm.orderStatus.PROCESSING_ERROR.desc':
    'Comm.orderStatus.PROCESSING_ERROR.desc',
  'Comm.orderStatus.WAITING_FOR_PAYMENT':
    'Comm.orderStatus.WAITING_FOR_PAYMENT',
  'Comm.orderStatus.WAITING_FOR_PAYMENT.desc':
    'Comm.orderStatus.PAYMENT_NOT_CAPTURED.desc',
  'Comm.orderStatus.B2B_PROCESSING_ERROR':
    'Comm.orderStatus.PAYMENT_NOT_CONFIRMED',
  'Comm.orderStatus.B2B_PROCESSING_ERROR.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.WAITING_FOR_EXCHANGE':
    'Comm.orderStatus.WAITING_FOR_EXCHANGE',
  'Comm.orderStatus.WAITING_FOR_EXCHANGE.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.EXCHANGED': 'Comm.orderStatus.EXCHANGED',
  'Comm.orderStatus.EXCHANGE_FAILED': 'Comm.orderStatus.EXCHANGE_FAILED',
  'Comm.orderStatus.EXCHANGE_FAILED.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.ERROR': 'Comm.orderStatus.PAYMENT_NOT_CONFIRMED',
  'Comm.orderStatus.ERROR.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.CLOSED': 'Comm.orderStatus.APPROVED',
  'Comm.orderStatus.IN_REVIEW': 'Comm.orderStatus.IN_REVIEW',
  'Comm.orderStatus.IN_REVIEW.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.DRAFTED': 'Comm.orderStatus.PAYMENT_NOT_CONFIRMED',
  'Comm.orderStatus.DRAFTED.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.EXPIRED': 'Comm.orderStatus.EXPIRED',
  'Comm.orderStatus.EXPIRED.desc': 'Comm.orderStatus.EXPIRED.desc',
  'Comm.orderStatus.REFUND_REQUESTED': 'Comm.orderStatus.REFUND_REQUESTED',
  'Comm.orderStatus.CVS_PAYMENT_REQSUCCESS':
    'Comm.orderStatus.CVS_PAYMENT_REQSUCCESS',
  'Comm.orderStatus.CVS_PAYMENT_REQSUCCESS.desc': 'orderbefPayment.callOutInfo',
  'Comm.orderStatus.CVS_PAYMENT_PAYSUCCESS': 'Comm.orderStatus.APPROVED',
  'Comm.orderStatus.CVS_PAYMENT_PAYFAIL':
    'Comm.orderStatus.CVS_PAYMENT_PAYFAIL',
  'Comm.orderStatus.CVS_PAYMENT_PAYFAIL.desc':
    'Comm.orderStatus.CVS_PAYMENT_PAYFAIL.desc',
  'Comm.orderStatus.CVS_PAYMENT_EXPIRED':
    'Comm.orderStatus.CVS_PAYMENT_EXPIRED',
  'Comm.orderStatus.CVS_PAYMENT_EXPIRED.desc':
    'Comm.orderStatus.CVS_PAYMENT_EXPIRED.desc',
  'Comm.orderStatus.CVS_PAYMENT_CANCELLED':
    'Comm.orderStatus.CVS_PAYMENT_CANCELLED',
  'Comm.orderStatus.CVS_PAYMENT_CANCELLED.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.TIMED_OUT': 'Comm.orderStatus.TIMED_OUT',
  'Comm.orderStatus.TIMED_OUT.desc': 'Comm.orderStatus.TIMED_OUT.desc',
  'Comm.orderStatus.AUTO_APPROVED': 'Comm.orderStatus.APPROVED',
  'Comm.orderStatus.PROCESSING': 'Comm.orderStatus.PROCESSING',
  'Comm.orderStatus.PROCESSING.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.CONFIRMED': 'Comm.orderStatus.APPROVED',
  'Comm.orderStatus.PENDING_APPROVAL': 'Comm.orderStatus.PAYMENT_NOT_CONFIRMED',
  'Comm.orderStatus.PENDING_APPROVAL.desc':
    'Comm.orderStatus.PENDING_APPROVAL.desc',
  'Comm.orderStatus.COMPLETED': 'Comm.orderStatus.APPROVED',
  'Comm.orderStatus.CONFIRM_FAILED': 'Comm.orderStatus.PAYMENT_NOT_CONFIRMED',
  'Comm.orderStatus.CONFIRM_FAILED.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.CREATED': 'Comm.orderStatus.PAYMENT_NOT_CONFIRMED',
  'Comm.orderStatus.CREATED.desc':
    'Comm.orderStatus.PRODUCT_REFUNDED_FAILED.desc',
  'Comm.orderStatus.CANCELLED': 'Comm.orderStatus.CANCELLED',
};
