import { Component, Inject, Injector, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EventNotificationService } from '@upr-web-primer/event-notification';
import { FormControlService } from '@upr-web-primer/form-fields';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { SpinnerService } from '@upr-web-primer/spinner';
import {
  LocalStorageService,
  SessionStorageService,
} from '@upr-web-primer/storage';
import { UrlInfo, UrlInfoService } from '@upr-web-primer/url-info';
import { WINDOW } from '@upr-web-primer/window';
import { get } from 'lodash-es';
import { EmailPasswordPattern } from '../../../config/pattern.constant';
import { EnvironmentService } from '../../../environments/environment.service';
import { ApplicationDataService } from '../../services/application-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  /**
   * Url info
   * @type UrlInfo
   */
  urlInfo: UrlInfo;

  /**
   * Page
   * @type any
   */
  page: any;

  /**
   * Page labels
   * @type any
   */
  pageLabels: any;

  /**
   * Component presentations
   * @type Array<any>
   */
  componentPresentations: Array<any>;

  /**
   * Forgot password link
   * @type string
   */
  forgotPasswordLink: string;

  /**
   * Login form
   * @type FormGroup
   */
  loginForm: FormGroup;

  /**
   * Flag: Is sign off
   * @type {boolean}
   */
  isSignOff = false;
  emailRegex = EmailPasswordPattern.LOGIN_EMAIL;

  /**
   * Parameter for iOS account delete.
   */
  isAccountDelete = false;
  termsConditionSelected: boolean;

  private route: ActivatedRoute;
  private common: CommonUtilityService;
  private guestsService: MsGuestsService;
  private formControlService: FormControlService;
  private notification: NotificationService;
  private urlInfoService: UrlInfoService;
  private sessionStorage: SessionStorageService;
  private environment: EnvironmentService;
  returnValue: boolean;
  constructor(
    protected injector: Injector,
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('Ranges') private ranges: any,
    @Inject(WINDOW) private window: any,
    private formBuilder: FormBuilder,
    private eventNotificationService: EventNotificationService,
    private spinner: SpinnerService,
    private localStorage: LocalStorageService,
    private applicationDataService: ApplicationDataService
  ) {
    this.setDependencies();
    this.isAccountDelete = this.window.location?.search.includes(
      'isAccountDelete=true'
    );
    this.urlInfo = this.route.snapshot.data.urlInfo;
    this.page = this.route.snapshot.data.page;
    this.componentPresentations =
      get(this.page, 'ComponentPresentations') || [];
    this.pageLabels = this.formControlService.pageLabels = get(
      this.route.snapshot.data,
      'pageLabels',
      []
    );
    this.forgotPasswordLink =
      this.urlInfoService.getUrlPrefix() + '/' + this.appRoutes.FORGOT_PASSWORD;
    this.route.queryParams?.subscribe((params) => {
      this.isSignOff = Boolean(get(params, 'signoff', false));
    });
    this.common.initPageDDL(this.page);
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    if (this.environment.oidcEnabled === true) {
      this.returnValue = true;
    }

    this.createForm();
  }

  /**
   * redirectToLogin - Redirect To the login page
   */
  redirectToLogin(): void {
    const data = {
      response_Type: this.globalDefault.RESPONSE_TYPE,
      scope: this.globalDefault.SCOPE,
      state: this.globalDefault.WEB,
      nonce: (Math.random() + this.ranges?.PASSWORDS?.INDEX[1])
        .toString(this.ranges?.STRING?.LENGTH)
        .substring(this.ranges?.PASSWORDS?.INDEX[7]),
    };

    this.common?.redirectToLogin(data);
  }

  /**
   * createForm - Create the login form with email and password field
   */
  createForm(): void {
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailRegex),
      ]),
      passWord: new FormControl('', [Validators.required]),
    });
  }

  /**
   * Add login form control
   * @param {string} name
   * @param {FormGroup} formGroup
   */

  /**
   * On login submit
   */
  onValueChange(event: any): void {
    if (event?.target?.id === 'termCondition') {
      if (event?.detail === 'checked') this.termsConditionSelected = true;
      else this.termsConditionSelected = false;

      this.loginForm?.patchValue({
        termCondition: this.termsConditionSelected,
      });
    }
  }

  /**
   * onLoginSubmit - On click of signin button function getting called
   * @returns - Show the error message
   */
  onLoginSubmit(): void {
    this.common.validateAllFormFields(this.loginForm);

    if (!this.loginForm?.valid) {
      this.common?.notifyFormErrorEvent(
        'Account Login Form',
        this.pageLabels.ER7
      );
      return this.notification.show(this.pageLabels?.['errCode.E019']);
    }
    const redirectUri = this.common.getOidcRedirectionUrl();
    this.guestsService.account
      .login(
        get(this.loginForm, 'value.email'),
        get(this.loginForm, 'value.passWord'),
        redirectUri,
        undefined,
        this.environment.oauthAppSourceId
      )
      .then((data) => {
        if (data) {
          this.sessionStorage.setItem('RETAIN_NOTIFICATION', 'false');
          this.common.notifyFormSuccessEvent('Account Login Form', 'OK');
          if (this.isAccountDelete) {
            this.common.changeRoute(
              this.appRoutes.DELETE_ACCOUNT,
              undefined,
              'preserve'
            );
          } else {
            this.eventNotificationService.eventNotification.next({
              action: 'cartCountUpdated',
            });
          }

          this.sessionStorage.setItem(
            'oAuthLoginUserPassword',
            this.loginForm.value.passWord
          );
          this.common.changeRoute(this.appRoutes.DASHBOARD);
        }
      })
      .catch((err) => {
        console.error(err);
        this.common.notifyFormErrorEvent('Account Login Form', err);
      });
  }

  /**
   * User sign off
   * @returns {Promise<any>}
   */
  // userSignOff(): Promise<any> {
  //   return this.guestsService?.account
  //     ?.logout()
  //     .then(() => {
  //       this.eventNotificationService.eventNotification.next({
  //         action: 'cartCountUpdated',
  //       });
  //       this.common.notifyFormSuccessEvent('Account Logout', 'OK');
  //     })
  //     .catch(() => {
  //       const error = 'Error on logoff';
  //       console.error(error);
  //       this.common.notifySystemErrorEvent(error, 'Account Logout');
  //       return error;
  //     });
  // }

  /**
   * Init form
   */

  signUp(): void {
    this.common.changeRoute(this.appRoutes.CREATE_ACCOUNT);
  }

  private setDependencies(): void {
    this.route = this.injector.get(ActivatedRoute);
    this.common = this.injector.get(CommonUtilityService);
    this.guestsService = this.injector.get(MsGuestsService);
    this.formControlService = this.injector.get(FormControlService);
    this.notification = this.injector.get(NotificationService);
    this.urlInfoService = this.injector.get(UrlInfoService);
    this.sessionStorage = this.injector.get(SessionStorageService);
    this.environment = this.injector.get(EnvironmentService);
  }

  /**
   * commerceAuthentication - After oidc login, commerce token and login service getting called
   */
  commerceAuthentication(): any {
    this.spinner.show();
    this.common.getB2COAuthToken().subscribe((authData: any) => {
      this.localStorage.setItem('authObj', JSON.stringify(authData));

      if (authData) {
        this.common.postauthLogin().subscribe(
          (loginAPIResponse: any) => {
            this.spinner.hide();
            this.localStorage.setItem(
              'loginAuthToken',
              JSON.stringify(loginAPIResponse)
            );
            this.applicationDataService.setApplicationCookieInfo(
              'commerceTokenInfo',
              JSON.stringify(loginAPIResponse)
            );

            this.common.changeRoute(this.appRoutes.DASHBOARD);
          },
          (errors: any) => {
            const errCode = 'errCode.' + errors.error?.errorCode;
            const errorCode = errors.error?.errorCode
              ? this.pageLabels?.[errCode]
                ? this.pageLabels?.[errCode]
                : this.pageLabels?.['errCode.E019']
              : errors.error?.errors[0]?.message;
            this.notification?.show(errorCode);
          }
        );
      }
    });
  }
}
