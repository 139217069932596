import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { WarningTravelPartyComponent } from '../components/warning-travel-party/warning-travel-party.component';
import { AddPartyFormComponent } from './components/add-party-form/add-party-form.component';
import { CouponCardComponent } from './components/coupon-card/coupon-card.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { SelectionCardComponent } from './components/selection-card/selection-card.component';
import { TicketCardComponent } from './components/ticket-card/ticket-card.component';
import { ToastModule } from './module/toast.module';
import { PostalCodePipe } from './postal-code.pipe';
@NgModule({
  declarations: [
    PasswordStrengthComponent,
    SelectionCardComponent,
    CouponCardComponent,
    AddPartyFormComponent,
    TicketCardComponent,
    PostalCodePipe,
    WarningTravelPartyComponent,
  ],
  imports: [CommonModule, ToastModule.forRoot()],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [
    PasswordStrengthComponent,
    SelectionCardComponent,
    CouponCardComponent,
    AddPartyFormComponent,
    TicketCardComponent,
    PostalCodePipe,
    WarningTravelPartyComponent,
  ],
})
export class SharedModule {}
