import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Inject, Injectable, Injector } from '@angular/core';
import {
  ToastConfig,
  ToastData,
  TOAST_CONFIG_TOKEN,
} from '../classes/toast-config';
import { ToastRef } from '../classes/toast-ref';
import { ToastComponent } from '../components/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private lastToast: ToastRef;

  constructor(
    private overlay: Overlay,
    private parentInjector: Injector,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
  ) {}

  /**
   * show - to show the toast
   * @param data - ToastData
   * @returns
   */

  show(data: ToastData): any {
    const positionStrategy = this.getPositionStrategy();
    const overlayRef = this.overlay.create({ positionStrategy });

    const toastRef = new ToastRef(overlayRef);
    this.lastToast = toastRef;

    const injector = this.getInjector(data, toastRef, this.parentInjector);
    const toastPortal = new ComponentPortal(ToastComponent, null, injector);

    overlayRef?.attach(toastPortal);

    return toastRef;
  }

  /**
   * getPositionStrategy - to set position
   * @returns - overlay position
   */
  getPositionStrategy(): any {
    const data = this.toastConfig;
    return this.overlay
      ?.position()
      ?.global()
      ?.top(this.getPosition())
      ?.right(data?.position?.right + 'px');
  }

  /**
   * getPosition - To get the position
   * @returns
   */

  getPosition(): any {
    const lastToastIsVisible = this.lastToast && this.lastToast.isVisible();
    const data = this.toastConfig;
    const position = lastToastIsVisible
      ? this.lastToast.getPosition().bottom
      : data?.position?.top;

    return position + 'px';
  }

  /**
   * getInjector - getInjector
   * @param data - ToastData
   * @param toastRef -ToastRef
   * @param parentInjector - Injector
   * @returns
   */

  getInjector(
    data: ToastData,
    toastRef: ToastRef,
    parentInjector: Injector
  ): any {
    const tokens = new WeakMap();

    tokens.set(ToastData, data);
    tokens.set(ToastRef, toastRef);

    return new PortalInjector(parentInjector, tokens);
  }
}
