<div class="container">
  <!--back to  dashboard page from birthday page-->
  <div class="club-universal-btn">
    <gds-utility-action
      [data]="{
              icon: {
              iconName: 'left-arrow-v2'
              },
              label: pageLabels?.['birthdayCoupon.backCTA'],
              type: 'left-arrow-v2',
              disabled: false
              }"
      (click)="goToPage('BIRTHDAY_DISCOUNT')"
    ></gds-utility-action>
  </div>
  <!--dynamically injecting the birthday container from the Tridion API response-->
  <ng-container #birthDayContainer></ng-container>
</div>
