export const SourceIdKey = {
  en: {
    WebUSJAccountCreation: '1032001',
    WebUSJAssignEntitlements: '1032002',
    WebUSJPurchaseConfirmation: '1032003',
    WebUSJEditCommunications: '1032004',
    WebUSJEditContactInfo: '1032005',
    WebUSJEditPersonalInfo: '1032006',
    WebUSJEditAddress: '1032007',
    WebUSJCheckout: '1032008',
    WebUSJPaymentBillingInfo: '1032009',
    WebUSJEmailNewsLetterGeneral: '1032010',
    WebUSJEmailUnsubscribeorEditInterest: '1032011',
    WebUSJCreateorEditProfileTravelParty: '1032012',
    WebUSJCard: '1032013',
    WebUSJSpendLimit: '1032014',
    WebUSJPIN: '1032015',
    WebUSJTravelPartyMember: '1032016',
    WebUSJLinkEntitlements: '1032017',
    WebUSJPackagesPurchaseConfirmation: '1032018',
    WebUSJPackagesCheckout: '1032019',
    WebUSJAccountLockedOutMultipleAttempts: '1032022',
    WebUSJEditPwd: '1032023',
    WebUSJAccountDelete: '1032024',
    WebUSJRetrieveEventReservation: '1032025',
    WebUSJResendEventReservation: '1032026',
    WebUSJCancelEventReservation: '1032027',
    WebUSJDiningReservation: '1032028',
  },
  ja: {
    WebUSJAccountCreation: '1032001',
    WebUSJAssignEntitlements: '1032002',
    WebUSJPurchaseConfirmation: '1032003',
    WebUSJEditCommunications: '1032004',
    WebUSJEditContactInfo: '1032005',
    WebUSJEditPersonalInfo: '1032006',
    WebUSJEditAddress: '1032007',
    WebUSJCheckout: '1032008',
    WebUSJPaymentBillingInfo: '1032009',
    WebUSJEmailNewsLetterGeneral: '1032010',
    WebUSJEmailUnsubscribeorEditInterest: '1032011',
    WebUSJCreateorEditProfileTravelParty: '1032012',
    WebUSJCard: '1032013',
    WebUSJSpendLimit: '1032014',
    WebUSJPIN: '1032015',
    WebUSJTravelPartyMember: '1032016',
    WebUSJLinkEntitlements: '1032017',
    WebUSJPackagesPurchaseConfirmation: '1032018',
    WebUSJPackagesCheckout: '1032019',
    WebUSJAccountLockedOutMultipleAttempts: '1032022',
    WebUSJEditPwd: '1032023',
    WebUSJAccountDelete: '1032024',
    WebUSJRetrieveEventReservation: '1032025',
    WebUSJResendEventReservation: '1032026',
    WebUSJCancelEventReservation: '1032027',
    WebUSJDiningReservation: '1032028',
  },
};
