<!--closeCTA back to address list page-->
<gds-full-screen-modal opened (modalClose)="backtoAddressList()">
  <div slot="components">
    <div class="container modal-content" #updateAddressModalContent>
      <gds-spacer classes="vertical-spacer-50"></gds-spacer>
      <div class="address-content">
        <ng-container>
          <!--Edit-Address heading -->
          <gds-heading
            [data]="{
            heading:pageLabels?.['editAddress.heading']
          }"
            class="A2-W5"
          >
          </gds-heading>
          <gds-spacer classes="spacer-50"></gds-spacer>

          <gds-body
            [data]="{
              description: pageLabels?.['editAddress.subText']
            }"
            class="B1-W3"
          ></gds-body>
          <gds-spacer classes="spacer-70"></gds-spacer>
          <!--Edit-Address formControl&formGroup creation -->
          <div [formGroup]="updateAddressForm">
            <gds-input
              formControlName="postalCode"
              ngDefaultControl
              id="postalCode"
              hide-spinner="true"
              [errors]="postalErrorMsg"
              [data]="{
              primaryLabel: pageLabels?.['editAddress.postalCode'],
              tooltip: '',
              type: 'number',
              altText: 'input for postal code of the user',
              ariaText: 'input for postal code of the user',
              required: true
            }"
              (blurChange)="getErrorMessageInfo($event)"
              onKeyPress="return /[0-9]/i.test(event.key) && this.value.length < 7;"
            >
            </gds-input>

            <gds-spacer classes="spacer-30"></gds-spacer>

            <gds-button
              class="autofill-btn"
              [data]="{
              type: 'secondary',
              href: '',
              label:  pageLabels?.['editAddress.autoFill'],
              disabled: false,
              target: ''
            }"
              (buttonClicked)="changePostalCode()"
            ></gds-button>

            <gds-spacer classes="spacer-50"></gds-spacer>

            <gds-dropdown
              *ngIf="showDropdown"
              formControlName="prefecture"
              ngDefaultControl
              id="prefecture"
              [errorMessage]="prefectureErrorMsg"
              [data]="{
              options: showPrefectureDropdownOptions,
              formField: { primaryLabel:   pageLabels?.['editAddress.prefecture']},
              required: true
            }"
              [value]="selectedPrefectureList"
              (valueChange)="matchPrefecture($event)"
              (blurChange)="getErrorMessageInfo($event)"
            >
              <span class="prefectureLabel">{{ prefectureFieldLabel }}</span>
            </gds-dropdown>
            <gds-dropdown
              *ngIf="showEmptyDropdown"
              formControlName="prefecture"
              ngDefaultControl
              id="prefecture"
              [errorMessage]="prefectureErrorMsg"
              [data]="{
            formField: { primaryLabel:   pageLabels?.['editAddress.prefecture']},
            options: [],
            required: true
          }"
              [value]="selectedPrefectureList"
              (valueChange)="matchPrefecture($event)"
              (blurChange)="getErrorMessageInfo($event)"
            >
              <span class="prefectureLabel">{{ prefectureFieldLabel }}</span>
            </gds-dropdown>
            <gds-spacer classes="spacer-50"></gds-spacer>
            <gds-input
              formControlName="city"
              ngDefaultControl
              id="city"
              [errors]="cityErrorMsg"
              [data]="{
              primaryLabel: pageLabels?.['editAddress.city'],
              tooltip: '',
              type: 'text',
              altText: 'input for city name of the user',
              ariaText: 'input for city name of the user',
              required: true
            }"
              (blurChange)="getErrorMessageInfo($event)"
              onkeydown="return /[a-zA-Z]/i.test(event.key)"
            >
            </gds-input>
            <gds-spacer classes="spacer-50"></gds-spacer>
            <gds-input
              formControlName="address1"
              ngDefaultControl
              id="address1"
              [errors]="address1ErrorMsg"
              [data]="{
              primaryLabel:pageLabels?.['editAddress.address1'],
              tooltip: '',
              type: 'text',
              altText: 'input for address 1 of the user',
              ariaText: 'input for address 1 of the user',
              required: true
            }"
              (blurChange)="getErrorMessageInfo($event)"
              onkeydown="return /[a-zA-Z0-9ー-]/.test(event.key);"
            >
            </gds-input>
            <gds-spacer classes="spacer-70"></gds-spacer>
            <gds-input
              formControlName="address2"
              ngDefaultControl
              [data]="{
              primaryLabel: pageLabels?.['editAddress.address2'],
              supportingText: pageLabels?.['editAddress.optional'],
              tooltip: '',
              type: 'text',
              altText: 'input for address 2 of the user',
              ariaText: 'input for address 2 of the user',
              required: true
            }"
              onkeydown="return /[a-zA-Z0-9ー-]/.test(event.key);"
            >
            </gds-input>
            <gds-spacer classes="spacer-70"></gds-spacer>
            <gds-input
              *ngIf="primaryVal === false"
              id="primary"
              ngDefaultControl
              class="checkbox-label"
              [data]="{
              primaryLabel: pageLabels?.['editAddress.primaryAddress'],
              secondaryText: '',
              primaryIconTitleText: '',
              type: 'checkbox',
              altText: 'input of re-enter password',
              ariaText: '',
              required: true
            }"
              (valueChange)="onPrimaryClick($event)"
              [value]="primaryVal ? 'checked' : ''"
            >
            </gds-input>
            <div class="align-left" *ngIf="primaryVal === true">
              <div class="icon-container">
                <gds-svg
                  icon-name="check-mark-box"
                  fill-color="rgba(161,157,157,1)"
                  width="24"
                  height="24"
                ></gds-svg>
                <gds-spacer classes="vertical-spacer-20"></gds-spacer>
                <gds-heading
                  class="B2-W3"
                  classes="heading-7"
                  [data]="{
                heading: pageLabels?.['editAddress.primaryAddress']
              }"
                ></gds-heading>
              </div>
            </div>
            <gds-spacer classes="spacer-30"></gds-spacer>
            <gds-heading
              [data]="{
              heading:
              pageLabels?.['editAddress.info']
            }"
              classes="A2-W5"
              classes="heading-7"
            >
            </gds-heading>
            <gds-spacer classes="spacer-70"></gds-spacer>
            <div class="align-delete-cta">
              <gds-button
                *ngIf="primaryVal === false"
                [data]="{
                type: 'tertiary',
                href: '',
                label:  pageLabels?.['editAddress.delete'],
                disabled: primaryVal ? 'true' : 'false',
                target: ''
              }"
                (buttonClicked)="onDelete()"
              >
              </gds-button>
              <gds-heading
                *ngIf="primaryVal === true"
                class="B1-W4"
                classes="heading-7"
                [data]="{ heading:
            pageLabels?.['editAddress.delete'] }"
              ></gds-heading>
            </div>
            <gds-spacer classes="spacer-85"></gds-spacer>
          </div>
        </ng-container>
      </div>
      <gds-spacer classes="vertical-spacer-50"></gds-spacer>
    </div>
    <!--Edit-Address saveCTA submit the form -->
    <div>
      <gds-sticky-nav-footer
        [data]="{
        button: {
          type: 'primary',
          title: '',
          disabled: updateAddressForm.valid && inputChanged  ? 'false' : 'true' ,
          label: pageLabels?.['editAddress.save']
        }
      }"
        (buttonClicked)="onSubmit()"
      >
      </gds-sticky-nav-footer>
    </div>
  </div>
</gds-full-screen-modal>
