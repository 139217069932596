<gds-full-screen-modal opened (modelClose)="goToLink(type)">
  <!--Confirm travel party model popup-->
  <div slot="components" class="components">
    <div class="container modal-content" #confirmPartyModalContent>
      <gds-spacer classes="vertical-spacer-50"></gds-spacer>
      <div class="content">
        <gds-heading
          [data]="{
          heading: pageLabels?.['trpAddConf.heading']
        }"
          class="A2-W5"
        >
        </gds-heading>
        <gds-spacer classes="spacer-50"></gds-spacer>

        <gds-body
          [data]="{
              description: pageLabels?.['trpConf.subText']
            }"
          class="B1-W3"
        ></gds-body>

        <gds-spacer
          *ngIf="pageLabels?.['trpConf.subText']"
          classes="spacer-50"
        ></gds-spacer>

        <!--Confirm travel party input fields-->
        <div class="card">
          <gds-heading
            [data]="{
            heading: pageLabels?.['trpAddConf.lastName']
          }"
            class="B1-W4"
          >
          </gds-heading>
          <gds-spacer classes="spacer-20"></gds-spacer>
          <gds-heading
            [data]="{
              heading: travelPartyInformation.lastName
            }"
            class="A5-W5"
          >
          </gds-heading>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-right-hand-rail-divider></gds-right-hand-rail-divider>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-heading
            [data]="{
            heading: pageLabels?.['trpAddConf.firstName']
          }"
            class="B1-W4"
          >
          </gds-heading>
          <gds-spacer classes="spacer-20"></gds-spacer>
          <gds-heading
            [data]="{
              heading: travelPartyInformation.firstName
            }"
            class="A5-W5"
          >
          </gds-heading>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-right-hand-rail-divider></gds-right-hand-rail-divider>
          <gds-spacer classes="spacer-50"></gds-spacer>

          <gds-heading
            [data]="{
            heading: pageLabels?.['trpAddConf.relationship']
          }"
            class="B1-W4"
          >
          </gds-heading>
          <gds-spacer classes="spacer-20"></gds-spacer>
          <gds-heading
            [data]="{
            heading: travelPartyInformation?.relationship?.length > 0
                    ? travelPartyInformation?.relationship
                    : this.pageLabels?.['trp.notRegistered']
          }"
            [ngClass]="
              travelPartyInformation?.relationship?.length > 0
                ? 'A5-W5'
                : 'A6-W3'
            "
          >
          </gds-heading>

          <div *ngIf="travelPartyInformation.dob">
            <gds-spacer classes="spacer-50"></gds-spacer>
            <gds-right-hand-rail-divider></gds-right-hand-rail-divider>
            <gds-spacer classes="spacer-50"></gds-spacer>
            <gds-heading
              [data]="{
            heading: pageLabels?.['trpAddConf.birthday']
          }"
              class="B1-W4"
            >
            </gds-heading>
            <gds-spacer classes="spacer-20"></gds-spacer>
            <gds-heading
              [data]="{
                heading: dateVal
              }"
              class="A5-W5"
            >
            </gds-heading>
            <div *ngIf="!travelPartyInformation.dob">
              <gds-spacer classes="spacer-50"></gds-spacer>
              <gds-right-hand-rail-divider></gds-right-hand-rail-divider>
              <gds-spacer classes="spacer-50"></gds-spacer>
            </div>
          </div>
          <div *ngIf="travelPartyInformation.gender">
            <gds-spacer classes="spacer-50"></gds-spacer>
            <gds-right-hand-rail-divider></gds-right-hand-rail-divider>
            <gds-spacer classes="spacer-50"></gds-spacer>
            <gds-heading
              [data]="{
            heading: pageLabels?.['trpAddConf.gender']
          }"
              class="B1-W4"
            >
            </gds-heading>
            <gds-spacer classes="spacer-20"></gds-spacer>
            <gds-heading
              [data]="{
                heading: travelPartyInformation.gender
              }"
              class="A5-W5"
            >
            </gds-heading>
          </div>
          <gds-spacer classes="spacer-70"></gds-spacer>
          <gds-content-callout
            [data]="{
            icon: 'info-solid',
            ariaLabel: pageLabels?.['trpAddConf.notesHeading'],
            borderColor: 'color-1',
            heading: pageLabels?.['trpAddConf.notesHeading'],
            description: pageLabels?.['trpAddConf.notes']
          }"
          >
          </gds-content-callout>
        </div>
        <gds-spacer classes="spacer-85"></gds-spacer>
      </div>
      <gds-spacer classes="vertical-spacer-50"></gds-spacer>
    </div>

    <!--Register footer button-->
    <gds-sticky-nav-footer
      [data]="{
        button: {
          type: 'primary',
          href: '',
          label: pageLabels?.['trpAddConf.register'],
          disabled: isSubmitting ? 'true' : 'false',
          target: ''
        }
      }"
      (buttonClicked)="registerTravelParty()"
    >
    </gds-sticky-nav-footer>
  </div>
</gds-full-screen-modal>
