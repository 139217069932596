<gds-full-screen-modal opened (modalClose)="backToTravelPage()">
  <!--Edit travel party model popup-->
  <div slot="components">
    <!--Warning travel party page section-->
    <div *ngIf="opened">
      <app-warning-travel-party
        [showConfirmPopup]="showConfirmPopup"
        [opened]="opened"
        (backToEditTravel)="onWarning($event)"
      >
      </app-warning-travel-party>
    </div>
    <div class="container modal-content" #editPartyModalContent *ngIf="!opened">
      <!--Confirmation popup component section-->
      <div *ngIf="showConfirmPopup">
        <app-confirm-travel-party-members
          [addTravelPartyForm]="editTravelPartyForm"
          (backToAddTravelParty)="onTravelPartyList($event)"
          [type]="fromPage"
        ></app-confirm-travel-party-members>
      </div>
      <!--Edit travel party data section-->
      <div *ngIf="!opened">
        <gds-heading
          [data]="{
            heading: pageLabels?.['trpUpdate.heading']
          }"
          class="A2-W5"
        >
        </gds-heading>
        <gds-spacer classes="spacer-50"></gds-spacer>

        <gds-content-callout
          [data]="{
            icon: 'info-solid',
            ariaLabel: pageLabels?.['trpAddConf.notesHeading'],
            borderColor: 'color-1',
            heading: pageLabels?.['trpAddConf.notesHeading'],
            description: pageLabels?.['trpAddConf.notes']
          }"
        >
        </gds-content-callout>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <!--Edit travel party form data-->
        <div class="card" [formGroup]="editTravelPartyForm">
          <gds-input
            id="lastName"
            formControlName="lastName"
            [errors]="lastNameErrorMsgInfo"
            ngDefaultControl
            [data]="{
              primaryLabel: pageLabels?.['trpUpdate.lastName'],
              supportingText: '',
              tooltip: '',
              type: 'text',
              altText: 'input of lastName',
              ariaText: '',
              required: true
            }"
            onkeydown="return /[a-zA-Z]/i.test(event.key)"
            (blurChange)="getErrorMessageInfo($event)"
            onKeyPress=" if( this.value?.length === 30 ) return false;"
          >
          </gds-input>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-input
            id="firstName"
            formControlName="firstName"
            [errors]="firstNameErrorMsgInfo"
            ngDefaultControl
            [data]="{
              primaryLabel: pageLabels?.['trpUpdate.firstName'],
              supportingText: '',
              tooltip: '',
              type: 'text',
              altText: 'input of firstName',
              ariaText: '',
              required: true
            }"
            onkeydown="return /[a-zA-Z]/i.test(event.key)"
            (blurChange)="getErrorMessageInfo($event)"
            onKeyPress=" if( this.value?.length === 30 ) return false;"
          >
          </gds-input>
          <gds-spacer classes="spacer-50"></gds-spacer>

          <div *ngIf="relationShow">
            <gds-heading
              [data]="{
              heading: pageLabels?.['trpUpdate.relationship']
            }"
              class="B1-W4"
            >
            </gds-heading>
            <gds-spacer classes="spacer-20"></gds-spacer>
            <gds-heading
              [data]="{
                heading: travelPartyMemberDetails?.relationship
              }"
              class="A5-W5"
            >
            </gds-heading>
          </div>
          <div *ngIf="!relationShow">
            <gds-dropdown
              id="relationship"
              formControlName="relationship"
              ngDefaultControl
              [data]="{
                formField: { primaryLabel:pageLabels?.['trpAdd.relationship'], supportingText: pageLabels?.['trpAdd.optional']},
                options: relationshipArray
              }"
              (valueChange)="matchDropDown($event, 'relationship')"
            >
            </gds-dropdown>
          </div>
          <div *ngIf="dateShow">
            <gds-spacer classes="spacer-50"></gds-spacer>
            <gds-heading
              [data]="{
              heading: pageLabels?.['trpUpdate.birthday']
            }"
              class="B1-W4"
            >
            </gds-heading>
            <gds-spacer classes="spacer-20"></gds-spacer>
            <gds-heading
              [data]="{
                heading: getDate(travelPartyMemberDetails?.date_of_birth)
              }"
              class="A5-W5"
            >
            </gds-heading>
          </div>
          <div *ngIf="!dateShow && dobGenderValue">
            <gds-spacer classes="spacer-50"></gds-spacer>
            <gds-input
              id="dob"
              formControlName="dob"
              [errors]="dobErrorMsgInfo"
              ngDefaultControl
              [data]="{
              primaryLabel: pageLabels?.['trpAdd.birthday'],
              supportingText: pageLabels?.['trpAdd.optional'],
              tooltip: pageLabels?.['trpAdd.birthdayFormat'],
              type: 'number',
              altText: 'input of surname',
              ariaText: '',
              required: false
            }"
              onKeyPress=" if( this.value?.length == 8 ) return false;"
              (valueChange)="onValueChange($event)"
              (blurChange)="getErrorMessageInfo($event)"
              (input)="validateDob($event)"
              hide-spinner="true"
              onkeydown="if(event.key === 'ArrowUp' || event.key === 'ArrowDown') event.preventDefault();"
            >
            </gds-input>
            <!-- Date of Birth input field heading -->
            <gds-spacer classes="spacer-30"></gds-spacer>
            <gds-heading
              [data]="{
          heading:
              pageLabels?.['trpAdd.birthdayInfo']
          }"
              class="B1-W3"
            >
            </gds-heading>
            <gds-spacer *ngIf="!genderShow" classes="spacer-50"></gds-spacer>
          </div>
          <div *ngIf="genderShow">
            <gds-spacer classes="spacer-50"></gds-spacer>
            <gds-heading
              [data]="{
              heading: pageLabels?.['trpUpdate.gender']
            }"
              class="B1-W4"
            >
            </gds-heading>
            <gds-spacer classes="spacer-20"></gds-spacer>
            <gds-heading
              [data]="{
                heading: travelPartyMemberDetails?.gender
              }"
              class="A5-W5"
            >
            </gds-heading>
          </div>
          <gds-spacer
            *ngIf="genderShow || dateShow"
            classes="spacer-50"
          ></gds-spacer>
          <div *ngIf="!genderShow && dobGenderValue">
            <gds-dropdown
              id="gender"
              formControlName="gender"
              ngDefaultControl
              [data]="{
              options: genderArray,
              formField: { primaryLabel: pageLabels?.['trpAdd.gender'], supportingText: pageLabels?.['trpAdd.optional']}
            }"
              (valueChange)="matchDropDown($event, 'gender')"
            >
            </gds-dropdown>
          </div>
        </div>
        <gds-spacer classes="spacer-85"></gds-spacer>
      </div>
    </div>
    <!--Continue footer button-->
    <ng-container *ngIf="!opened">
      <gds-sticky-nav-footer
        [data]="{
          button: {
            type: 'primary',
            href: '',
            label: pageLabels?.['trpUpdate.continue'],
            disabled: editTravelPartyForm?.valid && inputChanged && editTravelPartyForm?.dirty  ? 'false' : 'true',
            target: ''
          }
        }"
        (buttonClicked)="continueBtn()"
      >
      </gds-sticky-nav-footer>
    </ng-container>
  </div>
</gds-full-screen-modal>
