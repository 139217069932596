import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ticket-card',
  templateUrl: './ticket-card.component.html',
  styleUrls: ['./ticket-card.component.scss'],
})
export class TicketCardComponent implements OnInit {
  pageLabels: any;

  /**
   * constructor -  to get pageLabels from route
   * @param route - To get Page Lables
   * @param title - Set Page Title
   */
  constructor(private route: ActivatedRoute, private title: Title) {
    this.pageLabels = this.route.snapshot.data.pageLabels;
    this.title.setTitle(this.pageLabels?.['page.dashboard']);
  }

  ngOnInit(): void {}
}
