import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Injector,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NAME_REGEX } from '../../../../config/pattern.constant';
import { CommonUtilityService } from '../../../services/common-utility.service';

@Component({
  selector: 'app-add-party-form',
  templateUrl: './add-party-form.component.html',
  styleUrls: ['./add-party-form.component.scss'],
})
export class AddPartyFormComponent implements OnInit {
  addTravelPartyForm: FormGroup;
  nameRegex = NAME_REGEX;
  userArray: any = [];
  genderArray: any = [];
  relationshipArray: any = [];
  dobErrorMsgInfo: string;
  firstNameErrorMsgInfo: string;
  lastNameErrorMsgInfo: string;
  selectedGender: any;
  selectedRelationship: any;
  getTravelPartymembersData: any;
  showCourse = false;
  showConfirmPopup = false;
  fromPage: string;
  @Output() updateTravelPartyForm = new EventEmitter();
  /**
   * Page labels
   * @type any
   */
  pageLabels: any;
  dobGenderValue = false;

  constructor(
    @Inject('NamePattern') public namePattern: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    private formBuilder: FormBuilder,
    @Inject('Ranges') public ranges: any,
    @Inject('AppRoutes') public appRoutes: any,
    protected injector: Injector,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    public commonUtilityService: CommonUtilityService
  ) {
    this.pageLabels = this.route.snapshot.data.pageLabels;
  }
  /**
   * ngOnInit - On Initial load to create the form
   */
  ngOnInit(): void {
    this.fromPage = this.globalDefault.ADD;
    this.genderArray = [
      {
        label: this.pageLabels?.['trpAdd.genderFemale'],
        value: this.pageLabels?.['trpAdd.genderFemale'],
      },
      {
        label: this.pageLabels?.['trpAdd.genderMale'],
        value: this.pageLabels?.['trpAdd.genderMale'],
      },

      {
        label: this.pageLabels?.['trpAdd.genderOther'],
        value: this.pageLabels?.['trpAdd.genderOther'],
      },
      {
        label: this.pageLabels?.['trpAdd.gendernotResponded'],
        value: this.pageLabels?.['trpAdd.gendernotResponded'],
      },
    ];
    this.relationshipArray = [
      {
        label: this.pageLabels?.['trpAdd.spouse'],
        value: this.pageLabels?.['trpAdd.spouse'],
      },
      {
        label: this.pageLabels?.['trpAdd.child'],
        value: this.pageLabels?.['trpAdd.child'],
      },
      {
        label: this.pageLabels?.['trpAdd.other'],
        value: this.pageLabels?.['trpAdd.other'],
      },
    ];
    this.createForm();
  }

  /**
   * createForm - Creating form Group
   */

  createForm(): any {
    this.addTravelPartyForm = this.formBuilder.group({
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.ranges?.MAX?.LAST_NAME),
        this.noWhitespaceValidator(),
        Validators.pattern(this.nameRegex),
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(this.ranges?.MAX?.FIRST_NAME),
        Validators.pattern(this.nameRegex),
        this.noWhitespaceValidator(),
      ]),
      dob: new FormControl('', [this.dobValidator()]),
      gender: new FormControl(''),
      relationship: new FormControl(''),
    });
  }

  /**
   * onValueChange - Patch field value
   * @param event - Input field event
   */

  onValueChange(event: any): void {
    const field = event?.target?.id;
    const value = event?.detail;
    this.addTravelPartyForm.get(field)?.patchValue(value);
    this.updateTravelPartyForm.emit(this.addTravelPartyForm);
  }

  /**
   * dobValidator - dob validation
   * @returns - returns the invalidDOb as true if invalid date enetered
   */

  dobValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const dob = control.value;

      if (!dob) {
        // DOB is optional, so it's valid if not provided
        return null;
      }

      // Your custom DOB validation logic goes here
      const inputDate = dob.toString();
      this.dobErrorMsgInfo = this.commonUtilityService.checkDate(
        inputDate,
        this.pageLabels
      );
      return this.dobErrorMsgInfo === '' ? null : { invalidDob: true };
    };
  }
  /**
   * validateDob - dob validation
   */
  validateDob(event: any): any {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    // Allow only numbers
    input.value = value.replace(/[^0-9]/g, ''); // Clean the input
  }
  /**
   * noWhitespaceValidator - Validating white space
   * @returns - returns the whitespace error message
   */

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace =
        (control.value || '').trim().length === this.ranges.PASSWORDS.INDEX[0];
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  /**
   * getErrorMessageInfo - Checking the field error
   * @param event - Input field event
   */

  getErrorMessageInfo(event: any): void {
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.FIRSTNAME:
        this.firstNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.LASTNAME:
        this.lastNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.DOB:
        this.dobErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.GENDER:
        this.addTravelPartyForm?.get('gender')?.patchValue(this.genderArray);
    }
    this.cdRef.detectChanges();
  }

  /**
   * getErrorMessage - Returning error message
   * @param field - Input field event
   * @returns return the Input field error message
   */

  getErrorMessage(field: any): string {
    const fieldName = this.addTravelPartyForm?.get(field);
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      switch (field) {
        case this.globalDefault.FIRSTNAME: {
          if (!this.addTravelPartyForm.value.firstName) return emptyCheck;
          if (field == this.globalDefault.FIRSTNAME) {
            this.firstNameErrorMsgInfo = this.pageLabels?.['errCode.E032'];
            return this.firstNameErrorMsgInfo;
          } else {
            this.firstNameErrorMsgInfo =
              this.pageLabels?.['errCode.E020'] +
              ' ' +
              this.ranges.MAX.FIRST_NAME +
              ' ' +
              this.pageLabels?.['createAccount.maxCharCheck'];
            return this.firstNameErrorMsgInfo;
          }
        }
        case this.globalDefault.LASTNAME: {
          if (!this.addTravelPartyForm.value.lastName) return emptyCheck;
          if (field == this.globalDefault.LASTNAME) {
            this.lastNameErrorMsgInfo = this.pageLabels?.['errCode.E032'];
            return this.lastNameErrorMsgInfo;
          } else {
            this.lastNameErrorMsgInfo =
              this.pageLabels?.['errCode.E020'] +
              ' ' +
              this.ranges.MAX.LAST_NAME +
              ' ' +
              this.pageLabels?.['createAccount.maxCharCheck'];
            return this.lastNameErrorMsgInfo;
          }
        }
        case this.globalDefault.DOB: {
          if (!this.addTravelPartyForm.value.dob)
            this.dobErrorMsgInfo = this.pageLabels?.['errCode.E002'];
          else {
            const inputDate = this.addTravelPartyForm.value.dob.toString();
            this.dobErrorMsgInfo = this.commonUtilityService.checkDate(
              inputDate,
              this.pageLabels
            );
            return this.dobErrorMsgInfo;
          }
        }
      }
    }
    return '';
  }

  /**
   * matchDropDown - Validating Gender
   * @param event - Input field event
   * @param type - dropdown type
   */

  matchDropDown(event: any, type: any): any {
    this.dobGenderValue =
      event?.detail == this.pageLabels?.['trpAdd.other'] &&
      type == this.globalDefault.RELATIONSHIP
        ? false
        : true;
    type === this.globalDefault.GENDER
      ? this.genderArray?.filter((res: any) => {
          if (res.value === event.detail) {
            this.addTravelPartyForm.patchValue({
              gender: res.value,
            });
          }
        })
      : this.relationshipArray?.filter((res: any) => {
          if (res.value === event.detail) {
            this.addTravelPartyForm.patchValue({
              relationship: res.value,
            });
          }
          if (event.detail == this.pageLabels?.['trpUpdate.other']) {
            this.addTravelPartyForm.patchValue({
              gender: '',
              dob: '',
            });
          }
        });
    this.updateTravelPartyForm.emit(this.addTravelPartyForm);
  }

  onKeyPressEvent(event: KeyboardEvent): void {
    const isNumeric = /[0-9]/.test(event.key);
    const isControlKey = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
    ].includes(event.key);

    // Allow numeric input or control keys, prevent other characters
    if (!isNumeric && !isControlKey) {
      event.preventDefault();
    }
  }
}
