<section data-aui="header-top" class="row expanded top-header-section">
  <div class="row header-container align-middle">
    <div class="primary-content columns top-header-logo">
      <img
        class="tcm-image-container"
        [src]="siteHeaderLogo"
        alt="Universal Studio Japan"
      />
    </div>
    <aside
      class="secondary-content columns utilities align-middle"
      *ngIf="!isAsideHide"
    >
      <upr-language-selector
        [data]="languageSelector"
        [id]="2"
      ></upr-language-selector>
    </aside>
  </div>
</section>
