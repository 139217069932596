import { Component, Inject, OnInit } from '@angular/core';
import { get } from 'lodash';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerData: object | any;
  pageHeading: object | any;
  copyrightLabel: any;
  privacyLabel: any;

  constructor(
    private commonUtilityService: CommonUtilityService,
    @Inject('AppDefaults') public appDefaults: any
  ) {}

  // GetLayoutContent
  ngOnInit(): void {
    this.commonUtilityService
      .requestLayoutfooter()
      .subscribe((response: any) => {
        const cp = get(response, 'ComponentPresentations', []);
        // footer content
        if (response && Array.isArray(cp)) {
          this.footerData = cp.find((component) =>
            get(component, 'ComponentTemplate.Title', '')
              .toLowerCase()
              .startsWith('nav - footer ')
          );

          if (this.footerData) {
            cp.splice(cp.indexOf(this.footerData), 1);
            response.ComponentPresentations = cp;
          }
          const copyrightHeading: any =
            this.footerData.Component.Fields.Copyright.Values[0];
          this.pageHeading = copyrightHeading;

          //Copyright
          this.copyrightLabel =
            this.footerData.Component.Fields.LegalLinks.EmbeddedValues[0].Links.EmbeddedValues[0].Title.Values[0];

          //Privacy-policy
          this.privacyLabel =
            this.footerData.Component.Fields.LegalLinks.EmbeddedValues[0].Links.EmbeddedValues[1].Title.Values[0];
        }
      });
  }

  // copyrightredirect
  redirectToCopyrights(): void {
    window.open(this.appDefaults?.COPY_RIGHTS_LINK, this.appDefaults.BLANK);
  }
  // privacyredirect
  redirectToPrivacy(): void {
    window.open(this.appDefaults?.PRIVACY_LINK, this.appDefaults.BLANK);
  }
}
