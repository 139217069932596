// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormControlService, FormData } from '@upr-web-primer/form-fields';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
// Primer
import { UrlInfo } from '@upr-web-primer/url-info';
// Lodash
import { get, includes } from 'lodash-es';
// Service
import { CommonUtilityService } from '../../services/common-utility.service';
@Component({
  selector: 'app-forgot-my-password',
  templateUrl: './forgot-my-password.component.html',
  styleUrls: ['./forgot-my-password.component.scss'],
})

/**
 * Export ForgotMyPasswordComponent
 */
export class ForgotMyPasswordComponent implements OnInit {
  /**
   * Url info
   * @type UrlInfo
   */
  urlInfo: UrlInfo;

  /**
   * Page
   * @type any
   */
  page: any;

  /**
   * Page labels
   * @type any
   */
  pageLabels: any;

  /**
   * Component presentations
   * @type Array<any>
   */
  componentPresentations: Array<any>;

  /**
   * Content intro
   * @type any
   */
  contentIntro: any;

  /**
   * Forgot password content
   * @type any
   */
  forgotPasswordContent: any;

  /**
   * Right rail article
   * @type any
   */
  rightRailArticle: any;

  /**
   * Forgot password form
   * @type FormGroup
   */
  forgotPasswordForm: FormGroup;

  /**
   * Email control
   * @type FormData<any>
   */
  emailControl: FormData<any>;

  /**
   * Email
   * @type {string}
   */
  email = '';

  /**
   * Constructor
   * @param {ActivatedRoute} route
   * @param {CommonUtilityService} commonUtilityService
   * @param {MsGuestsService} guests
   * @param {FormBuilder} fb
   * @param {FormControlService} formControlService
   * @param {NotificationService} notification
   * @param {any} appRoutes
   */
  constructor(
    private route: ActivatedRoute,
    private commonUtilityService: CommonUtilityService,
    private guests: MsGuestsService,
    private fb: FormBuilder,
    private formControlService: FormControlService,
    private notification: NotificationService,
    @Inject('AppRoutes') private appRoutes: any
  ) {
    this.urlInfo = this.route.snapshot.data.urlInfo;
    this.page = this.route.snapshot.data.page;
    this.componentPresentations =
      get(this.page, 'ComponentPresentations') || [];
    this.pageLabels = this.formControlService.pageLabels = get(
      this.route.snapshot.data,
      'pageLabels',
      []
    );
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.notification.hide();
    this.contentIntro =
      this.componentPresentations.find((cp) =>
        includes(cp.ComponentTemplate.Title, 'Account Profile - Content Intro')
      ) || {};
    this.rightRailArticle =
      this.componentPresentations.find((cp) =>
        includes(
          cp.ComponentTemplate.Title,
          'Account Profile - Right Rail Article'
        )
      ) || {};
    this.forgotPasswordContent =
      this.componentPresentations.find((cp) =>
        includes(
          cp.ComponentTemplate.Title,
          'Account Profile - Forgot Password'
        )
      ) || {};
    if (this.contentIntro) {
      this.commonUtilityService.setPageSEO(this.contentIntro);
    }

    this.initForm();
    this.commonUtilityService.initPageDDL(this.page);
  }

  /**
   * Add forgot password form control
   * @param {string} name
   * @param {FormGroup} formGroup
   */
  addForgotPasswordFormControl(name: string, formGroup: FormGroup): void {
    if (!this.forgotPasswordForm) {
      return;
    }
    this.forgotPasswordForm.addControl(name, formGroup);
  }

  /**
   * On Submit
   */
  onSubmit(): void {
    this.commonUtilityService.validateAllFormFields(this.forgotPasswordForm);

    if (!this.forgotPasswordForm.valid) {
      return this.notification.show(this.pageLabels?.['errCode.E019']);
    }

    this.email = get(this.forgotPasswordForm, 'value.email.email');
    this.notification.hide();
    this.guests.account
      .validateEmail(this.email)
      .then(() => this.guests.account.forgotPassword(this.email))
      .then(() =>
        this.commonUtilityService.notifyFormSuccessEvent(
          'Forgot My Password Form',
          'OK'
        )
      )
      .then(() => this.commonUtilityService.changeRoute(this.appRoutes.LOGIN))
      .catch((err) => {
        console.error(err);
        this.commonUtilityService.notifyFormErrorEvent(
          'Forgot My Password Form',
          err
        );
      });
  }

  /**
   * Init form
   */
  private initForm(): void {
    this.forgotPasswordForm = this.fb.group({});
    this.emailControl = this.formControlService.emailControl;
    const emailLabel = get(
      this.forgotPasswordContent,
      'Component.Fields.EmailLabel.Values[0]'
    );
    if (emailLabel && this.emailControl) {
      this.emailControl.label = emailLabel;
    }
  }
}
