import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { WINDOW } from '@upr-web-primer/window';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';
import { ToastService } from '../../shared/service/toast.service';

@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss'],
})
export class AddCouponComponent implements OnInit, AfterViewInit {
  addCouponsForm: FormGroup;
  couponCode: any;
  pageLabels: any;
  couponCodeErrorMsgInfo: string;
  @ViewChild('addCouponModalContent') addCouponModalContent!: ElementRef;

  constructor(
    private commonUtilityService: CommonUtilityService,
    private guestsService: MsGuestsService,
    protected injector: Injector,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('AppRoutes') public appRoutes: any,
    private toastService: ToastService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private appDataService: AppDataService,
    @Inject('Ranges') public ranges: any,
    @Inject(WINDOW) private window: any,
    private title: Title,
    private notification: NotificationService
  ) {
    this.setDependencies();
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.enterCouponCode']);
  }
  /**
   * ngOnInit - initial methods are called
   */

  ngOnInit(): void {
    this.createForm();
  }
  /**
   * ngAfterViewInit - initial methods are called
   */
  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(this.addCouponModalContent, 164);
  }
  /**
   * onResize - To resize full screen modal popup
   */
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(this.addCouponModalContent, 164);
  }

  /**
   * createForm - create the form
   */

  createForm(): any {
    this.addCouponsForm = this.formBuilder.group({
      addCouponCode: new FormControl('', [
        Validators.required,
        this.noWhitespaceValidator(),
      ]),
    });
  }

  /**
   *
   * @returns noWhitespaceValidator
   */

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  /**
   * addCoupon - it will call api add the coupons and redirected to coupons page
   */

  addCoupon(): any {
    const guestId = this.guestsService.helper.getGuestId();
    const request = {
      couponCode: this.couponCode,
    };
    this.commonUtilityService.addCoupons(guestId, request).subscribe(
      (res: any) => {
        this.ddlEvents();
        if (res.status)
          this.toastService.show({
            text: this.pageLabels?.['inputCoupon.addSnack'],
            type: this.globalDefault.SUCCESS,
          });
        this.backToCoupons();
      },
      (err: any) => {
        this.ddlEvents();
        const commerceGenericErrorMessage =
          this.commonUtilityService.removeHyphensFromCommerceAPI(
            err?.errors?.[0]?.errorCode,
            this.pageLabels,
            this.globalDefault
          );
        this.notification?.show(commerceGenericErrorMessage);
      }
    );
  }

  /**
   * ddlEvents - Capture the events from add coupons
   */

  ddlEvents(): void {
    this.commonUtilityService.invokeFireEvent(
      this.pageLabels?.['inputCoupon.buttonCTA'],
      'Button',
      'Click',
      '',
      new Date().toISOString(),
      'Body | CTA:' + this.pageLabels?.['inputCoupon.buttonCTA'],
      '',
      this.window?.digitalData?.page?.category?.primaryCategory ?? '',
      this.window?.digitalData?.page?.category?.subCategory1 ?? ''
    );
  }

  /**
   * backToCoupons - redirected to coupons page
   */

  backToCoupons(): any {
    this.commonUtilityService.changeRoute(this.appRoutes.COUPONS, {
      account: this.globalDefault.ACCOUNT,
    });
  }

  /**
   * onValueChange - it will capture the user changes in the input filed
   * @param event : specify the selected event value
   */

  onValueChange(event: any): any {
    this.couponCode = event.target.value;
    this.addCouponsForm?.patchValue({
      addCouponCode: this.couponCode,
    });
  }

  /**
   * getErrorMessage - returns the error messages for the input fields
   * @param field : specify the input field
   * @returns
   */

  getErrorMessage(field: any): string {
    const fieldName = this.addCouponsForm?.get(field);
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      switch (field) {
        case this.globalDefault.ADD_COUPON_CODE: {
          if (!this.addCouponsForm.value.addCouponCode) return emptyCheck;
          else {
            this.couponCodeErrorMsgInfo =
              this.pageLabels?.['errCode.E020'] +
              ' ' +
              this.ranges.MAX.FIRST_NAME +
              ' ' +
              this.pageLabels?.['createAccount.maxCharCheck'];
            return this.couponCodeErrorMsgInfo;
          }
        }
      }
    }
    return '';
  }

  /**
   * getErrorMessageInfo -  returns the error messages for the input fields
   * @param event : specify the input field event
   */

  getErrorMessageInfo(event: any): void {
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.ADD_COUPON_CODE:
        this.couponCodeErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
    }
    this.cdRef.detectChanges();
  }

  private setDependencies(): void {
    this.commonUtilityService = this.injector.get(CommonUtilityService);
    this.guestsService = this.injector.get(MsGuestsService);
  }
}
