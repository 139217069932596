<gds-full-screen-modal opened (modalClose)="backToViewEntitlement()">
  <!--Enlarge Qr code model popup-->
  <div slot="components">
    <div class="container modal-content">
      <div class="card">
        <div class="center-align">
          <!--Qr code image-->
          <div class="content-center">
            <img class="square" [src]="QRCode" />
          </div>

          <!--Media ID and full name-->
          <div class="name-center">
            <gds-heading
              class="B1-W3"
              [data]="{
                heading: mediaID
              }"
            ></gds-heading>
            <gds-spacer classes="spacer-50"></gds-spacer>
            <gds-heading
              class="A2-W5"
              [data]="{
                heading: fullName
              }"
            ></gds-heading>
          </div>
          <gds-spacer classes="spacer-30"></gds-spacer>

          <!--QR code information messages-->
          <div class="name-center">
            <gds-heading
              class="B1-W3"
              [innerHtml]="pageLabels?.['scanQR.info']+'</br>'+pageLabels?.['enalrgeQR.info']"
            ></gds-heading>
            <gds-spacer classes="spacer-30"></gds-spacer>
          </div>
        </div>
      </div>
    </div>
  </div>
</gds-full-screen-modal>
