import { APP_BASE_HREF, CurrencyPipe, PlatformLocation } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { DalModule } from '@upr-web-primer/dal';
import { FormFieldsModule } from '@upr-web-primer/form-fields';
import { GdsFooterModule } from '@upr-web-primer/gds-footer';
import { GeolocationModule } from '@upr-web-primer/geolocation';
import { GoogleRecaptchaModule } from '@upr-web-primer/google-recaptcha';
import { LanguageSelectorModule } from '@upr-web-primer/language-selector';
import { K2LayoutModule } from '@upr-web-primer/layout-k2';
import { LocaleModule } from '@upr-web-primer/locale';
import { NotificationModule } from '@upr-web-primer/notification';
import { PageLabelsModule } from '@upr-web-primer/page-labels';
import { SimpleModalModule } from '@upr-web-primer/simple-modal';
import { SpinnerModule } from '@upr-web-primer/spinner';
import { TcmAnchorModule } from '@upr-web-primer/tcm-anchor';
import { UprMobileServiceModule } from '@upr-web-primer/upr-mobile-service';
import { UrlInfoModule } from '@upr-web-primer/url-info';
import { ViewportModule } from '@upr-web-primer/viewport';
import { WindowModule } from '@upr-web-primer/window';
import { BnNgIdleService } from 'bn-ng-idle';
import { CookieService } from 'ngx-cookie-service';
import { HEADER_NAV_FOOTER_ENV_REPLACE } from '../config/app-default.constant';
import { AccountModule } from './account/account.module';
import { AppLoadModule } from './app-load.module';
import { appProviders } from './app-providers';
import { AppComponent } from './app.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { HeaderComponent } from './layouts/header/header.component';
import { RoutingModule } from './routing/routing.module';
import { HttpErrorInterceptor } from './services/http-error.interceptor';
import { InitializationService } from './services/initialization.service';
import { DataResolver } from './shared/resolver/data.reslover';
import { PageResolver } from './shared/resolver/page.resolver';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    WindowModule,
    ViewportModule,
    LocaleModule,
    DalModule,
    HttpClientModule,
    GeolocationModule,
    AppLoadModule,
    PageLabelsModule,
    K2LayoutModule,
    AccountModule,
    RoutingModule,
    TcmAnchorModule,
    UrlInfoModule,
    SpinnerModule,
    NotificationModule,
    FormFieldsModule,
    GoogleRecaptchaModule,
    SimpleModalModule,
    UprMobileServiceModule.forRoot({} as any),
    StoreModule.forRoot({}),
    LanguageSelectorModule,
    GdsFooterModule,
  ],
  exports: [NotificationModule],
  providers: [
    CurrencyPipe,
    appProviders,
    DataResolver,
    CookieService,
    BnNgIdleService,
    PageResolver,
    InitializationService,
    {
      provide: APP_INITIALIZER,
      useFactory:
        (initService: InitializationService): (() => Promise<any>) =>
        () =>
          initService.initializeApp(),
      multi: true,
      deps: [InitializationService],
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation): string => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: 'CustomEnv',
      useValue: HEADER_NAV_FOOTER_ENV_REPLACE,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
