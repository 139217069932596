import { AnimationEvent } from '@angular/animations';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  toastAnimations,
  ToastAnimationState,
} from '../../classes/toast-animation';
import {
  ToastConfig,
  ToastData,
  TOAST_CONFIG_TOKEN,
} from '../../classes/toast-config';
import { ToastRef } from '../../classes/toast-ref';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [toastAnimations.fadeToast],
})
export class ToastComponent implements OnInit, OnDestroy {
  animationState: ToastAnimationState = 'default';
  iconType: string;
  private closeTimeoutId?: any;

  constructor(
    readonly data: ToastData,
    readonly ref: ToastRef,
    @Inject(TOAST_CONFIG_TOKEN) public toastConfig: ToastConfig
  ) {
    this.iconType = data.type === 'success' ? 'done' : data.type;
  }

  ngOnInit(): void {
    // Set a timeout to change the animation state to 'closing'
    const fadeOutDuration = this.toastConfig.animation?.fadeOut || 5000; // Default 5 seconds
    this.closeTimeoutId = setTimeout(() => {
      this.animationState = 'closing';
    }, fadeOutDuration - (this.toastConfig.animation?.fadeIn || 300)); // Adjust for fade-in
  }

  ngOnDestroy(): void {
    // Clear the timeout if the component is destroyed
    if (this.closeTimeoutId) {
      clearTimeout(this.closeTimeoutId);
    }
  }

  close(): void {
    this.ref.close();
  }

  onFadeFinished(event: AnimationEvent): void {
    const { toState } = event;
    if (toState === 'closing') {
      this.close();
    }
  }
}
