import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-cart-navigation',
  template: '',
  styles: [],
})
export class CartNavigationComponent implements OnInit {
  constructor(
    private commonUtilityService: CommonUtilityService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('CART_ITEM_COUNT')) {
      this.cookieService.set(
        'isCart',
        JSON.stringify(true),
        0,
        '/',
        `${environment.cookieSubDomain}`
      );
    }

    this.commonUtilityService.onClickRedirectWTS('', '', true);
  }
}
