import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { UrlInfoResolver } from '@upr-web-primer/url-info';
import { appRoutes } from '../../config/app-default.constant';
import { CartNavigationComponent } from '../account/cart-navigation/cart-navigation.component';
import { ForgotMyPasswordComponent } from '../account/forgot-my-password/forgot-my-password.component';
import { InquiryWebFormComponent } from '../account/inquiry-web-form/inquiry-web-form.component';
import { ConfirmLinkTicketsComponent } from '../account/link-ticket/confirm-link-tickets/confirm-link-tickets.component';
import { LinkTicketComponent } from '../account/link-ticket/link-ticket.component';
import { RegisteredLinkTicketsComponent } from '../account/link-ticket/registered-link-tickets/registered-link-tickets.component';
import { RegistrationLinkTicketsComponent } from '../account/link-ticket/registration-link-tickets/registration-link-tickets.component';
import { LoginComponent } from '../account/login/login.component';
import { LogoutComponent } from '../account/logout/logout.component';
import { LostFoundWebFormComponent } from '../account/lost-found-web-form/lost-found-web-form.component';
import { PageNotFoundComponent } from '../account/page-not-found/page-not-found.component';
import { PastTicketsComponent } from '../account/past-tickets/past-tickets.component';
import { ResetMyPasswordComponent } from '../account/reset-my-password/reset-my-password.component';
import { SignInComponent } from '../account/sign-in/sign-in.component';
import { TicketDetailsComponent } from '../account/ticket-details/ticket-details.component';
import { UnsubscribeEmailComponent } from '../account/unsubscribe-email/unsubscribe-email.component';
import { UpdatePasswordComponent } from '../account/update-password/update-password.component';
import { AuthGuard } from '../auth-guard.guard';
import { AddAddressComponent } from '../components/add-address/add-address.component';
import { AddCouponComponent } from '../components/add-coupon/add-coupon.component';
import { AddPhoneNumberComponent } from '../components/add-phone-number/add-phone-number.component';
import { AddTravelPartyComponent } from '../components/add-travel-party/add-travel-party.component';
import { BirthdayDiscountComponent } from '../components/birthday-discount/birthday-discount.component';
import { BuyTicketsComponent } from '../components/buy-tickets/buy-tickets.component';
import { ConfirmDeleteComponent } from '../components/confirm-delete/confirm-delete.component';
import { ConfirmTravelPartyMembersComponent } from '../components/confirm-travel-party-members/confirm-travel-party-members.component';
import { DeleteAccountConfirmationComponent } from '../components/delete-account-confirmation/delete-account-confirmation.component';
import { EditTravelPartyComponent } from '../components/edit-travel-party/edit-travel-party.component';
import { EnlargeQrCodeComponent } from '../components/enlarge-qr-code/enlarge-qr-code.component';
import { OrderDetailsComponent } from '../components/order-details/order-details.component';
import { ShareqrcodeComponent } from '../components/share-qr-code/share-qr-code.component';
import { SignupCompleteComponent } from '../components/signup-complete/signup-complete.component';
import { SignupConfirmComponent } from '../components/signup-confirm/signup-confirm.component';
import { UpdateAddressComponent } from '../components/update-address/update-address.component';
import { UpdatePersonalInfoComponent } from '../components/update-personal-info/update-personal-info.component';
import { UpdatePhoneNumberComponent } from '../components/update-phone-number/update-phone-number.component';
import { WarningTravelPartyComponent } from '../components/warning-travel-party/warning-travel-party.component';
import { OidcLoginGuard } from '../oidc-login.guard';
import { DataResolver } from '../shared/resolver/data.reslover';
import { PageResolver } from '../shared/resolver/page.resolver';
import { TcmPageResolver } from './tcm-page.resolver';

const appPrefix = ':language/:country/';
const indexPage = '/index.html';
const redirect_uri = 'redirect_uri';
// const redirect_url = 'https://example.com';

export const routes: Routes = [
  {
    path: appPrefix + appRoutes.LOGIN,
    component: LoginComponent,
    data: { slug: appRoutes.LOGIN },
    canActivate: [OidcLoginGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },

  {
    path: appPrefix + appRoutes.CREATE_ACCOUNT + indexPage,
    redirectTo: appPrefix + appRoutes.CREATE_ACCOUNT,
  },
  {
    matcher: (url: UrlSegment[]): any => {
      // Check if the URL has at least 3 segments and the third segment is 'signup'
      if (url.length >= 3 && url[2].path === appRoutes.CREATE_ACCOUNT) {
        // Extract query parameters from the URL
        const queryParams = new URLSearchParams(url.join('/').split('?')[1]);
        // If the URL has the correct parameters
        if (
          queryParams.has(redirect_uri)
          // &&
          // queryParams.get(redirect_uri) === redirect_url
        ) {
          return { consumed: url }; // Allow navigation for correct parameters
        } else {
          return {
            // If the URL has incorrect parameters navigate to not-page found
            consumed: url,
            posParams: {
              path: new UrlSegment(`${appPrefix}${appRoutes.NOT_FOUND}`, {}),
            },
          };
        }
      }
      return null;
    },

    loadChildren: () =>
      import('../account/create-account/create-account.module').then(
        (m) => m.CreateAccountModule
      ),
    data: { slug: appRoutes.CREATE_ACCOUNT },
    resolve: {
      pageLabels: PageResolver,
    },
  },

  {
    path: appPrefix + appRoutes.CREATE_ACCOUNT,
    loadChildren: () =>
      import('../account/create-account/create-account.module').then(
        (m) => m.CreateAccountModule
      ),
    data: { slug: appRoutes.CREATE_ACCOUNT },
    resolve: {
      pageLabels: PageResolver,
    },
  },

  {
    path: appPrefix + appRoutes.SIGNUP_CONFIRM + indexPage,
    redirectTo: appPrefix + appRoutes.SIGNUP_CONFIRM,
  },
  {
    path: appPrefix + appRoutes.SIGNUP_CONFIRM,
    component: SignupConfirmComponent,
    data: { slug: appRoutes.SIGNUP_CONFIRM },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.SIGNUP_COMPLETE + indexPage,
    redirectTo: appPrefix + appRoutes.SIGNUP_COMPLETE,
  },
  {
    path: appPrefix + appRoutes.SIGNUP_COMPLETE,
    component: SignupCompleteComponent,
    data: { slug: appRoutes.SIGNUP_COMPLETE },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.PACKAGE_CREATE_ACCOUNT + indexPage,
    redirectTo: appPrefix + appRoutes.PACKAGE_CREATE_ACCOUNT,
  },

  {
    path: appPrefix + appRoutes.REGISTER_ACCOUNT + indexPage,
    redirectTo: appPrefix + appRoutes.REGISTER_ACCOUNT,
  },

  {
    path: appPrefix + appRoutes.EMAIL_SUBSCRIBE + indexPage,
    redirectTo: appPrefix + appRoutes.EMAIL_SUBSCRIBE,
  },

  {
    path: `${appPrefix + appRoutes.EMAIL_SUBSCRIBE}/:interest` + indexPage,
    redirectTo: `${appPrefix + appRoutes.EMAIL_SUBSCRIBE}/:interest`,
  },

  {
    path: appPrefix + appRoutes.EMAIL_UNSUBSCRIBE + indexPage,
    redirectTo: appPrefix + appRoutes.EMAIL_UNSUBSCRIBE,
  },
  {
    path: appPrefix + appRoutes.CONTACT_US + indexPage,
    redirectTo: appPrefix + appRoutes.CONTACT_US,
  },
  {
    path: appPrefix + appRoutes.RESET_PASSWORD + indexPage,
    redirectTo: appPrefix + appRoutes.RESET_PASSWORD,
  },
  {
    path: appPrefix + appRoutes.RESET_PASSWORD,
    component: ResetMyPasswordComponent,
    data: { slug: appRoutes.RESET_PASSWORD },
    canActivate: [AuthGuard],
    resolve: {
      urlInfo: UrlInfoResolver,
      page: TcmPageResolver,
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.FORGOT_PASSWORD + indexPage,
    redirectTo: appPrefix + appRoutes.FORGOT_PASSWORD,
  },
  {
    path: appPrefix + appRoutes.FORGOT_PASSWORD,
    component: ForgotMyPasswordComponent,
    data: { slug: appRoutes.FORGOT_PASSWORD },
  },
  {
    path: appPrefix + appRoutes.DELETE_ACCOUNT + indexPage,
    redirectTo: appPrefix + appRoutes.DELETE_ACCOUNT,
  },
  {
    path: appPrefix + appRoutes.DELETE_ACCOUNT,
    // component: DeleteAccountComponent,
    loadChildren: () =>
      import('../account/delete-account/delete-account.module').then(
        (m) => m.DeleteAccountModule
      ),
    canActivate: [AuthGuard],
    data: { slug: appRoutes.DELETE_ACCOUNT },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.DELETE_ACCOUNT_CONFIRMATION + indexPage,
    redirectTo: appPrefix + appRoutes.DELETE_ACCOUNT_CONFIRMATION,
  },
  {
    path: appPrefix + appRoutes.DELETE_ACCOUNT_CONFIRMATION,
    component: DeleteAccountConfirmationComponent,
    canActivate: [AuthGuard],
    data: { slug: appRoutes.DELETE_ACCOUNT_CONFIRMATION },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.CONFIRM_DELETE + indexPage,
    redirectTo: appPrefix + appRoutes.CONFIRM_DELETE,
  },
  {
    path: appPrefix + appRoutes.CONFIRM_DELETE,
    component: ConfirmDeleteComponent,
    canActivate: [AuthGuard],
    data: { slug: appRoutes.CONFIRM_DELETE },
    resolve: {
      pageLabels: PageResolver,
    },
  },

  {
    path: appPrefix + appRoutes.MY_PROFILE,
    loadChildren: () =>
      import('../account/my-profile/my-profile.module').then(
        (m) => m.ProfileModule
      ),
    data: { slug: appRoutes.MY_PROFILE },
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
      entitlementData: DataResolver,
    },
  },
  {
    path: appPrefix + appRoutes.UPDATE_PERSONAL_INFO + indexPage,
    redirectTo: appPrefix + appRoutes.UPDATE_PERSONAL_INFO,
  },
  {
    path: appPrefix + appRoutes.UPDATE_PERSONAL_INFO,
    component: UpdatePersonalInfoComponent,
    data: { slug: appRoutes.UPDATE_PERSONAL_INFO },
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.UPDATE_PREFERENCES + indexPage,
    redirectTo: appPrefix + appRoutes.UPDATE_PREFERENCES,
  },

  {
    path: appPrefix + appRoutes.UPDATE_PASSWORD + indexPage,
    redirectTo: appPrefix + appRoutes.UPDATE_PASSWORD,
  },
  {
    path: appPrefix + appRoutes.UPDATE_PASSWORD,
    component: UpdatePasswordComponent,
    canActivate: [AuthGuard],
    data: { slug: appRoutes.UPDATE_PASSWORD },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.MY_ADDRESSES + indexPage,
    redirectTo: appPrefix + appRoutes.MY_ADDRESSES,
  },
  {
    path: appPrefix + appRoutes.MY_ADDRESSES,
    // component: MyProfileComponent,
    loadChildren: () =>
      import('../account/my-address/my-address.module').then(
        (m) => m.AddressModule
      ),
    data: { slug: appRoutes.MY_ADDRESSES },
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },

  {
    path: appPrefix + appRoutes.ADD_ADDRESS + indexPage,
    redirectTo: appPrefix + appRoutes.ADD_ADDRESS,
  },
  {
    path: appPrefix + appRoutes.ADD_ADDRESS,
    component: AddAddressComponent,
    data: { slug: appRoutes.ADD_ADDRESS },
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.EDIT_ADDRESS + indexPage,
    redirectTo: appPrefix + appRoutes.EDIT_ADDRESS,
  },
  {
    path: appPrefix + appRoutes.EDIT_ADDRESS,
    component: UpdateAddressComponent,
    data: { slug: appRoutes.ADD_ADDRESS },
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.DASHBOARD + indexPage,
    redirectTo: appPrefix + appRoutes.DASHBOARD,
  },
  {
    path: appPrefix + appRoutes.DASHBOARD,
    loadChildren: () =>
      import('../account/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuard],
    data: { slug: appRoutes.DASHBOARD },
    resolve: {
      pageLabels: PageResolver,
      entitlementData: DataResolver,
    },
  },
  {
    path: appPrefix + appRoutes.PHONE_NUMBERS_PAGE,
    loadChildren: () =>
      import('../account/phone-numbers-page/phone-number-page.moudle').then(
        (m) => m.PhoneNumberModule
      ),
    canActivate: [AuthGuard],
    data: { slug: appRoutes.PHONE_NUMBERS_PAGE },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.UPDATE_PHONE_NUMBER,
    component: UpdatePhoneNumberComponent,
    canActivate: [AuthGuard],
    data: { slug: appRoutes.UPDATE_PHONE_NUMBER },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.ADD_PHONE_NUMBER,
    component: AddPhoneNumberComponent,
    canActivate: [AuthGuard],
    data: { slug: appRoutes.UPDATE_PHONE_NUMBER },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.TICKET_DETAILS + '/:ticketId',
    component: TicketDetailsComponent,
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
      entitlementData: DataResolver,
    },
  },
  {
    path: appPrefix + appRoutes.TRAVEL_PARTY_INFORMATION,
    loadChildren: () =>
      import(
        '../account/travel-party-information/travel-party-information.module'
      ).then((m) => m.TravelPartyModule),
    canActivate: [AuthGuard],
    data: { slug: appRoutes.TRAVEL_PARTY_INFORMATION },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.ADD_TRAVEL_PARTY_MEMBERS,
    component: AddTravelPartyComponent,
    canActivate: [AuthGuard],
    data: { slug: appRoutes.ADD_TRAVEL_PARTY_MEMBERS },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.EDIT_TRAVEL_PARTY_MEMBERS,
    component: EditTravelPartyComponent,
    canActivate: [AuthGuard],
    data: { slug: appRoutes.EDIT_TRAVEL_PARTY_MEMBERS },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.CONFIRM_TRAVEL_PARTY_MEMBERS,
    component: ConfirmTravelPartyMembersComponent,
    canActivate: [AuthGuard],
    data: { slug: appRoutes.CONFIRM_TRAVEL_PARTY_MEMBERS },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.WARNING_TRAVEL_PARTY_MEMBERS,
    component: WarningTravelPartyComponent,
    canActivate: [AuthGuard],
    data: { slug: appRoutes.WARNING_TRAVEL_PARTY_MEMBERS },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.VIEW_ENTITLMENT + indexPage,
    redirectTo: appPrefix + appRoutes.VIEW_ENTITLMENT,
  },
  {
    path: appPrefix + appRoutes.VIEW_ENTITLMENT,
    loadChildren: () =>
      import('../account/entitlements/entitlement.module').then(
        (m) => m.EntitlementModule
      ),
    canActivate: [AuthGuard],
    data: { slug: appRoutes.VIEW_ENTITLMENT },
    resolve: {
      pageLabels: PageResolver,
      entitlementData: DataResolver,
    },
  },
  {
    path: appPrefix + appRoutes.BUY_TICKETS,
    component: BuyTicketsComponent,
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.ENLARGE_QR_CODE,
    component: EnlargeQrCodeComponent,
    canActivate: [AuthGuard],
    data: { slug: appRoutes.ENLARGE_QR_CODE },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.SHARE_QR_CODE + '/:data',
    component: ShareqrcodeComponent,
    data: { slug: appRoutes.SHARE_QR_CODE },
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.COUPONS,
    loadChildren: () =>
      import('../account/coupons/coupons.module').then((m) => m.CouponModule),
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
      entitlementData: DataResolver,
    },
  },
  {
    path: appPrefix + appRoutes.LINK_COUPON,
    component: AddCouponComponent,
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.REGISTER_TICKETS,
    component: RegistrationLinkTicketsComponent,
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.CONFIRM_TICKETS,
    component: ConfirmLinkTicketsComponent,
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },

  {
    path: appPrefix + appRoutes.CONFIRM_REGISTRATION,
    component: RegisteredLinkTicketsComponent,
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },

  {
    path: appPrefix + appRoutes.ORDER_LIST,
    loadChildren: () =>
      import('../account/order-history/order-history.module').then(
        (m) => m.OrderHistoryModule
      ),
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.ORDER_DETAILS + '/:orderId',
    component: OrderDetailsComponent,
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },

  {
    path: appPrefix + appRoutes.LINK_TICKETS,
    component: LinkTicketComponent,
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
      entitlementData: DataResolver,
    },
  },
  {
    path: appPrefix + appRoutes.PAST_TICKETS,
    component: PastTicketsComponent,
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
      entitlementData: DataResolver,
    },
  },
  {
    path: appPrefix + appRoutes.BIRTHDAY_DISCOUNT,
    // redirectTo: appPrefix + appRoutes.BIRTHDAY_DISCOUNT,
    component: BirthdayDiscountComponent,
    canActivate: [AuthGuard],
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.UNSUBSCRIBE,
    // redirectTo: appPrefix + appRoutes.BIRTHDAY_DISCOUNT,
    component: UnsubscribeEmailComponent,
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.CONTACT_US,
    // redirectTo: appPrefix + appRoutes.BIRTHDAY_DISCOUNT,
    component: InquiryWebFormComponent,
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appPrefix + appRoutes.LOST_FOUND,
    // redirectTo: appPrefix + appRoutes.BIRTHDAY_DISCOUNT,
    component: LostFoundWebFormComponent,
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appRoutes.CART,
    // redirectTo: appPrefix + appRoutes.BIRTHDAY_DISCOUNT,
    component: CartNavigationComponent,
    resolve: {
      pageLabels: PageResolver,
    },
  },
  {
    path: appRoutes.LOGOUT,
    component: LogoutComponent,
  },
  {
    path: appPrefix + appRoutes.SIGN_IN,
    component: SignInComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
