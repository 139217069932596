<gds-full-screen-modal opened (modalClose)="backtoDashboard()">
  <div slot="components">
    <div *ngIf="opened">
      <app-warning-travel-party
        [showConfirmPopup]="showConfirmPopup"
        [opened]="opened"
        (backToEditTravel)="onWarning($event)"
      >
      </app-warning-travel-party>
    </div>
    <div
      class="container modal-content"
      #linkTicketModalContent
      *ngIf="!opened"
    >
      <gds-spacer classes="spacer-70"></gds-spacer>
      <ng-container *ngIf="linkTickets">
        <!-- Linktickets Heading -->
        <gds-heading
          [data]="{
                heading: pageLabels?.['linkTicket.heading']
              }"
          class="A2-W5"
        >
        </gds-heading>
        <gds-spacer classes="spacer-50"></gds-spacer>

        <!-- Linktickets description -->
        <gds-body
          [data]="{
            description: pageLabels?.['linkTicket.subText']
          }"
          class="B1-W3"
        ></gds-body>

        <gds-spacer classes="spacer-30"></gds-spacer>

        <!-- LinkticketsCTA redirect to legacyUrl -->
        <gds-body
          [innerHtml]="
        pageLabels?.['linkticket.legacyDesc']"
          class="B1-W3"
        ></gds-body>

        <gds-spacer classes="spacer-70"></gds-spacer>

        <!-- Registration Information-->
        <gds-content-callout
          contrast="true"
          [data]="{
                  icon: 'info-solid',
                  heading: pageLabels?.['linkTicketConf.calloutInfo'],
                  description: pageLabels?.['linkTicketConf.calloutMsg']
              }"
        >
        </gds-content-callout>
        <gds-spacer classes="spacer-70"></gds-spacer>

        <!-- Linktickets formGroup&formControl Creation -->
        <div [formGroup]="linkTicketsForm">
          <gds-input
            id="ticketNumber"
            formControlName="ticketNumber"
            ngDefaultControl
            [data]="{
                primaryLabel:pageLabels?.['linkticket.inputLabel'],
                supportingText: '',
                tooltip: '',
                type: 'text',
                altText: 'input of phoneNumber',
                ariaText: '',
                required: true
                
              }"
            (blurChange)="getErrorInfo($event)"
            onkeypress="return /^[0-9a-zA-Z-.:]/i.test(event.key)"
          >
          </gds-input>
          <gds-spacer classes="spacer-30"></gds-spacer>

          <div class="content">
            <!-- Linktickets scan-qr icon -->
            <div class="icon">
              <gds-svg
                icon-name="qr-code"
                fill-color="rgb(3, 104, 217)"
                width="24"
                height="24"
                view-box="0 0 24 24"
              ></gds-svg>
            </div>
            <gds-spacer classes="vertical-spacer-30"></gds-spacer>
            <div class="right-description">
              <!-- Linktickets scan-qrCTA redirect to ticket-confirmation -->
              <gds-button
                [data]="{
                    class: 'A3-W6',
                    type: 'tertiary',
                    href: '',
                    label: pageLabels?.['linkticket.options'],
                    disabled: false,
                    target: ''
                  }"
                (buttonClicked)="openDialog()"
              >
              </gds-button>
            </div>
          </div>
          <gds-spacer classes="spacer-70"></gds-spacer>
          <!-- showPrefectureDropdownOptions -->
          <gds-dropdown
            ngDefaultControl
            id="prefecture"
            [data]="{
              formField: { primaryLabel: pageLabels?.['linkticketReg.entIndividual'] },
              options: showPrefectureDropdownOptions ,
              required: true
            }"
            [value]="selectedPrefectureList"
            (valueChange)="selectValue($event)"
          >
          </gds-dropdown>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-body
            *ngIf="showForm"
            [data]="{
          description: pageLabels?.['linkticketReg.entIndividual_dropdown_subText']
        }"
            class="A6-W3"
          ></gds-body>
        </div>
        <gds-spacer classes="spacer-50"></gds-spacer>
      </ng-container>

      <ng-container *ngIf="scanTickets">
        <!-- goToLinkTickets redirect to link-ticket form page -->
        <div class="club-universal-btn" (click)="goToLinkTickets()">
          <span>
            <i
              _ngcontent-wgy-c178=""
              aria-hidden=""
              class="icon icon-arrow-left"
            ></i>
          </span>
          <span class="back-link">
            <!--linktickets heading -->
            <gds-button
              [data]="{
                      type: 'tertiary',
                      href: '',
                      label: pageLabels?.['scanTicket.backCTA'],
                      disabled: false,
                      target: ''
                    }"
            >
            </gds-button>
          </span>
        </div>
        <gds-spacer classes="spacer-70"></gds-spacer>
        <gds-heading
          [data]="{
                heading: pageLabels?.['scanTicket.heading']
              }"
          class="A2-W5"
        >
        </gds-heading>

        <!--scan tickets successCTA & scanner part -->
        <gds-spacer classes="spacer-50"></gds-spacer>
        <div>
          <zxing-scanner
            #scanner
            start="true"
            (scanSuccess)="onScanSuccess($event)"
            [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']"
          ></zxing-scanner>

          <h1>
            <strong> {{ result }} </strong>
          </h1>
        </div>
        <!--scan tickets heading -->

        <!--scan tickets description1 -->
        <gds-spacer classes="spacer-50"></gds-spacer>
        <h6 class="B1-W3">{{pageLabels?.['scanTicket.info1']}}</h6>
        <!--scan tickets description2 -->
        <h6 class="B1-W3">{{pageLabels?.['scanTicket.info2']}}</h6>
        <div class="align-item-icon">
          <div class="icon-container">
            <gds-svg
              icon-name="tickets2"
              fill-color="rgba(32,30,30,1)"
              view-box="0 0 32 31"
              width="32"
              height="32"
            ></gds-svg>
            <gds-spacer classes="vertical-spacer-20"></gds-spacer>

            <!--scan tickets studiopass heading-->
            <gds-heading
              class="A3-W6"
              classes="heading-10"
              [data]="{
                  heading:  pageLabels?.['scanTicket.ticketType1']
                }"
            ></gds-heading>
          </div>
        </div>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <div class="align-item-icon">
          <div class="icon-container">
            <gds-svg
              icon-name="express-pass2"
              fill-color="rgba(32,30,30,1)"
              view-box="0 0 33 18"
              width="32"
              height="32"
            ></gds-svg>
            <gds-spacer classes="vertical-spacer-20"></gds-spacer>

            <!--scan tickets express-pass heading-->
            <gds-heading
              class="A3-W6"
              classes="heading-10"
              [data]="{
                  heading:  pageLabels?.['scanTicket.ticketType2']
                }"
            ></gds-heading>
          </div>
        </div>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <div class="align-item-icon">
          <div class="icon-container">
            <gds-svg
              icon-name="showtimes"
              fill-color="rgba(32,30,30,1)"
              view-box="0 0 32 31"
              width="32"
              height="32"
            ></gds-svg>
            <gds-spacer classes="vertical-spacer-20"></gds-spacer>

            <!--scan tickets event&show heading-->
            <gds-heading
              class="A3-W6"
              classes="heading-10"
              [data]="{
                  heading:  pageLabels?.['scanTicket.ticketType4']
                }"
            ></gds-heading>
          </div>
        </div>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <div class="align-item-icon">
          <div class="icon-container">
            <gds-svg
              icon-name="tickets2"
              fill-color="rgba(32,30,30,1)"
              width="32"
              height="32"
              view-box="0 0 32 31"
            ></gds-svg>
            <gds-spacer classes="vertical-spacer-20"></gds-spacer>

            <!--scan tickets annual-pass heading-->
            <gds-heading
              class="A3-W6"
              classes="heading-10"
              [data]="{
                  heading: pageLabels?.['scanTicket.ticketType3']
                }"
            ></gds-heading>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showForm">
        <app-add-party-form
          (updateTravelPartyForm)="getFormData($event)"
        ></app-add-party-form>
      </ng-container>
    </div>

    <!--RegisterCTA pass ticketNo to get response-->
    <div *ngIf="linkTickets && !opened">
      <gds-sticky-nav-footer
        [data]="{
            button: {
              label: pageLabels?.['linkticket.registerCTA'],
              type: 'primary',
              title: '',
            disabled:  linkTicketsForm.valid && (linkTicketsForm.value.ticketNumber.length >= 10 && linkTicketsForm.value.ticketNumber.length <= 99) &&  ( selectedPrefectureList!==pageLabels?.['linkticketReg.entIndividual_dropdown'] && !showForm || (showForm && data)) ? 'false' : 'true'
            }
          }"
        (buttonClicked)="onSubmit()"
      >
      </gds-sticky-nav-footer>
    </div>
  </div>
</gds-full-screen-modal>
