import { HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DalService } from '@upr-web-primer/dal';
import { IGuestsResponse } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import _ from 'lodash';
import { CommonUtilityService } from '../../services/common-utility.service';
import mockData from '../../shared/constants/mockData.json';

@Component({
  selector: 'app-unsubscribe-email',
  templateUrl: './unsubscribe-email.component.html',
  styleUrls: ['./unsubscribe-email.component.scss'],
})
export class UnsubscribeEmailComponent implements OnInit {
  pageLabels: any;
  mockData = mockData;
  @Input() subscriptionForm: FormGroup;
  emailErrorMsgInfo: any;
  userProfileData: IGuestsResponse;
  userData: any;
  fullName: string;
  addressData: any;
  contactsData: any;
  mailData: any;
  phoneData: any;

  constructor(
    public common: CommonUtilityService,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('Ranges') public ranges: any,
    private cdRef: ChangeDetectorRef,
    @Inject('EmailPasswordPattern') public emailRegex: any,
    private formBuilder: FormBuilder,

    private notification: NotificationService,
    private dal: DalService,
    @Inject('AppDefaults') public appDefault: any,
    private title: Title
  ) {
    this.pageLabels = this.common.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.unsubscribeEmail']);
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.subscriptionForm = this.formBuilder.group({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailRegex.EMAIL),
      ]),
    });
  }

  getErrorMessageInfo(event: any): void {
    const fieldInfo = event?.target?.id;

    const emailValue = this.subscriptionForm.value.email;
    if (emailValue.length >= this.ranges.MAX.EMAIL) {
      this.subscriptionForm.controls[this.globalDefault.EMAIL].setValue(
        this.subscriptionForm.value.email.substring(0, this.ranges.MAX.EMAIL)
      );
    }
    this.emailErrorMsgInfo = this.getErrorMessage(fieldInfo);

    this.cdRef.detectChanges();
  }

  getErrorMessage(field: any): string {
    const fieldName = this.subscriptionForm?.get(field);

    const emptyCheck = this.pageLabels?.['errCode.E001'];

    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      switch (field) {
        case this.globalDefault.EMAIL: {
          if (!this.subscriptionForm.value.email) return emptyCheck;
          else if (
            this.subscriptionForm.value.email.length < this.ranges.MAX.EMAIL &&
            this.subscriptionForm.value.email.match(
              this.emailRegex.EMAIL_SIGNUP
            ) == null
          ) {
            return this.pageLabels?.['errCode.E021'];
          } else if (
            this.subscriptionForm.value.email.length < this.ranges.MAX.EMAIL
          ) {
            return '';
          } else {
            return this.pageLabels?.['errCode.E021'];
          }
        }
      }
    }
    return '';
  }

  navigateTo(): any {
    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append('x-instance-id', '123');
    httpHeaders = httpHeaders.append('x-source-id', '1234');

    const request = {
      email_address: this.subscriptionForm.value.email,

      email_offers_promos: false,
    };

    const url = '/email-opt-in-out';
    this.dal
      .httpRequest(url, this.appDefault.POST, request, httpHeaders)
      .then((data: any) => {
        if (data)
          this.notification?.show(
            this.pageLabels?.['unsubscribe.success'],
            this.globalDefault.SUCCESS
          );
      })
      .catch((err: any) => {
        this.notification?.show(err.error.detail);
      });
  }

  fetchProfileInfo(
    addressList: any,
    phoneList: any,
    mailData: any,
    contachData: any
  ): void {
    _.each(addressList, (element: any) => {
      if (element?.primary) {
        this.addressData = element;
      }
    });
    _.each(contachData, (element: any) => {
      if (element?.primary == true) {
        this.contactsData = element;
        this.fullName = `${this.contactsData?.last_name}  ${this.contactsData?.first_name}`;
      }
    });
    _.each(mailData, (element: any) => {
      if (element?.primary) {
        this.mailData = element;
      }
    });
    _.each(phoneList, (element: any) => {
      if (element?.primary) {
        this.phoneData = element;
      }
    });
  }
}
