<div
  [ngClass]="couponData?.length > 1 ? 'couponsOffers' : 'couponsOffersSingle'"
>
  <!-- Coupon Card-->
  <ng-container *ngFor="let coupon of couponData; index as i; let last = last">
    <div class="card">
      <div>
        <!-- Coupon Title-->
        <div class="corner-box B1-W2">
          {{ pageLabels?.['coupons.couponCardTitle'] }}
        </div>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <!-- Coupon Details-->
        <gds-spacer classes="vertical-spacer-50"></gds-spacer>
        <div class="card-content">
          <h4 class="A4-W5 adventure">{{ coupon?.heading }}</h4>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <div
            class="card-description B1-W2"
            [innerHTML]="coupon?.description"
          ></div>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <div class="last-content">
            <h6 class="C1-W4 labelCss">
              {{ coupon?.attractionHeadline }}
            </h6>
            <gds-spacer classes="spacer-20"></gds-spacer>
            <div class="B2-W2">{{ coupon?.attractionBody }}</div>
          </div>
          <gds-spacer classes="spacer-50"></gds-spacer>
        </div>
      </div>

      <!--Redeem Button-->
      <div class="button-css">
        <hr />
        <gds-spacer classes="spacer-50"></gds-spacer>
        <gds-button
          [data]="{
            class: 'A3-W6',
            type: 'primary',
            href: '',
            label: pageLabels?.['coupons.redeemCTA'],
            disabled: isButtonDisabled(coupon),
            target: ''
          }"
          (buttonClicked)="redeemCoupons(coupon)"
          (keydown.enter)="redeemCoupons(coupon)"
          (keyup.Space)="redeemCoupons(coupon)"
          class="redeem-btn"
        >
        </gds-button>
        <gds-spacer classes="spacer-50"></gds-spacer>
      </div>
    </div>
    <gds-spacer classes="vertical-spacer-50" *ngIf="i % 2 === 0"></gds-spacer>
  </ng-container>
  <div
    *ngIf="displayProductList?.length && fromDashboard"
    class="offers-card container"
  >
    <!-- Display Product card List  -->
    <div class="offer-cards">
      <gds-spacer *ngIf="couponData?.length" classes="spacer-70"></gds-spacer>
      <gds-heading
        classes="heading-8"
        [data]="{
          heading: pageLabels?.['offers.text1']
        }"
      ></gds-heading>
      <gds-spacer classes="spacer-30"></gds-spacer>
      <gds-body
        classes="body-1"
        [data]="{
          description: pageLabels?.['offers.text2']
        }"
      ></gds-body>
    </div>
  </div>
</div>

<!-- Display coupons and products both are empty then showing No coupons& offers text-->
<div
  *ngIf="!displayProductList?.length && !couponData?.length"
  class="selected"
>
  <h6 class="A6-W3">
    {{pageLabels?.['dashboard.couponsEmpty']}}
  </h6>
</div>

<div *ngIf="isOpenPopup">
  <gds-modal
    opened="isModal"
    header="true"
    footer="true"
    backdrop="static"
    (modalClose)="closePopup()"
  >
    <div slot="header">
      <gds-heading
        [data]="{ heading: pageLabels?.['err.coupon_heading'] }"
        classes="heading-3"
      ></gds-heading>
    </div>
    <div slot="body" class="comm-msg">
      <gds-body
        classes="body-1"
        [data]="{
          description: commerceErrMsg
        }"
      ></gds-body>
    </div>
    <div slot="footer" class="warning-modal">
      <div class="d-flex">
        <gds-button
          [data]="{
            type: 'tertiary',
            label: pageLabels?.['err.coupon_link'],
            target: '_blank',
            disabled: 'false'
          }"
          (buttonClicked)="openWTSCartPage(wtsCartURL)"
        ></gds-button>
      </div>
    </div>
  </gds-modal>
</div>
