import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { LocaleService } from '@upr-web-primer/locale';
import { PageLabelsResolver } from '@upr-web-primer/page-labels';

@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  fontCssUrl: string;
  iconCssUrl: string;
  constructor(
    private pageLabels: PageLabelsResolver,
    private locale: LocaleService,
    @Inject(DOCUMENT) private document: Document,
    @Inject('AppDefaults') private appDefaults: any,
    @Inject('DefaultLanguage') private defaultLanguage: string,
    @Inject('DefaultCountry') private defaultCountry: string
  ) {
    this.fontCssUrl = this.appDefaults.FONT_CSS_URL;
    this.iconCssUrl = this.appDefaults.ICON_CSS_URL;
  }

  initAppData(): void {
    this.locale.init();
    this.loadFonts(this.fontCssUrl);
    this.loadFonts(this.iconCssUrl);
    const language =
      this.locale.getFromStorage().language || this.defaultLanguage;
    const country = this.locale.getFromStorage().country || this.defaultCountry;
    this.updateHtmLang(language, country);
    this.pageLabels.init();
  }

  private loadFonts(url: string): void {
    const link = this.document.createElement('link');
    link.href = url;
    link.rel = 'stylesheet';
    this.document.getElementsByTagName('head')[0].appendChild(link);
  }

  private updateHtmLang(language: string, country: string): void {
    if (language === 'zh') {
      language = language + '-' + (country || 'cn');
    }
    this.document.documentElement.lang = language;
  }

  public modalContentHeightCalc = (
    elementRef: ElementRef,
    heightPX: number
  ): void => {
    const deviceInnerHeight = window.innerHeight;
    const nativeElement: any = elementRef?.nativeElement;
    if (nativeElement)
      nativeElement.style.height = `${deviceInnerHeight - heightPX}px`;
  };

  public modalContentHeightGenerator = (elementRef: ElementRef): void => {
    const modalBtnWrapHeight = this.document?.querySelector(
      '.gds-full-screen-modal-btn-wrap'
    )?.clientHeight;
    const deviceInnerHeight = window?.innerHeight;
    const nativeElement: any = elementRef?.nativeElement;
    const footerHeight = nativeElement?.nextElementSibling?.offsetHeight;
    const remainingHeight =
      deviceInnerHeight - (footerHeight + modalBtnWrapHeight);
    if (nativeElement) nativeElement.style.height = `${remainingHeight}px`;
  };
}
