import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DalService } from '@upr-web-primer/dal';
import { NotificationService } from '@upr-web-primer/notification';
import { CommonUtilityService } from '../../services/common-utility.service';
import { ToastService } from '../../shared/service/toast.service';
@Component({
  selector: 'app-inquiry-web-form',
  templateUrl: './inquiry-web-form.component.html',
  styleUrls: ['./inquiry-web-form.component.scss'],
})
export class InquiryWebFormComponent implements OnInit {
  pageLabels: any;
  @Input() inquiryWebForm: FormGroup;
  nameRegex = this.namePattern.NAME_SIGNUP;
  firstNameErrorMsgInfo: string;
  invalidEmail = false;
  lastNameErrorMsgInfo: string;
  emailErrorMsgInfo: string;
  reEnterEmailErrorMsgInfo: string;
  agreeConditionSelected: boolean;
  replySelected: boolean;
  userProfileData: any;
  inputChanged: boolean;
  typeArray: { label: any; value: any }[];
  selectedValue: any;
  phoneNoErrorMsgInfo: any;
  lastNameFuriganaErrorMsgInfo: string;
  firstNameFuriganaErrorMsgInfo: string;
  replyInputErrorInfo: string;
  disableSendBtn = false;
  constructor(
    public common: CommonUtilityService,
    private formBuilder: FormBuilder,
    @Inject('NamePattern') public namePattern: any,
    @Inject('Ranges') public ranges: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    private cdRef: ChangeDetectorRef,
    @Inject('EmailPasswordPattern') public emailRegex: any,
    @Inject('AppDefaults') public appDefault: any,
    public dal: DalService,
    public toastService: ToastService,
    public notification: NotificationService
  ) {
    this.pageLabels = this.common.getResolvedPageData();
  }
  /**
   * ngOnInit - It will load all the functions
   */

  ngOnInit(): void {
    this.common?.OIDCTokenAnonymous();
    this.typeArray = [
      {
        label: this.pageLabels?.['inquiryForm.type_type_opinions'],
        value: 752510000,
      },
      {
        label: this.pageLabels?.['inquiryForm.type_type_inquiry'],
        value: 752510001,
      },
      {
        label: this.pageLabels?.['inquiryForm.type_type_others'],
        value: 752510002,
      },
    ];
    this.createForm();
    this.getFormData();
    this.enableButton();
  }
  /**
   * createForm - create the form
   */

  createForm(): void {
    this.inquiryWebForm = this.formBuilder.group({
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
        this.noWhitespaceValidator(),
        Validators.pattern(this.nameRegex),
      ]),
      last_name_furgina: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
        this.noWhitespaceValidator(),
        Validators.pattern(this.nameRegex),
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(this.nameRegex),
        this.noWhitespaceValidator(),
      ]),
      first_name_furgina: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(this.nameRegex),
        this.noWhitespaceValidator(),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailRegex.EMAIL),
      ]),
      reEnterEmail: new FormControl('', [
        Validators.required,
        Validators.pattern(this.emailRegex.EMAIL),
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(11),
      ]),
      type: new FormControl('', Validators.required),
      agree: new FormControl('', [Validators.requiredTrue]),
      replyInput: new FormControl('', [
        Validators.minLength(5),
        Validators.maxLength(2000),
      ]),
      reply: new FormControl(''),
    });
  }
  /**
   * noWhitespaceValidator
   * @returns
   */

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace =
        (control.value || '').trim().length === this.ranges.PASSWORDS.INDEX[0];
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  /**
   * getErrorMessageInfo - Validations of each field
   * @param event - event
   */
  getErrorMessageInfo(event: any): void {
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.FIRSTNAME:
        this.inquiryWebForm.value.firstName =
          this.inquiryWebForm.value.firstName.trim();

        const firstNameValue = this.inquiryWebForm.value.firstName;
        if (firstNameValue.length > this.ranges.MAX.FIRST_NAME) {
          this.inquiryWebForm.controls[this.globalDefault.FIRSTNAME].setValue(
            this.inquiryWebForm.value.firstName.substring(
              0,
              this.ranges.MAX.FIRST_NAME
            )
          );
        }
        this.firstNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.FURGINA_LASTNAME:
        this.inquiryWebForm.value.last_name_furgina =
          this.inquiryWebForm.value.last_name_furgina.trim();

        const lastNameFuriganaValue =
          this.inquiryWebForm.value.last_name_furgina;
        if (lastNameFuriganaValue.length > this.ranges.MAX.LAST_NAME) {
          this.inquiryWebForm.controls[
            this.globalDefault.FURGINA_LASTNAME
          ].setValue(
            this.inquiryWebForm.value.last_name_furgina.substring(
              0,
              this.ranges.MAX.LAST_NAME
            )
          );
        }
        this.lastNameFuriganaErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.FURGINA_FIRSTNAME:
        this.inquiryWebForm.value.first_name_furgina =
          this.inquiryWebForm.value.first_name_furgina.trim();

        const firstNameFuriganaValue =
          this.inquiryWebForm.value.first_name_furgina;
        if (firstNameFuriganaValue.length > this.ranges.MAX.FIRST_NAME) {
          this.inquiryWebForm.controls[
            this.globalDefault.FURGINA_LASTNAME
          ].setValue(
            this.inquiryWebForm.value.first_name_furgina.substring(
              0,
              this.ranges.MAX.FIRST_NAME
            )
          );
        }
        this.firstNameFuriganaErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.LASTNAME:
        this.inquiryWebForm.value.lastName =
          this.inquiryWebForm.value.lastName.trim();
        const lastNameValue = this.inquiryWebForm.value.lastName;
        if (lastNameValue.length > this.ranges.MAX.LAST_NAME) {
          this.inquiryWebForm.controls[this.globalDefault.LASTNAME].setValue(
            this.inquiryWebForm.value.lastName.substring(
              0,
              this.ranges.MAX.LAST_NAME
            )
          );
        }
        this.lastNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.EMAIL:
        const emailValue = this.inquiryWebForm.value.email;
        if (emailValue.length >= this.ranges.MAX.EMAIL) {
          this.inquiryWebForm.controls[this.globalDefault.EMAIL].setValue(
            this.inquiryWebForm.value.email.substring(0, this.ranges.MAX.EMAIL)
          );
        }
        this.emailErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.PHONENUMBER:
        this.phoneNoErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.REENTER_EMAIL:
        const reEnterEmailValue = this.inquiryWebForm.value.reEnterEmail;
        if (reEnterEmailValue.length >= this.ranges.MAX.EMAIL) {
          this.inquiryWebForm.controls['reEnterEmail'].setValue(
            this.inquiryWebForm.value.reEnterEmail.substring(
              0,
              this.ranges.MAX.EMAIL
            )
          );
        }
        this.reEnterEmailErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.REPLY_INPUT:
        this.replyInputErrorInfo = this.getErrorMessage(fieldInfo);
        break;
    }
    this.cdRef.detectChanges();
  }
  /**
   * getErrorMessage - Validations for each field
   * @param field
   * @returns
   */
  getErrorMessage(field: any): string {
    const fieldName = this.inquiryWebForm?.get(field);
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    const regex = /\s/;
    if (regex.test(this.inquiryWebForm.value[field]))
      this.inquiryWebForm.value[field] = '';

    if (field === this.globalDefault.REENTER_EMAIL) {
      // Checking re-enter email
      if (!this.inquiryWebForm.value.reEnterEmail) return emptyCheck;
      else if (
        this.inquiryWebForm.value.reEnterEmail.length < this.ranges.MAX.EMAIL &&
        this.inquiryWebForm.value.reEnterEmail.match(
          this.emailRegex.EMAIL_SIGNUP
        ) == null
      ) {
        return this.pageLabels?.['errCode.E021'];
      } else if (
        this.inquiryWebForm.value.email !==
        this.inquiryWebForm.value.reEnterEmail
      ) {
        this.inputChanged = false;
        this.reEnterEmailErrorMsgInfo = this.pageLabels?.['errCode.E030'];
        return this.reEnterEmailErrorMsgInfo;
      }
    }

    if (field === this.globalDefault.EMAIL) {
      if (!this.inquiryWebForm.value.email) return emptyCheck;
      else if (
        this.inquiryWebForm.value.email.length < this.ranges.MAX.EMAIL &&
        this.inquiryWebForm.value.email.match(this.emailRegex.EMAIL_SIGNUP) ==
          null
      ) {
        return this.pageLabels?.['errCode.E021'];
      } else if (
        this.inquiryWebForm.value.email.length < this.ranges.MAX.EMAIL
      ) {
        return '';
      } else {
        return this.pageLabels?.['errCode.E021'];
      }
    }
    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      return this.getErrorInfo(field);
    }
    this.cdRef.detectChanges();
    return '';
  }
  /**
   * getErrorInfo()
   * @param field Input field
   * @returns Error message
   */
  getErrorInfo(field: any): any {
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    switch (field) {
      case this.globalDefault.FIRSTNAME: {
        if (!this.inquiryWebForm.value.firstName) return emptyCheck;
        else if (
          this.inquiryWebForm.value.firstName.length <
          this.ranges.MAX.FIRST_NAME
        ) {
          return '';
        } else {
          this.firstNameErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.FIRST_NAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.firstNameErrorMsgInfo;
        }
      }
      case this.globalDefault.FURGINA_FIRSTNAME: {
        if (!this.inquiryWebForm.value.first_name_furgina) return emptyCheck;
        else if (
          this.inquiryWebForm.value.first_name_furgina.length <
          this.ranges.MAX.FIRST_NAME
        ) {
          return '';
        } else {
          this.firstNameFuriganaErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.FIRST_NAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.firstNameFuriganaErrorMsgInfo;
        }
      }
      case this.globalDefault.LASTNAME: {
        if (!this.inquiryWebForm.value.lastName) return emptyCheck;
        else if (
          this.inquiryWebForm.value.lastName.length < this.ranges.MAX.LAST_NAME
        ) {
          return '';
        } else {
          this.lastNameErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.LAST_NAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.lastNameErrorMsgInfo;
        }
      }
      case this.globalDefault.FURGINA_LASTNAME: {
        if (!this.inquiryWebForm.value.last_name_furgina) return emptyCheck;
        else if (
          this.inquiryWebForm.value.last_name_furgina.length <
          this.ranges.MAX.LAST_NAME
        ) {
          return '';
        } else {
          this.lastNameFuriganaErrorMsgInfo =
            this.pageLabels?.['errCode.E020'] +
            ' ' +
            this.ranges.MAX.LAST_NAME +
            ' ' +
            this.pageLabels?.['createAccount.maxCharCheck'];
          return this.lastNameFuriganaErrorMsgInfo;
        }
      }
      case this.globalDefault.PHONENUMBER: {
        if (!this.inquiryWebForm.value.phoneNumber) return emptyCheck;
        else {
          this.phoneNoErrorMsgInfo = this.pageLabels?.['errCode.E007'];
          return this.phoneNoErrorMsgInfo;
        }
      }
      case this.globalDefault.REPLY_INPUT: {
        if (!this.inquiryWebForm.value.replyInput) return '';
        else {
          this.replyInputErrorInfo = this.pageLabels?.['errCode.E031'];
          return this.replyInputErrorInfo;
        }
      }
    }
  }
  /**
   * onCheckboxChange - to handle the change of checkbox values
   * @param event The event object
   * @param checkboxType The type of checkbox ('agree' or 'reply')
   */
  onCheckboxChange(event: any, checkboxType: string): void {
    let checkboxValue = false;

    // Determine the checkbox value based on the event detail
    if (event?.target?.id === checkboxType) {
      checkboxValue =
        event?.detail === this.globalDefault.CHECKED ? true : false;
    }

    // Update the corresponding form control based on the checkbox type
    switch (checkboxType) {
      case this.globalDefault.AGREE:
        this.agreeConditionSelected = checkboxValue;
        this.inquiryWebForm.patchValue({
          agree: this.agreeConditionSelected,
        });
        break;
      case this.globalDefault.REPLY:
        this.replySelected = checkboxValue ? false : true;
        this.inquiryWebForm.patchValue({
          reply: this.replySelected,
        });
        break;
      default:
        break;
    }
  }
  /**
   * enableButton-It will use when user changes previous value
   */

  enableButton(): any {
    this.inquiryWebForm.valueChanges.subscribe((currentValue) => {
      if (
        (currentValue.lastName === this.userProfileData?.lastName &&
          currentValue.firstName === this.userProfileData?.firstName &&
          currentValue.email === this.userProfileData?.email &&
          currentValue.reEnterEmail === this.userProfileData?.reEnterEmail &&
          currentValue.agree === this.userProfileData?.agree &&
          currentValue.reply === this.userProfileData?.reply &&
          currentValue?.last_name_furgina === this.userProfileData?.lastName &&
          currentValue?.first_name_furgina ===
            this.userProfileData?.firstName &&
          currentValue?.phoneNumber === '') ||
        (currentValue?.phoneNumber?.length || 0) < this.ranges.MAX.USA_PHONE ||
        (currentValue?.phoneNumber?.length || 0) > 11
      ) {
        this.inputChanged = false;
      } else {
        this.inputChanged = true;
      }
    });
  }
  /**
   * getAddress-Get edited value from the Address
   */

  getFormData(): void {
    this.userProfileData = this.common.getFormData();
  }
  /**
   * onKeyPressEvent - on key press event occur to check the input type
   * @param event - contains the characters entered in input field
   */
  onKeyPressEvent(event: KeyboardEvent): void {
    const isNumeric = /[0-9]/.test(event.key);
    const isControlKey = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
    ].includes(event.key);

    // Allow numeric input or control keys, prevent other characters
    if (!isNumeric && !isControlKey) {
      event.preventDefault();
    }
  }
  /**
   * matchDropDown - drop down for gender and relationship
   * @param event : specify the event
   */
  matchDropDown(event: any): any {
    this.inquiryWebForm.markAsDirty();
    this.selectedValue = event.detail;
    this.inquiryWebForm.patchValue({
      type: this.selectedValue,
    });
  }
  /**
   * onSend - It will submit the formControl to api url
   */

  onSend(): any {
    const data = this.inquiryWebForm.value;
    const request = {
      udx_emailaddress: data?.email,
      udx_type: data?.type,
      udx_furiganafirstname: data?.first_name_furgina,
      udx_firstname: data?.firstName,
      udx_surname: data?.lastName,
      udx_furiganasurname: data?.last_name_furgina,
      udx_comments: data?.replyInput,
      udx_acceptedprivacypolicy: data?.agree,
      udx_responserequested: data?.reply === '' || data?.reply ? true : false,
      udx_phonenumber: data?.phoneNumber,
    };
    this.common.postInquiryWebForm(request).subscribe(
      (res: any) => {
        if (res) this.disableSendBtn = true;
        this.notification.show(
          this.pageLabels?.['inquiryForm.success'],
          this.globalDefault.SUCCESS
        );
      },
      (err: any) => {
        if (err) this.notification.show(this.pageLabels?.['errCode.E019']);
      }
    );
  }
  /**
   * getRelativeUrls-It will construct the URL for Post email-validation
   */

  public getRelativeUrls(url: string, areaCode: string): string {
    return url.replace('{resort-area-code}', areaCode);
  }
}
