<gds-full-screen-modal opened (modalClose)="backToPhoneNumberPage()">
  <!--Update phone number model popup-->
  <div slot="components">
    <div class="container modal-content" #updatePhoneContentModal>
      <!--Edit phone number header-->
      <gds-heading
        [data]="{
          heading: pageLabels?.['editPhone.heading']
        }"
        class="A2-W5"
      >
      </gds-heading>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!--Edit phone number description-->

      <gds-body
        [data]="{
            description: pageLabels?.['editPhone.subText']
          }"
        class="B1-W3"
      ></gds-body>

      <gds-spacer classes="spacer-70"></gds-spacer>
      <!--Update phone number form data-->
      <div [formGroup]="phoneNumberForm">
        <!--Phone number input-->
        <gds-input
          id="phoneNumber"
          formControlName="phoneNumber"
          ngDefaultControl
          [data]="{
            primaryLabel: pageLabels?.['editPhone.phoneNumber'],
            supportingText: '',
            tooltip: '',
            type: 'text',
            altText: 'input of phoneNumber',
            ariaText: '',
            required: true
          }"
          (keypress)="onKeyPressEvent($event)"
          onKeyPress=" if( this.value?.length === 11 ) return false;"
        >
        </gds-input>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <!--Checkbox of phone number-->
        <gds-input
          *ngIf="!phoneNumberPrimary"
          id="primary"
          class="checkbox-label"
          [data]="{
            primaryLabel: pageLabels?.['editPhone.setPrimary'],
            secondaryText: '',
            primaryIconTitleText: '',
            type: 'checkbox',
            altText: '',
            ariaText: ''
          }"
          [value]="phoneNumberPrimary ? 'checked' : ''"
          [class.disabled]="
            totalPhoneNumberLists?.length === 1 || phoneNumberPrimary
          "
          (valueChange)="editPrimaryNumber($event)"
        >
        </gds-input>
        <!--Set as primary button-->
        <div class="align-left" *ngIf="phoneNumberPrimary">
          <div class="icon-container">
            <gds-svg
              icon-name="check-mark-box"
              fill-color="rgba(161,157,157,1)"
              width="24"
              height="24"
            ></gds-svg>
            <gds-spacer classes="vertical-spacer-30"></gds-spacer>
            <gds-heading
              class="B1-W4"
              classes="heading-7"
              [data]="{
              heading: pageLabels?.['editPhone.setPrimary']
            }"
            ></gds-heading>
          </div>
        </div>
        <!--Delete button-->
        <div *ngIf="totalPhoneNumberLists?.length >= 2">
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-button
            *ngIf="!phoneNumberPrimary"
            [data]="{
              type: 'tertiary',
              href: '',
              label: pageLabels?.['editPhone.delete'],
              target: '',
              disabled: phoneNumberPrimary ? 'true' : 'false'
            }"
            [class.disabled]="phoneNumberPrimary"
            (buttonClicked)="deletePhoneNumber()"
          >
          </gds-button>
          <gds-heading
            *ngIf="phoneNumberPrimary"
            class="B1-W4"
            classes="heading-7"
            [data]="{ heading:
        pageLabels?.['editPhone.delete'] }"
          ></gds-heading>
        </div>
        <gds-spacer classes="spacer-85"></gds-spacer>
      </div>
    </div>
    <!--Save footer button-->
    <gds-sticky-nav-footer
      [data]="{
        button: {
          type: 'primary',
          href: '',
          label: pageLabels?.['addAddress.save'],
          disabled: inputChanged || phoneNumberChecked ? 'false' : 'true',
          target: ''
        }
      }"
      (buttonClicked)="onSubmit()"
    >
    </gds-sticky-nav-footer>
  </div>
</gds-full-screen-modal>
