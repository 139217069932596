export const TICKET_STATUS: any = {
  'MS.ticketStatus.2': 'ticketStatus.Inactive',
  'MS.ticketStatus.5': 'ticketStatus.Inactive',
  'MS.ticketStatus.9': 'ticketStatus.Inactive',
  'MS.ticketStatus.101': 'ticketStatus.cancelled',
  'MS.ticketStatus.102': 'ticketStatus.cancelled',
  'MS.ticketStatus.103': 'ticketStatus.cancelled',
  'MS.ticketStatus.104': 'ticketStatus.cancelled',
  'MS.ticketStatus.105': 'ticketStatus.cancelled',
  'MS.ticketStatus.109': 'ticketStatus.Inactive',
  'MS.ticketStatus.110': 'ticketStatus.expired',
  'MS.ticketStatus.114': 'ticketStatus.cancelled',
  'MS.ticketStatus.115': 'ticketStatus.cancelled',
  'MS.ticketStatus.1010': 'ticketStatus.Inactive',
  'MS.ticketStatus.1013': 'ticketStatus.Inactive',
  'MS.ticketStatus.1015': 'ticketStatus.Inactive',
};
