<div [ngClass]="isExpired ? 'showOpacity' : ''">
  <!--To display content of Timed Attraction-->

  <div *ngIf="timedAttractionsWithGroupWise">
    <gds-content-section-header
      [data]="{
      heading: pageTitle?.['tdExpressPass.timedAttraction'],
      icon: ''
    }"
    ></gds-content-section-header>
    <div class="selection-card">
      <gds-spacer classes="vertical-spacer-50"></gds-spacer>
      <div>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <div class="card-content">
          <!--Display content of Timed Attractions-->
          <div
            *ngFor="
              let eachGroupAttractions of timedAttractionsWithGroupWise;
              let index = index;
              let last = last
            "
          >
            <div
              *ngFor="
                let event of eachGroupAttractions?.attractions;
                first as isFirst;
                last as isLast
              "
            >
              <div class="card-content-image">
                <div>
                  <gds-picture
                    desktop="{{ event?.tridionContent?.image[0]?.desktop }}"
                    tablet="{{ event?.tridionContent?.image[0]?.tablet }}"
                    mobile="{{ event?.tridionContent?.image[0]?.mobile }}"
                  >
                  </gds-picture>
                </div>
                <gds-spacer classes="vertical-spacer-30"></gds-spacer>
                <div class="card-content-description">
                  <div class="align-left-content">
                    <div class="icon-container">
                      <gds-spacer classes="vertical-spacer-20"></gds-spacer>
                      <div class="chart">
                        <gds-location-eyebrow
                          [data]="{
                            eyebrow:
                              event?.selectionFromTime +
                              '~' +
                              event?.selectionToTime,
                            bgColor: event?.tridionContent?.icon_color
                              ? event?.tridionContent?.icon_color
                              : ''
                          }"
                        ></gds-location-eyebrow>
                      </div>

                      <gds-heading
                        class="B2-W3"
                        [data]="{
                          heading: event?.eventName
                        }"
                      ></gds-heading>
                    </div>
                  </div>
                </div>

                <div *ngIf="!last">
                  <gds-spacer classes="spacer-50"></gds-spacer>
                  <hr class="divider" />
                  <gds-spacer classes="spacer-50"></gds-spacer>
                </div>
              </div>
              <div *ngIf="!isLast">
                <gds-spacer classes="spacer-30"></gds-spacer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <gds-spacer classes="spacer-50"></gds-spacer>

  <!--To display Untimed Attraction Content-->
  <div *ngIf="nonTimedAttractionsWithGroupWise">
    <gds-content-section-header
      [data]="{
        heading: pageTitle?.['tdExpressPass.untimedAttraction'],
        icon: ''
      }"
    ></gds-content-section-header>
    <div class="selection-card">
      <div class="card-content untimed-content">
        <!--Display content if non timed attractions-->
        <div
          *ngFor="
            let eachGroupAttractions of nonTimedAttractionsWithGroupWise;
            let index = index;
            let last = last
          "
        >
          <gds-spacer classes="spacer-50"></gds-spacer>

          <div
            *ngFor="
              let event of eachGroupAttractions?.attractions;
              first as isFirst;
              last as isLast
            "
            class="event-container"
          >
            <!--Display subgroup content of choice A-->
            <div *ngIf="event?.subgroup?.endsWith(choiceAHeading) && isFirst">
              <gds-heading
                class="A6-W4"
                [data]="{
                  heading: pageLabels?.['tdExpressPass.choiceA']
                }"
              ></gds-heading>
              <gds-spacer classes="spacer-30"></gds-spacer>
              <gds-heading
                class="choice_description"
                [data]="{
                  heading:pageLabels?.['tdExpressPass.choiceDesc']
                }"
              ></gds-heading>
              <gds-spacer classes="spacer-30"></gds-spacer>
            </div>
            <!--Display subgroup content of choice B-->
            <div *ngIf="event?.subgroup?.endsWith(choiceBHeading) && isFirst">
              <gds-heading
                class="A6-W4"
                [data]="{
                  heading: pageLabels?.['tdExpressPass.choiceB']
                }"
              ></gds-heading>
              <gds-spacer classes="spacer-30"></gds-spacer>
              <gds-heading
                class="choice_description"
                [data]="{
                  heading:  pageLabels?.['tdExpressPass.choiceDesc']
                }"
              ></gds-heading>
              <gds-spacer classes="spacer-30"></gds-spacer>
            </div>

            <div class="card-content-image">
              <div>
                <gds-picture
                  class="responsiveImage"
                  [desktop]="event?.tridionContent?.image[0]?.desktop"
                  [tablet]="event?.tridionContent?.image[0]?.tablet"
                  [mobile]="event?.tridionContent?.image[0]?.mobile"
                  width="80"
                  height="34"
                >
                </gds-picture>
              </div>
              <gds-spacer classes="vertical-spacer-30"></gds-spacer>
              <div class="card-content-description">
                <div class="align-left-content">
                  <div class="icon-container">
                    <gds-spacer classes="vertical-spacer-20"></gds-spacer>
                    <div class="chart">
                      <gds-location-eyebrow
                        [data]="{
                          eyebrow: event?.eventName,
                          bgColor: event?.tridionContent?.icon_color
                            ? event?.tridionContent?.icon_color
                            : ''
                        }"
                      ></gds-location-eyebrow>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="!isLast">
                <gds-spacer classes="spacer-50"></gds-spacer>
                <hr class="divider" />
                <gds-spacer classes="spacer-50"></gds-spacer>
              </div>
            </div>

            <div *ngIf="isLast">
              <gds-spacer classes="spacer-50"></gds-spacer>
              <hr class="divider" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<gds-spacer classes="spacer-50"></gds-spacer>
