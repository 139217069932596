import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormControlService } from '@upr-web-primer/form-fields';
import { GeolocationService } from '@upr-web-primer/geolocation';
import { GoogleRecaptchaService } from '@upr-web-primer/google-recaptcha';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { SpinnerService } from '@upr-web-primer/spinner';
import { EnvironmentService } from '../../../environments/environment.service';
import { CommonUtilityService } from '../../services/common-utility.service';

@Injectable({
  providedIn: 'root',
})

/**
 * @export
 * @class AccountServicesInjectorClass
 */
export class AccountServicesInjectorClass {
  /**
   * Constructor.
   * @param {CommonUtilityService} common
   * @param {MsGuestsService} guests
   * @param {GoogleRecaptchaService} recaptcha
   * @param {FormBuilder} fb
   * @param {FormControlService} formControlService
   * @param {NotificationService} notification
   * @param {SpinnerService} spinner
   * @param {EnvironmentService} environmentService
   * @param {GeolocationService} geolocationService
   * @memberof WebServicesInjectorClass
   */

  constructor(
    public common: CommonUtilityService,
    public guests: MsGuestsService,
    public recaptcha: GoogleRecaptchaService,
    public fb: FormBuilder,
    public formControlService: FormControlService,
    public notification: NotificationService,
    public spinner: SpinnerService,
    public environmentService: EnvironmentService,
    public geolocationService: GeolocationService
  ) {}
}
