<!--Full Screen Modal-->
<gds-full-screen-modal opened="isOpened" (modalClose)="goToPage('close')">
  <div slot="components">
    <div class="container">
      <!--Screen Header-->
      <gds-spacer class="spacer-70"></gds-spacer>
      <gds-body
        [data]="{
          description: pageLabels?.['linkticketReg.userRegHeading']
        }"
        class="A2-W5"
      ></gds-body>

      <gds-spacer class="spacer-50"></gds-spacer>
      <gds-body
        [data]="{
        description: pageLabels?.['linkticketReg.subText']
      }"
        class="B1-W3"
      ></gds-body>

      <gds-spacer class="spacer-80"></gds-spacer>

      <!--Dropdown to display guest or travel party member name-->
      <gds-dropdown
        ngDefaultControl
        id="prefecture"
        [data]="{
      formField: { primaryLabel: pageLabels?.['linkticketReg.entIndividual'] },
      options: showPrefectureDropdownOptions,
      required: true
    }"
        [value]="selectedPrefectureList"
        (valueChange)="selectValue($event)"
      >
      </gds-dropdown>
      <gds-spacer class="spacer-80"></gds-spacer>

      <!--Display form to add new travel party member-->
      <div *ngIf="showForm">
        <app-add-party-form
          (updateTravelPartyForm)="getFormData($event)"
        ></app-add-party-form>
      </div>

      <!--On adding new Travel party member, confirm its information-->
      <div *ngIf="showConfirmPopup">
        <app-confirm-travel-party-members
          [addTravelPartyForm]="travelPartyForm"
          (backToAddTravelParty)="onTravelPartyList($event)"
          [type]="fromPage"
        ></app-confirm-travel-party-members>
      </div>
      <gds-spacer class="spacer-80"></gds-spacer>
      <!-- <app-ticket-card></app-ticket-card> -->
    </div>
    <gds-spacer class="spacer-80"></gds-spacer>

    <!--Footer-->
    <gds-sticky-nav-footer
      [data]="{
        button: {
          type: 'primary',
          href: '',
          label:  pageLabels?.['linkticketReg.confirmCTA'],
          disabled: false,
          target: '',
          action: pageLabels?.['linkticketReg.confirmCTA']
        },
        secondaryButton: {
          label:  pageLabels?.['linkticketReg.backCTA'],
          type: 'secondary',
          href: '',
          disabled: '',
          target: '',
          action:  pageLabels?.['linkticketReg.backCTA']
        }
      }"
      (buttonClicked)="navigatePage($event)"
    >
    </gds-sticky-nav-footer>
  </div>
</gds-full-screen-modal>
