<section class="top-page-margin">
  <div class="row" *ngIf="contentIntro">
    <div class="columns small-12">
      <account-content-intro [data]="contentIntro"></account-content-intro>
    </div>
  </div>
  <div class="row">
    <div class="columns small-12 large-6">
      <form [formGroup]="forgotPasswordForm">
        <upr-email-field
          *ngIf="
            (forgotPasswordContent?.Component?.Fields?.EmailLabel?.Values)[0]
          "
          (formReady)="addForgotPasswordFormControl('email', $event)"
          [formData]="emailControl"
        ></upr-email-field>

        <button
          class="btn btn-primary btn-expanded-mobile account-profile"
          data-aui="submit"
          *ngIf="
            (forgotPasswordContent?.Component?.Fields?.SubmitLabel?.Values)[0]
          "
          [innerHtml]="
            (forgotPasswordContent?.Component?.Fields?.SubmitLabel?.Values)[0]
          "
          (click)="onSubmit()"
          (keydown.enter)="onSubmit()"
          (keyup.Space)="onSubmit()"
          type="submit"
        ></button>
      </form>
    </div>
    <div class="columns large-6 show-for-large">
      <account-right-rail-article
        *ngIf="rightRailArticle"
        [data]="rightRailArticle"
      ></account-right-rail-article>
    </div>
  </div>
</section>
