import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-signup-complete',
  templateUrl: './signup-complete.component.html',
  styleUrls: ['./signup-complete.component.scss'],
})
export class SignupCompleteComponent implements OnInit {
  pageLabels: any;
  @Output() resetCreateForm = new EventEmitter<any>();
  constructor(
    @Inject('AppRoutes') public appRoutes: any,
    private common: CommonUtilityService,
    private title: Title
  ) {}

  /**
   * ngOnInit - Initial call, pagelabels are retrive from tridion api call
   */
  ngOnInit(): void {
    this.pageLabels = this.pageLabels = this.common.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.createAccountComplete']);
    this.resetCreateForm.emit(true);
    this.common.scrollTop();
  }

  /**
   * redirectToLogin - On click of button signIn redirectToLogin
   */
  redirectToLogin(): void {
    const redirectUrl = this.common.getOidcRedirectionUrl();
    this.common.redirectToOidcApp(redirectUrl);
  }
}
