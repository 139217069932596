import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { IGuestsResponse, MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { WINDOW } from '@upr-web-primer/window';
import { get } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss'],
})

/**
 * Update password component
 */
export class UpdatePasswordComponent implements OnInit, AfterViewInit {
  /**
   * Page labels
   * @type any
   */
  pageLabels: any;

  /**
   * Update password form
   * @type FormGroup
   */
  updatePasswordForm: FormGroup;
  strongPassword = false;
  passWordErrorMsgInfo: any;
  newPassWordErrorMsgInfo: any;
  rePassWordErrorMsgInfo: any;
  user_id: string | undefined;
  @ViewChild('updatePwdModalContent') updatePwdModalContent!: ElementRef;

  /**
   * Constructor
   * @param {ActivatedRoute} route
   * @param {CommonUtilityService} commonUtilityService
   * @param {MsGuestsService} guestsService
   * @param {FormBuilder} fb
   * @param {NotificationService} notification
   * @param {any} appRoutes
   */
  constructor(
    private commonUtilityService: CommonUtilityService,
    private guestsService: MsGuestsService,
    private fb: FormBuilder,
    private notification: NotificationService,
    private cdRef: ChangeDetectorRef,
    private appDataService: AppDataService,
    @Inject('GlobalDefaults') private globalDefault: any,
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('Ranges') private ranges: any,
    @Inject(WINDOW) private window: any,
    private cookieService: CookieService,
    private title: Title
  ) {
    if (this.commonUtilityService?.getResolvedPageData) {
      this.pageLabels = this.commonUtilityService.getResolvedPageData();
      this.title.setTitle(this.pageLabels?.['editPwd.Password']);
    }
  }

  /**
   * NgOnInit - initial call to methods initform and loadUserInfo
   */
  ngOnInit(): void {
    this.notification.hide();
    this.initForm();
    this.loadUserInfo();
  }

  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(this.updatePwdModalContent, 164);
  }

  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(this.updatePwdModalContent, 164);
  }
  /**
   * Load user info - To fetch user details
   * @returns {any}
   */
  loadUserInfo(ignoreUserName?: boolean): Promise<boolean> {
    if (!ignoreUserName && !this.guestsService.helper.isAuth()) {
      return Promise.resolve(false);
    }
    return this.guestsService.profile
      .getByGuestId(this.guestsService.helper.getGuestId())
      .then((userData: IGuestsResponse) => {
        this.user_id = userData?.user_id;
        return true;
      })
      .catch((error: any) => {
        if (error.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
        return false;
      });
  }

  /**
   * OnSubmit - service call on click of update button
   */
  onSubmit(): any {
    const existingPwd = get(this.updatePasswordForm, 'value.passWord');
    const newPwd = get(this.updatePasswordForm, 'value.newPassWord');

    if (
      !localStorage.oAuthLoginUserName ||
      newPwd === localStorage.oAuthLoginUserName
    ) {
      return this.notification.show(this.pageLabels?.ER60);
    }
    this.notification.hide();
    const request = {
      oldPassword: existingPwd,
      newPassword: newPwd,
    };

    this.guestsService.account.changePassword(request, this.user_id).then(
      () => {
        this.ddlEvents('Success');
        this.notification.hide();
        this.goToProfile();
      },
      (err: any) => {
        this.ddlEvents('Failed');
        this.notification.hide();
        if (err === this.globalDefault.ERROR_ER71) {
          this.passWordErrorMsgInfo = this.pageLabels?.['errCode.MS.pwd_400'];
        } else if (err == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        } else {
          this.passWordErrorMsgInfo = this.pageLabels?.['errCode.E019"'];
        }
      }
    );
  }

  /**
   * ddlEvents - On password update these events are getting set
   * @param type - On api response success or failed
   */
  ddlEvents(type: string): void {
    const guestId = this.guestsService.helper.getGuestId();
    this.commonUtilityService.invokeFireEvent(
      'passwordChange',
      'passwordChange',
      'Form',
      'null',
      new Date().toISOString(),
      guestId,
      type,
      this.window?.digitalData?.page?.category?.primaryCategory ?? '',
      this.window?.digitalData?.page?.category?.subCategory1 ?? ''
    );
  }

  /**
   * initForm - update form created
   */
  private initForm(): void {
    this.updatePasswordForm = this.fb.group({
      passWord: new FormControl('', [Validators.required]),
      newPassWord: new FormControl('', [
        Validators.required,
        this.commonUtilityService.passwordValidator,
      ]),
      rePassWord: new FormControl('', [
        Validators.required,
        this.commonUtilityService.passwordValidator,
      ]),
    });
  }

  /**
   * onPasswordStrengthChanged - emiied from password strength component to get the strength of password
   * @param event
   */
  onPasswordStrengthChanged(event: boolean): void {
    this.strongPassword = event;
    this.cdRef.detectChanges();
  }

  /**
   * goToProfile - navigate to profile page
   */

  goToProfile(): void {
    this.commonUtilityService.changeRoute(this.appRoutes.MY_PROFILE);
  }

  /**
   * getErrorMessageInfo - Validation for all the fields
   * @param event
   */

  getErrorMessageInfo(event: any): void {
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.PASSWORD:
        this.passWordErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.NEWPASSWRD:
        this.newPassWordErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.REPASSWORD:
        this.rePassWordErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
    }
    this.cdRef.detectChanges();
  }

  /**
   * getErrorMessage - To get error meassges for all the fields
   * @param field
   * @returns errorMessage
   */

  getErrorMessage(field: any): any {
    switch (field) {
      case this.globalDefault.PASSWORD:
        return this.validatePassword(
          this.updatePasswordForm.value.passWord,
          this.globalDefault.PASSWORD
        );

      case this.globalDefault.NEWPASSWRD:
        return this.validatePassword(
          this.updatePasswordForm.value.newPassWord,
          this.globalDefault.NEWPASSWRD
        );

      case this.globalDefault.REPASSWORD: {
        if (!this.updatePasswordForm.value.rePassWord) {
          return this.pageLabels?.['errCode.E001'];
        } else if (
          this.updatePasswordForm.value.newPassWord !==
          this.updatePasswordForm.value.rePassWord
        ) {
          return this.pageLabels?.['errCode.E012'];
        }
      }
    }
    return '';
  }

  /**
   *  validatePassword - to validate the enter value as password
   * @param value - enetered value
   * @returns
   */

  validatePassword(value: any, result: any): any {
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    const spaceCheck = this.pageLabels?.['errCode.E033'];

    // Check if the value is empty
    if (!value) {
      if (result === this.globalDefault.PASSWORD) {
        return (this.passWordErrorMsgInfo = emptyCheck);
      } else {
        return (this.newPassWordErrorMsgInfo = emptyCheck);
      }
    }

    // Check for password strength if required
    if (!this.strongPassword && result === this.globalDefault.NEWPASSWRD) {
      return (this.newPassWordErrorMsgInfo = this.pageLabels?.['errCode.E005']);
    }
    if (result === this.globalDefault.NEWPASSWRD) {
      // Check for special characters

      // Check for spaces
      if (/\s/.test(value)) {
        return (this.newPassWordErrorMsgInfo = spaceCheck);
      }

      // If all checks pass
      this.newPassWordErrorMsgInfo = '';
    }

    if (result === this.globalDefault.REPASSWORD) {
      const newPassWord = this.updatePasswordForm?.value?.newPassWord || '';
      // Check if passwords match
      if (value === newPassWord) {
        this.rePassWordErrorMsgInfo = '';
      } else {
        this.rePassWordErrorMsgInfo = this.pageLabels?.['errCode.E012'];
      }

      // Check for spaces in re-entered password
      if (/\s/.test(value)) {
        this.rePassWordErrorMsgInfo = spaceCheck;
      }
    }
  }

  /**
   * redirectToLogin - redirect to login
   */
  redirectToLogin(): void {
    const data = {
      response_Type: this.globalDefault.RESPONSE_TYPE,
      scope: this.globalDefault.SCOPE,
      state: this.globalDefault.WEB,
      nonce: (Math.random() + this.ranges.PASSWORDS.INDEX[1])
        .toString(this.ranges.STRING.LENGTH)
        .substring(this.ranges.PASSWORDS.INDEX[7]),
    };

    this.commonUtilityService.redirectToLogin(data);
  }

  /**
   * checkEnterVal -  to verify the newpassword and rePassword fields input
   */
  checkEnterVal(): any {
    const newPassWord = this.updatePasswordForm?.value?.newPassWord || '';
    const rePassWord = this.updatePasswordForm?.value?.rePassWord || '';

    // Check if passwords match
    this.rePassWordErrorMsgInfo =
      newPassWord === rePassWord ? '' : this.pageLabels?.['errCode.E012'];

    // Check for spaces in the new password
    this.newPassWordErrorMsgInfo = /\s/.test(newPassWord)
      ? this.pageLabels?.['errCode.E033']
      : '';

    // Check for spaces in the re-entered password
    if (/\s/.test(rePassWord)) {
      this.rePassWordErrorMsgInfo = this.pageLabels?.['errCode.E033'];
    }
  }

  /**
   * redirectToForget -  To redirect forgot password oidc page
   */
  redirectToForget(): void {
    const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
    this.commonUtilityService.redirectToOidcForget(redirectUrl);
  }
}
