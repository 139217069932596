<div class="container">
  <!--Dashboard back button-->
  <div class="club-universal-btn">
    <gds-utility-action
      [data]="{
            icon: {
            iconName: 'left-arrow-v2'
            },
            label: pageLabels?.['orderDetails.backCTA'],
            type: 'left-arrow-v2',
            disabled: false
            }"
      (click)="goToPage(pageLabels?.['orderDetails.backCTA'])"
    ></gds-utility-action>
  </div>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <!--Order details header-->
  <gds-heading
    [data]="{
      heading: pageLabels?.['orderDetails.heading']
    }"
    class="A3-W6"
  >
  </gds-heading>
  <!--Order details data-->
  <!-- It will show the callout message of the Order in Order Details Page by clicking the Order expect CONFIRMED, WAITING_FOR_PAYMENT and CVS_PAYMENT_REQSUCCESS these status -->
  <div *ngIf="!isConfirmed">
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div
      [ngClass]="
        orderDetailsData?.status === globalDefault.CANCELLED
          ? 'content-call'
          : ''
      "
    >
      <gds-content-callout
        [data]="{
          ariaLabel: 'Character events',
          borderColor: '',
          heading: heading,
          description: description
        }"
      >
      </gds-content-callout>
    </div>
  </div>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <!--Payment method section in order details if the paymentType is convenience store-->
  <div
    *ngIf="
      orderDetailsData?.paymentType?.code === globalDefault.CONVENIENCESTORE &&
      orderDetailsData?.udxPaymentInfo
    "
  >
    <gds-content-section-header
      [data]="{ heading: pageLabels?.['orderDetails.paymentMethod'] , icon: '' }"
    ></gds-content-section-header>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="cvsPayment-content">
      <gds-spacer classes="vertical-spacer-50"></gds-spacer>
      <div class="cvsPayment-cls">
        <gds-summary-row-2
          [data]="{
        label: pageLabels?.['orderDetails.paymentCVS'],
        price: orderDetailsData?.udxPaymentInfo?.cvsStoreName
      }"
        ></gds-summary-row-2>
        <gds-spacer classes="spacer-50"></gds-spacer>

        <ng-container
          *ngIf="
            StoreCodesInfo?.CUSTOM_CONFIRMATION_NUMBERS?.includes(
              orderDetailsData?.udxPaymentInfo?.cvsOption
            )
          "
        >
          <gds-summary-row-2
            [data]="{
          label: pageLabels?.['orderDetails.customerNumber'],
          price: orderDetailsData?.udxPaymentInfo?.customerNumber
        }"
          ></gds-summary-row-2>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-summary-row-2
            [data]="{
          label: pageLabels?.['orderDetails.confNumber'],
          price: orderDetailsData?.udxPaymentInfo?.confirmNumber
        }"
          ></gds-summary-row-2>
          <gds-spacer classes="spacer-50"></gds-spacer>
        </ng-container>
        <ng-container
          *ngIf="
            orderDetailsData?.udxPaymentInfo?.cvsOption === StoreCodesInfo?.SEJ
          "
        >
          <gds-summary-row-2
            [data]="{
          label: pageLabels?.['orderDetails.payment_slipNo'],
          price: orderDetailsData?.udxPaymentInfo?.customerNumber
        }"
          ></gds-summary-row-2>
          <gds-spacer classes="spacer-50"></gds-spacer>
        </ng-container>
        <ng-container
          *ngIf="
            orderDetailsData?.udxPaymentInfo?.cvsOption ===
            StoreCodesInfo?.SEICOMART
          "
        >
          <gds-summary-row-2
            [data]="{
          label: pageLabels?.['orderDetails.online_paymentNo'],
          price: orderDetailsData?.udxPaymentInfo?.confirmNumber
        }"
          ></gds-summary-row-2>
          <gds-spacer classes="spacer-50"></gds-spacer>
        </ng-container>

        <gds-summary-row-2
          [data]="{
        label: pageLabels?.['orderDetails.payDueBy'],
        price: formattedPaymentDueDate + ', '+formattedTime
      }"
        ></gds-summary-row-2>
        <gds-spacer classes="spacer-50"></gds-spacer>
        <gds-summary-row-2
          [data]="{ label: selectedStoreDescription }"
          class="store-description"
        ></gds-summary-row-2>
      </div>
      <gds-spacer classes="vertical-spacer-50"></gds-spacer>
    </div>
    <gds-spacer classes="spacer-70"></gds-spacer>
  </div>
  <!--Payment method section in order details if the paymentType is credit card-->
  <div
    *ngIf="
      orderDetailsData?.paymentType?.code === globalDefault.CARD &&
      orderDetailsData?.paymentInfo
    "
  >
    <gds-content-section-header
      [data]="{ heading: pageLabels?.['orderDetails.paymentMethod'], icon: '' }"
    ></gds-content-section-header>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="paymentSection-content">
      <gds-spacer classes="vertical-spacer-50"></gds-spacer>
      <div class="paymentSec-cls">
        <gds-summary-row-credit
          [data]="{
        label: '',
        price: orderDetailsData?.totalPriceWithTax?.formattedValue,
        featureGroup: {
          heading: pageLabels?.['orderDetails.card']
        }
      }"
        >
        </gds-summary-row-credit>
      </div>
      <gds-spacer classes="vertical-spacer-50"></gds-spacer>
    </div>
    <gds-spacer classes="spacer-70"></gds-spacer>
  </div>

  <!--Order details information text-->
  <gds-content-section-header
    [data]="{
    heading: this.pageLabels?.['orderDetails.orderInfo']
  }"
  ></gds-content-section-header>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <!--Order confirmation number-->
  <div class="orderConfirm-content">
    <gds-spacer classes="vertical-spacer-50"></gds-spacer>
    <div class="orderCfm-cls">
      <gds-heading
        [data]="{
        heading:pageLabels?.['orderDetails.confNumber']
      }"
        class="B1-W4"
      >
      </gds-heading>
      <gds-spacer classes="spacer-20"></gds-spacer>
      <!--Order details code-->
      <gds-heading
        [data]="{
          heading: orderDetailsData?.code
        }"
        class="A5-W5"
      >
      </gds-heading>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <hr class="hr-rule" />
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!--Order details purchase date-->
      <gds-heading
        [data]="{
        heading:pageLabels?.['orderDetails.purchaseDate']
      }"
        class="B1-W4"
      >
      </gds-heading>
      <gds-spacer classes="spacer-20"></gds-spacer>
      <!--Order details date-->
      <gds-heading
        [data]="{
          heading: getDate(orderDetailsData?.created)
        }"
        class="A5-W5"
      >
      </gds-heading>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <hr class="hr-rule" />
      <gds-spacer classes="spacer-50"></gds-spacer>
      <!--Order pickup and delivery mode name-->
      <div *ngIf="!orderCancelledStatus">
        <gds-heading
          [data]="{
        heading:  pageLabels?.['orderDetails.pickUpMethod']
      }"
          class="B1-W4"
        >
        </gds-heading>
        <gds-spacer classes="spacer-20"></gds-spacer>
        <gds-heading
          [data]="{
            heading: orderDetailsData?.deliveryMode?.name
          }"
          class="A5-W5"
        >
        </gds-heading>

        <gds-spacer classes="spacer-50"></gds-spacer>
        <hr class="hr-rule" />
      </div>
      <!--Order sub total/shipping/coupon cost-->
      <div>
        <div *ngIf="!orderCancelledStatus">
          <gds-spacer classes="spacer-50"></gds-spacer>
          <gds-summary-row-2
            [data]="{
      label: this.pageLabels?.['orderDetails.subTotal'],
      price: orderDetailsData?.subTotal?.formattedValue + ' ' + pageLabels?.['orderDetails.taxIncluded']
    }"
            class="orderFieldsCls"
          ></gds-summary-row-2>
          <gds-spacer
            classes="spacer-50"
            *ngIf="
              orderDetailsData?.deliveryCost?.formattedValue &&
              orderShipping > 0
            "
          ></gds-spacer>
          <gds-summary-row-2
            *ngIf="
              orderDetailsData?.deliveryCost?.formattedValue &&
              orderShipping > 0
            "
            [data]="{
      label: this.pageLabels?.['orderDetails.shipping'],
      price: orderDetailsData?.deliveryCost?.formattedValue
    }"
            class="orderFieldsCls"
          ></gds-summary-row-2>
          <gds-spacer
            classes="spacer-50"
            *ngIf="
              orderDetailsData?.totalDiscounts?.formattedValue && couponCost > 0
            "
          ></gds-spacer>
          <gds-summary-row-2
            *ngIf="
              orderDetailsData?.totalDiscounts?.formattedValue && couponCost > 0
            "
            [data]="{
      label: this.pageLabels?.['orderDetails.coupon'],
      price: '-' + ' ' + orderDetailsData?.totalDiscounts?.formattedValue
    }"
            class="orderFieldsCls"
          ></gds-summary-row-2>
        </div>
        <gds-spacer
          *ngIf="!orderCancelledStatus"
          classes="spacer-50"
        ></gds-spacer>
        <!--Order total amount-->
        <gds-heading
          [data]="{
      heading:pageLabels?.['orderDetails.orderTotal']
    }"
          class="B1-W4"
        >
        </gds-heading>
        <gds-spacer classes="spacer-20"></gds-spacer>
        <gds-heading
          [data]="{
    heading: orderCancelledStatus
      ? '-'
      : orderDetailsData?.totalPriceWithTax?.formattedValue + ' ' + pageLabels?.['orderDetails.taxIncluded']
  }"
          class="A5-W5"
        >
        </gds-heading>
      </div>
    </div>
    <gds-spacer classes="vertical-spacer-50"></gds-spacer>
  </div>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <!--Order details section-->
  <gds-content-section-header
    [data]="{
  heading: this.pageLabels?.['orderDetails.details']
}"
  ></gds-content-section-header>
  <div class="orderDetails-content">
    <gds-spacer classes="vertical-spacer-50"></gds-spacer>
    <div class="orderDetails-cls">
      <gds-spacer classes="spacer-70"></gds-spacer>
      <!--Travel tickets section-->
      <div [class.grayed-out]="orderCancelledStatus">
        <div *ngFor="let item of groupByNameTickets">
          <gds-heading
            [data]="{
              heading: item?.fullName
            }"
            class="A3-W6"
            classes="heading-3"
          >
          </gds-heading>
          <gds-spacer classes="spacer-50"></gds-spacer>
          <div *ngFor="let entry of item.entries">
            <div
              class="contentTab"
              tabindex="0"
              (keydown.enter)="getTickets(entry, item?.fullName)"
              (keyup.Space)="getTickets(entry, item?.fullName)"
            >
              <a
                [attr.href]="
                  entry?.subEntries[0]?.ticketId
                    ? orderProfileUrl(entry?.subEntries[0].ticketId)
                    : null
                "
                class="ticketctalink image-section contentTab ticket-details"
                [disabled]="!entry?.subEntries[0]?.ticketId ? 'true' : null"
              >
                <div class="badge-icon">
                  <gds-svg
                    [iconName]="entry?.icon"
                    height="24"
                    width="24"
                    view-box="0 0 32 32"
                    fill-color="#222"
                    class="adventure"
                  ></gds-svg>
                </div>
                <div class="ticket-data">
                  <gds-spacer classes="vertical-spacer-50"></gds-spacer>
                  <div class="content">
                    <gds-heading
                      [data]="{
                        heading: entry?.product?.name
                      }"
                      class="A6-W4"
                    ></gds-heading>
                    <gds-body
                      [data]="{
              description: this.pageLabels?.['linkTicketConf.ticketDet_entry'] + ' ' +
              getDate(entry?.ticketEntryInfo?.visitationDate,true)
            }"
                      class="B2-W3"
                    ></gds-body>
                  </div>
                </div>
                <!--Tickets arrow redirect to Tickets-details page-->
                <div class="arrow-icon" *ngIf="entry?.subEntries[0].ticketId">
                  <gds-spacer classes="vertical-spacer-50"></gds-spacer>
                  <div class="icon-names">
                    <i
                      _ngcontent-wgy-c178=""
                      aria-hidden=""
                      class="icon icon-arrow-right"
                    ></i>
                  </div>
                </div>
              </a>
            </div>
            <gds-spacer classes="spacer-50"></gds-spacer>
            <hr class="hr-rule" />
            <gds-spacer classes="spacer-50"></gds-spacer>
          </div>
        </div>
      </div>
    </div>
    <gds-spacer classes="vertical-spacer-50"></gds-spacer>
  </div>
</div>
