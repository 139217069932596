import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { WINDOW } from '@upr-web-primer/window';
import { get } from 'lodash-es';
import { CookieService } from 'ngx-cookie-service';
import { PhonePattern } from '../../../config/pattern.constant';
import { environment } from '../../../environments/environment';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';
import { ToastService } from '../../shared/service/toast.service';

@Component({
  selector: 'app-update-phone-number',
  templateUrl: './update-phone-number.component.html',
  styleUrls: ['./update-phone-number.component.scss'],
})
export class UpdatePhoneNumberComponent implements OnInit, AfterViewInit {
  userPhoneNumberData: any;
  phoneNumberPrimary = false;
  phoneNumber: any;
  phoneNumberForm: FormGroup;
  formValues: any;
  totalPhoneNumberLists: any;
  inputChanged = false;
  /**
   * Page labels
   * @type any
   */
  pageLabels: any;
  phoneNumberChecked = false;
  savedSourceId: any;
  @ViewChild('updatePhoneContentModal') updatePhoneContentModal!: ElementRef;
  phoneNumberType: any;
  phoneNoErrorMsgInfo: string;
  phoneNumberRegex = PhonePattern.PHONE_SIGNUP;
  /**
   * Constructor
   * @param {ActivatedRoute} route
   * @param {CommonUtilityService} commonUtilityService
   * @param {MsGuestsService} guests
   * @param {FormBuilder} fb
   * @param {FormControlService} formControlService
   * @param {NotificationService} notification
   * @param {any} appRoutes
   */
  constructor(
    private commonUtilityService: CommonUtilityService,
    @Inject('AppRoutes') private appRoutes: any,
    private formBuilder: FormBuilder,
    private guestsService: MsGuestsService,
    protected injector: Injector,
    private toastService: ToastService,
    private title: Title,
    private appDataService: AppDataService,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('Ranges') public ranges: any,
    @Inject(WINDOW) private window: any,
    @Inject('sourceKeys') public sourceKeys: any,
    private cookieService: CookieService,
    private cdRef: ChangeDetectorRef
  ) {
    if (this.commonUtilityService?.getResolvedPageData) {
      this.pageLabels = this.commonUtilityService.getResolvedPageData();
    }
    this.title.setTitle(this.pageLabels?.['page.phoneEdit']);
    this.setDependencies();
  }
  /**
   * ngAfterViewInit - initial methods are called
   */
  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(
      this.updatePhoneContentModal,
      164
    );
  }
  /**
   * onResize - Resize the fullscreen modal popup
   */
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(
      this.updatePhoneContentModal,
      164
    );
  }
  /**
   * ngOnInit - initial methods are called
   */

  ngOnInit(): void {
    this.userPhoneNumberData = this.commonUtilityService.getFormData();
    this.phoneNumberPrimary = this.userPhoneNumberData?.primary;
    this.phoneNumber = this.userPhoneNumberData?.phone_number;
    this.totalPhoneNumberLists = this.userPhoneNumberData?.phoneNumberLists;
    const matchingObject = this.totalPhoneNumberLists?.find(
      (item: any) => item?.phone_number === this.phoneNumber
    );
    if (matchingObject) {
      this.phoneNumberType = matchingObject?.phone_type;
    }
    this.createForm();
    this.setFormValues();
    this.enableButton();
  }

  /**
   * backToPhoneNumberPage - redirected to phone number page
   */

  backToPhoneNumberPage(): void {
    this.commonUtilityService.changeRoute(this.appRoutes.PHONE_NUMBERS_PAGE);
  }

  /**
   * createForm - it will create the form with input fields
   */

  createForm(): any {
    this.phoneNumberForm = this.formBuilder.group({
      phoneNumber: new FormControl('', [
        <any>Validators.required,
        Validators.minLength(10),
        Validators.maxLength(11),
        Validators.pattern(this.phoneNumberRegex),
      ]),
      phoneType: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
      ]),
      primary: new FormControl(),
    });
  }

  /**
   * enableButton - It will enable the save button if valid phone number
   */

  enableButton(): any {
    this.phoneNumberForm.valueChanges.subscribe((currentValue) => {
      this.inputChanged =
        currentValue?.phoneNumber === this.phoneNumber ||
        currentValue?.phoneNumber === '' ||
        (currentValue?.phoneNumber?.length || 0) < this.ranges.MAX.USA_PHONE ||
        (currentValue?.phoneNumber?.length || 0) > 11
          ? false
          : true;
    });
  }
  /**
   * getErrorMessageInfo - Validations of each field
   * @param event - event
   */
  getErrorMessageInfo(event: any): void {
    debugger;
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.PHONENUMBER:
        this.phoneNoErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
    }
    this.cdRef.detectChanges();
  }
  /**
   * getErrorMessage - Validations for each field
   * @param field
   * @returns
   */
  getErrorMessage(field: any): string {
    const fieldName = this.phoneNumberForm?.get(field);
    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      return this.getErrorInfo(field);
    }
    this.cdRef.detectChanges();
    return '';
  }
  /**
   * getErrorInfo()
   * @param field Input field
   * @returns Error message
   */
  getErrorInfo(field: any): any {
    const emptyCheck = this.pageLabels?.['errCode.E001'];
    switch (field) {
      case this.globalDefault.PHONENUMBER: {
        if (!this.phoneNumberForm?.value?.phoneNumber) return emptyCheck;
        else {
          this.phoneNoErrorMsgInfo = this.pageLabels?.['errCode.E007'];
          return this.phoneNoErrorMsgInfo;
        }
      }
    }
  }
  /**
   * onKeyPressEvent - on key press event occur to check the input type
   * @param event - contains the characters entered in input field
   */
  onKeyPressEvent(event: KeyboardEvent): void {
    const isNumeric = /[0-9]/.test(event.key);
    const isControlKey = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
    ].includes(event.key);

    // Allow numeric input or control keys, prevent other characters
    if (!isNumeric && !isControlKey) {
      event.preventDefault();
    }
  }

  /**
   * editPrimaryNumber - primary phone check box changes
   * @param event : specify the input field event
   */

  editPrimaryNumber(event: any): any {
    this.phoneNumberChecked =
      event?.detail === this.globalDefault.CHECKED ? true : false;
  }

  /**
   * setFormValues - set and patch the form values
   */

  setFormValues(): void {
    this.phoneNumberForm.patchValue({
      phoneNumber: this.phoneNumber,
      phoneType: this.phoneNumberType,
      primary: this.phoneNumberPrimary,
    });
  }

  /**
   * deletePhoneNumber - delete the phone number
   */

  deletePhoneNumber(): any {
    this.toastService.show({
      text: this.pageLabels?.['editPhone.deleteSnack'],
      type: this.globalDefault.SUCCESS,
    });
    const phone_number = get(this.phoneNumberForm, 'value.phoneNumber');
    this.savedSourceId = this.commonUtilityService?.getSourceId(
      this.sourceKeys?.WEB_EDITCONTACT
    );
    this.guestsService.personal
      ?.deletePhone(this.savedSourceId, phone_number)
      ?.then(() => {
        this.commonUtilityService.notifyFormSuccessEvent(
          this.pageLabels?.['editPhone.deleteSnack'],
          this.globalDefault.OK
        );
        this.commonUtilityService.changeRoute(
          this.appRoutes.PHONE_NUMBERS_PAGE
        );
      })
      .catch((error) => {
        console.error(error);
        if (error.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl =
            this.commonUtilityService?.getOidcRedirectionUrl();
          this.commonUtilityService?.redirectToOidcAppEndSession(redirectUrl);
        } else {
          this.commonUtilityService?.notifyFormErrorEvent(
            this.pageLabels?.['editPhone.deleteSnack'],
            this.pageLabels?.['errCode.E004']
          );
        }
      });
    this.commonUtilityService.changeRoute(this.appRoutes.PHONE_NUMBERS_PAGE);
  }

  /**
   * onSubmit - submit the phone number
   */

  onSubmit(): any {
    const phone_number = get(this.phoneNumberForm, 'value.phoneNumber');
    const primary =
      this.phoneNumberPrimary === false
        ? this.phoneNumberChecked
        : this.phoneNumberPrimary;
    const phoneType = get(this.phoneNumberForm, 'value.phoneType');
    const request = {
      phone_number: phone_number,
      phone_type: phoneType,
      primary: primary,
    };
    this.savedSourceId = this.commonUtilityService?.getSourceId(
      this.sourceKeys?.WEB_EDITCONTACT
    );
    this.guestsService.personal
      ?.updatePhone(request, this.savedSourceId, this.phoneNumber)
      ?.then(() => {
        this.toastService.show({
          text: this.pageLabels?.['editPhone.updateSnack'],
          type: this.globalDefault.SUCCESS,
        });
        this.ddlEvents();
        this.commonUtilityService.changeRoute(
          this.appRoutes.PHONE_NUMBERS_PAGE
        );
      })
      .catch((error) => {
        console.error(error);
        if (error.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        } else {
          this.commonUtilityService.notifyFormErrorEvent(
            this.pageLabels?.['editPhone.updateSnack'],
            this.pageLabels?.['errCode.E004']
          );
          this.ddlEvents();
          this.toastService.show({
            text: this.pageLabels?.['errCode.E007'],
            type: this.pageLabels?.['trpAdd.warning'],
          });
        }
      });
  }

  /**
   * ddlEvents - On phone number update these events are getting set
   * @param type - On api response success or failed
   */
  ddlEvents(): void {
    this.commonUtilityService.invokeFireEvent(
      this.pageLabels?.['addAddress.save'],
      'Button',
      'Click',
      '',
      new Date().toISOString(),
      'Body | CTA:' + this.pageLabels?.['addAddress.save'],
      '',
      this.window?.digitalData?.page?.category?.primaryCategory ?? '',
      this.window?.digitalData?.page?.category?.subCategory1 ?? ''
    );
    // this.commonUtilityService?.setUser();
  }

  /**
   * setDependencies - It will set the injector of dependencies services
   */
  private setDependencies(): void {
    this.commonUtilityService = this.injector.get(CommonUtilityService);
    this.guestsService = this.injector.get(MsGuestsService);
  }
}
