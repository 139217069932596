export const relativeUrls = {
  CREATE_USER: '/guest/GuestProfiles/createRegistered',
  CREATE_UNREGISTERES_USER: '/guest/GuestProfiles/createUnregistered',
  MODIFY_UNREGISTERES_USER: '/guest/GuestProfiles/updateUnregistered/{guestId}',
  CREATE_LINKED_ACCOUNT: '/guest/GuestProfiles/createLinkedAccount',
  UNREGISTERED_USER: '/guest/GuestProfiles/unregistered',
  GUEST_IDENTITY: '/guest/GuestProfiles/commerce/authN',
  CONTRACT: '/shop/wcs/resources/store/{storeId}/contract?q=eligible',
  USER_BY_GUEST_ID: 'security/getUserDetailsByGuestId?guestId=',
  MODIFY_USER: 'security/modifyUser',
  USER_BY_USER_ID: '/guest/GuestProfiles?email=',
  USER_LOGIN: '/pkmslogin.form',
  WEB_SEAL_USER_LOGOUT: '/pkmslogout',
  USER_LOGOUT: '/pkmslogout',
  DEFAULT_PREFERENCE: '/guest/GuestProfiles/defaultPreferences',
  POSSIBLE_PREFERENCE: '/guest/GuestProfiles/list/guestPreferences',
  VALIDATE_PWD: 'security/validateUserPassword',
  MODIFY_USER_CONTACT: '/guest/GuestProfiles/{guestId}/contact',
  MODIFY_USER_ADDRESS: '/guest/GuestProfiles/{guestId}/addresses',
  MODIFY_USER_PREFERENCE: '/guest/GuestProfiles/{guestId}/preferences',
  MODIFY_USER_PREFERENCE_BY_EMAIL:
    '/guest/GuestProfiles/preferences/updateByEmail',
  MODIFY_USER_GUEST_PROFILE: '/guest/GuestProfiles/{guestId}',
  MODIFY_USER_PWD: '/cred/auth/changePassword/{loginId}',
  GET_TCM_CONTENT_BY_IDS: '/cp/content/pages?ids=',
  RETRIEVE_ASSIGNED_NAMES: '/guest/TravelParty/retrieveAssignedNames?guestId=',
  CREATE_ASSIGNEE: '/guest/TravelParty/createAssignee',
  GUEST_ACCOUNTAVAILABLE: '/guest/GuestProfiles/accountAvailable?email=',

  // general module
  PREFIX: '/prefixes',
  COUNTRY: '/countries',
  STATE: '/states',
  USER_LOCATION: '/utils/utilities/locations/checkip?detail=location',
  VALIDATE_EMAIL: '/utils/utilities/validateEmail',

  // shopping module
  CART_DETAILS:
    '/shop/wcs/resources/store/{storeId}/cart/byGroupMethod/ice_v2?contractId=',
  CART_DETAILS_NO_CALC:
    '/shop/wcs/resources/store/{storeId}/cart/byGroupMethod/ice_v2?doCalculate=N&contractId=',
  ADD_TO_CART: '/shop/wcs/resources/store/{storeId}/cart',
  CATEGORY_VIEW: '/shop/wcs/resources/store/{storeId}/categoryview/@top',
  CATEGORY_BY_PARENT_ID:
    '/shop/wcs/resources/store/{storeId}/categoryview/byParentCategory/',
  UPDATE_TICKET:
    '/shop/wcs/resources/store/{storeId}/cart/@self/update_order_item',
  DELETE_TICKET:
    '/shop/wcs/resources/store/{storeId}/cart/@self/delete_order_item',
  PRODUCT_PRICE_BY_PART_NUMBER:
    '/shop/wcs/resources/store/{storeId}/price?q=byPartNumbers&partNumber=',
  SEASONAL_CALENDAR_DATES: '/utils/utilities/entitlements/calendar/',
  DELIVERY_METHODS:
    '/shop/wcs/resources/store/{storeId}/cart/eligible_ship_modes',

  // wallet module
  WALLET: '/inv/Orders/wallet',
  QR_CODE_GEN_PROD:
    'https://www.universalorlando.com/webdata/CodeGenerator/QRCodeGen/?factor=8&textToEncode=',
  FETCH_TRIDION_ID_EQV_NAME: 'cp/content/pages?ids=',

  // folio module
  GET_FOLIO: '/folio/Folios/{guestId}',
  GET_FOLIO_CARDS: '/folio/Folios/{guestId}/cards',
  UPSERT_FOLIO_CARD: '/folio/Folios/{guestId}/card',
  DELETE_FOLIO_CARD: '/folio/Folios/{guestId}/card',
  DELETE_FOLIO_CARD_OAUTH: '/folio/Folios/{guestId}/card/{cardId}',
  SET_PRIMARY_FOLIO_CARD: '/folio/Folios/card',
  CHANGE_ALERT_PREFERENCES: '/folio/Folios/{guestId}/alert/preferences',
  SET_FOLIO_PIN: '/folio/Folios/{guestId}/pin',
  SET_SPENDING_LIMIT: '/folio/Folios/{guestId}/limit',
  GET_TRANSACTION_HISTORY: '/folio/Folios/{guestId}/transactions',
  ADD_FOLIO_CARD: '/pay/payment/wallet',

  // E-Spot servicess
  TICKET_LIST_BY_DAY: '/shop/wcs/resources/store/{storeId}/card/',

  COMMERCE_CARD: '/wcs/card/',

  // payment
  PAYMENT_SUBMIT: '/pay/payment/card',
  SHIPPING_ADDRESS_SAVE:
    '/shop/wcs/resources/store/{storeId}/person/@self/contact',
  SET_CART_SHIPPING_INFO:
    '/shop/wcs/resources/store/{storeId}/cart/@self/shipping_info',
  ONLINE_METRIX_CHECK:
    '{protocol}//h.online-metrix.net/fp/check.js?org_id={orgId}&session_id={sessionId}',

  // call MIC
  PRODUCT_BY_CATEGORY_MIC: '/cp/personalization/getextras',
  GET_TICKETS_MIC: '/cp/personalization/gettickets',
  GET_CART_MIC: '/cp/personalization/getcart?groupId=ice_v2&contractId=',
  GET_OFFER: '/cp/personalization/getoffers',
  TOP_CAT_MIC: '/cp/catalog/category',
  SUB_CAT_MIC: '/cp/catalog/subcategory/',

  OFFERS_ACCEPTED: '/cp/personalization/offersaccepted',
  // INVENTORY
  PRICING_INVENTORY:
    '/shop/wcs/resources/store/{storeId}/event/priceAndInventory/v2',
  VALIDATEFLRESIDENT: '/utils/utilities/validateFLresident?zipCode={zip}',
  PROMOTION_CODE:
    '/shop/wcs/resources/store/{storeId}/cart/@self/assigned_promotion_code',
  CARDINAL_TOKEN: '/pay/cardinal/token',
  GET_FLEXPAY_CONTRACT: '/flexpay/Flexpay/retrieveContract',

  // AmEx Promo
  AMEX_PROMO: '/api/amex/promo',

  // AmEx Promo
  METRIC_LOGGER: '/api/metric/logger',

  // Entitlement api
  ENTITLEMENTS_LINK_METHODS: 'et/Entitlements/link/methods',
  ENTITLEMENTS_SALES_PROGRAMS: 'et/SalesPrograms',
  ENTITLEMENTS_LINK: 'et/Entitlements/link',
  ENTITLEMENTS_LINK_SEARCH: 'et/Entitlements/search',
  ENTITLEMENTS_LINK_SEARCH_LINK: 'et/Entitlements/search/link',
  RESET_PASSWORD: '/cred/auth/resetPassword',
  FORGOT_PASSWORD: '/cred/auth/forgotPassword',

  CART_PAGE_LINK: '/shopping-cart',

  ACCOUNT_LOGIN: '/account-management/login/index.html',
  ACCOUNT_CREATE: '/account-management/create-account/index.html',
  ACCOUNT_FORGOT_PASSWORD: '/account-management/forgot-my-password/index.html',
  ACCOUNT_EMAIL_SUBSCRIBE: '/account-management/email-sign-up/index.html',
  ACCOUNT_EMAIL_UNSUBSCRIBE: '/account-management/email-unsubscribe/index.html',

  // Delete Account
  DELETE_ACCOUNT: '/account-management/delete-account/index.html',
  DELETE_ACCOUNT_CONFIRMATION:
    '/account-management/delete-account-success/index.html',

  SELECT_VACATION_PACKAGE_REQUEST: '/vacation-package-requests/{code}/select',

  VIEW_GUEST_ORDERS: '/guests/{guest-id}/orders',
  VIEW_PACKAGE_DETAILS: '/guests/{guest-id}/orders/{order-id}',
  RETRIEVE_PURCHASES: '/guests/{guest-id}/assign-to-guest-orders',
  VIEW_TRIPS: '/guests/{guest-id}/saved-vacation-searches',
  DELETE_TRIP:
    '/guests/{guest-id}/saved-vacation-searches/{vacation-package-saved-search-id}',
  VIEW_TRIP_PRICE:
    '/guests/{guest-id}/saved-vacation-searches/{vacation-package-saved-search-id}/price',
  TRIP_DETAILS: '/guests/{guest-id}/trips/{trip-id}',
  RETRIEVE_OPERATION_IDS:
    '/resort-areas/{code}/content?ids=entitlement-operation-ids',
  RETRIEVE_ENTITLEMENT_TYPES:
    '/resort-areas/{code}/content?ids=entitlement-types',
  VIEW_GUEST_HOTEL_STAYS: '/guests/{guest-id}/hotel-stays',
  RETERIVE_RESERVATIONS: '/guests/{guest-id}/link-hotel-stay',
  GET_TRAVEL_PARTY: '/guests/{guestId}/travel-party-members',
  UPDATE_USER: '/guests/{guestId}',
  GET_INSTANCES: '/instances',
  GET_ENTITLEMENT:
    '/guest/{guestId}/travel-party-entitlements-and-media?remove_expired=false',
  GET_QRCODE: '/codegen',
  GET_SHAREQR: '/guest/entitlements/{guestId}/{mediaId}',
  POSTAL_CODE: '/postal-codes/',
  CUSTOMER_TOKEN: 'authorizationserver/oauth/token',
  COMMERCE_API: 'occ/v2/b2cportal',
  CUSTOMER_LOGIN: '/customers/login',
  COUPON_URL: '/coupons/guests/',
  LAYOUTS: '/usj/ja/jp/views/layout/',
  EMAIL_VALIDATE: '/resorts/{resort-area-code}/validate-email',
  REDEEM_COUPON: '/coupons/coupon/redeem/{guestId}/{couponCode}',
  WTS_CART: 'cart',
  GET_ORDER_HISTORY:
    '/users/{userId}/orders?isRegularOrder=true&currentPage={pageNumber}&fields=DEFAULT',
  GET_ORDER_DETAILS: '/users/{userId}/orders/{code}',
  GET_LINK_TICKETS:
    '/resort-areas/USJ/entitlements/{entitlementId}/ticket-name',
  UPDATE_TRAVELPARTY: '/guests/{guest_id}/travel-party-members/{member_id}',
  LINK_ENTITLEMENT: '/guest/{guest-id}/link-entitlements',
  GET_CONTENT: '/api/getcontent?contentIds={content-Id}&extendcl=true',
  GET_ACCOUNT: 'resort-areas/usj/content-labels/accounts',
  GET_CARTS: '/users/current/carts/current',
  GET_APPLY: '/users/current/carts/{cartId}/vouchers?voucherId={voucherId}',
  GET_CONTENTIDTOKEN: '/connect/token',
  POST_INQUIRY_URL: '/api/data/v9.1/udx_generalinquiries',
  POST_LOST_FOUND_URL: '/api/data/v9.1/udx_lostandfounds',
  GET_CART_ITEM: '/users/current/carts',
  CART_ROUTE: '/cart',
};
