import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { LocaleService } from '@upr-web-primer/locale';
import { MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { LocalStorageService } from '@upr-web-primer/storage';
import { WINDOW } from '@upr-web-primer/window';
import _, { get } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CommonUtilityService } from '../../../services/common-utility.service';
import mockData from '../../../shared/constants/mockData.json';
@Component({
  selector: 'app-coupon-card',
  templateUrl: './coupon-card.component.html',
  styleUrls: ['./coupon-card.component.scss'],
})
export class CouponCardComponent implements OnInit, OnDestroy {
  pageLabels: any;
  progressBarCategoryInfo: any;
  @Input() couponData: any;
  @Input() pageLabelsData: any;
  @Input() productCardCount: any;
  @Input() fromDashboard: any;
  todayDate: string;
  couponStartDate: string;
  cartItems: any;
  setValue: boolean;
  mockData = mockData;
  isOpenPopup = false;
  commerceErrMsg: any;
  wtsCartURL: string;
  errorMessage: any;
  activecartProductCode: any;
  displayProductCard = false;
  displayProductList: any = [];
  isAuthenticatedSubscription: Subscription;
  cartItemLength: any;
  getCartsData: any;
  getCurrentCartData: any;
  isCart: Subscription;
  constructor(
    @Inject('GlobalDefaults') public globalDefault: any,
    public guestsService: MsGuestsService,
    public common: CommonUtilityService,
    private localStorage: LocalStorageService,
    @Inject('RelativeUrls') private relativeUrls: any,
    // private toastService: ToastService,
    private http: HttpClient,
    private cookieService: CookieService,
    private locale: LocaleService,
    @Inject(WINDOW) private window: any,
    private notification: NotificationService,
    private cdr: ChangeDetectorRef
  ) {}

  /**
   * ngOnInit - To get pageLabels from routes
   */
  ngOnInit(): void {
    this.pageLabels = this.pageLabelsData;
    if (!this.pageLabelsData)
      this.pageLabels = this.common.getResolvedPageData();
    const authToken = this.localStorage.getItem('loginAuthToken');
    this.isAuthenticatedSubscription = this.common
      .getIsAuthenticated()
      .subscribe((isAuthenticated) => {
        this.isCart = this.common.getIsCart().subscribe((isCart) => {
          if (isAuthenticated || (authToken && isCart)) {
            this.getActiveCartItems();
          }
        });
      });
  }
  /**
   * ngOnDestroy - Unsubscribe the methods
   */
  ngOnDestroy(): void {
    if (this.isAuthenticatedSubscription) {
      this.isAuthenticatedSubscription.unsubscribe();
    }
  }
  /**
   * getActiveCartItems - get the current cart items from the user
   */
  getActiveCartItems(): any {
    this.getCartsData = this.common.getGetCartsData();
    this.cartItemLength = this.getCartsData?.carts?.length;
    if (this.cartItemLength === 1 && this.getCartsData?.carts?.[0]?.saveTime)
      return;
    if (this.cartItemLength > 0) {
      this.cartItems = this.common.getCurrentCartsData();
      this.activecartProductCode = this.cartItems.entries[0]?.baseProduct?.code;
    }
    this.getGuestAffiliatedProductInfo();
  }
  /**
   * getGuestAffiliatedProductInfo - It will fetch Guest Affiliated Products from commerce API
   */

  getGuestAffiliatedProductInfo(): void {
    const guestId = this.localStorage.getItem('LOGGEDIN_GUEST_ID');
    let displayProductCount: any;
    this.common.getGuestAffiliatedProducts(guestId).subscribe(
      (data: any) => {
        if (this.productCardCount) {
          displayProductCount = this.productCardCount;
        }
        let productList = [];
        if (data?.products?.length > displayProductCount) {
          productList = data?.products.splice(0, displayProductCount);
        } else {
          productList = data.products;
        }
        const productListCount = productList?.length;
        _.each(productList, (productInfo: any, productIndex: number) => {
          this.addProductToList(productInfo);
          if (productListCount === productIndex + 1) {
            this.displayProductCard = true;
          }
        });
      },
      (errors: any) => {
        if (errors.status == this.globalDefault.ERROR_401) {
          this.common.invokeFireEvent(
            'accountLogout',
            this.pageLabels?.['accountInfo.logout'],
            'Form',
            'Success',
            new Date().toISOString(),
            localStorage.getItem('OAUTH_LOGIN_GID') ?? '',
            '',
            this.window?.digitalData?.page?.category?.primaryCategory,
            this.window?.digitalData?.page?.category?.subCategory1
          );
          this.common.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.common.getOidcRedirectionUrl();
          this.common.redirectToOidcAppEndSession(redirectUrl);
        } else {
          // const errCode = 'errCode.' + errors.error?.errorCode;
          // const errorCode = errors.error?.errorCode
          //   ? this.pageLabels?.[errCode]
          //     ? this.pageLabels?.[errCode]
          //     : this.pageLabels?.['errCode.E019']
          //   : errors.error?.errors[0]?.message;

          // this.notification?.show(errorCode);
          const errorMessage = this.common.removeHyphensFromCommerceAPI(
            errors?.errors?.[0]?.errorCode,
            this.pageLabels,
            this.globalDefault
          );
          this.notification?.show(errorMessage);
        }
      }
    );
  }
  /**
   * addProductToList - Extract properties from tridion content property and build GDS product card object
   * @param productInfo : specify which route to navigate
   * @param purchasable : Indicates product purchasable or not
   */

  addProductToList(productInfo: any): void {
    if (productInfo?.tridionContent) {
      const tridionData = JSON.parse(productInfo?.tridionContent);
      const productCardData: any = tridionData[0]?.data;
      const purchasable = productInfo?.purchasable;
      const featureGroupListProperties = _.map(
        productCardData.feature1Group,
        (item) => {
          const cta = item.cta[0];
          let buttonLinkInfo;
          if (item?.cta[0]?.link) {
            buttonLinkInfo = item?.cta[0]?.link[0];
          }

          return {
            icon: item.icon,
            heading: item.shortDescription
              ? `${item.heading} ${item.shortDescription}`
              : item.heading,
            description: item.description,
            button: {
              label: cta.label,
              type: cta.type,
              href: buttonLinkInfo ? buttonLinkInfo.External : '',
              analytics: cta.type,
              ariaLabel: cta.type,
              target: cta.target,
            },
          };
        }
      );

      const buttonList = _.map(productCardData.cta, (buttonInfo) => {
        const { analytics, ariaLabel, label, target, title, type } = buttonInfo;
        let gdsButtonData: any = {
          type,
          analytics,
          ariaLabel,
          title,
          productData: productInfo,
          actionType:
            type === this.globalDefault.BTN_SECONDARY
              ? this.globalDefault.PDP_PAGE
              : this.globalDefault.PCP_OVERLAY,
        };
        if (type === this.globalDefault.BTN_PRIMARY) {
          gdsButtonData = {
            ...gdsButtonData,

            label: purchasable
              ? label
              : this.pageLabels?.['productCard.notAvailable'],
            disabled: purchasable
              ? this.globalDefault.FLAGS[1]
              : this.globalDefault.FLAGS[0],
          };
        } else {
          gdsButtonData = {
            ...gdsButtonData,
            label,
            disabled: this.globalDefault.FLAGS[1],
          };
        }
        if (target) {
          gdsButtonData = {
            ...gdsButtonData,
            target: target,
          };
        }
        return gdsButtonData;
      });

      const productCard = {
        awning: get(
          productCardData?.categoryLabelGroup?.[0],
          'categoryLabel',
          ''
        ),
        image: {
          alt: get(productCardData?.image[0], 'alt', ''),
          desktop: get(productCardData?.image[0], 'desktop', ''),
          tablet:
            get(productCardData?.image[0], 'tablet', '') ??
            get(productCardData?.image[0], 'desktop', ''),
          mobile:
            get(productCardData?.image[0], 'mobile', '') ??
            get(productCardData?.image[0], 'tablet', '') ??
            get(productCardData?.image[0], 'desktop', ''),
        },
        eyebrow: get(productCardData, 'eyebrow', ''),
        heading: get(productCardData, 'name', ''),
        openingDescription: get(productCardData, 'description', ''),
        description: get(productInfo[0]?.data, 'legalDescription', ''),
        featureList: featureGroupListProperties,
        price: {},
        buttons: buttonList,
      };
      this.displayProductList.push(productCard);
    } else {
      const productCard = {
        heading: get(productInfo, 'name', ''),
        openingDescription: get(productInfo, 'description', ''),
        featureList: [{}],
        buttons: [
          {
            type: 'secondary',
            label: this.pageLabels['coupons.seeDetailsCTA'],
            analytics: 'secondary',
            ariaLabel: this.pageLabels['coupons.seeDetailsCTA'],
            productData: productInfo,
            actionType: 'pdpPage',
          },
          {
            type: 'primary',
            label: productInfo?.purchasable
              ? this.pageLabels['coupons.selectCTA']
              : this.pageLabels?.['productCard.notAvailable'],
            disabled: productInfo?.purchasable
              ? this.globalDefault.FLAGS[1]
              : this.globalDefault.FLAGS[0],
            analytics: 'primary',
            ariaLabel: this.pageLabels?.['coupons.selectCTA'],
            actionType: 'pcpOverlay',
            productData: productInfo,
          },
        ],
      };
      this.displayProductList.push(productCard);
    }
    this.cdr.detectChanges();
  }
  /**
   * isButtonDisabled - Disable button for coupon start date is greater than today's date
   * @param coupon - Contains coupon valid start date
   * @returns - Boolean value to disable button
   */
  isButtonDisabled(coupon: any): any {
    this.todayDate = formatDate(new Date(), 'YYYY-MM-dd', 'en-US');
    this.couponStartDate = formatDate(
      coupon.couponValidStartDate,
      'YYYY-MM-dd',
      'en-US'
    );
    return this.couponStartDate <= this.todayDate ? 'false' : 'true';
  }

  /**
   * redeemCoupons - On coupon apply, it will check for account type
   * For WTS user navigate to WTS cart page
   * For guest user navigate to PDP page
   * @param coupon - contains coupon details
   */
  redeemCoupons(coupon: any): any {
    this.errorMessage = this.common.getCurrentCartErrorMsg();
    const guestId = this.guestsService.helper.getGuestId();
    const couponData = {
      guestId: guestId,
      couponCode: coupon?.couponCode,
    };
    //to access data in WTS added data in cookieService
    this.addCouponData(coupon);
    this.common.clearAllCookies(false, false, true, false);
    //to get info about user logged in from WTS or guest
    const fromWTS = this.cookieService.get('couponList');
    if (coupon.couponType === this.globalDefault.COUPON_TYPE) {
      this.redeemCouponsData(couponData, coupon);
    } else {
      //if current carts API getting executed successfully
      if (!this.errorMessage) {
        this.applyCouponsData(couponData, fromWTS);
      } else {
        if (this.errorMessage === this.globalDefault.CART_ERROR) {
          this.window.open(
            `${
              environment.wtsHostBaseUrl
            }${this.globalDefault.PRODUCT_CATEGORY.substring(1)}`,
            '_self'
          ); //PLP page
        } else {
          const err = this.globalDefault.ERROR_CODE + this.errorMessage;
          const pageLabels = this.getErrorCodesArray();
          //current carts API getting failed then show errorMessage
          this.notification?.show(
            err ? pageLabels?.[err] : this.pageLabels?.['errCode.E019']
          );
        }
      }
    }
  }
  /**
   * applyCouponsData - on coupon apply, redirect to WTS cart or PLP page
   * @param couponData -passing guestID and couponCode
   * @param fromWTS  - check the user coming from WTS
   */
  applyCouponsData(
    couponData: { guestId: string; couponCode: any },
    fromWTS: string
  ): any {
    this.common.clearAllCookies(false, false, true, false);
    this.getApply(couponData).subscribe(
      () => {
        if (fromWTS) {
          this.common.onClickRedirectWTS('', '', true, false);
        } else {
          this.getRedeemCoupons(couponData).subscribe(
            (data: any) => {
              this.ddlEvents();
              if (data && this.cartItems.length == 0) {
                this.window.open(
                  `${
                    environment.wtsHostBaseUrl
                  }${this.globalDefault.PRODUCT_CATEGORY.substring(1)}`,
                  '_self'
                ); //PLP page
              } else {
                this.common.onClickRedirectWTS('', '', true, false); //cart
              }
            },
            (errors: any) => {
              this.showErrorMessage(errors);
            }
          );
        }
      },
      (errors: any) => {
        const errorArray: any = errors.error.errors;
        const isValidCoupon = errorArray?.find((errorObject: any) => {
          return errorObject?.errorCode === 'B002-0012';
        });
        if (isValidCoupon) {
          this.cookieService.set(
            'PLP_PAGE',
            'yes',
            0,
            '/',
            `${environment.cookieSubDomain}`
          );
          this.common.clearAllCookies(false, false, false, false);
          this.common.onClickRedirectWTS('', '', true, false);
        } else {
          this.showErrorMessage(errors);
        }
      }
    );
  }
  /**
   * redeemCouponsData - on coupon apply, redirect to WTS cart or PDP page
   * @param couponData - passing guestID and couponCode
   * @param coupon - contains coupon details
   */
  redeemCouponsData(
    couponData: { guestId: string; couponCode: any },
    coupon: any
  ): any {
    this.getRedeemCoupons(couponData).subscribe(
      (data: any) => {
        if (data) {
          const userToken = this.localStorage.getItem('OAUTH_LOGIN_GID');
          this.cookieService.set(
            'guestId',
            userToken,
            0,
            '/',
            `${environment.cookieSubDomain}`
          );
          this.cookieService.set(
            'COUPON_DETAILS',
            JSON.stringify(data?.associatedProduct?.code),
            0,
            '/',
            `${environment.cookieSubDomain}`
          );
          this.cookieService.set(
            'COUPON_TYPE',
            JSON.stringify(coupon.couponType),
            0,
            '/',
            `${environment.cookieSubDomain}`
          );
          if (coupon?.visitationDate && coupon?.redeemableProducts) {
            const formattedDate: any = this.common.formattedDate(
              coupon.visitationDate
            );
            this.cookieService.set(
              'VISITATION_DATE',
              formattedDate,
              0,
              '/',
              `${environment.cookieSubDomain}`
            );
            this.cookieService.set(
              'REDEEMABLE_PRODUCTS',
              JSON.stringify(coupon.redeemableProducts),
              0,
              '/',
              `${environment.cookieSubDomain}`
            );
          }
          sessionStorage.setItem('COUPON_DATA', JSON.stringify(data));
          if (
            this.activecartProductCode === data?.associatedProduct?.code ||
            (coupon.visitationDate && coupon.redeemableProducts)
          ) {
            const localeInfo = this.locale.getFromUrl();
            const productCode = data?.associatedProduct?.code;
            const productCategoryCode = this.globalDefault.PRODUCT_CATEGORY;
            const wtsRedirectUrl = `${environment.wtsHostBaseUrl}${localeInfo.language}/${localeInfo.country}${productCategoryCode}/${productCode}`;
            this.common.onClickRedirectWTS('', wtsRedirectUrl, true, false);
          } else {
            this.fetchWtsProgressBarInfo();
            this.onProductCardCta(data);
          }
        }
      },
      (errors: any) => {
        this.showErrorMessage(errors);
        this.clearCouponCookie();
      }
    );
  }

  /**
   * clearCouponCookie - Clear coupon cookie if there is error
   */
  clearCouponCookie(): void {
    this.cookieService.delete(
      'COUPON_NAME',
      '/',
      `${environment.cookieSubDomain}`,
      true,
      'None'
    );
    this.cookieService.delete(
      'COUPON_CODE',
      '/',
      `${environment.cookieSubDomain}`
    );
    this.cookieService.delete(
      'COUPON_INFO',
      '/',
      `${environment.cookieSubDomain}`
    );
    this.cookieService.delete(
      'COUPON_TYPE',
      '/',
      `${environment.cookieSubDomain}`
    );
    this.cookieService.delete(
      'COUPON_DETAILS',
      '/',
      `${environment.cookieSubDomain}`
    );
  }

  /**
   * CommerceErrMsg - display the commerce messages along with the WTS link
   * @param pageLabels - getting pageLabels
   * @param errorMsg - getting error messages from Commerce API
   */
  CommerceErrMsg(pageLabels: any, errorMsg: any): any {
    this.commerceErrMsg = pageLabels?.[errorMsg]
      ? pageLabels?.[errorMsg]
      : this.pageLabelsData?.['errCode.E019'];
    this.wtsCartURL = this.cartItems?.entries?.length
      ? `${environment.wtsHostBaseUrl}cart`
      : `${
          environment.wtsHostBaseUrl
        }${this.globalDefault.PRODUCT_CATEGORY.substring(1)}`;
  }
  /**
   * openWTSCartPage - open the WTS cart URL in new tab
   * @param wtsCartURL - pass the WTS cart URL
   */
  openWTSCartPage(wtsCartURL: any): any {
    window.open(wtsCartURL, '_blank');
  }
  /**
   * closePopup - close the model popup
   */
  closePopup(): any {
    this.isOpenPopup = false;
  }
  /**
   * ddlEvents - Capture the events from add coupons
   */
  ddlEvents(): void {
    this.common.invokeFireEvent(
      'Apply',
      'Button',
      'Click',
      '',
      new Date().toISOString(),
      'Body | CTA: Apply',
      '',
      this.window?.digitalData?.page?.category?.primaryCategory ?? '',
      this.window?.digitalData?.page?.category?.subCategory1 ?? ''
    );
  }

  /**
   * getRedeemCoupons - on apply coupon send coupon code and guest id in request
   * @param couponData - It contains guest Id, coupon code
   * @returns - return the API call response
   */
  public getRedeemCoupons(couponData: any): any {
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };
    const url =
      environment.commerceApiUrl +
      this.relativeUrls.COMMERCE_API +
      this.getRelativeUrl(
        this.relativeUrls.REDEEM_COUPON,
        couponData?.guestId,
        couponData?.couponCode
      );

    return this.http.get<any>(url, options);
  }

  /**
   * getApply - On click of redeem button navigate from WTS to guest account
   * @param couponData - contains details about coupon
   * @returns - Response of API
   */
  public getApply(couponData: any): any {
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };
    const url =
      environment.commerceApiUrl +
      this.relativeUrls.COMMERCE_API +
      this.getRelativeUrlVoucher(
        this.relativeUrls.GET_APPLY,
        this.cartItems?.code,
        couponData?.couponCode
      );

    return this.http.post(url, {}, options);
  }
  /**
   * fetchActiveCart - Get details of active cart items of logged in user
   * @returns - Cart Item details
   */
  public fetchActiveCart(): any {
    const loggedInUserToken = JSON.parse(
      this.localStorage.getItem('loginAuthToken')
    );

    let httpHeaders = new HttpHeaders({});
    httpHeaders = httpHeaders.append(
      'Authorization',
      `Bearer ${loggedInUserToken?.accessToken?.access_token}`
    );
    const options = { headers: httpHeaders };
    const url =
      environment.commerceApiUrl +
      this.relativeUrls.COMMERCE_API +
      this.relativeUrls.GET_CARTS;
    return this.http.get<any>(url, options);
  }
  /**
   *
   * @param url - Pass url with attributes
   * @param guestId - Pass guest id needs to replace in url
   * @param couponCode - Pass coupon code needs to replace in url
   * @returns - return the url with guestId and coupon code
   */
  public getRelativeUrl(
    url: string,
    guestId: string,
    couponCode: string
  ): string {
    url = url.replace('{guestId}', guestId);
    url = url.replace('{couponCode}', couponCode);
    return url;
  }

  public getRelativeUrlVoucher(
    url: string,
    cartId: string,
    voucherId: string
  ): string {
    url = url.replace('{cartId}', cartId);
    url = url.replace('{voucherId}', voucherId);
    return url;
  }

  /**
   * fetchWtsProgressBarInfo - Fetch tridion data to get category codes
   */
  fetchWtsProgressBarInfo(): void {
    this.common.getProgressBarData().subscribe((response: any) => {
      this.progressBarCategoryInfo = response[0].data.progressBar
        .filter(
          (categoryInfo: any) =>
            categoryInfo?.pageType === this.globalDefault.PRODUCT_LISTING_PAGE
        )
        .map((categoryInfo: any) => {
          return {
            code: categoryInfo?.url,
          };
        });
    });
  }

  /**
   * onProductCardCta - Check API response to form PDP page url
   * @param data - It contains response which having category code, product code
   */
  onProductCardCta(data: any): void {
    let productCategoryCode: any;
    const localeInfo = this.locale.getFromUrl();
    const productCode = data?.associatedProduct?.code;
    const matchingURLs: any[] = [];
    if (this.progressBarCategoryInfo?.length) {
      const categories = data?.associatedProduct?.categories.map(
        (category: any) => `${category?.code}`
      );
      categories.filter((productCategoryCodeValue: any) => {
        const matchingEntry = this.progressBarCategoryInfo.find((info: any) =>
          info.code.endsWith(productCategoryCodeValue)
        );
        if (matchingEntry) {
          matchingURLs.push(matchingEntry);
        }
      });
      if (matchingURLs?.length) {
        productCategoryCode = matchingURLs[0]?.code;
      } else {
        productCategoryCode = this.globalDefault.PRODUCT_CATEGORY;
      }
    }
    if (!this.progressBarCategoryInfo?.length) {
      productCategoryCode = this.globalDefault.PRODUCT_CATEGORY;
    }
    if (productCategoryCode) {
      const wtsRedirectUrl = `${environment.wtsHostBaseUrl}${localeInfo.language}/${localeInfo.country}${productCategoryCode}/${productCode}`;
      this.common.onClickRedirectWTS(productCode, wtsRedirectUrl, true, false);
    }
  }

  /**
   * getErrorCodesArray - Filter Error codes from pageLabels
   * @returns - Return only error codes start with errCode.COMM.
   */
  getErrorCodesArray(): any {
    const pageLabels = Object.entries(this.pageLabels);
    const obj: any = {};
    _.each(pageLabels, (key: any) => {
      if (key[0].startsWith(this.globalDefault.ERROR_CODE)) {
        obj[key[0]] = key[1];
      }
    });
    return obj;
  }

  /**
   * showErrorMessage - call the method to display error message
   * @param errors - contains details of errors
   */
  showErrorMessage(errors: any): any {
    if (errors?.status === this.globalDefault.ERROR_401) {
      this.common.setUser();
      localStorage.clear();
      sessionStorage.clear();
      this.cookieService.deleteAll('/', environment.cookieSubDomain);
      const redirectUrl = this.common.getOidcRedirectionUrl();
      this.common.redirectToOidcAppEndSession(redirectUrl);
    } else {
      const errorCode = errors?.error?.errors[0]?.errorCode?.replace(/-/g, '');
      const err = this.globalDefault.ERROR_CODE + errorCode;
      const pageLabels = this.getErrorCodesArray();
      this.CommerceErrMsg(pageLabels, err);
      this.isOpenPopup = true;
    }
  }

  /**
   * addCouponData - to store data in cookies
   * @param coupon - contains coupon data
   */
  addCouponData(coupon: any): any {
    this.cookieService.set(
      'COUPON_NAME',
      coupon?.couponName,
      0,
      '/',
      `${environment.cookieSubDomain}`
    );
    this.cookieService.set(
      'COUPON_CODE',
      coupon?.couponCode,
      0,
      '/',
      `${environment.cookieSubDomain}`
    );
    this.cookieService.set(
      'COUPON_INFO',
      JSON.stringify(coupon),
      0,
      '/',
      `${environment.cookieSubDomain}`
    );
  }
}
