import { OverlayModule } from '@angular/cdk/overlay';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ModuleWithProviders,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import {
  defaultToastConfig,
  TOAST_CONFIG_TOKEN,
} from '../classes/toast-config';
//import { MatIconModule } from '@angular/material/icon';
import { ToastComponent } from '../components/toast/toast.component';
import { ToastService } from '../service/toast.service';

declare module '@angular/core' {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
@NgModule({
  imports: [OverlayModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  declarations: [ToastComponent],
  entryComponents: [ToastComponent],
})
export class ToastModule {
  public static forRoot(config = defaultToastConfig): ModuleWithProviders {
    return {
      ngModule: ToastModule,
      providers: [
        {
          provide: TOAST_CONFIG_TOKEN,
          useValue: { ...defaultToastConfig, ...config },
        },
        ToastService,
      ],
    };
  }
}
