<gds-spacer classes="spacer-70"></gds-spacer>
<!--Account Confirmation Heading -->
<gds-heading
  [data]="{
    heading: pageLabels?.['accountConf.heading']
  }"
  class="A3-W6"
>
</gds-heading>
<gds-spacer classes="spacer-30"></gds-spacer>

<!--Account Confirmation Description -->
<gds-heading
  [data]="{
    heading:  pageLabels?.['accountConf.confirm']
  }"
  class="A5-W4"
>
</gds-heading>
<gds-spacer classes="spacer-70"></gds-spacer>

<!--Account Confirmation Name Heading -->
<gds-heading
  [data]="{
    heading:  pageLabels?.['accountConf.name']
  }"
  class="B1-W4"
>
</gds-heading>
<gds-spacer classes="spacer-20"></gds-spacer>
<gds-heading
  [data]="{
    heading: fullName
  }"
  class="A5-W5"
>
</gds-heading>
<gds-spacer classes="spacer-50"></gds-spacer>
<hr class="hr-rule" />
<gds-spacer classes="spacer-50"></gds-spacer>

<!--Account Confirmation Email Heading -->
<gds-heading
  [data]="{
    heading: pageLabels?.['accountConf.emailAddress']
  }"
  class="B1-W4"
>
</gds-heading>
<gds-spacer classes="spacer-20"></gds-spacer>
<gds-heading
  [data]="{
    heading: userArray?.[0].email
  }"
  class="A5-W5 email-field"
>
</gds-heading>
<gds-spacer classes="spacer-50"></gds-spacer>
<hr class="hr-rule" />
<gds-spacer classes="spacer-50"></gds-spacer>

<!--Account Confirmation Mobile Heading -->
<gds-heading
  [data]="{
    heading: pageLabels?.['accountConf.phoneNumber']
  }"
  class="B1-W4"
>
</gds-heading>
<gds-spacer classes="spacer-20"></gds-spacer>
<gds-heading
  [data]="{
    heading: userArray?.[0].phoneNumber
  }"
  class="A5-W5"
>
</gds-heading>
<gds-spacer classes="spacer-50"></gds-spacer>
<hr class="hr-rule" />
<gds-spacer classes="spacer-50"></gds-spacer>

<!--Account Confirmation Birthday Heading -->
<gds-heading
  [data]="{
    heading: pageLabels?.['accountConf.birthday']
  }"
  class="B1-W4"
>
</gds-heading>
<gds-spacer classes="spacer-20"></gds-spacer>
<gds-heading
  [data]="{
    heading: dateVal?.length > 0 ? dateVal : pageLabels?.['accountInfo.notRegistered']
  }"
  [ngClass]="dateVal?.length > 0 ? 'A5-W5' : 'A6-W3'"
>
</gds-heading>
<gds-spacer classes="spacer-50"></gds-spacer>
<hr class="hr-rule" />
<gds-spacer classes="spacer-50"></gds-spacer>

<!--Account Confirmation Password Heading -->
<gds-heading
  [data]="{
    heading: pageLabels?.['accountConf.password']
  }"
  class="B1-W4"
>
</gds-heading>
<gds-spacer classes="spacer-20"></gds-spacer>
<gds-heading
  [data]="{
    heading: encryptPassword
  }"
  class="A5-W5"
>
</gds-heading>
<gds-spacer classes="spacer-50"></gds-spacer>
<hr class="hr-rule" />
<gds-spacer classes="spacer-50"></gds-spacer>

<!--Account Confirmation Offers Heading -->
<gds-heading
  [data]="{
    heading: pageLabels?.['accountConf.offers']
  }"
  class="B1-W4"
>
</gds-heading>
<gds-spacer classes="spacer-20"></gds-spacer>
<gds-heading
  [data]="{
    heading: userArray?.[0].specialOffer ? pageLabels?.['accountConf.yes'] : pageLabels?.['accountConf.no']
  }"
  class="A5-W5"
>
</gds-heading>
<gds-spacer classes="spacer-70"></gds-spacer>

<!--Sinup-Confirm CTA it redirect to signup complete page -->
<div class="align-left">
  <gds-button
    [data]="{
      type: 'secondary',
      href: '',
      label: pageLabels?.['accountConf.backButton'],
      disabled: false,
      target: ''
    }"
    (buttonClicked)="createBackbtn()"
  >
  </gds-button>
  <gds-spacer classes="vertical-spacer-50"></gds-spacer>
  <gds-button
    [data]="{
      type: 'primary',
      href: '',
      label: pageLabels?.['accountConf.nextButton'],
      disabled: false,
      target: ''
    }"
    (buttonClicked)="onSubmitAccCreation()"
  >
  </gds-button>
</div>
