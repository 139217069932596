import {
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
// import { LocaleService } from '@upr-web-primer/locale';
import { IGuestsResponse, MsGuestsService } from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { SpinnerService } from '@upr-web-primer/spinner';
import { LocalStorageService } from '@upr-web-primer/storage';
import _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { CommonUtilityService } from '../../services/common-utility.service';
import { TICKET_STATUS } from '../ticket-details/ticket-status';
@Component({
  selector: 'app-past-tickets',
  templateUrl: './past-tickets.component.html',
  styleUrls: ['./past-tickets.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class PastTicketsComponent implements OnInit {
  pageLabels: any;
  userProfileData: any;
  entitlementArray: any[];
  expiredTicketsArray: any[];
  travelMembersDetails: any;
  expiredTicketsDetails: any[];
  ticketStatus: any;
  formattedCurrentDate: any;
  formattedComingDate: any;
  languageCode: string;
  TICKET_STATUS: {
    'MS.ticketStatus.2': string;
    'MS.ticketStatus.109': string;
    'MS.ticketStatus.110': string;
  };
  constructor(
    // private locale: LocaleService,
    protected injector: Injector,
    public commonUtilityService: CommonUtilityService,
    public guestsService: MsGuestsService,
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    private localStorage: LocalStorageService,
    private cdr: ChangeDetectorRef,
    private cookieService: CookieService,
    private title: Title,
    private spinner: SpinnerService,
    private notification: NotificationService
  ) {
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.expiredTickets']);
  }
  /**
   * ngOnInit - Initialize the component
   */
  ngOnInit(): void {
    this.spinner?.show();
    this.entitlementArray = this.commonUtilityService.getResolvedData();
    this.travelMembersDetails =
      this.commonUtilityService.getTravelMembersDetails();
    this.getEntitlement();
  }

  /**
   * getDate - It will format the date based on locale
   */

  getDate(date: any, weekday?: any): string {
    return this.commonUtilityService.getFormattedDateOnLocale(date, weekday);
  }

  /**
   * getEntitlement - Get the entitlement Data ticket details
   */

  getEntitlement(): void {
    this.loadUserInfo();
    const expiredTicketStatusArray = _.filter(
      this.entitlementArray,
      (item: any) => {
        const statusKey = `MS.ticketStatus.${item?.ticket_status}`;
        const statusValue = TICKET_STATUS[statusKey];
        const isValidDate = this.isGreaterDateValid(item?.valid_to);
        return statusValue !== undefined && isValidDate;
      }
    );
    const expiredDateValidArray = _.filter(this.entitlementArray, (item: any) =>
      this.isLessDateValid(item?.valid_to)
    );
    this.expiredTicketsArray = expiredTicketStatusArray.concat(
      expiredDateValidArray
    );
    this.isDateChangeable();
  }

  /**
   * getTravelParty - Get the trp Data ticket details to map each array
   */

  getTravelParty(): void {
    this.travelMembersDetails = _.filter(
      this.travelMembersDetails,
      (member) => member?.is_active === true
    );

    const namesWithIds = _.map(this.travelMembersDetails, (item) => ({
      id: item?.member_id,
      full_name: `${item?.contact?.last} ${item?.contact?.first}`,
    }));

    namesWithIds.push({
      id: this.guestsService.helper.getGuestId(),
      full_name: `${this.localStorage.getItem(
        'USER_LNAME'
      )}  ${this.localStorage.getItem('USER_FNAME')}`,
    });
    this.expiredTicketsDetails = _.map(namesWithIds, (nameItem) => {
      const matchingTickets = this.expiredTicketsArray.filter(
        (ticketItem) => ticketItem?.guest_id === nameItem?.id
      );

      if (matchingTickets.length > 0) {
        return {
          full_name: nameItem.full_name,
          id: nameItem.id,
          tickets: _.map(matchingTickets, (ticket) => ({
            product_name: ticket?.product_name,
            ticket_id: ticket?.ticket_id,
            valid_from: ticket?.valid_from,
            valid_to: ticket?.valid_to,
            ticket_status: ticket?.ticket_status,
            icons: ticket?.icons,
            isDateChange: ticket?.isDateChange_allowed,
          })),
        };
      }

      return null;
    }).filter(Boolean);
    this.spinner?.hide();
    this.expiredTicketsDetails.reverse();
    this.sortTicketsByValidTo();
  }
  /**
   * sortTicketsByValidTo -  sorted the tickets based on the recent dates.
   */
  sortTicketsByValidTo(): void {
    this.expiredTicketsDetails.forEach((user) => {
      user.tickets.sort((a: any, b: any) => {
        const dateA = new Date(a.valid_to);
        const dateB = new Date(b.valid_to);
        const now = new Date();

        const isFutureA = dateA >= now;
        const isFutureB = dateB >= now;

        if (isFutureA && !isFutureB) {
          return -1;
        } else if (!isFutureA && isFutureB) {
          return 1;
        } else {
          return dateB.getTime() - dateA.getTime();
        }
      });
    });
  }

  /**
   * getTickets-It will navigate to ticket-detail component
   */

  getTickets(data: any): any {
    this.commonUtilityService.changeRoute(
      `${this.appRoutes.TICKET_DETAILS}/${data.ticket_id}`
    );
  }

  /**
   * loadUserInfo-It will load the user data
   */

  loadUserInfo(): any {
    this.guestsService.profile
      .getByGuestId(this.guestsService.helper.getGuestId())
      .then((userData: IGuestsResponse) => {
        this.spinner?.show();
        this.userProfileData = userData?.personal_info?.contacts;
      })
      .catch((errors: any) => {
        if (errors?.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService?.setUser();
          localStorage?.clear();
          sessionStorage?.clear();
          this.cookieService?.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl =
            this.commonUtilityService?.getOidcRedirectionUrl();
          this.commonUtilityService?.redirectToOidcAppEndSession(redirectUrl);
        }
        false;
      });
  }

  /**
   * isDateValid-It will check expired tickets with past date
   */

  isLessDateValid(validDate: string): boolean {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in "yyyy-MM-dd" format
    return validDate < currentDate;
  }
  /**
   * isDateValid-It will check expired tickets with future date
   */

  isGreaterDateValid(validDate: string): boolean {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in "yyyy-MM-dd" format
    return validDate > currentDate;
  }
  /**
   * redirectTo-Redirect to wallet page
   */
  redirectTo(): void {
    this.commonUtilityService.changeRoute(this.appRoutes.VIEW_ENTITLMENT);
  }

  isDateChangeable(): void {
    const studioPassTicketIds = this.expiredTicketsArray
      .filter(
        (ticket: any) =>
          ticket.categoryCode === this.globalDefault?.COMM_STUDIO_PASS
      )
      .map((ticket: any) => ticket.ticket_id)
      .join(',');
    this.commonUtilityService
      ?.getIsDateChangeable(studioPassTicketIds)
      ?.subscribe(
        (res: any) => {
          const isDateChangeableArray = Object.values(res).map(
            (ticket: any) => ({
              ticketId: ticket?.ticketId,
              productName: ticket?.productName,
              isRenew: ticket?.rescheduleValidation?.isAllowed,
            })
          );
          this.entitlementArray.forEach((entitlement) => {
            const correspondingDCItem = _.find(
              isDateChangeableArray,
              (dcItem) => dcItem.ticketId === entitlement.ticket_id
            );
            if (correspondingDCItem) {
              entitlement.isDateChange_allowed = correspondingDCItem?.isRenew;
            }
          });
          this.commonUtilityService.setResolvedData(this.entitlementArray);
          this.getTravelParty();
          this.cdr.detectChanges();
        },
        (error: any) => {
          this.spinner?.hide();
          const commerceGenericErrorMessage =
            this.commonUtilityService.removeHyphensFromCommerceAPI(
              error?.errors?.[0]?.errorCode,
              this.pageLabels,
              this.globalDefault
            );
          this.notification?.show(commerceGenericErrorMessage);
        }
      );
  }

  getDateChange(element: any): string {
    if (element.isDateChange === true) {
      const dateChangeStatus = this.pageLabels?.['pastTickets.dateChangeLabel'];
      return dateChangeStatus || '';
    } else {
      return '';
    }
  }
  /**
   * redirecting to the actual page when we right click and open with new tabs and windows.
   */
  // orderProfileUrl(endpointName: any): any {
  //   const localeInfo = this.locale.getFromUrl();
  //   return (this.languageCode = `${localeInfo.language}/${localeInfo.country}/wallet/${endpointName}`);
  // }
}
