<div class="container">
  <gds-spacer classes="spacer-130"></gds-spacer>
  <gds-heading
    tagtype="h4"
    [data]="{
      heading: pageLabels?.['lostForm.heading']
    }"
  ></gds-heading>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <gds-body [innerHtml]="pageLabels?.['lostForm.body']" class="B1-W2">
  </gds-body>
  <gds-spacer classes="spacer-70"></gds-spacer>
  <div class="privacy-cls">
    <gds-heading
      [data]="{
      heading: pageLabels?.['lostForm.noticeHeading']
    }"
      Classes="heading-11"
    ></gds-heading>
    <gds-body
      [data]="{
      description: pageLabels?.['inquiryForm.required']
    }"
      class="required-cls"
    >
    </gds-body>
  </div>
  <gds-spacer classes="spacer-30"></gds-spacer>
  <gds-body [innerHtml]="pageLabels?.['lostForm.notice']" class="B1-W2">
  </gds-body>
  <gds-spacer classes="spacer-30"></gds-spacer>
  <div [formGroup]="lostFoundWebForm">
    <gds-input
      id="agree"
      ngDefaultControl
      class="checkbox-label"
      [data]="{
        primaryLabel: pageLabels?.['lostForm.agree'],
        type: 'checkbox',
        required: true
      }"
      (valueChange)="onCheckboxChange($event, 'agree')"
      [value]="lostFoundWebForm.value?.agree ? 'true' : 'false'"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="privacy-cls">
      <gds-heading
        [data]="{
        heading: pageLabels?.['lostForm.lostItems_count_Heading']
      }"
        Classes="heading-11"
      ></gds-heading>
      <gds-body
        [data]="{
        description: pageLabels?.['inquiryForm.required']
      }"
        class="required-cls"
      >
      </gds-body>
    </div>
    <gds-spacer classes="spacer-20"></gds-spacer>
    <gds-body
      [data]="{
        description: pageLabels?.['lostForm.lostItems_count_subText']
      }"
      class="B1-W2"
    >
    </gds-body>
    <gds-spacer classes="spacer-20"></gds-spacer>
    <gds-body
      [data]="{
        description: pageLabels?.['lostForm.lostItems_count_notice']
      }"
      class="B1-W2"
    >
    </gds-body>
    <gds-spacer classes="spacer-30"></gds-spacer>
    <gds-input
      id="countAgree"
      ngDefaultControl
      class="checkbox-label"
      [data]="{
        primaryLabel: pageLabels?.['lostForm.lostItems_count_agree'],
        type: 'checkbox',
        required: true
      }"
      (valueChange)="onCheckboxChange($event, 'countAgree')"
      [value]="lostFoundWebForm.value?.countAgree ? 'true' : 'false'"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="privacy-cls">
      <gds-heading
        [data]="{
        heading: pageLabels?.['lostForm.privacyPolicy_heading']
      }"
        Classes="heading-11"
      ></gds-heading>
      <gds-body
        [data]="{
        description: pageLabels?.['inquiryForm.required']
      }"
        class="required-cls"
      >
      </gds-body>
    </div>
    <gds-spacer classes="spacer-20"></gds-spacer>
    <gds-body
      [innerHtml]="pageLabels?.['lostForm.privacyPolicy']"
      class="B1-W2"
    >
    </gds-body>
    <gds-spacer classes="spacer-30"></gds-spacer>
    <gds-input
      id="privacyAgree"
      ngDefaultControl
      class="checkbox-label"
      [data]="{
        primaryLabel: pageLabels?.['lostForm.privacyPolicy_agree'],
        type: 'checkbox',
        required: true
      }"
      (valueChange)="onCheckboxChange($event, 'privacyAgree')"
      [value]="lostFoundWebForm.value?.privacyAgree ? 'true' : 'false'"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="lastName"
      formControlName="lastName"
      ngDefaultControl
      [errors]="lastNameErrorMsgInfo"
      [data]="{
        primaryLabel: pageLabels?.['lostForm.lastName'],
        supportingText: pageLabels?.['lostForm.required'],
        secondaryText: pageLabels?.['lostForm.name_fullWidth'],
        type: 'text',
        altText: 'input of lastName',
        required: true
      }"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress=" if( this.value.length === 30 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="last_name_furgina"
      formControlName="last_name_furgina"
      [errors]="lastNameFurginaErrorMsgInfo"
      ngDefaultControl
      [data]="{
        primaryLabel: pageLabels?.['lostForm.lastName'] + pageLabels?.['lostForm.furigana'],
        supportingText: pageLabels?.['lostForm.required'],
        type: 'text',
        required: true
      }"
      (blurChange)="getErrorMessageInfo($event)"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      onKeyPress=" if( this.value?.length === 30 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="firstName"
      formControlName="firstName"
      ngDefaultControl
      [errors]="firstNameErrorMsgInfo"
      [data]="{
        primaryLabel: pageLabels?.['lostForm.firstName'],
        supportingText: pageLabels?.['lostForm.required'],
        secondaryText: pageLabels?.['lostForm.name_fullWidth'],
        type: 'text',
        altText: 'Input of first name',
        required: true
      }"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress=" if( this.value.length === 25 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="first_name_furgina"
      formControlName="first_name_furgina"
      [errors]="firstNameFurginaErrorMsgInfo"
      ngDefaultControl
      [data]="{
        primaryLabel:
        pageLabels?.['lostForm.firstName'] + pageLabels?.['lostForm.furigana'],
        supportingText: pageLabels?.['lostForm.required'],
        type: 'text',
        required: true
      }"
      (blurChange)="getErrorMessageInfo($event)"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      onKeyPress=" if( this.value?.length === 25 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="email"
      formControlName="email"
      ngDefaultControl
      [errors]="emailErrorMsgInfo"
      [data]="{
        primaryLabel: pageLabels?.['lostForm.emailAddress'],
        supportingText: pageLabels?.['lostForm.required'],

        type: 'email',
        altText: 'input of email',
        ariaText: '',
        required: true
      }"
      onKeyPress=" if( this.value.length >= 100 ) return false;"
      (blurChange)="getErrorMessageInfo($event)"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="reEnterEmail"
      formControlName="reEnterEmail"
      ngDefaultControl
      [errors]="reEnterEmailErrorMsgInfo"
      [data]="{
        primaryLabel: pageLabels?.['lostForm.email_reEnter'],
        supportingText: pageLabels?.['lostForm.required'],
        secondaryText: pageLabels?.['lostForm.emailEnsure'],

        type: 'email',
        altText: 'Re-enter email',
        ariaText: '',
        required: true
      }"
      onKeyPress=" if( this.value.length >= 40 ) return false;"
      (blurChange)="getErrorMessageInfo($event)"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="phoneNumber"
      formControlName="phoneNumber"
      [errors]="phoneNumberErrorInfo"
      ngDefaultControl
      [data]="{
        primaryLabel: pageLabels?.['lostForm.phoneNumber'],
        supportingText: pageLabels?.['lostForm.required'],
        secondaryText: pageLabels?.['lostForm.phoneNumber_text'],
        tooltip: '',
        type: 'text',
        altText: 'input of phoneNumber',
        ariaText: '',
        required: true
      }"
      onkeydown="return (/[0-9]/i.test(event.key) || event.key === 'Delete' || event.key === 'Backspace' || event.key==='Tab' || (event.key.startsWith('Arrow')) ?  true :  false)"
      (blurChange)="getErrorMessageInfo($event)"
      hide-spinner="true"
      onKeyPress=" if( this.value?.length === 11 ) return false;"
    >
    </gds-input>

    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      formControlName="postalCode"
      ngDefaultControl
      id="postalCode"
      [errors]="postalErrorMsg"
      hide-spinner="true"
      [data]="{
        primaryLabel: pageLabels?.['lostForm.zipcode'],
        supportingText: pageLabels?.['lostForm.required'],
        tooltip: '',
        type: 'number',
        altText: 'input for postal code of the user',
        ariaText: 'input for postal code of the user',
        required: true
      }"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress="return /[0-9]/i.test(event.key) && this.value.length < 20;"
    >
    </gds-input>

    <gds-spacer classes="spacer-50"></gds-spacer>

    <gds-button
      class="autofill-btn"
      [data]="{
        type: 'secondary',
        href: '',
        label: pageLabels?.['lostForm.autoFill'],
        disabled: false,
        target: ''
      }"
      (buttonClicked)="getPostalCodes()"
    ></gds-button>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="prefecture"
      formControlName="prefecture"
      [errors]="prefectureErrorMsg"
      ngDefaultControl
      [data]="{
        primaryLabel: pageLabels?.['lostForm.prefecture'],
        supportingText: pageLabels?.['lostForm.required'],
        type: 'text',
        altText: 'input of prefecture',
        required: true
      }"
      (blurChange)="getErrorMessageInfo($event)"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      onKeyPress=" if( this.value.length === 120 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="city"
      formControlName="city"
      [errors]="cityErrorMsg"
      ngDefaultControl
      [data]="{
        primaryLabel: pageLabels?.['lostForm.city'],
        supportingText: pageLabels?.['lostForm.required'],
        type: 'text',
        altText: 'input of city',
        required: true
      }"
      (blurChange)="getErrorMessageInfo($event)"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      onKeyPress=" if( this.value.length === 50 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="town"
      formControlName="town"
      [errors]="townErrorMsg"
      ngDefaultControl
      [data]="{
        primaryLabel: pageLabels?.['lostForm.town'],
        supportingText: pageLabels?.['lostForm.required'],
        type: 'text',
        altText: 'input of town',
        required: true
      }"
      (blurChange)="getErrorMessageInfo($event)"
      onkeydown="return /[a-zA-Z]/i.test(event.key)"
      onKeyPress=" if( this.value.length === 50 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="street"
      formControlName="street"
      [errors]="streetErrorMsg"
      ngDefaultControl
      [data]="{
      primaryLabel: pageLabels?.['lostForm.street'],
      supportingText: pageLabels?.['lostForm.required'],
      type: 'text',
      altText: 'input of street',
      required: true
    }"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress=" if( this.value.length === 100 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-heading
      [data]="{
      heading: pageLabels?.['lostForm.propertyInfo']
    }"
      Classes="heading-7"
      class="lost-property"
    ></gds-heading>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="date-cls">
      <gds-body
        [data]="{
        description: pageLabels?.['lostForm.visitDate']
      }"
        classes="body-8"
      >
      </gds-body>
      <gds-body
        [data]="{
    description: pageLabels?.['inquiryForm.required']
  }"
        class="required-cls"
      >
      </gds-body>
    </div>
    <gds-spacer classes="spacer-30"></gds-spacer>
    <gds-body
      [data]="{
        description: pageLabels?.['lostForm.visitDate_subText']
      }"
      classes="body-1"
    >
    </gds-body>
    <gds-spacer classes="spacer-30"></gds-spacer>
    <gds-date-picker
      id="dob"
      formControlName="dob"
      ngDefaultControl
      [data]="{
        label: {
          label: pageLabels?.['lostForm.visitDate_ChooseDate']
        },
        modalControlBackground: {
          primaryButtonLabel: pageLabels?.['lostForm.visitDate_Apply'],
          secondaryButtonLabel: pageLabels?.['lostForm.visitDate_Reset']
        },
        applyAriaLive: 'bbb'
      }"
      [calendarInfo]="gdsDatePickerCalendarInfo"
      [requestedCalendars]="datePickerMonths"
      (valueChange)="getSelectDate($event)"
    >
    </gds-date-picker>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="lostTime"
      formControlName="lostTime"
      [errors]="lostTimeErrorInfo"
      ngDefaultControl
      [data]="{
        primaryLabel: pageLabels?.['lostForm.visitTime'],
        supportingText: pageLabels?.['lostForm.required'],
        secondaryText: pageLabels?.['lostForm.visitTime_subText'],
        type: 'text',
        altText: 'input of lostTime',
        required: true
      }"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress=" if( this.value.length === 10 ) return false;"
    >
    </gds-input>

    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <gds-input
      id="lostLocation"
      formControlName="lostLocation"
      [errors]="lostLocErrorInfo"
      ngDefaultControl
      [data]="{
        primaryLabel: pageLabels?.['lostForm.location'],
        supportingText: pageLabels?.['lostForm.required'],
        secondaryText: pageLabels?.['lostForm.location_subText'],
        type: 'text',
        altText: 'input of lostLocation',
        required: true
      }"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress=" if( this.value.length === 100 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="date-cls">
      <gds-heading
        [data]="{
        heading: pageLabels?.['lostForm.lostItem_heading']
      }"
        Classes="heading-11"
      ></gds-heading>
      <gds-body
        [data]="{
        description: pageLabels?.['inquiryForm.required']
      }"
        class="required-cls"
      >
      </gds-body>
    </div>
    <gds-spacer classes="spacer-20"></gds-spacer>
    <gds-body
      [data]="{
        description: pageLabels?.['lostForm.lostItem_subText']
      }"
      class="B1-W2 lost-property"
    >
    </gds-body>
    <gds-spacer classes="spacer-50"></gds-spacer>
    <div class="radio-button-group">
      <div class="left-column">
        <ng-container
          *ngFor="
            let radioButton of lostItemRadioButtons?.slice(0, 12);
            let i = index
          "
        >
          <gds-input
            id="selectedLostItemButton"
            class="custom-radio-button"
            [data]="{
              type: 'radio',
              primaryLabel: radioButton.label,
              secondaryText: '',
              priceText: '',
              name: 'lostItemRadioButtons',
              ariaText: '',
              required: true,
              default: '',
              groupErrorState: false
            }"
            (valueChange)="onRadioButtonChange($event, radioButton)"
          >
          </gds-input>
        </ng-container>
      </div>

      <div class="right-column">
        <ng-container
          *ngFor="
            let radioButton of lostItemRadioButtons?.slice(12, 24);
            let i = index
          "
        >
          <gds-input
            id="selectedLostItemButton"
            class="custom-radio-button"
            [data]="{
              type: 'radio',
              primaryLabel: radioButton.label,
              secondaryText: '',
              priceText: '',
              name: 'lostItemRadioButtons',
              ariaText: '',
              required: true,
              default: '',
              groupErrorState: false
            }"
            (valueChange)="onRadioButtonChange($event, radioButton)"
          >
          </gds-input>
        </ng-container>
      </div>
    </div>

    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="date-cls">
      <gds-heading
        [data]="{
        heading: pageLabels?.['lostForm.lostItem_details']
      }"
        Classes="heading-11"
      ></gds-heading>
      <gds-body
        [data]="{
    description: pageLabels?.['inquiryForm.required']
  }"
        class="required-cls"
      >
      </gds-body>
    </div>
    <gds-spacer classes="spacer-20"></gds-spacer>
    <gds-body
      [innerHtml]="pageLabels?.['lostForm.lostItem_details_text']"
      class="B1-W2"
    >
    </gds-body>
    <gds-spacer classes="spacer-20"></gds-spacer>
    <gds-input
      id="detailLostItem"
      formControlName="detailLostItem"
      ngDefaultControl
      [errors]="detailsOfLostLocErrorInfo"
      [data]="{
        maxCharacterCount: 2000,
        characterCountThreshold: 50,
        clearButtonLabel: '',
        cols: 4,
        rows: null,
        type: 'textarea',
        primaryLabel: '',
        supportingText: '',
        secondaryText: '',
        primaryIconTitleText: '',
        secondaryIconTitleText: '',
        tooltip: '',
        altText: '',
        ariaText: '',
        labelledby: '',
        describedby: '',
        required: false,
        disabled: false,
        readonly: false,
        pattern: '',
        inputmode: '',
        placeholder: '',
        maxLength: null,
        name: '',
        default: false,
        groupErrorState: false,
        autocomplete: '',
        checked: '',
        customEventDetail: {},
        prependIconName: '',
        maxCharacterThreshold: null
      }"
      maxlength="2000"
      (blurChange)="getErrorMessageInfo($event)"
      onKeyPress=" if( this.value.length === 2000 ) return false;"
    >
    </gds-input>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="date-cls">
      <gds-heading
        [data]="{
        heading: pageLabels?.['lostForm.property_found_heading']
      }"
        Classes="heading-11"
      ></gds-heading>
      <gds-body
        [data]="{
    description: pageLabels?.['inquiryForm.required']
  }"
        class="required-cls"
      >
      </gds-body>
    </div>
    <gds-spacer classes="spacer-20"></gds-spacer>
    <gds-body
      [innerHtml]="pageLabels?.['lostForm.property_found_text']"
      class="B1-W2"
    >
    </gds-body>
    <gds-spacer classes="spacer-30"></gds-spacer>
    <div class="radio-button-group2">
      <ng-container *ngFor="let radioButton of lostPropertyRadioButtons">
        <gds-input
          id="selectedLostPropertyButton"
          class="custom-radio-button2"
          [data]="{
            type: 'radio',
            primaryLabel: radioButton.label,
            secondaryText: '',
            priceText: '',
            name: 'propertyRadio',
            ariaText: '',
            required: true,
            default: '',
            groupErrorState: false
          }"
          (valueChange)="onRadioButtonChange($event, radioButton)"
        >
        </gds-input>
      </ng-container>
    </div>
    <gds-spacer classes="spacer-20"></gds-spacer>
    <gds-body
      [innerHtml]="pageLabels?.['lostForm.property_found_text_1']"
      class="B1-W2"
    >
    </gds-body>
    <gds-spacer classes="spacer-70"></gds-spacer>
    <hr class="hr-rule" />
    <gds-spacer classes="spacer-70"></gds-spacer>
    <div class="align-left">
      <gds-button
        [data]="{
          type: 'primary',
          href: '',
          label: pageLabels?.['lostForm.send'],
          disabled:
            lostFoundWebForm.valid &&
            (selectedLostItemRadio && selectedLostPropertyRadio) && selectionDate && !disableSendBtn 
              ? 'false'
              : 'true',
          target: ''
        }"
        (buttonClicked)="onSend()"
      >
      </gds-button>
    </div>
  </div>
</div>
