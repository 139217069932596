import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';

@Component({
  selector: 'app-add-travel-party',
  templateUrl: './add-travel-party.component.html',
  styleUrls: ['./add-travel-party.component.scss'],
})
export class AddTravelPartyComponent implements OnInit, AfterViewInit {
  @Input() addTravelPartyForm: FormGroup;

  @Output() confirmTravelPartyNxt = new EventEmitter<any>();
  @Output() backToTravelList = new EventEmitter<any>();
  opened = false;
  nameRegex = this.namePattern.NAME_SIGNUP;
  genderArray: any = [];
  relationshipArray: any = [];
  dobErrorMsgInfo: string;
  firstNameErrorMsgInfo: string;
  lastNameErrorMsgInfo: string;
  getTravelPartymembersData: any;
  showCourse = false;
  showConfirmPopup = false;
  fromPage: string;
  travelMembersCount: any;
  /**
   * Page labels
   * @type any
   */
  pageLabels: any;

  @ViewChild('addPartyModalContent') addPartyModalContent!: ElementRef;
  constructor(
    public commonUtilityService: CommonUtilityService,
    @Inject('AppRoutes') public appRoutes: any,
    protected injector: Injector,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private appDataService: AppDataService,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('NamePattern') public namePattern: any,
    @Inject('Ranges') public ranges: any,
    private title: Title
  ) {
    this.setDependencies();
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.travelPartyMemberAdd']);
  }

  /**
   * ngOnInit - initial methods are called
   */

  ngOnInit(): void {
    const travelPartyData = this.commonUtilityService.getFormData();
    this.travelMembersCount = travelPartyData?.length;
    this.genderArray = [
      {
        label: this.pageLabels?.['trpAdd.genderMale'],
        value: this.pageLabels?.['trpAdd.genderMale'],
      },
      {
        label: this.pageLabels?.['trpAdd.genderFemale'],
        value: this.pageLabels?.['trpAdd.genderFemale'],
      },
      {
        label: this.pageLabels?.['trpAdd.genderOther'],
        value: this.pageLabels?.['trpAdd.genderOther'],
      },
      {
        label: this.pageLabels?.['trpAdd.gendernotResponded'],
        value: this.pageLabels?.['trpAdd.gendernotResponded'],
      },
    ];
    this.relationshipArray = [
      {
        label: this.pageLabels?.['trpAdd.spouse'],
        value: this.pageLabels?.['trpAdd.spouse'],
      },
      {
        label: this.pageLabels?.['trpAdd.child'],
        value: this.pageLabels?.['trpAdd.child'],
      },
      {
        label: this.pageLabels?.['trpAdd.other'],
        value: this.pageLabels?.['trpAdd.other'],
      },
    ];
    this.fromPage = this.globalDefault.ADD;
    this.createForm();
  }
  /**
   * ngAfterViewInit - initial methods are called
   */
  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(this.addPartyModalContent, 164);
  }

  /**
   * onResize - To resize full screen modal popup
   */
  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(this.addPartyModalContent, 164);
  }

  /**
   * createForm - it will create the form
   */

  createForm(): any {
    this.addTravelPartyForm = this.formBuilder.group({
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(30),
        this.noWhitespaceValidator(),
        Validators.pattern(this.nameRegex),
      ]),
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(this.nameRegex),
        this.noWhitespaceValidator(),
      ]),
      dob: new FormControl(''),
      gender: new FormControl(''),
      relationship: new FormControl(''),
    });
  }

  /**
   * noWhitespaceValidator
   * @returns
   */

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace =
        (control.value || '').trim().length === this.ranges.PASSWORDS.INDEX[0];
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  /**
   * checkDate - check the date and passed to service
   */

  checkDate(): any {
    const inputDate = this.addTravelPartyForm.value.dob.toString();
    this.dobErrorMsgInfo = this.commonUtilityService.checkDate(
      inputDate,
      this.pageLabels
    );
  }

  /**
   * backToTravelPartyInfo - back to travel party info page
   */

  backToTravelPartyInfo(): any {
    this.backToTravelList.emit({ type: 'info' });
    this.commonUtilityService.changeRoute(
      this.appRoutes.TRAVEL_PARTY_INFORMATION
    );
  }

  /**
   * closePopup - close the popup
   */

  closePopup(): any {
    this.opened = false;
  }

  /**
   * onConfirm - confirm the change
   */

  onConfirm(): any {
    this.getTravelPartymembersData = this.commonUtilityService.getFormData();
    this.sameNameWarningMsg();
    this.showConfirmPopup = this.showConfirmPopup;
  }

  /**
   * sameNameWarningMsg - shows the warning messages
   */

  sameNameWarningMsg(): any {
    this.showCourse = this.getTravelPartymembersData?.some(
      (item: any) =>
        item?.contact?.last === this.addTravelPartyForm.value.lastName &&
        item?.contact?.first === this.addTravelPartyForm.value.firstName
    );
    if (this.showCourse) {
      this.showConfirmPopup = false;
      this.opened = true;
    } else this.showConfirmPopup = true;
  }

  /**
   * confirmTravelPartyMember - store the variables
   */

  confirmTravelPartyMember(): any {
    this.showConfirmPopup = true;
    this.opened = false;
  }

  /**
   * getErrorMessage - shows the error messages of inputs
   * @param field : specify the field of inputs
   * @returns
   */

  getErrorMessage(field: any): string {
    const fieldName = this.addTravelPartyForm?.get(field);
    const emptyCheck = this.pageLabels?.['errCode.E002'];
    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      switch (field) {
        case this.globalDefault.FIRSTNAME: {
          if (!this.addTravelPartyForm.value.firstName) return emptyCheck;
          else {
            this.firstNameErrorMsgInfo =
              this.pageLabels?.['errCode.E020'] +
              ' ' +
              this.ranges.MAX.FIRST_NAME +
              ' ' +
              this.pageLabels?.['createAccount.maxCharCheck'];
            return this.firstNameErrorMsgInfo;
          }
        }
        case this.globalDefault.LASTNAME: {
          if (!this.addTravelPartyForm.value.lastName) return emptyCheck;
          else {
            this.lastNameErrorMsgInfo =
              this.pageLabels?.['errCode.E020'] +
              ' ' +
              this.ranges.MAX.LAST_NAME +
              ' ' +
              this.pageLabels?.['createAccount.maxCharCheck'];
            return this.lastNameErrorMsgInfo;
          }
        }
      }
    }
    return '';
  }

  /**
   * getErrorMessageInfo - shows the error messages of inputs
   * @param event : specify the event
   */

  getErrorMessageInfo(event: any): void {
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.FIRSTNAME:
        this.firstNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.LASTNAME:
        this.lastNameErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.DOB:
        this.dobErrorMsgInfo = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.GENDER:
        this.addTravelPartyForm?.get('gender')?.patchValue(this.genderArray);
    }
    this.cdRef.detectChanges();
  }

  private setDependencies(): void {
    this.commonUtilityService = this.injector.get(CommonUtilityService);
  }

  /**
   * matchDropDown - drop down for gender and relationship
   * @param event : specify the event
   * @param type : specify the type
   */

  matchDropDown(event: any, type: any): any {
    type === this.globalDefault.GENDER
      ? this.genderArray?.filter((res: any) => {
          if (res.value === event.detail) {
            this.addTravelPartyForm.patchValue({
              gender: res.value,
            });
          }
        })
      : this.relationshipArray?.filter((res: any) => {
          if (res.value === event.detail) {
            this.addTravelPartyForm.patchValue({
              relationship: res.value,
            });
          }
        });
  }

  /**
   * onWarning - shows the warning popup
   * @param e : specify the event
   */

  onWarning(e: any): any {
    this.opened = e.opened;
    this.showConfirmPopup = e.showConfirm;
  }

  /**
   * onTravelPartyList - store the values and redirect to travel party info page
   * @param e : specify the event
   */

  onTravelPartyList(e: any): any {
    this.showConfirmPopup = e.showConfirmPopup;
    this.commonUtilityService.changeRoute(
      this.appRoutes.TRAVEL_PARTY_INFORMATION,
      { page: this.fromPage }
    );
  }
}
