<div class="container">
  <!--QR code section-->
  <div class="card">
    <div class="center-align">
      <img class="square" [src]="qrCode" />

      <!--Medai id of QR code-->
      <div class="content-center">
        <gds-spacer classes="spacer-20"></gds-spacer>
        <gds-heading
          class="B1-W4"
          classes="heading-7"
          [data]="{
            heading: mediaID
          }"
        ></gds-heading>
      </div>

      <!--Qr code description-->
      <div class="name-center">
        <gds-heading
          class="A3-W6"
          classes="heading-7"
          [data]="{
            heading: qrDesc
          }"
        ></gds-heading>
      </div>
      <gds-spacer classes="spacer-50"></gds-spacer>
      <gds-heading
        class="A3-W6"
        classes="heading-7"
        [data]="{
          heading: qrDetail
        }"
      ></gds-heading>
      <gds-spacer classes="spacer-50"></gds-spacer>
    </div>
  </div>
  <!--Print-Qr-->
  <div class="box box-info" *ngIf="dateShow">
    <div class="box-body" *ngFor="let data of ticketDetails">
      <div class="col-md-3">
        <div class="align-left">
          <div class="image-sec">
            <gds-poi-feature
              [data]="{
                icon: data?.icons,
                heading: data?.product_name,
                description: dateEntry + getDate(data?.valid_from)
              }"
            >
            </gds-poi-feature>
          </div>
        </div>
      </div>
    </div>
    <gds-spacer classes="spacer-70"></gds-spacer>
  </div>
  <!--Share-Qr-->
  <div class="box box-info" *ngIf="!dateShow">
    <div class="box-body" *ngFor="let data of ticketDetails">
      <div class="col-md-3">
        <div class="align-left">
          <div class="image-sec">
            <gds-poi-feature
              [data]="{
                icon: data?.icons,
                heading: data?.product_name
              }"
            >
            </gds-poi-feature>
          </div>
        </div>
      </div>
    </div>
    <gds-spacer classes="spacer-70"></gds-spacer>
  </div>
</div>
