import { OverlayRef } from '@angular/cdk/overlay';

export class ToastRef {
  constructor(private readonly overlay: OverlayRef) {}

  /**
   * close - to close the overlay
   */
  close(): void {
    this.overlay.dispose();
  }

  /**
   * isVisible() - To check the visibility
   * @returns overlay
   */

  isVisible(): any {
    return this.overlay && this.overlay.overlayElement;
  }

  /**
   * getPosition - To fetch the position of overlay
   * @returns to get position of overlay element
   */
  getPosition(): any {
    return this.overlay.overlayElement.getBoundingClientRect();
  }
}
