import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
  IAddress,
  IGuestsResponse,
  MsGuestsService,
} from '@upr-web-primer/ms-guests';
import { NotificationService } from '@upr-web-primer/notification';
import { UrlInfoService } from '@upr-web-primer/url-info';
import _, { get } from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import {
  EmailPasswordPattern,
  PhonePattern,
} from '../../../config/pattern.constant';
import { environment } from '../../../environments/environment';
import { AppDataService } from '../../services/app-data.service';
import { CommonUtilityService } from '../../services/common-utility.service';
import { ToastService } from '../../shared/service/toast.service';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss'],
})
export class AddAddressComponent implements OnInit, AfterViewInit {
  page: any;
  pageLabels: any;
  backLink: string;
  prefixes: any;
  addAddressForm: FormGroup;
  emailRegex = EmailPasswordPattern.EMAIL_SIGNUP;
  phoneNumberRegex = PhonePattern.PHONE_SIGNUP;
  primaryOfferSelected = false;
  termsConditionSelected = false;
  userProfileData: any;
  termsErrorMsg = '';
  guestId: any;
  postalPin: any;
  validPostalResponse: any;
  prefectureDetailsInfo: any;
  showPrefectureDropdownOptions: any = [];
  selectedPrefectureList: any;
  postalErrorMsg: any;
  prefectureErrorMsg: any;
  cityErrorMsg: any;
  address1ErrorMsg: any;
  phoneErrorMsg: any;
  postalCodeLengthError = '';
  commonErrorMsg = '';
  prefectureFieldLabel = '';
  primaryVal = false;
  showDropdown = true;
  showEmptyDropdown = false;
  savedSourceId: any;

  private route: ActivatedRoute;
  private commonUtilityService: CommonUtilityService;
  private guestsService: MsGuestsService;
  private notification: NotificationService;
  private urlInfoService: UrlInfoService;
  @ViewChild('addAddressModalContent') addAddressModalContent!: ElementRef;

  constructor(
    private injector: Injector,
    private formBuilder: FormBuilder,
    public cdRef: ChangeDetectorRef,
    private toastService: ToastService,
    private title: Title,
    private appDataService: AppDataService,
    @Inject('AppRoutes') public appRoutes: any,
    @Inject('Ranges') public ranges: any,
    @Inject('GlobalDefaults') public globalDefault: any,
    @Inject('sourceKeys') public sourceKeys: any,
    private cookieService: CookieService
  ) {
    this.setDependencies();
    this.page = this.route.snapshot.data.page;
    this.pageLabels = this.commonUtilityService.getResolvedPageData();
    this.title.setTitle(this.pageLabels?.['page.addressAdd']);
    this.backLink =
      this.urlInfoService.getUrlPrefix() + '/' + this.appRoutes.MY_PROFILE;
  }

  /**
   * ngOnInit-It will initialize the  Page
   */

  ngOnInit(): void {
    this.createForm();
    this.loadPrefecture();
    this.notification.hide();
    this.loadUserInfo().then((bool) => {
      if (!bool) {
        this.commonUtilityService.changeRoute(this.appRoutes.LOGIN, {
          signoff: true,
        });
      }
    });
    this.commonUtilityService.initPageDDL(this.page);
  }

  ngAfterViewInit(): void {
    this.appDataService.modalContentHeightCalc(
      this.addAddressModalContent,
      164
    );
  }

  @HostListener('window:resize')
  onResize(): any {
    this.appDataService.modalContentHeightCalc(
      this.addAddressModalContent,
      164
    );
  }

  public loadUserInfo(): Promise<boolean> {
    return this.guestsService.profile
      .getByGuestId(this.guestsService.helper.getGuestId())
      .then((userData: IGuestsResponse) => {
        if (!userData) {
          return false;
        }
        this.userProfileData = userData;
        this.checkAddress();
        return true;
      })
      .catch((errors: any) => {
        if (errors.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
        return false;
      });
  }

  /**
   * checkAddress-Check the Address Primary_Value
   */
  checkAddress(): void {
    if (this.userProfileData?.personal_info?.addresses?.length == 0) {
      this.primaryVal = true;
    } else {
      this.primaryVal = false;
    }
    this.addAddressForm?.patchValue({
      primary: this.primaryVal,
    });
  }

  private setDependencies(): void {
    this.route = this.injector.get(ActivatedRoute);
    this.commonUtilityService = this.injector.get(CommonUtilityService);
    this.guestsService = this.injector.get(MsGuestsService);
    this.notification = this.injector.get(NotificationService);
    this.urlInfoService = this.injector.get(UrlInfoService);
  }

  /**
   * createForm-It will create a Add_Address_Form
   */

  createForm(): void {
    this.addAddressForm = this.formBuilder.group({
      postalCode: new FormControl('', [
        <any>Validators.required,
        Validators.minLength(7),
        Validators.maxLength(7),
        this.noWhitespaceValidator(),
      ]),
      prefecture: new FormControl('', [Validators.required]),
      city: new FormControl('', [
        <any>Validators.required,
        this.noWhitespaceValidator(),
      ]),
      address1: new FormControl('', [
        <any>Validators.required,
        this.noWhitespaceValidator(),
      ]),
      address2: new FormControl(),
      primary: new FormControl(),
    });
  }

  /**
   * noWhitespaceValidator-It won't allow white space from  Add_Address_Form
   */

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  /**
   * getErrorMessageInfo-It will get the error from the events
   */

  getErrorMessageInfo(event: any): any {
    const fieldInfo = event?.target?.id;
    switch (fieldInfo) {
      case this.globalDefault.POSTALCODE:
        if (event?.target?.value?.length !== this.ranges?.MAX?.POSTALCODE) {
          this.addAddressForm.reset();
          this.showEmptyDropdown = true;
          this.showDropdown = false;
          this.cdRef.detectChanges();
          return (this.postalErrorMsg = this.pageLabels?.['errCode.E013']);
        } else {
          this.showDropdown = true;
          this.showEmptyDropdown = false;
          this.selectedPrefectureList = '';
        }
        break;
      case this.globalDefault.PREFECTURE:
        this.addAddressForm
          ?.get('prefecture')
          ?.patchValue(this.selectedPrefectureList);
        this.prefectureErrorMsg = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.CITY:
        this.cityErrorMsg = this.getErrorMessage(fieldInfo);
        break;
      case this.globalDefault.ADDRESS1:
        this.address1ErrorMsg = this.getErrorMessage(fieldInfo);
        break;
    }
    this.cdRef.detectChanges();
  }

  /**
   * getErrorMessage-It will get the error-msg from the events
   */

  getErrorMessage(field: any): string {
    const fieldName = this.addAddressForm?.get(field);
    const emptyCheck = this.pageLabels?.['errCode.E015'];
    fieldName?.markAsTouched();

    if (!fieldName?.valid && (fieldName?.dirty || fieldName?.touched)) {
      switch (field) {
        case this.globalDefault.CITY: {
          if (!this.addAddressForm.value.city) return emptyCheck;
          else return this.pageLabels?.['errCode.E015'];
        }

        case this.globalDefault.ADDRESS1: {
          if (!this.addAddressForm.value.address1) return emptyCheck;
          else return this.pageLabels?.['errCode.E015'];
        }
        case this.globalDefault.PREFECTURE: {
          if (!this.addAddressForm.value.prefecture) return emptyCheck;
          else return this.pageLabels?.['errCode.E015'];
        }
      }
    }
    return '';
  }

  /**
   * loadPrefecture-It will load the Prefecture
   */

  loadPrefecture(): void {
    this.commonUtilityService
      .loadPrefectureList()
      .then((res) => {
        this.prefectureDetailsInfo = res;
        this.sortAddress();
        this.showPrefectureDropdownOptions = _.map(
          this.prefectureDetailsInfo,
          (optionInfo: any) => {
            return { label: optionInfo.name, value: optionInfo };
          }
        );
        this.cdRef.detectChanges();
      })
      .catch((errors: any) => {
        if (errors.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
      });
  }

  /**
   * matchPrefecture-It will load the Prefecture based on name
   */

  matchPrefecture(event: any): void {
    this.prefectureDetailsInfo?.filter((res: any) => {
      if (res.name === event.detail.name) {
        this.selectedPrefectureList = res;
      }
    });
  }

  /**
   * onPrimaryClick-It will set the primary Address
   */

  onPrimaryClick(event: any): void {
    if (event?.target?.id === this.globalDefault?.BTN_PRIMARY) {
      this.primaryOfferSelected =
        event?.detail === this.globalDefault?.CHECKED ? true : false;

      this.addAddressForm.patchValue({
        primary: this.primaryOfferSelected,
      });
    }
  }

  /**
   * backtoAddressList-It will navigate to My_Address Page
   */

  backtoAddressList(): void {
    this.commonUtilityService.changeRoute(this.appRoutes.MY_ADDRESSES);
  }

  /**
   * getPostalCodes-Based on postal code will return the prefecture,city,line1
   */

  getPostalCodes(): any {
    this.postalPin = this.addAddressForm.get('postalCode')?.value;
    if (
      this.addAddressForm.value.postalCode.length !== this.ranges.MAX.POSTALCODE
    ) {
      this.addAddressForm.reset();
      this.cdRef.detectChanges();
      return (this.postalErrorMsg = this.pageLabels?.['errCode.E013']);
    } else {
      this.postalErrorMsg = '';
    }
    this.commonUtilityService.loadPostal(this.postalPin).then(
      (res: any) => {
        this.validPostalResponse = res;
        this.showDropdown = true;
        this.showEmptyDropdown = false;
        this.addAddressForm
          .get('city')
          ?.patchValue(this.validPostalResponse?.[0]?.region);
        this.addAddressForm
          .get('address1')
          ?.patchValue(this.validPostalResponse?.[0]?.locality);
        _.filter(this.prefectureDetailsInfo, (pdiRes: any) => {
          if (pdiRes.name === this.validPostalResponse[0].municipality) {
            this.selectedPrefectureList = pdiRes;
            this.addAddressForm
              ?.get('prefecture')
              ?.patchValue(this.selectedPrefectureList);
            this.cityErrorMsg = '';
            this.address1ErrorMsg = '';
            this.prefectureErrorMsg = '';
          }
        });
        this.cdRef.detectChanges();
      },
      (err) => {
        if (err.error.title === this.globalDefault.NOT_FOUND) {
          this.showEmptyDropdown = true;
          this.showDropdown = false;
          this.postalErrorMsg = this.pageLabels?.['errCode.E014'];
          this.addAddressForm.get('city')?.patchValue('');
          this.addAddressForm.get('address1')?.patchValue('');
          this.cityErrorMsg = '';
          this.address1ErrorMsg = '';
          this.prefectureErrorMsg = '';
          this.cdRef.detectChanges();
        }
        if (err.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
      }
    );
  }

  /**
   * onSubmit-It will save the form value to API
   */

  onSubmit(): void {
    this.savedSourceId = this.commonUtilityService?.getSourceId(
      this.sourceKeys?.WEB_EDITADDRESS
    );
    this.commonUtilityService.validateAllFormFields(this.addAddressForm);
    const request: IAddress = {
      first_name: this.userProfileData?.personal_info?.contacts[0].first_name,
      last_name: this.userProfileData?.personal_info?.contacts[0].last_name,
      postal_code: get(this.addAddressForm, 'value.postalCode', ''),
      state: get(this.addAddressForm, 'value.prefecture.name', ''),
      city: get(this.addAddressForm, 'value.city', ''),
      country: get(this.addAddressForm, 'value.prefecture.code', ''),
      country_code: get(
        this.addAddressForm,
        'value.prefecture.country_code',
        ''
      ),
      address_line1: get(this.addAddressForm, 'value.address1', ''),
      address_line2: get(this.addAddressForm, 'value.address2', ''),
      primary: get(this.addAddressForm, 'value.primary', ''),
    };
    this.guestsService.personal
      .createAddress(request, this.savedSourceId)
      .then(() => {
        this.toastService.show({
          text: this.pageLabels?.['addAddress.addSnack'],
          type: this.globalDefault?.SUCCESS,
        });
        this.commonUtilityService.setCommonData(this.ranges.SUCCESS_STATUS);
        this.commonUtilityService?.changeRoute(this.appRoutes?.MY_ADDRESSES);
      })
      .catch((error) => {
        if (error?.status == this.globalDefault.ERROR_409) {
          this.commonUtilityService.setCommonData(error?.status);
          this.commonUtilityService?.changeRoute(this.appRoutes?.MY_ADDRESSES);
        }
        if (error.status == this.globalDefault.ERROR_401) {
          this.commonUtilityService.setUser();
          localStorage.clear();
          sessionStorage.clear();
          this.cookieService.deleteAll('/', environment.cookieSubDomain);
          const redirectUrl = this.commonUtilityService.getOidcRedirectionUrl();
          this.commonUtilityService.redirectToOidcAppEndSession(redirectUrl);
        }
      });
  }

  /**
   * sortAddress-Sort Address based on Iso-Numeric
   */
  sortAddress(): void {
    if (Array.isArray(this.prefectureDetailsInfo)) {
      this.prefectureDetailsInfo.sort((a: any, b: any) => {
        const extractNumeric = (obj: any): string =>
          (obj.iso_numeric || '').replace(/^\D+/g, ''); // Specify the return type here
        const a1 = parseFloat(extractNumeric(a));
        const b1 = parseFloat(extractNumeric(b));
        return a1 - b1;
      });
    } else {
      console.error('prefectureDetailsInfo is not an array.');
    }
  }
}
