import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CommonUtilityService } from '../../../services/common-utility.service';
@Component({
  selector: 'app-confirm-link-tickets',
  templateUrl: './confirm-link-tickets.component.html',
  styleUrls: ['./confirm-link-tickets.component.scss'],
})
export class ConfirmLinkTicketsComponent implements OnInit {
  pageLabels: any;
  userName: string;
  showContent = false;
  content: any;

  constructor(
    private route: ActivatedRoute,
    private title: Title,
    @Inject('AppRoutes') public appRoutes: any,
    private common: CommonUtilityService,
    @Inject('GlobalDefaults') private globalDefault: any
  ) {
    this.title.setTitle(this.pageLabels?.['page.dashboard']);
  }

  /**
   * ngOnInit - Get username from url, on basis of username shwoContent is true or false
   * Get Data from get travel party member
   */

  ngOnInit(): void {
    if (this.common?.getResolvedPageData) {
      this.pageLabels = this.common.getResolvedPageData();
    }
    this.userName = this.route.snapshot?.queryParams?.guest_name;
    this.showContent =
      this.userName != this.globalDefault.NEW_TPM ? true : false;
    // this.content = this.common.getTravelPartyMemberData();
  }

  /**
   * goToPage - navigation as per selected type
   * @param type - Type to route
   */

  goToPage(type: any): any {
    const guest_id = '4d3fd1e0-1610-11ee-ad4f-d1d5f163fdc9';
    switch (type) {
      case this.globalDefault.CLOSE:
        this.common.changeRoute(this.appRoutes.DASHBOARD);
        break;

      case this.globalDefault.BACK_NAVIGATION:
        this.common.changeRoute(this.appRoutes.REGISTER_TICKETS, {
          guest_id: guest_id,
        });
        break;

      case this.globalDefault.REGISTER:
        this.common.changeRoute(this.appRoutes.CONFIRM_REGISTRATION, {
          guest_name:
            this.userName != this.globalDefault.NEW_TPM
              ? this.userName
              : this.content.lastName + ' ' + this.content.firstName,
        });
        break;
    }
  }

  /**
   * navigatePage - on button click
   * @param event - as per button click, check the action and pass to gotoPage function
   */

  navigatePage(event: any): any {
    const type =
      event.target?.data.action === this.pageLabels?.['linkticketReg.backCTA']
        ? this.globalDefault.BACK_NAVIGATION
        : this.globalDefault.REGISTER;
    this.goToPage(type);
  }
}
